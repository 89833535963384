import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CostSaving } from '../models/cost-saving';
import { McostType } from '../models/mcost-type';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
@Injectable({
  providedIn: 'root'
})

export class CostsavingdetailsService {

  constructor(private http: HttpClient) { }

  private GetAllData: string = environment.baseURL + "api/Master/GetAllData";
  private urlGetCostSavingDetail: string = environment.baseURL + "api/CostSaving/GetCostSavingDetailById";
  private urlPostCostSavingDetail: string = environment.baseURL + "api/CostSaving/InsertUpdateCostSavingDetail";

  getCostType(areaId: number, lineId: number): Observable<McostType[]> {
    return this.http.get<McostType[]>(this.GetAllData + '?masterTypeId='+EnumMasterType.CostType + "&businessAreaId="+areaId+"&businessLineId="+lineId)
  }

  getCostSavingByIdeaNSolutionId(obj): Observable<CostSaving> {
    let _ideaId = Number(obj.ideaId)
    let _solutionId = Number(obj.solutionId)
    let _userId = Number(obj.userId)
    return this.http.get<CostSaving>(this.urlGetCostSavingDetail + '?ideaId=' + _ideaId + '&solutionId=' + _solutionId + '&userId=' + _userId);
  }

  postCostSavingDetailData(data) {
    return this.http.post(this.urlPostCostSavingDetail, data);
  };
}



