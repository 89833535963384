import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ActualUnitsService } from 'src/app/services/actual-units.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import * as XLSX from 'xlsx';
import { Router } from "@angular/router";
import { serverExpression } from 'src/app/validationExpressions/Expressions';

let countryCode;
@Component({
  selector: 'app-actual-unit',
  templateUrl: './actual-unit.component.html'
  // styleUrls: ['./actual-unit.component.css']
})

export class ActualUnitComponent implements OnInit {
  public FilterMonth: any = [];
  public FilterData: any = [];
  public FilterData1: any = [];
  public FilterData2: any = [];
  public FilterData3: any = [];
  public FilterData4: any = [];
  public FilterData5: any = [];
  public FilterData6: any = [];
  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public Editable: boolean = true;
  public modifiedActualUnitRows = [];
  public alertErrorBox = [];
  public errorMessage = []
  public roleMasterData = [];
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;

  public sizeToFitRefresh: number = 0;
  public showGrid = false;

  submitResponse: any;
  refObject: any;
  receivedData: any;
  headerYears: any = [];
  startYear: any = "";
  monthsArr: any = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep"];
  unsvdParams: boolean = false;
  importExcelData: any;
  hideExportImport: boolean = true;

  s1: any = ["Actual Unit"];


  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";

  constructor(
    private actualUnitsService: ActualUnitsService,
    private router: Router,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private toast: ToasterService) {
    this.frameworkComponents = { doublingEditor: DoublingEditorComponent };
  }
  

  ngOnInit(): void {
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.updateYearDropdown();
          this.disableBusinessLineDropdown = true;
        }
        this.showGrid = true;
      }
    )

    this.adminSubscription = this.sharedService.actuals.subscribe(() => {
      this.submit();
    })

    countryCode = this.localStorageService.get('countryCode');
    this.bindActualUnitData();
  }

  updateBusinessLine() {
    this.hideExportImport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.bindActualUnitData();
  }

  updateYearDropdown() {
    this.startYear = "";
    if (this.selectBusinessLine != "") {
      this.startYear = 2021;
      this.hideExportImport = false;
      for (let val = 0; val < 20; val++) {
        this.headerYears.push(this.startYear + val)
      }
    }
    else {
      this.startYear = "";
      this.headerYears = [];
      this.hideExportImport = true;
      this.showGrid = true;
    }
    this.bindActualUnitData();
  }


  updateActualUnitGrid() {
    this.checkUnSavedChanges();
    this.bindActualUnitData();
  }

  checkUnSavedChanges() {
    if (this.unsvdParams) {
      let changeMessage = "There are unsaved changes \n Do You want to save the changes ?";
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: changeMessage, okBtn: "Yes", cancelBtn: "No" } })

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.submit();
        }
        else {
          this.onCancel();
        }
      })
    }
  }

  exportGridData() {
    this.showGrid = false;
    this.SelectFilterOnIsActive();
    this.gridApi.exportDataAsExcel({
      processCellCallback(params) {
        return params.value ?? 0;
      },
      allColumns: true,
      protection: true,
      sheetName: "Actual Unit",
      fileName: "Actual Unit",
    });
    this.RemoveFilterOnIsActive();
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage, "success");
  }

  SelectFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive')!;
    instance.setModel({ values: ['No'] });
    this.gridApi.onFilterChanged();
  }

  RemoveFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive')!;
    instance.setModel(null);
    this.gridApi.onFilterChanged();
  }

  importExcel(event: any) {
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      //Validation for excel type.
      let ext = file.name.split('.').pop();
      if (ext != "xlsx") {
        this.toast.notify(serverMessage.excelnotImportMessage, "error"); this.onCancel();
      }
      else {
        // To check the size of the Excel file
        if (event.target.files[0].size / 1024 / 1024 > 10) {
          this.toast.notify(serverMessage.excelImportMessage + ' is More Than 10MB', "error"); this.onCancel();
        }
        else {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(file);
          fileReader.onload = (e) => {
            let workBook = XLSX.read(fileReader.result, { type: 'binary' });
            let workSheet = workBook.SheetNames;

            this.importExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[workSheet[0]]);
            //If user uploads different excel file
            if (workBook.SheetNames[0] !== this.s1[0]) { this.toast.notify(serverMessage.WorksheetError2, "error"); this.onCancel(); }
            else {
              //if user uploads blank excel file
              if (this.importExcelData.length == 0) { this.toast.notify(serverMessage.Blanksheeterror, "error"); this.onCancel(); }

              else {

                //validations for headers-ActualUnitId, ProductId, ParentId, Year, Product and IsReadonly-headers&Column data.
                
                for (let i = 0; i < this.importExcelData.length; i++) {
                  if (this.importExcelData[i].ActualUnitId == "") {
                    this.importExcelData[i].ActualUnitId = null;
                  }
                  let Product = this.importExcelData[i].Product;
                  let IsReadOnly1 = this.importExcelData[i].IsReadOnly;
                  let ActualUnitId = this.importExcelData[i].ActualUnitId;
                  let ProductId = this.importExcelData[i].ProductId;
                  let ParentId = this.importExcelData[i].ParentId;
                  let Year = this.importExcelData[i].Year;
                  const BreakError = {};

                  this.FilterData = (this.rowData).filter(obj => obj.IsActive == 'No');

                  this.FilterData6 = (this.FilterData).filter(obj => obj.ActualUnitId == ActualUnitId &&
                    obj.ProductId == ProductId &&
                    obj.ParentId == ParentId && obj.Year == Year && obj.ProductTitle == Product);

                  this.FilterData1 = (this.FilterData).filter(obj => obj.ActualUnitId == ActualUnitId && obj.ProductTitle == Product);
                  this.FilterData2 = (this.FilterData).filter(obj => obj.ProductId == ProductId && obj.ActualUnitId == ActualUnitId);
                  this.FilterData3 = (this.FilterData).filter(obj => obj.ParentId == ParentId && obj.ActualUnitId == ActualUnitId);
                  this.FilterData4 = (this.FilterData).filter(obj => obj.Year == Year && obj.ActualUnitId == ActualUnitId);
                  this.FilterData5 = (this.FilterData).filter(obj => obj.ProductTitle == Product && obj.ActualUnitId == ActualUnitId);


                  if (Product == undefined || IsReadOnly1 == undefined || ActualUnitId === undefined || ProductId == undefined || ParentId == undefined || Year == undefined) {
                    this.toast.notify(serverMessage.HeadereTitleNotMatched, "error"); this.onCancel();
                    break;
                  }

                  else {
                    if (serverExpression.dotnadno.test(IsReadOnly1) || IsReadOnly1.toLowerCase() !== 'no' && IsReadOnly1.toLowerCase() !== 'NO' && IsReadOnly1.toLowerCase() !== 'No' && IsReadOnly1.toLowerCase() !== 'nO') {
                      this.toast.notify(serverMessage.IsReadOnlyError, "error"); this.onCancel();
                      break;
                    }

                    else {
                      if (this.FilterData1 == 0) {
                        this.toast.notify(serverMessage.ActualUntIDProductError, "error"); this.onCancel();
                        break ;
                      }

                      else {
                        if (this.FilterData2 == 0) {
                          this.toast.notify(serverMessage.ProductIdError, "error"); this.onCancel();
                          break ;
                        }

                        else {
                          if (this.FilterData3 == 0) {
                            this.toast.notify(serverMessage.ParentIdError, "error"); this.onCancel();
                            break ;
                          }

                          else {
                            if (this.FilterData4 == 0) {
                              this.toast.notify(serverMessage.YearError, "error"); this.onCancel();
                              break ;
                            }

                            else {
                              if (this.FilterData5 == 0) {
                                this.toast.notify(serverMessage.ProductTitleError, "error"); this.onCancel();
                                break ;
                              }

                              else {

                                //Validations for Month Headers and Month column Data 
                                let monthColumns = [];
                                if (Year != "") {
                                  for (let val = 0; val < 12; val++) {
                                    if (val <= 2) {
                                      monthColumns.push(this.monthsArr[val] + String(Year - 1).slice(-2));
                                    }
                                    else { monthColumns.push(this.monthsArr[val] + String(Year).slice(-2)); }
                                  }
                                }
                                let MonthsData = [];
                                MonthsData = monthColumns;

                                for (let monthss of MonthsData) {
                                  let ExcelHeaderYearData = this.importExcelData[i][monthss];
                                  const str = String(ExcelHeaderYearData);

                                  if (ExcelHeaderYearData === undefined) {
                                    this.toast.notify(serverMessage.HeadereTitleNotMatched, "error"); this.onCancel();
                                     throw BreakError;
                                  }
                                  else {
                                    if (!serverExpression.dotnadno.test(ExcelHeaderYearData)) {
                                      this.toast.notify(serverMessage.AlphabetsError, "error"); this.onCancel();
                                      throw BreakError;
                                    }

                                    else {
                                      if (str.length > 10) {
                                        this.toast.notify(serverMessage.RangeLengthError, "error"); this.onCancel();
                                        throw BreakError;
                                      }
                                    }
                                  }
                                }
                              }
                              //Validation for Success message if excel file successfully processed without errors.
                              if (i == this.importExcelData.length - 1) {
                                this.excelDatatoSend(this.importExcelData);
                                this.toast.notify(serverMessage.excelImportMessage, "success"); break ;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  excelDatatoSend(importededData: any) {
    let ProductArray = [];
    importededData.forEach(node => {
      node.ActualUnitId = node.ActualUnitId == '' ? 0 : node.ActualUnitId;
      node["IsActive"] = node.IsReadOnly;
      ProductArray.push(node);
      let index = this.modifiedActualUnitRows.findIndex(obj => obj["ProductId"] == node["ProductId"] && obj["Year"] == node["Year"]);
      if (this.modifiedActualUnitRows.length == 0 || index < 0) {
        this.modifiedActualUnitRows.push(node);
      }
      else {
        this.modifiedActualUnitRows.forEach(
          (row, index) => {
            if (row["ProductId"] == node["ProductId"] && row["Year"] == node["Year"]) {
              this.modifiedActualUnitRows[index] = node;
            }
          }
        )
      }
    });
    if (ProductArray.length != 0) {
      this.Editable = false;
      this.loadActualUnitGrid();
    }
  }


  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.unsvdParams = false;
    this.modifiedActualUnitRows = [];
    this.hideExportImport = false;
    this.unsvdAdminChanges = false;
    this.unSavedChanges.emit(valToEmit);
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.bindActualUnitData();
    this.Editable = true;

  }

  bindActualUnitData() {
    this.showGrid = false;
    this.actualUnitsService.getAllActualUnits(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((data) => {
      this.getResponse = data;

      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedData = data.Data;
        if (this.receivedData == "" || this.startYear == "") {
          this.rowData = [];
          this.showGrid = true;
          return;
        }
        let productArray = [];
        this.receivedData.forEach((value, index) => {
          if (value.ActualUnitId == null) {
            this.headerYears.forEach(element => {
              if (!this.headerYears) {
                this.toast.notify(serverMessage.excelnotImportMessage, "error");
              }
              else {
                let productRow = {};
                productRow["ActualUnitId"] = value.ActualUnitId;
                productRow["ProductTitle"] = value.ProductTitle;
                productRow["ProductId"] = value.ProductId;
                productRow["IsActive"] = value.IsActive ? 'No' : 'Yes';
                productRow["ParentId"] = value.ParentId;
                productRow["CreatedBy"] = value.CreatedBy;
                productRow["CreatedOn"] = value.CreatedOn;
                productRow["Year"] = element;

                for (let val = 0; val < 12; val++) {
                  if (val <= 2) {
                    productRow[this.monthsArr[val] + String(this.startYear - 1).slice(-2)] = null;

                  }
                  else {
                    productRow[this.monthsArr[val] + String(this.startYear).slice(-2)] = null;
                  }
                }

                productArray.push(productRow);
                if (productRow["IsActive"] == 'No') {
                  let index = this.modifiedActualUnitRows.findIndex(obj => obj["ProductId"] == productRow["ProductId"] && obj["Year"] == productRow["Year"]);
                  if (this.modifiedActualUnitRows.length == 0 || index < 0) {
                    this.modifiedActualUnitRows.push(productRow);
                  }
                  else {
                    this.modifiedActualUnitRows.forEach(
                      (row, index) => {
                        if (row["ProductId"] == productRow["ProductId"] && row["Year"] == productRow["Year"]) {
                          this.modifiedActualUnitRows[index] = productRow;
                        }
                      }
                    )
                  }
                }
              }
            });
          }
          else {
            let productRow = {};
            productRow["ActualUnitId"] = value.ActualUnitId;
            productRow["ProductTitle"] = value.ProductTitle;
            productRow["ProductId"] = value.ProductId;
            productRow["IsActive"] = value.IsActive ? 'No' : 'Yes';
            productRow["ParentId"] = value.ParentId;
            productRow["CreatedBy"] = value.CreatedBy;
            productRow["CreatedOn"] = value.CreatedOn;
            productRow["Year"] = value.Year;
            productRow[this.monthsArr[0] + String(this.startYear - 1).slice(-2)] = value.Month1;
            productRow[this.monthsArr[1] + String(this.startYear - 1).slice(-2)] = value.Month2;
            productRow[this.monthsArr[2] + String(this.startYear - 1).slice(-2)] = value.Month3;
            productRow[this.monthsArr[3] + String(this.startYear).slice(-2)] = value.Month4;
            productRow[this.monthsArr[4] + String(this.startYear).slice(-2)] = value.Month5;
            productRow[this.monthsArr[5] + String(this.startYear).slice(-2)] = value.Month6;
            productRow[this.monthsArr[6] + String(this.startYear).slice(-2)] = value.Month7;
            productRow[this.monthsArr[7] + String(this.startYear).slice(-2)] = value.Month8;
            productRow[this.monthsArr[8] + String(this.startYear).slice(-2)] = value.Month9;
            productRow[this.monthsArr[9] + String(this.startYear).slice(-2)] = value.Month10;
            productRow[this.monthsArr[10] + String(this.startYear).slice(-2)] = value.Month11;
            productRow[this.monthsArr[11] + String(this.startYear).slice(-2)] = value.Month12;
            productArray.push(productRow);
          }
        })
        this.rowData = productArray.filter(obj => obj.Year == this.startYear);
        this.loadActualUnitGrid();
      }
    });
  }

  loadActualUnitGrid() {
    let monthColumnsGroup = [];
    if (this.startYear != "") {
      for (let val = 0; val < 12; val++) {
        if (val <= 2) {
          monthColumnsGroup.push(this.monthsArr[val] + String(this.startYear - 1).slice(-2));
        }
        else {
          monthColumnsGroup.push(this.monthsArr[val] + String(this.startYear).slice(-2));
        }
      }
    }
    this.columnDefs = [
      {
        headerName: "ActualUnitId",
        field: "ActualUnitId",
        tooltipField: "ActualUnitId",
        headerTooltip: "ActualUnitId",
        editable: false,
        hide: true
      },
      {
        headerName: "ProductId",
        field: "ProductId",
        tooltipField: "ProductId",
        headerTooltip: "ProductId",
        editable: false,
        hide: false
      },
      {
        headerName: "ParentId",
        field: "ParentId",
        tooltipField: "ParentId",
        headerTooltip: "ParentId",
        editable: false,
        hide: false
      },
      {
        headerName: "Year",
        field: "Year",
        tooltipField: "Year",
        headerTooltip: "Year",
        editable: false,
        hide: true
      },
      {
        headerName: "Product",
        field: "ProductTitle",
        tooltipField: "ProductTitle",
        headerTooltip: "Product",
        editable: false,
        sortable: true
      }
    ]

    monthColumnsGroup.forEach((month, index) => {
      this.columnDefs.push({
        headerName: month,
        field: String(month),
        tooltipValueGetter: CurrencyCellRenderer,
        headerTooltip: month,
        //editable: this.checkEditable,
        editable: (params) => {
          if (params.data.IsActive == 'Yes') {

            return false;

          }

          else {

            return this.Editable;

          }

        },
        singleClickEdit: true,
        cellRenderer: CurrencyCellRenderer,
        cellEditor: "doublingEditor",
        minWidth: 50
      });
    });

    this.columnDefs.push({
      headerName: "IsReadOnly",
      field: "IsActive",
      tooltipField: "IsActive",
      headerTooltip: "IsReadOnly",
      editable: false,
      minWidth: 50,
      filter: 'agSetColumnFilter'
    })

    this.defaultColDef = {
      filter: true,
      sortable: true,
      editable: true,
      suppressMovable: true,
      singleClickEdit: true,
    };
    this.domLayout = 'autoHeight';
    this.showGrid = true;

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onCellValueChanged(params) {
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }

    this.unSavedChanges.emit(valToEmit);
    let rowPushed = false;

    this.unsvdParams = true;
    this.hideExportImport = true;

    if (this.modifiedActualUnitRows.length == 0) {
      this.modifiedActualUnitRows.push(params.data);
      rowPushed = true;
    }
    else {
      this.modifiedActualUnitRows.forEach(
        (row, index) => {
          if (row["ProductId"] == params.data["ProductId"] && row["Year"] == params.data["Year"]) {
            this.modifiedActualUnitRows[index] = params.data;
            rowPushed = true;
          }
        }
      )
    }
    if (!rowPushed) {
      this.modifiedActualUnitRows.push(params.data);
    }
  }

  checkEditable(params) {
    if (params.data.IsActive == 'Yes') {
      return false;
    }
    else {
      return true;
    }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  onClickCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.unSavedChanges.emit(valToEmit);
  }

  submit(event?: any) {
    this.dataToSend();
    let response = null;
    let postData: any;
    if (this.modifiedActualUnitRows.length != 0) {
      this.modifiedActualUnitRows = [];
      this.showGrid = false;
      postData = this.refObject;
      response = this.actualUnitsService.updateData(postData).toPromise()
        .then(res => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unsvdAdminChanges = false;
              this.unSavedChanges.emit(valToEmit);
              this.unsvdParams = false;
              // end;
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.modifiedActualUnitRows = [];
              this.hideExportImport = false;
              this.bindActualUnitData();
              this.Editable = true;
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.showGrid = true;
              return false;
              
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI           
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.showGrid = true;
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.showGrid = true;
          return false;
        })
    } else {
      // changes;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unsvdAdminChanges = false;
      this.unSavedChanges.emit(valToEmit);
      // end;
      return true;
    }
    return response;
  }

  dataToSend() {
    let ProductArray = [];
    this.modifiedActualUnitRows.forEach(node => {
      ProductArray.push({
        ActualUnitId: node["ActualUnitId"],
        ProductId: node["ProductId"],
        ParentId: node["ParentId"],
        BusinessAreaId: this.selectBusinessArea,
        BusinessLineId: this.selectBusinessLine,
        Year: node["Year"],
        Month1: node[this.monthsArr[0] + String(node["Year"] - 1).slice(-2)] == "" ? null : node[this.monthsArr[0] + String(node["Year"] - 1).slice(-2)],
        Month2: node[this.monthsArr[1] + String(node["Year"] - 1).slice(-2)] == "" ? null : node[this.monthsArr[1] + String(node["Year"] - 1).slice(-2)],
        Month3: node[this.monthsArr[2] + String(node["Year"] - 1).slice(-2)] == "" ? null : node[this.monthsArr[2] + String(node["Year"] - 1).slice(-2)],
        Month4: node[this.monthsArr[3] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[3] + String(node["Year"]).slice(-2)],
        Month5: node[this.monthsArr[4] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[4] + String(node["Year"]).slice(-2)],
        Month6: node[this.monthsArr[5] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[5] + String(node["Year"]).slice(-2)],
        Month7: node[this.monthsArr[6] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[6] + String(node["Year"]).slice(-2)],
        Month8: node[this.monthsArr[7] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[7] + String(node["Year"]).slice(-2)],
        Month9: node[this.monthsArr[8] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[8] + String(node["Year"]).slice(-2)],
        Month10: node[this.monthsArr[9] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[9] + String(node["Year"]).slice(-2)],
        Month11: node[this.monthsArr[10] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[10] + String(node["Year"]).slice(-2)],
        Month12: node[this.monthsArr[11] + String(node["Year"]).slice(-2)] == "" ? null : node[this.monthsArr[11] + String(node["Year"]).slice(-2)],
        IsActive: node.IsActive == 'Yes' ? 0 : 1,
        //CreatedBy: node["CreatedBy"] == null ? this.loggedInUser.UserId : node["CreatedBy"],
        //CreatedOn: node["CreatedOn"] == null ? new Date().toISOString() : node["CreatedOn"],
        CreatedBy:  node["CreatedBy"] ?? this.loggedInUser.UserId,
        CreatedOn: node["CreatedOn"] ?? new Date().toISOString(),
        ModifiedBy: this.loggedInUser.UserId,
        ModifiedOn: new Date().toISOString()
      })
    })
    this.refObject = ProductArray;
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }



}

//use to format the cell according to german currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
}




