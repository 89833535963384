import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PeoplePickerComponent),
    multi: true
  }],
})

export class PeoplePickerComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() disable: boolean = false
  @Input() labelName: string = "Label";
  @Input() singleSelection: boolean = true;
  @Input() minSearchLength: number = 1;
  @Input() searchedData: Array<any> = [];
  @Output() inputValChange = new EventEmitter<any>();

  email: string = "email";
  displayName: string = "displayName";
  firstName: string = "firstName"
  lastName: string = "lastName"
  department: string = "department"

  customControl = new FormControl();
  filterControl = new FormControl();
  users: Array<User> = [];

  dataSource: any = [];
  _dataSource: any = [];
  userData: string[] = [];
  @ViewChild('myInput') myInputField: ElementRef;

  value: any;
  onChange!: ((value: any) => void);
  constructor(private cd: ChangeDetectorRef) {

  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }
  
  ngOnChanges() {
    if (this.searchedData != undefined) {
      this.getSearchedData()
    }   
  }

  ngOnInit() {   
    if (this.disable) {
      this.customControl.disable()
    }
  }

  // To move the cursor automatically to the input field
  autofocus() {
    this.cd.detectChanges()
    setInterval(() => this.myInputField?.nativeElement.focus(), 500);
  }

  clearSearch() {
    this.filterControl.setValue("");
    this.users = this.dataSource;
  }

  // To get the selected user from the dropdown
  getDetails(user: any) {
    user.checked = !user.checked  
    if (!this.singleSelection) {
      if (user.checked) {
        this.dataSource.push(user)
        this.dataSource.forEach(obj => {
          let name = obj.displayName + "[" + obj.department + "]";
          this.userData.push(name);
        });
      }
      else {
        // To remove the user from the selected list if it is unselected
        this.dataSource = this.dataSource.filter((data: any) => data.email != user.email)
      }
    } else {
      if (user.checked) {
        this.dataSource = [user]
      } else {
        this.dataSource.pop()
      }
      this.userData.push(user.email);
    }

    // To send the data to the component using this reusable component
    this._dataSource = this.dataSource.map((data: any) => {
      return {
        [this.displayName]: data.displayName,
        [this.firstName]: data.firstName,
        [this.lastName]: data.lastName,
        [this.email]: data.email,
        [this.department]: data.department
      };
    })

    if (!this.singleSelection) {
      this.customControl.setValue(this.dataSource)
    }

    this.onChange(this._dataSource)
  }

  // To fetch the data containing the input character
  getSearchedData() {
    if (this.filterControl.value !== "") {
      if (this.searchedData) {
        try {
          this.users = this.searchedData.map((user: any) => {
            return {
              displayName: user[this.displayName],
              firstName: user[this.firstName],
              lastName: user.lastName,
              email: user[this.email],
              department: user[this.department],
              checked: false
            };
          })

          // To override the unchecked user in users data with same user in selected user
          if (this.dataSource != undefined) {
            this.dataSource.forEach((data: any) => {
              this.users.forEach((user: any) => {
                let index = this.users.indexOf(user);
                this.users[index] = data.email == user.email ? data : user;
              }
              )
            })
          }
        } catch (error) {

        }
      }
    }
  }

  // this will emit the searched input if the  search length > provided search length
  callParent() {
    if (this.filterControl.value !== "" && this.filterControl.value.length >= this.minSearchLength) {
      this.inputValChange.emit(this.filterControl.value)
    }

    // If the input search length is less than the provided in put the dropdown will show only the selected users
    if (this.filterControl.value === "" || this.filterControl.value.length < this.minSearchLength) {
      this.users = this.dataSource;
    }
  }

  // To make two user same if their email is same
  compare(o1: any, o2: any) {
    return o1 && o2 && o1.email === o2.email;
  }
}

export interface User {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  department: string;
  checked: boolean;
}
