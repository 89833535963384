import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { Help } from 'src/app/models/help';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { HelpService } from 'src/app/services/help.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { AuditLogService } from 'src/app/services/audit-log.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { UserInfoService } from 'src/app/services/user-info.service';
import { MenuService } from 'src/app/services/menu.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { default as moment } from 'moment';

export interface AuditObj {
  IdeaId: number,
  FromDate: Date,
  ToDate: Date,
  UserId: number
}

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AuditLogComponent implements OnInit, Dirty {
  public userPeoplePicker: FormGroup;
  public apiAuditData = [];
  public showGrid = false;
  public disableRemoveFltr: boolean = true;
  // this variable will contain all the users data from tbl_User of DB;
  public allUserList: any;
  public userId: any;
  public hasUnsavedChanges: boolean = false;
  //  user input search field;
  public searchInputUser = new FormControl();
  public userSearchData: any = [];
  public oldDataUser = "";
  public pageForm: FormGroup;
  public pageName: any;
  public pageData: any;
  public idea: any;
  public parentPages: any;
  public childPages: any;
  public childData = new Help();
  public parentData = new Help();
  public childId: any;
  public val: any;
  public selectParent: boolean;
  public ideaSubmitterId: any;
  public user: any;
  public gridApi: any;
  public popupParent: any;
  public mindate: Date;
  public disableDateTo: boolean = true;
  public defaultColDef: any;
  public columnDefinition: any;
  public ideaId: number;
  public disableAppyFilter: boolean = true;
  public disableDownload: boolean = true;
  public showPinner: boolean = true;
  public userPresent: boolean = false;
  public dateFromPresent: boolean = false;
  public dateToPresent: boolean = false;

  public paginationPageSize: any;  
  public excelStyles:any;
  public pageError = [];
  public editorStyle = {
    height: '160px'
  }

  @ViewChild('inputUserId') inputUser: ElementRef;

  constructor(private http: HttpClient,
    private cd: ChangeDetectorRef,
    private sharedService: SharedServiceService,
    private helpService: HelpService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private menuService: MenuService,
    private localStorageService: LocalStorageService,
    private auditLogService: AuditLogService,
    // service for the UserList;
    private userService: UserInfoService,
    private router: Router
  ) {
    this.popupParent = document.querySelector('body');
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  gridReady(params) {
    this.gridApi = params.api;
  }

  // this function is called directly from the grid to reset the overall dimension of the grid;
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  ngOnInit(): void {
    // Getting current ideaId from the LocalSessionStorge;
    this.ideaId = this.localStorageService.get('ideaId');
    // User People Picker;
    this.userPeoplePicker = this.formBuilder.group({
      userPeoplePicker: ['']
      // userPeoplePicker: [null, Validators.required]
    });
    // fetching all the userdetails from the tbl_User DB;
    this.userService.getUserList().subscribe((data) => {
      this.allUserList = data;
    });
    this.pageForm = this.formBuilder.group({     
      "parentId": [''],    
      "dateFrom": [''],
      "dateTo": [{ value: '', disabled: true }]
    });

    this.pageForm.get("dateFrom").valueChanges.subscribe(res => {
      if (res) {
        this.mindate = new Date(res)
        this.pageForm.get("dateTo").setValue('');
        this.pageForm.get("dateTo").enable();
        this.disableAppyFilter = false;
        this.dateFromPresent = true;

      } else {
        this.dateFromPresent = false;
        if (this.userPresent || this.dateFromPresent) {
          this.disableAppyFilter = false;
        } else {
          this.disableAppyFilter = true;
        }

        this.mindate = new Date(res);
        this.pageForm.get("dateTo").setValue('');
        this.pageForm.get("dateTo").disable();
      }
    });

    this.userPeoplePicker.get('userPeoplePicker').valueChanges.subscribe(res => {
      if (res) {
        this.disableAppyFilter = false;
        this.userPresent = true;
      } else {
        this.userPresent = false;
        if (this.userPresent || this.dateFromPresent) {
          this.disableAppyFilter = false;
        } else {
          this.disableAppyFilter = true;
        }
      }
    });

    this.sharedService.ideaSubmitterId.subscribe(
      (id) => {
        this.ideaSubmitterId = id;
      }
    );

    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
    });

    this.helpService.getAllData().subscribe(
      (res: any) => {
     
        let errorMsgeFromBack = res.Data.Message;
        if (res.StatusCode == 200) {
          this.parentPages = res.Data.filter((data: any) => {
            if (data.ParentId == 0) {
              return data;
            }
          })
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
    // Loading audit log on data on page load;
    this.applyFilter(0);
  }

  // will load the grid structure
  loadGridStructure() {
    this.showGrid = false;
    this.showPinner = true;
    this.defaultColDef = {     
      sortable: false,
      cellClass: 'multiline',
      suppressMovable: true,
      wrapText: true,
      autoHeight: true,
    };
    // column structure of the Grid;
    this.columnDefinition = [
      { headerName: "Idea Id", width: 80, headerTooltip: "Idea Id", field: 'IdeaId', tooltipField: "IdeaId", },
      { headerName: "Area", headerTooltip: "Area", field: 'Page', width: 200, tooltipField: "Page", filter: 'agSetColumnFilter', },
      {
        headerName: "Sub Area", headerTooltip: "Sub Area", field: 'SubPage', width: 200, filter: 'agSetColumnFilter',valueGetter: function (param) {
          if (param.data.SubPage != null) {
            let subTitle = param.data.SubPage;
            if (subTitle == "Target" || subTitle == "Reference") {
              subTitle = subTitle + " Scenario";
            }
            return subTitle;
          } else {
            return param.data.SubPage;
          }
        },
        cellRenderer: function (param) {
          if (param.data.SubPage != null) {
            let subTitle = param.data.SubPage;
            if (subTitle == "Target" || subTitle == "Reference") {
              subTitle = subTitle + " Scenario";
            }
            return subTitle;
          } else {
            return param.data.SubPage;
          }
        }, tooltipField: "SubPage"
      },

      {
        headerName: "Changed By", headerTooltip: "Changed By", field: 'FullName', filter: true, cellRenderer: function (param) {
          if (param.data.FullName != null) {
            let multilineVal = param.data.FullName.split("[").join('<br/> [');
            return multilineVal;
          } else {
            return param.data.FullName;
          }
        }, tooltipField: "FullName"
      },
      {
        headerName: "Current Value(s)", headerTooltip: "Current Value(s)", field: 'CurrentValues', filter: 'agTextColumnFilter',
        cellRenderer: function (param) {
          if (param.data.CurrentValues != null) {          
            return param.data.CurrentValues.split('\n').join('<br/>');
          } else {
            return param.data.CurrentValues;
          }
        }, tooltipField: 'CurrentValues'
      },
      {
        headerName: "Previous Value(s)", headerTooltip: "Previous Value(s)", field: 'OldValues', filter: 'agTextColumnFilter',
        cellRenderer: function (param) {
          if (param.data.OldValues != null) {           
            return param.data.OldValues.split('\n').join('<br/>');
          } else {
            return param.data.OldValues;
          }
        }, tooltipField: 'OldValues',
      },
      {
        headerName: "Changed On", headerTooltip: "Changed On", field: 'ChangedOn', width: 150, tooltipField: 'ChangedOn',  filter: "agDateColumnFilter", filterParams: {
          buttons: ['reset'],
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            let changedValue: any = moment(filterLocalDateAtMidnight).format('MM/DD/YYYY');
            let changedValue1: any = moment(cellValue).format('MM/DD/YYYY');
            changedValue = new Date(changedValue);
            changedValue1 = new Date(changedValue1);
            if (changedValue.getTime() === changedValue1.getTime()) {
              return 0;
            }
            if (changedValue1 < changedValue) {
              return -1;
            }
            if (changedValue1 > changedValue) {
              return 1;
            }
          },
          browserDatePicker: true,         
        },
      },
    ]
    this.excelStyles = [
      {
        id: 'multiline',
        alignment: { wrapText: true },
      },
    ]
  
    this.paginationPageSize = 10;
    this.showPinner = false;
    this.showGrid = true;
  } 

  // it changes the size of grid and the number of rows to display; called when the user select a value from dropdown;
  onPageSizeChanged() {
    this.gridApi.setDomLayout('normal');
    document.getElementById('auditPageId').style.height = '560px';
    let value = (<HTMLInputElement>document.getElementById('pageSize')).value;   
    let totalpages = this.gridApi.paginationGetRowCount();
    if (value == "all") {
      this.gridApi.paginationSetPageSize(Number(totalpages));
    }
    else {
      this.gridApi.paginationSetPageSize(Number(value));
    }
  }

  resetDate() {
    this.pageForm.get('dateFrom').setValue('');
    this.pageForm.get('dateTo').setValue('');
  }

  resetUser() {
    this.userSearchData = [];
    this.oldDataUser = "";
    this.userPeoplePicker.get('userPeoplePicker').setValue('');
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error });
  }


  // All below chnages are for User People Picker:
  // It will clear the search input field once the dropdown is closed;
  clearSearch(formField) {
    if (formField == "user") {
      if (this.oldDataUser != "") {
        this.userSearchData = [this.oldDataUser];
        this.userPeoplePicker.get("userPeoplePicker").setValue(this.oldDataUser);
      }
      this.searchInputUser.setValue("");
    }
  }

  // It will focus the cursor to the input search field;
  autofocus(field) {
    this.cd.detectChanges();
    if (field == "user") {
      setInterval(() => this.inputUser?.nativeElement.focus(), 500);
      this.inputUser.nativeElement.focus();
    }
  }

  // People Picker Functions;
  fetchUserData(input, bind, formField) {
    let searchInput: string = "";
    // bind == false; it means function is called from the HTML;
    // bind == true; it means function is called from the Class;
    if (!bind) {
      searchInput = input.target.value.toLowerCase();
    } else {
      searchInput = input.toLowerCase();
    }
    if (searchInput.length >= 4) {
      if (this.allUserList == undefined) {
        this.userService.getUserList().subscribe((data) => {
          this.allUserList = data;
          let userDetail = [];
          userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
          this.bindValueToSubRes(bind, formField, userDetail);
          this.fillSearchValue(formField, bind, userDetail);
        })
      } else {
        let userDetail = [];
        userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
        this.bindValueToSubRes(bind, formField, userDetail);
        this.fillSearchValue(formField, bind, userDetail);
      }
    }
    else {
      if (formField == "user") {
        if (this.oldDataUser == "") {
          this.userSearchData = []
        } else {
          this.userSearchData = [this.oldDataUser];
        }
        this.userPeoplePicker.get("userPeoplePicker").setValue("");
      }
    }
  }

  // this will fill the array with search data if found and set a value to null in the start of the search;
  fillSearchValue(formField, bind, userDetail) {
    if (formField == "user") {
      this.userSearchData = userDetail;
      if (!bind ) {
        this.userPeoplePicker.get("userPeoplePicker").setValue("");
      }
    }
  }

  // It will run a search operation in the userlist recieved from DB and return the user Information;
  searchUsrInUsrlst(userList, searchValue) {
    let userDetail = []
    userList.filter(data => {
      if (data.Email.toLowerCase().includes(searchValue)) {
        userDetail.push({
          Email: data.Email,
          FirstName: data.FirstName,
          FullName: data.FullName,
          GID: data.GID,
          IsActive: data.IsActive,
          LastName: data.LastName,
          OrgCode: data.OrgCode,
          RoleId: data.RoleId,
          RoleTitle: data.RoleTitle,
          UserDetails: data.UserDetails,
          UserId: data.UserId
        });
      }
    });
    return userDetail;
  }

  // It will bind the data to the submitter and user field on page load;
  bindValueToSubRes(bind, formField, userDetail) {
    if ((bind && formField == 'user') || (bind  && formField == "both")) {
      this.oldDataUser = userDetail[0];
      this.userPeoplePicker.get("userPeoplePicker").setValue(userDetail[0]);
      this.userId = this.userPeoplePicker.get("userPeoplePicker").value.UserId;
    } 
    // else if (bind  && formField == "both") {
    //   this.oldDataUser = userDetail[0];
    //   this.userPeoplePicker.get("userPeoplePicker").setValue(userDetail[0]);
    //   this.userId = this.userPeoplePicker.get("userPeoplePicker").value.UserId;
    // }
  }

  // It will assign the ID to submitter and user;
  assignValueId(userDetail, formField) {
    if (formField == "user") {
      this.oldDataUser = userDetail;
      this.userId = userDetail.UserId;
    }
  }

  removeFilter(params) {
    this.resetUser();
    this.resetDate();
    if (params == 2) {
      this.disableRemoveFltr = true;
    }
    this.applyFilter(2);

  }
 
  // User people picker function ended here;
  applyFilter(params) {

    this.showPinner = true;
    this.disableDownload = false;   
    let userFormValue = this.userPeoplePicker.value;
    let pageFormValue = this.pageForm.value;
    this.pageError = [];    
    let userId: number = userFormValue.userPeoplePicker == '' ? null : userFormValue.userPeoplePicker.UserId;
    let fromDate: any = pageFormValue.dateFrom == '' ? null : pageFormValue.dateFrom;
    let toDate: any = pageFormValue.dateTo;
    if (toDate == '' || toDate == undefined) {
      toDate = null;
    } else {
      toDate = moment(toDate).set({ 'hour': 23, 'minute': 59, 'second': 59 });
    }

    if (fromDate != null && toDate == null) {
      this.pageError.push({ "Date (To)": messages.cellEmptyErrorMesssage })
    };

    if (this.pageError.length) {
      this.showPinner = false;
      this.openAlertDialog(this.pageError);
    } else {
      let getObj: AuditObj = {
        IdeaId: this.ideaId,
        FromDate: fromDate,
        ToDate: toDate,
        UserId: userId
      };

      this.auditLogService.getAuditData(getObj).subscribe((data) => {
     
        if (data == null) {
          return;
        };       
        let statusCode = data.StatusCode;
        let errMsgFrmBack = data.Message;
        if (statusCode == 200) {
          let dataRecieved = data.Data;         
          this.apiAuditData = [];
          this.apiAuditData = dataRecieved.map((data) => {
            let PageName: string = "";
            let displayPrevious = "";
            let displayCurrent = "";      
            
            let previousValue = JSON.parse(data.OldValues);              
            let currentValue = JSON.parse(data.CurrentValues);
            if (data.Area == "Remarks") {
              if (data.CurrentValues != null) {
                PageName = data.Area + ": " + currentValue.Change[0].Value.split('-', 2)[0];
                let count = 0;
                for (let j of currentValue.Change) {
                  if (count != 0) {
                    displayCurrent = displayCurrent + j.Field + ": " + j.Value + " " + "\n";
                  }
                  count = 1;
                }               
              } else {
                PageName = data.Area;
              }
            } else {
              PageName = data.Area;
              for (let j of currentValue.Change) {
                displayCurrent = displayCurrent + j.Field + ": " + j.Value + " " + "\n";
              }
            }

            for (let i of previousValue.Change) {
              displayPrevious = displayPrevious + i.Field + ": " + i.Value + " " + "\n";
            }
            let date = moment(data.ChangedOn).format('MM/DD/YYYY');
            return {
              Page: PageName,
              SubPage: data.SolutionTitle,
              CurrentValues: displayCurrent,
              FullName: data.FullName,
              IdeaId: data.IdeaId,
              OldValues: displayPrevious,
              SourceId: data.SourceId,
              UserId: data.SourceId,
              ChangedOn: date
            }
          });

          if (params == 0) {
            this.loadGridStructure();
            this.disableRemoveFltr = true;
          } else if (params == 1) {
            this.disableRemoveFltr = false;
          } else if (params == 2) {
            this.disableRemoveFltr = true;
          }

          this.showPinner = false;
          if (this.apiAuditData.length) {
            this.disableDownload = false;
          } else {
            this.disableDownload = true;
          }
        } else {
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errMsgFrmBack]: serverMessage.serverErrorHeader }
          ];
          this.openAlertDialog(errorContainer);
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      })
    }
  };

  onFilterChanged(params) {   
    this.onPageSizeChanged();   
    this.gridApi.refreshCells() 

  }

  downloadGrid() {    
    this.gridApi.exportDataAsExcel();
  }

  // Navigation Button:-
  // Get called on click of Back button:
  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }
  // Get called on click of Exit button:
  submit(event?) {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }
}
