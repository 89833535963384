export enum EnumRemarkType {
    CostSaving = 1,
    DirectCost = 2,
    Impact = 3,
    Categorization = 4,
    NonPLCComplexityRisk = 5,
    PartsUsed = 6,
    IBase = 7,
    PLCComplexityRisk = 8,
    ProcessCost = 9
}