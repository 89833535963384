
export const messages = {
    logoutMsg1: "You are not logged in.",
    logoutMsg2: "Please login to the application.",
    deleteMessage: "Do you want to delete it ?",
    removeUser: "Do you want to remove the user details permanently from the system?",
    userOnLegalHold: "User on legal hold cannot be removed from the system.",
    copyMessage: "Create a copy of idea id ",
    //copySolutionMwessage: "You will swap the values of Option  with solution proposal.<br> Press yes to continue or cancel to keep the previous saved data. ",
    swapMessage1: "You will swap the values of Option" ,
    swapMessage2: " with solution proposal.",
    swapMessage3: "Press Yes to continue or No to keep the previous saved data.",
    admin: {
        userManagement: {
            activateUser: 'User already exist in Database (In-Active state). \n To make fresh entry Kindly remove the user from "User Removal" Page \r\n or to make user active again, press "Yes". To revert changes, press "No".'
        },
        userReplacement: {
            changeLegarHold: "Do you want to remove the Legal hold from the user ? \n Once removed it cannot be changed.\n Press \"Yes\" to continue, \"No\" to cancel."
        },
        actualsAndPlanunits: {
            waitMessage: "Please dont close the window untill success message appears."
        },

        navigationMessage: 'Do you want to save unsaved changes before \n moving to another tab ?',
        activateUser: "",

    },
    ideaForm: {
        responsibleEmailMessage: "Responsible",
        optionRemovalMessage: "You will lose the following Option field. Do you really want to delete all related data? Please confirm and save Again.",
        invalidOPWId: "OPW ID should be a number of maximum 10 digits.Check field:"
    },
    cellEmptyErrorMesssage: "Please fill the following field:",
    cellLengthErrorMessage: "Maximum character exceeded: ",
    impact: {
        orgUntErrMessage: "Same Org unit is selected multiple times.Please check and correct column:"
    },
    userManagement: {
        duplicateField: "You can not give same value for multiple users, Check column:",
        InvalidGID: "GID should have maximum length of 10 characters.Check column:"
    },
    categorization: {
        compareValidationMessage: "PLC assessment is required, no product is impacted. Please select Impacted Product.",
        plcChangeToRequiredMessage: "You have changed the PLC assessment from \"Not Required\" to \"Required\". This will delete all previously entered data in the \"Standard Valuation\". Press yes to continue or cancel to restore the previous saved data. Cancelling will restore the previous saved data of fields Plc Assesment, Status and StartDate.",
        plcChangeToNotRequiredMessage: "You have changed the PLC assessment from \"Required\" to \"Not Required\". This will delete all previously entered data in the \"Product Lifecycle Valuation\". Press yes to continue or cancel to restore the previous saved data. Cancelling will restore the previous saved data of fields Plc Assesment, Status and StartDate.",
        equalDate: "As the Idea start date is today, the Idea status can't be changed to:",
        todayDateBig: "As Idea start date is less than today's date, the Idea status can't be changed to:",
        startDateBig: "As Idea start date is greater than today's date, the Idea status can't be changed to:",
        plcAssesmentChange: "Product Lifecycle Cost assessment has been changed, the Idea status has been set to \"Valuation\". Please change in case needed.",
        plcChangeCancellationMessage: "Note: Cancelling will restore the previous saved data of fields Plc Assesment,Status and StartDate."
    },
    costAndSaving: {
        fillRefCostTypeValidationMessage: "Please fill the following field of Reference Scenario",
        fillTarCostTypeValidationMessage: "Please fill the following field of Target Scenario",
        fillOptACostTypeValidationMessage: "Please fill the following field of Option A",
        fillOptBCostTypeValidationMessage: "Please fill the following field of Option B",
        fillOptCCostTypeValidationMessage: "Please fill the following field of Option C",
    },
    complexityAndRisk: {
        headingComplexity: "Please fill the following field of Complexity Assessment Table",
        headingRisk: "Please fill the following field of Risk Assessment Table",
        validationMitigation: "Mitigation: Please enter mitigation potentials for the defined risk(s)",
    },
    directCost: {
        referenceSc: "Please fill the following field of Reference Scenario Table",
        targetSc: "Please fill the following field of Target Scenario Table",
    },
    nonPLCSummary: {
        validationMessage: "OPW productivity's Total and Recurring expenses Total should be same. Please check and correct."
    },
    PLCSummary: {
        validationOPWTotalMessage: "OPW productivity's Total and Monthly Rolled up Total should be same.\r\n Please check and correct."
    },
    processCostPastDate: "You have selected a past Year.",
    ProcessCostvalidationMessage: "Please fill the following field:",
    Help: {
        pageSelect: "Please select parent page"
    },
    masterData: {
        OrgUnitTitle: "Please fill the OrgUnitTitle",
        MeasureTypeTitle: "Please fill the MeasureTypeTitle",
        MeasureCategoryTitle: "Please fill the MeasureCategoryTitle",
        CostImpactTitle: "Please fill the CostImpactTitle",
        MeasureStatusTitle: "Please fill the MeasureStatusTitle",
        CommodityTitle: "Please fill the CommodityTitle",
        SubCommodityTitle: "Please fill the SubCommodityTitle",
        CostTypeTitle: "Please fill the CostTypeTitle",
        ImpactTitle: "Please fill the ImpactTitle",
        SettingValue: "Please fill the SettingValue",
        ProductTitle: "Please fill the ProductTitle",
        selectMaster: "Please select Master Data Type",
        commodity: "Please select Commodity",
        opwFilter: "Please select OPWFilter",
        updateData: "Please update data to save",
        opwFilterTitle: "Please fill the OPWFilterTitle",
        opwSubFilterTitle: "Please fill the OPWSubFilterTitle",
        opwTagTitle: "Please fill the OPWTagTitle"
    },
    Ibase: {
        EmptyData: "Data should not be empty between two cells"
    },
    ideaData: "Data can not be saved. Please fill in the Idea tab first.",
    impactData: "Data can not be saved. Please fill in the Impact tab first.",
    startDatePopUpMessage:"Since the measure has been copied, all original dates were set to Jan 1st 2999. Please check the start date in the Impact page and the years within the Process Cost pages and change as needed",
    PeriodLock: {
        BackDatedPopUpMessage:"Warning: Changing the date will remove already reported values from that period. Do you want to continue?"
    }
};
