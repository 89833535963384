import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { PeoplePickerService } from '../services/people-picker.service';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-people-drop-down',
  templateUrl: './people-drop-down.component.html'
  // styleUrls: ['./people-drop-down.component.css']
})
export class PeopleDropDownComponent implements ICellRendererAngularComp {
  userId: number = 1;
  searchedData: any;
  peoplePicker = new FormControl();
  value: any;
  previousValue: any;
  gridApi: any;
  rowIndex: number;
  @Output() userSendData = new EventEmitter<any>();

  constructor(
    private peoplePickerService: PeoplePickerService,
    private sharedService: SharedServiceService
  ) { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.rowIndex = params.node.rowIndex;
    this.previousValue = params.data.Email;
    this.gridApi = params.api
  }

  getValue() {
    this.value = this.peoplePicker.value;
    if (this.value && this.value!=0) {       
      this.gridApi.forEachNode(node => {
        if (this.rowIndex == node.rowIndex) {
          node.data.FirstName = this.value[0].firstName;
          node.data.LastName = this.value[0].lastName;
          node.data.OrgCode = this.value[0].department;
          node.data.FullName = this.value[0].displayName;
        }
      })
      this.sharedService.userMangementObj.emit(this.gridApi);
      return this.value[0].email
    }
    else {
      return this.previousValue
    }
  }

  getUsers(input) {   
    this.peoplePickerService.getUsers(input).subscribe(
      (res: any) => {
        if (res) {
          this.searchedData = res.value.map(user => {
            return {
              // passing a user id;
              userId: this.userId,
              displayName: user.displayName,
              firstName: user.givenName,
              lastName: user.surname,
              email: user.mail,
              department: user.department
            }
          })
        }
      }
    )
  }

  userData(data) {
    this.userSendData.emit(data)
  }
}
