<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
<div *ngIf="showGrid" class="container">
    <div class="label-header">
        <label class="label-headercolor">Option B </label>
    </div>
    <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" id="ProcessCost" [rowData]="opBProcessCostRowData"
        [columnDefs]="opBProcessCostColumnDefs" [defaultColDef]="opBProcessCostDefaultColDef"
        [rowClassRules]="rowClassRules" [domLayout]="domLayout" [rowHeight]="rowHeight" [headerHeight]="headerHeight"
        (gridReady)="onopBProcessCostGridReady($event)" (gridSizeChanged)="onopBProcessCostGridSizeChanged($event)">
    </ag-grid-angular>
    <div class="row" style="margin-top: 20px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle">
                <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
                <button class="button button--small" (click)="onClickBack()" type="submit">Back</button>&nbsp;
                <button class="button button--small" (click)="onClickNext()" type="submit">Next</button>&nbsp;
            </div>
        </div>
    </div>
</div>