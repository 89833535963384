import { ViewChild ,Component, ViewEncapsulation, OnInit, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from '../app/services/shared-service.service'
import { Subject } from 'rxjs';
import { LocalStorageService } from '../app/services/local-storage.service';
import { CategorizationService } from "../app/services/categorization.service";

//Defined Global variables
let countryCode;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AppComponent implements OnInit {
  public Identifier: any;
  public CurrencyFromDB: any;
  public currency: any;
  public Productivity: any;
  public dataRecived: any;
  public ProductivityFCEUR: any;
  public productivity:any;

  @ViewChild('sidenav') sidenav: any;
  @ViewChild('sidehelp') sidehelp: any;

  title = 'eco';
  navopened: boolean = true;
  helpopened: boolean = false;
  sideHelpOpen: boolean = false;
  loggedIn = false;
  isIframe = false;
  sidenavopened: boolean = true;
  fixed: any = 7;
  username: string = ""
  userInfo: any;
  ideaIdAndTitle: Subject<any> = new Subject<any>();
  idea: any;
  userAccount: any;
  maxValue: any = 50;
  product: any;
  marqueeMessage:string="";
  currencyValueFromCategorization = '';

  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedServiceService,
    private LocalStorageService: LocalStorageService,
    private catService: CategorizationService,
  ) { }

  ngOnInit(): void {
    window.onunload = () => {
      // Clear the local storage of categorization on close of page tab or on refresh;    
      this.sharedService.onRemoveCategorizationData();
   }
    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');   
    this.catService.currentMsg.subscribe(x => this.currency = x);
    setTimeout(() => {
      this.sharedService.data.subscribe((data) => {       
        this.dataRecived = data;
        this.Identifier = this.dataRecived.ideaId;
        this.product = this.dataRecived.title;
        this.CurrencyFromDB = this.dataRecived.currency;
        this.currency = this.CurrencyFromDB;
        this.productivity=this.dataRecived.productivity;
        countryCode = this.LocalStorageService.get('countryCode');      
        this.ProductivityFCEUR =  new Intl.NumberFormat(countryCode).format(Number(parseFloat(this.productivity).toFixed(2)));
        this.marqueeMessage=this.dataRecived.marqueeMessage; 
        if(!this.marqueeMessage){
          this.marqueeMessage='';         
        }
      
        if (this.product) {
          if (this.dataRecived.title.length <= this.maxValue) {
            this.Productivity = this.dataRecived.title;
          }
          else {
            this.Productivity = this.dataRecived.title.substr(0, this.maxValue) + '...';
          }
        }
        else {
          this.Productivity = null;
        }
        
      });
    }, 50)
  }
  changeVal() {
    return this.product
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getRoute() {
    if (this.router.url == "/home") {
      return "mainside-container";
    }
    else {
      return "main-container";
    }
  }

  toggleSideNav() {
    this.sidenav.toggle();
    this.sidenavopened = !this.sidenavopened
  }

  toggleHelpNav() {
    this.sidehelp.toggle();
    this.sideHelpOpen = !this.sideHelpOpen;
    if (this.fixed == 238) {
      this.fixed = 7;
    }
    else if (this.fixed == 7) {
      this.fixed = 238;
    }
  }
}
