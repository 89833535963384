import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Ibase } from '../models/ibase'
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class IbaseService {

  constructor(private http: HttpClient) { }
  
  private urlGet: string = environment.baseURL+ "api/IBase/GetIBase";
  private urlUpdate: string = environment.baseURL+ "api/IBase/InsertUpdateIBaseDetail";

  //This is used to get data from backend 
  getAllData(ideaId,solutionId): Observable<Ibase> {
    return this.http.get<Ibase>(this.urlGet+ '?ideaID=' + ideaId + '&solutionId='+ solutionId).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  //  This is used to update new Ibase Data in Database;
  updateData(data): Observable<Ibase> {
    return this.http.post<Ibase>(this.urlUpdate, data).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}