import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlcSummaryService {

  constructor(private http: HttpClient) { }
  //private urlGetPLCSummaryCosts: string = environment.baseURL + "api/PLCSummary/GetPLCSummaryCostsByIdeaId";
  private urlGetPLCSummaryOPWProductivity: string = environment.baseURL + "api/PLCSummary/GetPLCSummaryOPWProductivity";
  private urlPostPLCSummaryOPWProductivity: string = environment.baseURL + "api/PLCSummary/InsertUpdatePLCSummaryOPWProductivity";
  private urlGetPLCSummaryAnalysis: string = environment.baseURL + "api/PLCSummary/GetPLCSummaryAnalysis";
  private urlGetPLCSummaryData: string = environment.baseURL + "api/PLCSummary/GetPLCSummaryByIdeaId" ;

   getPLCSummaryOPWProductivity(ideaId) {
    return this.http.get(this.urlGetPLCSummaryOPWProductivity +'?ideaID=' + ideaId).pipe(
      retry(1),
      catchError(this.handleError))
  }

  postPLCSummaryOPWProductivityData(data) {
    return this.http.post(this.urlPostPLCSummaryOPWProductivity, data);
  };
  
  getPLCSummaryAnalysis(ideaId, solutionId) {
    return this.http.get(this.urlGetPLCSummaryAnalysis +'?ideaID=' + ideaId + '&solutionID='+ solutionId).pipe(
      retry(1),
      catchError(this.handleError))
  }

  getPLCSummaryData (ideaId) {
   
    return this.http.get(this.urlGetPLCSummaryData +'?ideaID=' + ideaId).pipe(
      retry(1),
      catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
