import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { NonPlcSummaryCalc } from '../models/non-plcsummarycalc';

@Injectable({
  providedIn: 'root'
})
export class NonplcsummaryService {

  constructor(private http: HttpClient) { }
  private urlGetNonPLCSummaryCalc: string = environment.baseURL + "api/NonPLCSummary/GetNonPLCSummaryCalcByIdeaId";
  private urlGetOPWProductivity: string = environment.baseURL + "api/NonPLCSummary/GetOPWProductivityByIdeaId";
  private urlPostNonPLCSummaryCostSaving: string = environment.baseURL + "api/NonPLCSummary/InsertUpdateOPWProductivityDetail";

  getNonPLCSummary(ideaId, userId): Observable<NonPlcSummaryCalc> {
    let _ideadId = Number(ideaId)
    let _userId = userId
   
    return this.http.get<NonPlcSummaryCalc>(this.urlGetNonPLCSummaryCalc + '?ideaId=' + _ideadId + '&userId=' + _userId);
  } 

  getOPWProductivity(ideaId): Observable<NonPlcSummaryCalc> {
    let _ideadId = Number(ideaId)
    return this.http.get<NonPlcSummaryCalc>(this.urlGetOPWProductivity + '?ideaId=' + _ideadId);
  }

  postOPWProductivityData(data) {
    return this.http.post(this.urlPostNonPLCSummaryCostSaving, data);
  };
}
