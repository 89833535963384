export class Idea {
    IdeaId: number;
    Title: string;
    SubmitterId:string;
    Submitter: string;
    SubmitterOrgCode: string;
    ResponsibleId :string;
    Responsible: string;
    ResponsibleOrgCode: string;
    MeasureStatusId: number;
    Situation: string;
    Dreamer: string;
    Critic: string;
    Realist: string;
    Rationale: string;
    Impact: string;
    Efforts: string;
    Opwnumber: number;
    Tfsid: string;
    TargetSol: string;
    OptionA: string;
    OptionB: string;
    OptionC: string;
    IsActive: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    ModifiedBy: number;
    ModifiedOn: Date;
    IsFBPApproved: boolean;
    IsFPCApproved: boolean;   
    BusinessAreaId: number;
    BusinessLineId: number;
    IsOriginatedOPW: boolean;
}
