import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { IbaseService } from 'src/app/services/ibase.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NumericvalueEditorComponent } from '../plc-ibase-ref-scenario/numeric-value-editor.component';
import { Ibase } from 'src/app/models/ibase';
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../../../../alert-dialog/alert-dialog.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Router } from '@angular/router';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { MenuService } from 'src/app/services/menu.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { NumericEditorComponent } from 'src/app/pages/non-plc/costsavings/numeric-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';

let countryCode

@Component({
  selector: 'app-plc-ibase-tar-scenario',
  templateUrl: './plc-ibase-tar-scenario.component.html',
  styleUrls: ['./plc-ibase-tar-scenario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlcIbaseTarScenarioComponent implements OnInit, Dirty {

  //declare ideaId and solutionId
  ideaId: any;
  tarSolutionId: any = MSolutionType.TargetScenario;
  //reference scenario

  public modifiedRows = [];

  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;

  alertErrorBox: any;
  error: any = [];
  ibasedata: any;
  productData: any
  tarScenerioRowdata: any;
  tarTotalrowdata: any;
  ConvRateYear: any;
  ImpactRateYear: any;
  IbaseupdateData = new Ibase();
  tarColumnDefs: any;
  defaultTarColDef: any;
  headerHeight: any;
  rowHeight: any;
  rowData: any;
  headerYears: any;
  domLayout: any;
  rowClassRules: any;
  headerHeightRef: any;
  frameworkComponents: any;
  sendData: any;
  gridApi: any;
  gridColumnApi: any;
  suppressRowTransform: boolean = true;
  loginUserDetails: any;
  showIbase: boolean = false
  hasUnsavedChanges: boolean = false;
  submitted: boolean = false;
  public isEditable: boolean = false;
  public sizeToFitRefresh: number = 0;
    
  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  constructor(private localService: LocalStorageService, private menuService: MenuService, private ibaseService: IbaseService, private sharedService: SharedServiceService,
    private dialog: MatDialog, private toasterService: ToasterService, private route: Router, private ideaService: IdeaformService, private userService: UserInfoService, private catService: CategorizationService) {

  }
  canDeactivate() {
    return this.hasUnsavedChanges
  }
  loadGrid() {
    this.tarColumnDefs = [
      {
        field: 'ProductTitle', headerName: 'Product', rowSpan: rowSpan,
        headerTooltip: 'Product', tooltipField: 'ProductTitle',
        cellClass: 'cell-span', editable: false, minWidth: 130, pinned: 'left'
      },
      {
        field: 'Unit', headerName: 'IBase(units)', headerTooltip: 'IBase(units)', tooltipField: 'Unit',
        rowSpan: rowSpan, cellClass: 'cell-span', editable: function (params) {
          if (params.data.CoverageRate == 'Coverage rate, in %') {
            return params.data.isEditable
          }
        }, minWidth: 40, pinned: 'left', cellEditor: 'IntegerEditor',cellStyle: { textAlign: "left" }
      },
      {
        field: 'CoverageRate', headerName: 'Key figure', headerTooltip: 'Key figure', tooltipField: 'CoverageRate',
        editable: false, minWidth: 185, pinned: 'left'
      },
      {
        field: 'conRate1', headerName: 'FY2021', headerTooltip: 'FY2021', tooltipField: 'conRate1',
        minWidth: 51, cellRenderer: limitDecimal, cellClass: 'numCell-align',
        cellEditor: 'numericEditor', cellStyle: (params) =>
          (params.data.conRate1 == null) && (params.data.conRate2 || params.data.conRate3 || params.data.conRate4 ||
            params.data.conRate5 || params.data.conRate6 || params.data.conRate7 || params.data.conRate8
            || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442'} : { borderLeft: '', }
      },
      {
        field: 'conRate2', headerName: 'FY2022', headerTooltip: 'FY2022', tooltipField: 'conRate2',
        cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) =>
          (isNaN(params.data.conRate2) || params.data.conRate2 == null) && (params.data.conRate3 || params.data.conRate4 ||
            params.data.conRate5 || params.data.conRate6 || params.data.conRate7 || params.data.conRate8
            || params.data.conRate9 || params.data.conRate10)
            ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate3', headerName: 'FY2023', headerTooltip: 'FY2023', tooltipField: 'conRate3',
        cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate3) || params.data.conRate3 == null) && (params.data.conRate4 ||
          params.data.conRate5 || params.data.conRate6 || params.data.conRate7 || params.data.conRate8
          || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate4', headerName: 'FY2024', headerTooltip: 'FY2024', tooltipField: 'conRate4',
        cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate4) || params.data.conRate4 == null) && (params.data.conRate5
          || params.data.conRate6 || params.data.conRate7 || params.data.conRate8
          || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate5', headerName: 'FY2025', headerTooltip: 'FY2025', tooltipField: 'conRate5',
        cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) =>
          (isNaN(params.data.conRate5) || params.data.conRate5 == null) && (params.data.conRate6
            || params.data.conRate7 || params.data.conRate8
            || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }

      },
      {
        field: 'conRate6', headerName: 'FY2026', headerTooltip: 'FY2026', tooltipField: 'conRate6',
        cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate6) || params.data.conRate6 == null) && (params.data.conRate7
          || params.data.conRate8
          || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate7', headerName: 'FY2027', cellClass: 'numCell-align',
        headerTooltip: 'FY2027', tooltipField: 'conRate7', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate7) || params.data.conRate7 == null) && (params.data.conRate8
          || params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate8', headerName: 'FY2028', headerTooltip: 'FY2028',
        tooltipField: 'conRate8', cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate8) || params.data.conRate8 == null) && (params.data.conRate9 || params.data.conRate10) ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        field: 'conRate9', headerName: 'FY2029', headerTooltip: 'FY2029', tooltipField: 'conRate9', cellClass: 'numCell-align',
        minWidth: 51, cellRenderer: limitDecimal,
        cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate9) || params.data.conRate9 == null) && params.data.conRate10 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
      },
      {
        headerName: 'Years11-20', headerTooltip: 'Years11-20', minWidth: 109, children: [
          {
            field: 'conRate10', headerName: 'FY2030', headerTooltip: 'FY2030',
            tooltipField: 'conRate10', cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate10) || params.data.conRate10 == null) && params.data.conRate11 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate11', headerName: 'FY2031', headerTooltip: 'FY2031', columnGroupShow: 'open',
            tooltipField: 'conRate11', cellClass: 'numCell-align', minWidth: 51, cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate11) || params.data.conRate11 == null) && params.data.conRate12 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate12', headerName: 'FY2032', headerTooltip: 'FY2032', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate12', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate12) || params.data.conRate12 == null) && params.data.conRate13 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate13', headerName: 'FY2033', headerTooltip: 'FY2033', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate13', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate13) || params.data.conRate13 == null) && params.data.conRate14 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate14', headerName: 'FY2034', headerTooltip: 'FY2034', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate14', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate14) || params.data.conRate14 == null) && params.data.conRate15 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate15', headerName: 'FY2035', headerTooltip: 'FY2035', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate15', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate15) || params.data.conRate15 == null) && params.data.conRate16 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate16', headerName: 'FY2036', headerTooltip: 'FY2036', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate16', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate16) || params.data.conRate16 == null) && params.data.conRate17 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate17', headerName: 'FY2037', headerTooltip: 'FY2037', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate17', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate17) || params.data.conRate17 == null) && params.data.conRate18 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate18', headerName: 'FY2038', headerTooltip: 'FY2038', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate18', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate18) || params.data.conRate18 == null) && params.data.conRate19 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate19', headerName: 'FY2039', headerTooltip: 'FY2039', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate19', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor', cellStyle: (params) => (isNaN(params.data.conRate19) || params.data.conRate19 == null) && params.data.conRate20 ? { borderLeft: '2px solid #a94442' } : { borderLeft: '' }
          },
          {
            field: 'conRate20', headerName: 'FY2040', headerTooltip: 'FY2040', columnGroupShow: 'open',
            minWidth: 51, tooltipField: 'conRate20', cellClass: 'numCell-align', cellRenderer: limitDecimal,
            cellEditor: 'numericEditor'
          }]
      }
    ]
    //Target scenario column defination----
    this.defaultTarColDef = {
      flex: 1,
      editable: this.isEditable,
      suppressMovable: true,
      sortable: false,
      singleClickEdit: true
    };
    this.domLayout = 'autoHeight';
    this.rowHeight = 40;
    this.headerHeight = 50;
    this.headerHeightRef = 50;
    this.frameworkComponents = {
      numericEditor: NumericvalueEditorComponent,
      IntegerEditor: NumericEditorComponent
    }
  }

  ngOnInit(): void {
    this.sharedService.loggedInUser.subscribe(
      (user) => {
        this.loginUserDetails = user;
      }
    );
    //Get Country Code
    countryCode = this.localService.get('countryCode');
    this.sharedService.getIdeaIdAndtitle();
    this.ideaId = this.localService.get('ideaId')
    //getting reference scenario data

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadTargetGridData();
            }
          })
        })
      }
    })
  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if(pageLockMessage != ""){
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }
  
  loadTargetGridData() {
    this.ibaseService.getAllData(this.ideaId, this.tarSolutionId).subscribe(
      (res: any) => {
        let errorMsgeFromBack = res.Data.Message;
        if (res.StatusCode == 200) {
          this.loadGrid();
          this.ibasedata = res.Data;
          // for remark GET;
          this.remarkDataRecieved = this.ibasedata.Remark;
          if (this.remarkDataRecieved != null) {
            this.remarkId = this.remarkDataRecieved.RemarkId;
            this.remark = this.remarkDataRecieved.Remark;
            this.isChangeRemarks = this.remarkDataRecieved.Remark;
          } else {
            this.remarkId = null;
            this.remark = null;
            this.isChangeRemarks = null;
          }
          // Changes remarks;

          this.tarScenerioRowdata = []
          this.productData = []
          this.ibasedata = this.ibasedata.IBaseData;
          for (let data of this.ibasedata) {

            this.headerYears = data.Headeryears.split(",")
            this.productData.push(data.ProductId)
            this.tarScenerioRowdata.push(
              {
                ProductId: data.ProductId ?? data.ProductId,
                IbaseId: data.IbaseID ?? data.IbaseID,
                headerYears: data.Headeryears ?? data.Headeryears,
                ProductTitle: data.ProductTitle,
                Unit: data.Unit,
                CoverageRate: 'Coverage rate, in %',
                conRate1: data.ConvRateYear1 == 0 ? null : data.ConvRateYear1,
                conRate2: data.ConvRateYear2 == 0 ? null : data.ConvRateYear2,
                conRate3: data.ConvRateYear3 == 0 ? null : data.ConvRateYear3,
                conRate4: data.ConvRateYear4 == 0 ? null : data.ConvRateYear4,
                conRate5: data.ConvRateYear5 == 0 ? null : data.ConvRateYear5,
                conRate6: data.ConvRateYear6 == 0 ? null : data.ConvRateYear6,
                conRate7: data.ConvRateYear7 == 0 ? null : data.ConvRateYear7,
                conRate8: data.ConvRateYear8 == 0 ? null : data.ConvRateYear8,
                conRate9: data.ConvRateYear9 == 0 ? null : data.ConvRateYear9,
                conRate10: data.ConvRateYear10 == 0 ? null : data.ConvRateYear10,
                conRate11: data.ConvRateYear11 == 0 ? null : data.ConvRateYear11,
                conRate12: data.ConvRateYear12 == 0 ? null : data.ConvRateYear12,
                conRate13: data.ConvRateYear13 == 0 ? null : data.ConvRateYear13,
                conRate14: data.ConvRateYear14 == 0 ? null : data.ConvRateYear14,
                conRate15: data.ConvRateYear15 == 0 ? null : data.ConvRateYear15,
                conRate16: data.ConvRateYear16 == 0 ? null : data.ConvRateYear16,
                conRate17: data.ConvRateYear17 == 0 ? null : data.ConvRateYear17,
                conRate18: data.ConvRateYear18 == 0 ? null : data.ConvRateYear18,
                conRate19: data.ConvRateYear19 == 0 ? null : data.ConvRateYear19,
                conRate20: data.ConvRateYear20 == 0 ? null : data.ConvRateYear20,
                isEditable: this.isEditable
              }
            );
            this.tarScenerioRowdata.push(
              {
                IbaseId: data.IbaseID ?? data.IbaseID,
                ProductId: data.ProductId ?? data.ProductId,
                CoverageRate: 'Impact change rate, in % of coverage rate',
                conRate1: data.ImpactRateYear1 == 0 ? null : data.ImpactRateYear1,
                conRate2: data.ImpactRateYear2 == 0 ? null : data.ImpactRateYear2,
                conRate3: data.ImpactRateYear3 == 0 ? null : data.ImpactRateYear3,
                conRate4: data.ImpactRateYear4 == 0 ? null : data.ImpactRateYear4,
                conRate5: data.ImpactRateYear5 == 0 ? null : data.ImpactRateYear5,
                conRate6: data.ImpactRateYear6 == 0 ? null : data.ImpactRateYear6,
                conRate7: data.ImpactRateYear7 == 0 ? null : data.ImpactRateYear7,
                conRate8: data.ImpactRateYear8 == 0 ? null : data.ImpactRateYear8,
                conRate9: data.ImpactRateYear9 == 0 ? null : data.ImpactRateYear9,
                conRate10: data.ImpactRateYear10 == 0 ? null : data.ImpactRateYear10,
                conRate11: data.ImpactRateYear11 == 0 ? null : data.ImpactRateYear11,
                conRate12: data.ImpactRateYear12 == 0 ? null : data.ImpactRateYear12,
                conRate13: data.ImpactRateYear13 == 0 ? null : data.ImpactRateYear13,
                conRate14: data.ImpactRateYear14 == 0 ? null : data.ImpactRateYear14,
                conRate15: data.ImpactRateYear15 == 0 ? null : data.ImpactRateYear15,
                conRate16: data.ImpactRateYear16 == 0 ? null : data.ImpactRateYear16,
                conRate17: data.ImpactRateYear17 == 0 ? null : data.ImpactRateYear17,
                conRate18: data.ImpactRateYear18 == 0 ? null : data.ImpactRateYear18,
                conRate19: data.ImpactRateYear19 == 0 ? null : data.ImpactRateYear19,
                conRate20: data.ImpactRateYear20 == 0 ? null : data.ImpactRateYear20,
                isEditable: this.isEditable
              }

            )


          }

          this.showIbase = true;
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  allGridData() {
    let totalRows = [];
    let coverageRows = [];
    let ImpactRows = [];
    this.sendData = [];
    this.gridApi.forEachNode((rowNode, index) => {
      totalRows.push(rowNode.data)
    })
    for (let i = 0; i < totalRows.length; i += 2) {
      coverageRows.push(totalRows[i])
    }
    for (let i = 1; i < totalRows.length; i += 2) {
      ImpactRows.push(totalRows[i])
    }
    for (let data of coverageRows) {
      for (let val = 0; val < ImpactRows.length; val++) {
        if (data.ProductId == ImpactRows[val].ProductId) {
          this.IbaseupdateData = new Ibase();

          this.IbaseupdateData.ProductId = this.productData[val];
          this.IbaseupdateData.IbaseID = data.IbaseId;
            this.IbaseupdateData.ProductTitle = data.ProductTitle;
            this.IbaseupdateData.IdeaID = Number(this.ideaId);
            this.IbaseupdateData.SolutionId = Number(this.tarSolutionId);
            this.IbaseupdateData.Unit = data.Unit ?? data.Unit;
            this.IbaseupdateData.ConvRateYear1 = data.conRate1 == null ? null : parseFloat(data.conRate1);
            this.IbaseupdateData.ConvRateYear2 = data.conRate2 == null ? null : parseFloat(data.conRate2);
            this.IbaseupdateData.ConvRateYear3 = data.conRate3 == null ? null : parseFloat(data.conRate3);
            this.IbaseupdateData.ConvRateYear4 = data.conRate4 == null ? null : parseFloat(data.conRate4);
            this.IbaseupdateData.ConvRateYear5 = data.conRate5 == null ? null : parseFloat(data.conRate5);
            this.IbaseupdateData.ConvRateYear6 = data.conRate6 == null ? null : parseFloat(data.conRate6);
            this.IbaseupdateData.ConvRateYear7 = data.conRate7 == null ? null : parseFloat(data.conRate7);
            this.IbaseupdateData.ConvRateYear8 = data.conRate8 == null ? null : parseFloat(data.conRate8);
            this.IbaseupdateData.ConvRateYear9 = data.conRate9 == null ? null : parseFloat(data.conRate9);
            this.IbaseupdateData.ConvRateYear10 = data.conRate10 == null ? null : parseFloat(data.conRate10);
            this.IbaseupdateData.ConvRateYear11 = data.conRate11 == null ? null : parseFloat(data.conRate11);
            this.IbaseupdateData.ConvRateYear12 = data.conRate12 == null ? null : parseFloat(data.conRate12);
            this.IbaseupdateData.ConvRateYear13 = data.conRate13 == null ? null : parseFloat(data.conRate13);
            this.IbaseupdateData.ConvRateYear14 = data.conRate14 == null ? null : parseFloat(data.conRate14);
            this.IbaseupdateData.ConvRateYear15 = data.conRate15 == null ? null : parseFloat(data.conRate15);
            this.IbaseupdateData.ConvRateYear16 = data.conRate16 == null ? null : parseFloat(data.conRate16);
            this.IbaseupdateData.ConvRateYear17 = data.conRate17 == null ? null : parseFloat(data.conRate17);
            this.IbaseupdateData.ConvRateYear18 = data.conRate18 == null ? null : parseFloat(data.conRate18);
            this.IbaseupdateData.ConvRateYear19 = data.conRate19 == null ? null : parseFloat(data.conRate19);
            this.IbaseupdateData.ConvRateYear20 = data.conRate20 == null ? null : parseFloat(data.conRate20);
            this.IbaseupdateData.ImpactRateYear1 = ImpactRows[val].conRate1 == null ? null : parseFloat(ImpactRows[val].conRate1);
            this.IbaseupdateData.ImpactRateYear2 = ImpactRows[val].conRate2 == null ? null : parseFloat(ImpactRows[val].conRate2);
            this.IbaseupdateData.ImpactRateYear3 = ImpactRows[val].conRate3 == null ? null : parseFloat(ImpactRows[val].conRate3);
            this.IbaseupdateData.ImpactRateYear4 = ImpactRows[val].conRate4 == null ? null : parseFloat(ImpactRows[val].conRate4);
            this.IbaseupdateData.ImpactRateYear5 = ImpactRows[val].conRate5 == null ? null : parseFloat(ImpactRows[val].conRate5);
            this.IbaseupdateData.ImpactRateYear6 = ImpactRows[val].conRate6 == null ? null : parseFloat(ImpactRows[val].conRate6);
            this.IbaseupdateData.ImpactRateYear7 = ImpactRows[val].conRate7 == null ? null : parseFloat(ImpactRows[val].conRate7);
            this.IbaseupdateData.ImpactRateYear8 = ImpactRows[val].conRate8 == null ? null : parseFloat(ImpactRows[val].conRate8);
            this.IbaseupdateData.ImpactRateYear9 = ImpactRows[val].conRate9 == null ? null : parseFloat(ImpactRows[val].conRate9);
            this.IbaseupdateData.ImpactRateYear10 = ImpactRows[val].conRate10 == null ? null : parseFloat(ImpactRows[val].conRate10);
            this.IbaseupdateData.ImpactRateYear11 = ImpactRows[val].conRate11 == null ? null : parseFloat(ImpactRows[val].conRate11);
            this.IbaseupdateData.ImpactRateYear12 = ImpactRows[val].conRate12 == null ? null : parseFloat(ImpactRows[val].conRate12);
            this.IbaseupdateData.ImpactRateYear13 = ImpactRows[val].conRate13 == null ? null : parseFloat(ImpactRows[val].conRate13);
            this.IbaseupdateData.ImpactRateYear14 = ImpactRows[val].conRate14 == null ? null : parseFloat(ImpactRows[val].conRate14);
            this.IbaseupdateData.ImpactRateYear15 = ImpactRows[val].conRate15 == null ? null : parseFloat(ImpactRows[val].conRate15);
            this.IbaseupdateData.ImpactRateYear16 = ImpactRows[val].conRate16 == null ? null : parseFloat(ImpactRows[val].conRate16);
            this.IbaseupdateData.ImpactRateYear17 = ImpactRows[val].conRate17 == null ? null : parseFloat(ImpactRows[val].conRate17);
            this.IbaseupdateData.ImpactRateYear18 = ImpactRows[val].conRate18 == null ? null : parseFloat(ImpactRows[val].conRate18);
            this.IbaseupdateData.ImpactRateYear19 = ImpactRows[val].conRate19 == null ? null : parseFloat(ImpactRows[val].conRate19);
            this.IbaseupdateData.ImpactRateYear20 = ImpactRows[val].conRate20 == null ? null : parseFloat(ImpactRows[val].conRate20);
            this.IbaseupdateData.IsActive = true;
            this.IbaseupdateData.CreatedBy = this.userInOrg.UserId;
            this.IbaseupdateData.CreatedOn = new Date();
            this.IbaseupdateData.Headeryears = data.headerYears;

          this.sendData.push(this.IbaseupdateData)
        }
      }
    }
    // Remarks changes: While POST;
    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: MSolutionType.TargetScenario,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.IBase
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }
    let finalRow = [];
    for (let i of this.sendData) {
      for (let k of this.modifiedRows) {
        if (i.IbaseID == k.IbaseId && i.ProductId == k.ProductId) {
          finalRow.push(i);
          break;
        }
      }
    }
    return {
      IBaseData: finalRow,
      IdeaId: this.ideaId,
      Remark: Remark,
      UserId: this.userInOrg.UserId,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId
    }
  }


  submit(event?) {
    let response: any;
    this.submitted = true;
    this.sendData = [];
    
    this.error = [];
    this.gridApi.forEachNode((rowNode, index) => {
   
      if (((rowNode.data.conRate1 == '' || rowNode.data.conRate1 == null) && (rowNode.data.conRate2)) ||
        ((rowNode.data.conRate2 == '' || rowNode.data.conRate2 == null) && (rowNode.data.conRate3)) ||
        ((rowNode.data.conRate3 == '' || rowNode.data.conRate3 == null) && (rowNode.data.conRate4)) ||
        ((rowNode.data.conRate4 == '' || rowNode.data.conRate4 == null) && (rowNode.data.conRate5)) ||
        ((rowNode.data.conRate5 == '' || rowNode.data.conRate5 == null) && (rowNode.data.conRate6)) ||
        ((rowNode.data.conRate6 == '' || rowNode.data.conRate6 == null) && (rowNode.data.conRate7)) ||
        ((rowNode.data.conRate7 == '' || rowNode.data.conRate7 == null) && (rowNode.data.conRate8)) ||
        ((rowNode.data.conRate8 == '' || rowNode.data.conRate8 == null) && (rowNode.data.conRate9)) ||
        ((rowNode.data.conRate9 == '' || rowNode.data.conRate9 == null) && (rowNode.data.conRate10)) ||
        ((rowNode.data.conRate10 == '' || rowNode.data.conRate10 == null) && (rowNode.data.conRate11)) ||
        ((rowNode.data.conRate11 == '' || rowNode.data.conRate11 == null) && (rowNode.data.conRate12)) ||
        ((rowNode.data.conRate12 == '' || rowNode.data.conRate12 == null) && (rowNode.data.conRate13)) ||
        ((rowNode.data.conRate13 == '' || rowNode.data.conRate13 == null) && (rowNode.data.conRate14)) ||
        ((rowNode.data.conRate14 == '' || rowNode.data.conRate14 == null) && (rowNode.data.conRate15)) ||
        ((rowNode.data.conRate15 == '' || rowNode.data.conRate15 == null) && (rowNode.data.conRate16)) ||
        ((rowNode.data.conRate16 == '' || rowNode.data.conRate16 == null) && (rowNode.data.conRate17)) ||
        ((rowNode.data.conRate17 == '' || rowNode.data.conRate17 == null) && (rowNode.data.conRate18)) ||
        ((rowNode.data.conRate18 == '' || rowNode.data.conRate18 == null) && (rowNode.data.conRate19)) ||
        ((rowNode.data.conRate19 == '' || rowNode.data.conRate19 == null) && (rowNode.data.conRate20))
      ) {


        this.error.push(rowNode.data.ProductTitle)
      }
    })
    if (this.error.length != 0) {
      this.dialog.open(DialogComponent, { data: { message: messages.Ibase.EmptyData, okBtn: "OK" } });
      this.submitted = false;
    }
    else {
      let sendData = this.allGridData();

      if (this.hasUnsavedChanges) {
        response = this.ibaseService.updateData(sendData).toPromise().then(
          (res: any) => {
            let successCode = res.StatusCode;
            let errorCode = res.Data.ErrorCode;
            let errorMsgeFromBack = res.Data.Message;
            //Check if successCode is ok i.e 200
            if (successCode == 200) {
              //check if errorCode in the response is 0 then everythig is fine.
              if (errorCode == 0) {
                this.toasterService.notify(serverMessage.dataSaveMessage, "success");
                this.hasUnsavedChanges = false;
                if (event != undefined) {
                  this.route.navigate(['/manageIdea'])
                }
                return true;
              }
              //If the error code in the resposne is not 0, then display the error in UI.
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
                this.submitted = false;
                return false;

              }
            }
            //Going in else means that success code is not. ie. Http Status from server is not ok.
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }, (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            this.submitted = false;
            return false;
          }
        )
      }
      else {
        this.hasUnsavedChanges = false;
        if (event != undefined) {
          this.route.navigate(['/manageIdea']);
        }
        return true;
      }
    }
    return response;


  }
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    setTimeout((params) => {
      let rowCount = this.gridApi.getDisplayedRowCount();
      if (rowCount <= 10) {
        this.gridApi.setDomLayout('autoHeight');
        document.getElementById('tarIbase').style.height = null;
      }
      else {
        this.gridApi.setDomLayout('normal');
        document.getElementById('tarIbase').style.height = '400px';
      }
    }, 1001)

    setTimeout(() => {
      this.headerYears.forEach((value, index) => {

        this.gridApi.getColumnDef("conRate" + (index + 1)).headerName = 'FY' + value;
        this.gridApi.getColumnDef("conRate" + (index + 1)).headerTooltip = 'FY' + value
      });
      this.gridApi.refreshHeader();
    }, 1000);

    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit()
    }
  }
  onFirstDataRendered(event) {

  }
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.gridApi.redrawRows();

    // updating modified row;
    let isPushed = false;
    if (this.modifiedRows.length == 0) {
      this.modifiedRows.push(params.data)
      isPushed = true;
    }
    else {
      // same row changed;
      this.modifiedRows.forEach(row => {
        if (row.CoverageRate == params.data.CoverageRate &&
          row.IbaseId == params.data.IbaseId &&
          row.ProductId == params.data.ProductId
        ) {
          isPushed = true;
        }
      });
    }
    // if another row is changed;
    if (!isPushed) {
      this.modifiedRows.push(params.data)
    }

  }
  onClickCancel() {
    this.hasUnsavedChanges = false
    this.route.navigate(['/manageIdea']);
  }
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  // Remarks changes: While Input Changes;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
}
function rowSpan(params) {
  let product = params.data.CoverageRate
  if (product == 'Coverage rate, in %') {
    return 2
  }
  else {
    return 1
  }
}
function limitDecimal(params) {

  if (params.value == null) {
    return null
  }
  return new Intl.NumberFormat(countryCode).format(params.value) + '%';
}

