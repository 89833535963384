import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
//import models;
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AuditLogService {

  constructor(private http: HttpClient) { }
  readonly urlGet: string = environment.baseURL + "api/Audit/GetAuditData";

  // makes an API call to fetch the audit data;
  getAuditData(auditObj): Observable<any> {     
    return this.http.post<any>(this.urlGet,auditObj).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  // It will throw error when the server connection is failed;
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  };
};
