<app-header class="header" [userName]="username"></app-header>
<mat-sidenav-container id="container" fullscreen autosize>
    <mat-sidenav #sidenav
        *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'"
        mode="side" [(opened)]="navopened">
        <app-nav></app-nav>
    </mat-sidenav>

    <mat-sidenav
        *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'"
        #sidehelp mode="side" [(opened)]="helpopened" position="end">
        <app-help
            *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData'">
        </app-help>
    </mat-sidenav>

    <mat-sidenav-content>
        <div style="position: fixed;width :20px;margin-top: 1px;z-index: 10;">
            <button aria-label="Arrow" style="width: 20px;height: 25px;background-color: transparent;"
                *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'"
                class="navbar-toggle" (click)="toggleSideNav()">
                <img alt="LeftArrow" *ngIf="sidenavopened" src="../assets/icons/LeftArrow.png" class="arrow-style">
                <img alt="RightArrow" *ngIf="!sidenavopened" src="../assets/icons/RightArrow.png" class="arrow-style">
            </button>
        </div>
        <div style="position: fixed;top:70px;width: 20px;margin-top: 1px;z-index: 10;" [style.right.px]="fixed">
            <button aria-label="Arrow" style="width: 20px;height: 25px;background-color: transparent;"
                *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'"
                class="navbar-toggle" (click)="toggleHelpNav()">

                <img alt="RightArrow" *ngIf="sideHelpOpen" src="../assets/icons/RightArrow.png" class="arrow-style">
                <img alt="LeftArrow" *ngIf="!sideHelpOpen" src="../assets/icons/LeftArrow.png" class="arrow-style">
            </button>
        </div>
        <div *ngIf="marqueeMessage!=''">
            <div  class="marqueDiv"
            *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'">
            <p  class="marqueP">{{marqueeMessage}}
           </p>
        </div>
        </div>
       
        <div class="container"
            *ngIf="router.url != '/home' && router.url != '/manageIdea' && router.url != '/welcome' && router.url != '/manageData' && router.url!='/page-not-found'">
            <div class="row" style="margin-right: 0.5px;margin-left: 0.5px;">
                <div class="col-2" style="margin-left: -2px;">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;"><b>Identifier: {{Identifier}}</b>
                    </p>
                </div>
                <div class="col-7">
                    <p id="para" style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px; margin-left:22px"
                        title="{{product}}"><b>Title: {{Productivity}}</b></p>
                </div>
                <div class="col-3">
                    <p style="font-size:0.7rem; font-weight: 550;margin-bottom: 0px;text-align: right;">
                        <b>Productivity Target In {{currency}}: {{ ProductivityFCEUR }}</b>
                    </p>
                </div>
            </div>
        </div>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<div>
    <app-footer id="footer"></app-footer>
</div>