<div class="form-row container">
    <div class="form-group col-md-3">
        <label for="businessArea" class="label">Business Area</label>
        <select [(ngModel)]="selectBusinessArea" (change)="updateBusinessArea()" id="businessArea" class="form-control" aria-label="businessArea" [disabled]="this.disableBusinessAreaDropdown == true">
            <option value="">Please select</option>
            <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
              {{ba.BusinessAreaName}}
            </option>
        </select>
    </div>
    <div class="form-group col-md-3">
        <label for="businessLine" class="label">Business Line</label>
        <select [(ngModel)]="selectBusinessLine" (change)="updateBusinessLine()" id="businessLine" class="form-control" aria-label="businessLine" [disabled]="this.disableBusinessLineDropdown == true">
            <option value="">Please select</option>
            <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
              {{bl.BusinessLineName}}
            </option>
        </select>
    </div>
</div>
<br>

<form [formGroup]="masterForm">
    <div class="container">
        <app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>

        <div class="card" style="height:100%">
            <div class="label-header" style="width: 100%;margin-top: 0px;">
                <label class="label-headercolor">Master Data (Add New Master Data</label>
                <button [ngClass]="{'click-not-allowed':addDisable}" (click)="AddNewData()" title="Add" [disabled]="addDisable">
                    <img alt="plusIcon" src="../assets/icons/Plus.svg" width="25" height="25" class="icon-add" id="button">
                </button><label class="label-headercolor">)</label>
            </div>
            
            <div class="card-body" style="padding:10px">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="masterPage" class="label">Master Data Type</label>
                        <select id="masterPage" class="form-control" formControlName="masterId" aria-label="masterPage"
                            (change)="dataChange($event.target.value)">
                            <option value="">Please select</option>
                            <option *ngFor="let mType of masterArray" [value]="mType.Key">{{mType.Value}}</option>
                        </select>
                    </div>
                    <div *ngIf="ifSubCommoditeSelected" class="form-group col-md-6">
                        <label for="commodity" class="label">Commodity</label>
                        <select id="commodity" class="form-control" aria-label="Commodity" formControlName="commodity" (change)="onCommodityChange($event.target.value)">
                            <option value="">Please select</option>
                            <option *ngFor="let commodity of commodityArray" [value]="commodity.CommodityId">{{commodity.CommodityTitle}}</option>
                        </select>
                    </div>
                    <div *ngIf="ifOpwSubFilterSelected" class="form-group col-md-6">
                        <label for="opwFilter" class="label">OPWFilter</label>
                        <select id="opwFilter" class="form-control" aria-label="opwFilter" formControlName="opwFilter" (change)="onOpwFilterChange($event.target.value)">
                            <option value="">Please select</option>
                            <option *ngFor="let opwFilter of opwFilterArray" [value]="opwFilter.OPWFilterId">{{opwFilter.OPWFilterTitle}}</option>
                        </select>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="form-group col-md-12">
                        <ag-grid-angular style="width: 100%;height: 720px;border: 1px solid #e6e6e6;" class="ag-theme-alpine"
                           [columnDefs]="masterColumnDefs" [rowData]="masterRowData" id="masterGrid"
                            [defaultColDef]="defaultMasterColDef" [domLayout]="domLayout" [suppressRowTransform]="true"
                            [headerHeight]="headerHeight" [rowHeight]="rowHeight" (gridReady)="onGridReady($event)"
                            (gridSizeChanged)="onGridSizeChanged($event)" [frameworkComponents]="frameworkComponents"
                             (cellValueChanged)="onCellValueChanged($event)" 
                            >
                        </ag-grid-angular>
                    </div>
                </div>
                <div class="row pt-0">
                    <div class="offset-8 col-md-4">
                        <div class="buttonStyle">
                            <button class="button button--small" style="margin-right: 5px;"  (click)="onCancel()">Cancel</button>
                            <button class="button button--small"  (click)="Submit('true')">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>