<app-progress-spinner *ngIf="(!showCost && !showOpw )|| submitted"></app-progress-spinner>

<div class="container" *ngIf="showCost">
  <div class="label-header">
    <label class="label-headercolor">Cost </label>
  </div>
  <div class="table-div">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <caption hidden></caption>
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Cost type Column -->
      <ng-container matColumnDef="costType">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':true}"></th>
        <td style="width:18% !important" mat-cell *matCellDef="let element"
          [ngClass]="{mainCost:costTypeList.includes(element.costType), percentCost:element.CostType=='in % of cost'}">
          {{element.CostType}} </td>
      </ng-container>

      <!-- Reference cost column -->
      <ng-container matColumnDef="refCost">
        <th mat-header-cell *matHeaderCellDef class="refSubCol"> Costs </th>
        <td mat-cell *matCellDef="let element"> {{element.RefCost}} </td>
      </ng-container>

      <!-- Target cost column -->
      <ng-container matColumnDef="tarCost">
        <th mat-header-cell *matHeaderCellDef class="tarSubCol section-separator"> Costs </th>
        <td class="section-separator" mat-cell *matCellDef="let element"> {{element.TarCost}} </td>
      </ng-container>

      <!-- Target saving column -->
      <ng-container matColumnDef="tarSaving">
        <th mat-header-cell *matHeaderCellDef class="tarSubCol"> Saving </th>
        <td mat-cell *matCellDef="let element"> {{element.TarSaving}} </td>
      </ng-container>

      <!-- target saving in % column -->
      <ng-container matColumnDef="tarPercentSaving">
        <th mat-header-cell *matHeaderCellDef class="tarSubCol">Saving in %</th>
        <td class="tgtsaving" mat-cell *matCellDef="let element"> {{element.TarPercentSaving}} </td>
      </ng-container>

      <!-- Option A cost Column -->
      <ng-container matColumnDef="optA_Cost">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptA,optSubCol:true}">
          Costs </th>
        <td class="section-separator" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptA}">
          {{element.OptA_Cost}} </td>
      </ng-container>

      <!-- Option A saving column -->
      <ng-container matColumnDef="optA_Saving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptA,optSubCol:true}"> Saving </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptA}"> {{element.OptA_Saving}}
        </td>
      </ng-container>

      <!-- Option A saving in % column -->
      <ng-container matColumnDef="optA_PercentSaving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptA,optSubCol:true}"> Saving in % </th>
        <td class="tgtsaving" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptA}">
          {{element.OptA_PercentSaving}}</td>
      </ng-container>

      <!-- Option B cost Column -->
      <ng-container matColumnDef="optB_Cost">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptB,optSubCol:true}">
          Costs </th>
        <td class="section-separator" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptB}">
          {{element.OptB_Cost}} </td>
      </ng-container>

      <!-- Option B saving column -->
      <ng-container matColumnDef="optB_Saving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptB,optSubCol:true}"> Saving </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptB}"> {{element.OptB_Saving}}
        </td>
      </ng-container>

      <!-- Option B saving in % column -->
      <ng-container matColumnDef="optB_PercentSaving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptB,optSubCol:true}"> Saving in % </th>
        <td class="tgtsaving" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptB}">
          {{element.OptB_PercentSaving}}
        </td>
      </ng-container>

      <!-- Option B cost Column -->
      <ng-container matColumnDef="optC_Cost">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptC,optSubCol:true}">
          Costs </th>
        <td class="section-separator" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptC}">
          {{element.OptC_Cost}} </td>
      </ng-container>

      <!-- Option B saving column -->
      <ng-container matColumnDef="optC_Saving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptC,optSubCol:true}"> Saving </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptC}"> {{element.OptC_Saving}} </td>
      </ng-container>

      <!-- Option B saving in % column -->
      <ng-container matColumnDef="optC_PercentSaving">
        <th mat-header-cell *matHeaderCellDef [ngClass]="{'hide':hideOptC,optSubCol:true}"> Saving in % </th>
        <td class="tgtsaving" mat-cell *matCellDef="let element" [ngClass]="{'hide':hideOptC}">
          {{element.OptC_PercentSaving}}
        </td>
      </ng-container>

      <!-- Header row cost type column group -->
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="costType">Cost Type</th>
      </ng-container>

      <!-- Header row Reference column group -->
      <ng-container matColumnDef="header-row-ref-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" class="reference"> Reference </th>
      </ng-container>
      <!-- Header row target column group -->
      <ng-container matColumnDef="header-row-target-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="target section-separator"> Target </th>
      </ng-container>
      <!-- Header row option A column group -->
      <ng-container matColumnDef="header-row-optA-group">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [attr.colspan]="3"
          [ngClass]="{'hide':hideOptA,option:true}"> Option A </th>
      </ng-container>
      <!-- Header row option B column group -->
      <ng-container matColumnDef="header-row-optB-group">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [attr.colspan]="3"
          [ngClass]="{'hide':hideOptB,option:true}"> Option B </th>
      </ng-container>
      <!-- Header row option C column group -->
      <ng-container matColumnDef="header-row-optC-group">
        <th class="section-separator" mat-header-cell *matHeaderCellDef [attr.colspan]="3"
          [ngClass]="{'hide':hideOptC,option:true}"> Option C </th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 
      'header-row-ref-group','header-row-target-group','header-row-optA-group','header-row-optB-group',
      'header-row-optC-group']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'rowStyle':costTypeList.includes(row.CostType),
        'noGap':costTypesForNoRowgap.includes(row.CostType)}"></tr>
    </table>
  </div>

  <div class="grid-div" *ngIf="showOpw">
    <div class="label-header">
      <label class="label-headercolor">OPW Productivity (</label>
      <label class="label-headercolor" style="font-size: 0.55rem !important;"> If total is not divisable by 12 w/o
        remainder, 11 months will be linearly populated; remainder in 12. month; please change as needed)</label>
    </div>

    <ag-grid-angular id="myGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine"
      [headerHeight]="headerHeight" [rowData]="opwProductivityRowData" [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef" [domLayout]="domLayout" [rowClassRules]="rowClassRules"
      (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
      (cellValueChanged)="onCellValueChanged($event)" (gridSizeChanged)="onGridSizeChanged($event)">
    </ag-grid-angular>
  </div>
  <div class="grid-div" *ngIf="showCost">
    <div class="label-header">
      <label class="label-headercolor">Decision Support</label>
    </div>
    <ag-charts-angular  [options]="options">
    </ag-charts-angular>
  </div>

  <div class="row">
    <div class="offset-4 col-md-8" style="margin-top: 10px;">
      <div class="buttonStyle">
        <button class="button button--small" type="submit" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" type="submit"
          (click)="onClickBack()">Back</button>&nbsp;
        <button class="button button--small" [disabled]="submitted || !isEditable" type="submit"
          (click)="submit($event)">Save & Exit</button>
      </div>
    </div>
  </div>
</div>