import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HelpService } from '../../services/help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HelpComponent implements OnInit {
  helpText: any;
  helpId: any = 1;
  helpText1: any;

  constructor(private helpService: HelpService) { }
  ngOnInit(): void {
    this.helpService.helpIdEvent.subscribe(
      (response) => {
        if (document.getElementById("helpText")) {
          document.getElementById("helpText").innerHTML = response
        }
      }
    )
  }
}
