import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInfo } from '../models/user-info';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';

@Injectable({
  providedIn: 'root'
})
export class UserReplacementService {

  
  urlForUserInfo:string=environment.baseURL+"api/userinfo/getuserinfo";
  urlForGetUserList:string=environment.baseURL+"api/userinfo/GetAllActiveAndInActiveUsers"
  urlFoInsertUpdateUserInfo:string=environment.baseURL+"api/userinfo/RemoveUserTrace"
  urlForRole=environment.baseURL+"api/Master/GetAllData?masterTypeId="+EnumMasterType.Role;
  urlForBusinessArea=environment.baseURL+"api/Master/GetAllData?masterTypeId="+EnumMasterType.BusinessArea;
  urlForBusinessLine=environment.baseURL+"api/Master/GetAllData?masterTypeId="+EnumMasterType.BusinessLine;
  constructor(private http:HttpClient) { }

  getUserList(businessAreaId?: number, businessLineId?: number):Observable<UserInfo>{
    return this.http.get<UserInfo>(this.urlForGetUserList+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId)
  }
  removeUser(UserInfo):Observable<UserInfo>{
    return this.http.post<UserInfo>(this.urlFoInsertUpdateUserInfo,UserInfo)
  }
  getRoleMasterData(){
    return this.http.get<UserInfo>(this.urlForRole)
  }
  getBusinessAreaMasterData() {
    return this.http.get<UserInfo>(this.urlForBusinessArea)
  }
  getBusinessLineMasterData() {
    return this.http.get<UserInfo>(this.urlForBusinessLine)
  }
}
