import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { messages } from '../popUpMessages/messages';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})

export class AlertDialogComponent  {
  // contains all error message in a sequence to the field.
  public errorMessage1 = [];
  public errorMessage2 = [];
  public errorMessage3 = [];
  // contains all field values.
  public field1 = [];
  public error1 = false;
  public error2 = false;
  public field2 = [];
  public showOkCancel = true;
  public showOk = false;
  public showCancel = false;

  public noBulletCheck = false;
  public bulletCheck = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sharedService: SharedServiceService) {
    this.showOkCancel = true;
    this.showOk = false;
    this.showCancel = false;
    //  data is recieved in the format -- [{field:message},{field:message},{field:message}.....].
    //  the field value should be Camel Case and two words separated by '_'.. example - Form_Data_Value.
    
    for (let i of data) {
      let key = Object.keys(i)[0]
      let message = Object.values(i)[0]
      let check: boolean;
      key = key.split('_').join(' ')
      if (this.errorMessage1.length == 0) {
        this.errorMessage1.push(message)
        this.field1.push(key)
      } else if (this.errorMessage1[0] === message) {
        this.errorMessage1.push(message);
        this.field1.push(key)
      } else {
        this.errorMessage2.push(message);
        this.field2.push(key)
      }
    }

    // this condition used to check whether the alert message is warning or any functionality based box;
    if (this.field1.includes("Option A") || this.field1.includes("Option B")
      || this.field1.includes("Option C") || this.field2.includes("Option A")
      || this.field2.includes("Option B") || this.field2.includes("Option C") ||
      this.field1.includes(messages.categorization.plcChangeToNotRequiredMessage) || this.field1.includes(messages.categorization.plcChangeToRequiredMessage)
      || this.field2.includes(messages.categorization.plcChangeToNotRequiredMessage) || this.field2.includes(messages.categorization.plcChangeToRequiredMessage)
      || this.field1.includes(messages.admin.actualsAndPlanunits.waitMessage)|| this.field1.includes(messages.admin.actualsAndPlanunits.waitMessage)
    ) {
      this.showOkCancel = false;
      this.showOk = true;
      this.showCancel = true;
      if (this.field1.includes(messages.categorization.plcChangeToNotRequiredMessage) || this.field1.includes(messages.categorization.plcChangeToRequiredMessage)
        || this.field2.includes(messages.categorization.plcChangeToNotRequiredMessage) || this.field2.includes(messages.categorization.plcChangeToRequiredMessage)) {
        this.noBulletCheck = true;
        this.bulletCheck = false;
      } else {
        this.noBulletCheck = false;
        this.bulletCheck = true;
      }
      if (this.errorMessage1.includes(messages.nonPLCSummary.validationMessage) || this.errorMessage2.includes(messages.nonPLCSummary.validationMessage)
        || this.errorMessage1.includes(messages.PLCSummary.validationOPWTotalMessage) || this.errorMessage2.includes(messages.PLCSummary.validationOPWTotalMessage)
      ) {
        this.showCancel = false;
      }
      if(this.field1.includes(messages.admin.actualsAndPlanunits.waitMessage))
      {
        this.showOkCancel = false;
        this.showOk = false;
        this.showCancel = false;
      }
    }
  }

  // gets called when ok button is clicked on the box;
  callEventEmitrOk() {
    this.sharedService.onIdeaformCheckOption();
    this.sharedService.catgorizationOnClickOk.emit()
  }

  // gets called when cancel button is clicked on the box;
  callEventEmitrCancel() {
    this.sharedService.onIdeaFormCheckCancel();
    this.sharedService.catgorizationOnClickCancel.emit();
  }
  
}
