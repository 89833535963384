import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '../toaster/toaster.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  constructor(private snackbar:MatSnackBar) { }

  notify(displaMessage:string,messageType:string){
    this.snackbar.openFromComponent(ToasterComponent,{
      data:{
        message:displaMessage
      },
      duration:5000,
      horizontalPosition:'right',
      verticalPosition:'top',
      panelClass:messageType
    })
  }
}
