import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";


@Component({
  selector: 'numeric-value',
  template: `<input
  type="number" id="input"
  [(ngModel)]="value" 
  #input
  style="width: 100%; border: none !important;outline-color:#D3D3D3;position:absolute;"
/>`
})

export class NumericvalueEditorComponent implements AfterViewInit {
    public params: any;
    public value: any;
    cancelBeforeStart:any
    @ViewChild('input', { read: ViewContainerRef })
    public input: ViewContainerRef;
  
    ngAfterViewInit() {
      // focus on the input
      setTimeout(() => this.input.element.nativeElement.focus());
    }
  
    agInit(params: any): void {
    this.params = params;
    if (params.value == null) {     
      this.value=null     
    }
    else {
      this.value = parseFloat(this.params.value)
    }
  }

  getValue() {
    if (this.value == null || this.value==0){
      return null;
    }
    else {
      return Number(parseFloat(this.value).toFixed(2));
    }   
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  } 
}
