import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { Dirty } from 'src/app/interface/dirty-interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DeleteiconComponent } from '../../../../pages/deleteIconNormalGrid/deleteicon.component';
import { DirectCostService } from '../../../../services/direct-cost.service';
import { DoublingEditorComponent } from '../../../non-plc/costsavings/doubling-editor.component';
import { MenuService } from 'src/app/services/menu.service';
import { messages } from '../../../../popUpMessages/messages';
import { serverMessage } from '../../../../popUpMessages/serverMessage';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';
let countryCode;
@Component({
  selector: 'app-directcost-option-a',
  templateUrl: './directcost-option-a.component.html',
  styleUrls: ['./directcost-option-a.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DirectcostOptionAComponent implements OnInit, Dirty {
  public newComponentIdA:number=0;
  public isEditable: boolean = false;
  public regex = new RegExp(/[^\\s]+/);
  public OptionARowData: any;
  public OptionAColumnDef: any;
  public defaultProperties: any;
  public headerHeightRef: any;
  public pinnedBottomRowData: any;
  public domLayout: any;
  public rowHeight: any;
  public total: any;
  public hasUnsavedChanges: boolean = false;
  // hover 
  public frameworkComponents;
  public rowStyle;
  // fot total row color
  public rowClassRules;;
  public getRowHeight;
  // for IdeaId and SolutionId
  public ideaId: number;
  public solutionIdReference: any;
  public solutionIdOptionA: any;
  public UserId: number;
  // for storing Data coming from API Call;
  public referenceDataReceived: any;
  public optionADataReceived: any;
  // for last total row data coming from API Call;
  public optionATotalRowData: any; 
  // load Grid after loading data;
  public showGrid = false;
  public CreatedBy: any;
  public ModifiedBy = null;
  public referenceTotalRow: any;
  public optionADirectCostSumId: any;
  //will contain only that Grid row whose data is modified or new row;
  public modifiedRows = [];
  // will store the ideaId of submitter;
  public ideaSubmitterId: any;
  public finalErrorOptionA = [];
  // target scenerio
  public gridApiOptionA: any;
  public gridColApiTarget: any;
  public pinnedBottomRowDataTarget: any;
  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;
  public submitted: boolean = false;
  
  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  constructor(
    private localStorageService: LocalStorageService,
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private directCostService: DirectCostService,
    public dialog: MatDialog,
    private LocalStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService
    ) { }
  ngOnInit(): void {

    this.newComponentIdA=0;

    this.sharedService.getIdeaIdAndtitle();

    this.remarkId = EnumRemarkType.DirectCost;
    //Get Country Code for currency;

    countryCode = this.LocalStorageService.get('countryCode');
    this.sharedService.plcDirectCostOptionA.subscribe((response) => {
      this.onCellValueChanged(1);
      this.onRowClicked(1);
     
      this.hasUnsavedChanges = true;
      if (response.DirectCostId != 0) {
        response.IsActive = false;
      
        let isPushed = false
        if (this.modifiedRows.length == 0) {
          this.modifiedRows.push(response)
          isPushed = true
        }
        else {
          this.modifiedRows.forEach(row => {
            if (row.DirectCostId == response.DirectCostId) {
              isPushed = true

            }
          });
        }
        if (!isPushed) {
          this.modifiedRows.push(response)
        }
      }
    
    });
    this.solutionIdReference = MSolutionType.ReferenceScenario;
    this.solutionIdOptionA = MSolutionType.OptionA;
    this.ideaId = this.localStorageService.get('ideaId');
    this.showGrid = false;

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {
              this.ideaSubmitterId = this.userInOrg.UserId;

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadReferenceGridData();
            }
          })
        })
      }
    })
  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if(pageLockMessage != ""){
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  // will load reference row data through API calls;
  loadReferenceGridData() {
    this.directCostService.getAllData(this.ideaId, this.solutionIdReference, this.userInOrg.UserId).subscribe((data) => {
      if (data == null) {
        return;
      }
      let referenceOnlyTotalDataRec: any;
      this.referenceDataReceived = data;     
      if (this.referenceDataReceived.StatusCode == 200) {
        this.referenceDataReceived = this.referenceDataReceived.Data;
        referenceOnlyTotalDataRec = this.referenceDataReceived.DirectCostSums;
        if (referenceOnlyTotalDataRec === null) {
          this.referenceTotalRow = {
            FactorySum: 0,
            GrandSum: 0,
            LocalSum: 0,
            OtherSum: 0,
            Prdt_GrandSum: 0,
            Prdt_LaborSum: 0,
            Prdt_MaterialSum: 0,
            Prdt_OverheadSum: 0,
            SpclCostGrandSum: 0,
            Svc_GrandSum: 0,
            Svc_LaborSum: 0,
            Svc_MaterialSum: 0,
            Svc_TravelSum: 0,
          };
        }
        else {
          this.referenceTotalRow = referenceOnlyTotalDataRec;
        }
        this.loadOptionAGridData();
      } else {
        let errorMsgeFromBack =this.referenceDataReceived.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);      
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  }
  // load option A grid data through API call;
  loadOptionAGridData() {
    this.directCostService.getAllData(this.ideaId, this.solutionIdOptionA, this.userInOrg.UserId).subscribe((data) => {
      if (data == null) {
        return;
      }
     
      let OptionARowDataOnly: any;
      let OptionAOnlyTotalDataRecieved: any;
      this.optionADataReceived = data;
      if (this.optionADataReceived.StatusCode == 200) {
        this.optionADataReceived = this.optionADataReceived.Data;
        // for remark field;
        this.remarkDataRecieved = this.optionADataReceived.Remarks;
        OptionAOnlyTotalDataRecieved = this.optionADataReceived.DirectCostSums;
        OptionARowDataOnly = this.optionADataReceived.DirectCosts;
        // for remark field;
        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }
        if (OptionAOnlyTotalDataRecieved === null) {
          this.optionADirectCostSumId = 0;
        }
        else {
          this.optionADirectCostSumId = OptionAOnlyTotalDataRecieved.DirectCostSumId;
        }
        this.OptionARowData = [];
        for (let i of OptionARowDataOnly) {
          i['IsEditable'] = this.isEditable;
          this.OptionARowData.push({
            DirectCostId: i.DirectCostId,
            IdeaId: i.IdeaId,
            SolutionId: i.SolutionId,
            ComponentId: i.ComponentId,
            OptionAScenerioTitle: i.ComponentTitle,
            material1: i.Prdt_Material,
            labor1: i.Prdt_Labor,
            overhead: i.Prdt_Overhead,
            product: i.Prdt_Total,
            material2: i.Svc_Material,
            labor2: i.Svc_Labor,
            travel: i.Svc_Travel,
            service: i.Svc_Total,
            specialfactory: i.Factory,
            local: i.Local,
            others: i.Other,
            special: i.SpclCostTotal,
            totaldirectcost: i.GrandTotal,
            IsActive: i.IsActive,
            CreatedBy: i.CreatedBy,
            CreatedOn: i.CreatedOn,
            ModifiedBy: i.ModifiedBy,
            ModifiedOn: i.ModifiedOn,
            IsEditable: i.IsEditable
          })
        }

        this.loadGridStructure();
      } else {
        let errorMsgeFromBack =this.optionADataReceived.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);      
      }

    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  }
  // this will load the structure of grid to display the data coming from API Calls;
  loadGridStructure() {
    this.getRowHeight = params => params.data.OptionAScenerioTitle == 'Total' || params.data.OptionAScenerioTitle == 'Change to reference' ? 32 : 35;
    this.rowClassRules = {
      'colorTotal': function (params) {
        let data = params.data.OptionAScenerioTitle;
        return data === 'Total' || data === 'Change to reference';
      }
    }
    this.domLayout = 'autoHeight';
    this.defaultProperties = {     
      suppressMovable: true,
      sortable: false,
      enableValue: false,
      resizable: false
    }
   
    this.headerHeightRef = 62;
    this.rowStyle = {
      fontSize: '0.7rem'
    }
    // used for cell format decimal and german format
    this.frameworkComponents = {
      DoublingEditor: DoublingEditorComponent,
    };
    this.OptionAColumnDef = [
      {
        headerName: "Component",headerTooltip: "Component", field: "OptionAScenerioTitle", filter: true, singleClickEdit: true,width:300,
        cellStyle: (params) => (params.node.data.OptionAScenerioTitle != 'Total' && params.node.data.OptionAScenerioTitle != 'Change to reference' &&
         (params.data.OptionAScenerioTitle == null || params.data.OptionAScenerioTitle == '' ||
          !this.regex.test(params.data.OptionAScenerioTitle) )) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },        
        editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, tooltipField: 'OptionAScenerioTitle',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },
      },
      {
        headerName: "Material",headerTooltip: "Material", field: "material1", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
        editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Labor",headerTooltip: "Labor",  field: "labor1",tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
        editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Overhead", field: "overhead",headerTooltip: "Overhead", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Direct Product Costs",headerTooltip: "Direct Product Costs", field: "product",tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal', cellStyle: { textAlign: "left" }, valueGetter: directProductCost
      },
      {
        headerName: "Material",headerTooltip: "Material", field: "material2", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Labor",headerTooltip: "Labor", field: "labor2", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Travel",headerTooltip: "Travel", field: "travel", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Direct Service Costs",headerTooltip: "Direct Service Costs", field: "service", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" }, valueGetter: directServiceCost
      },
      {
        headerName: "Special Direct Costs Factory",headerTooltip: "Special Direct Costs Factory", cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', field: "specialfactory", tooltipValueGetter:CurrencyCellRenderer, editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Local Cost",headerTooltip: "Local Cost",  field: "local",tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Others",headerTooltip: "Others", field: "others", tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.OptionAScenerioTitle === 'Total' || params.node.data.OptionAScenerioTitle === 'Change to reference') {
            return false;
          }
          else {

            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Special Direct Costs",headerTooltip: "Special Direct Costs", field: "special", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, valueGetter: specialDirectCost,
        headerClass: 'right-border', cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Total Direct Costs",headerTooltip: "Total Direct Costs", field: "totaldirectcost", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, valueGetter: totalDirectCost,
        headerClass: 'right-border', cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Action",
        headerTooltip: "Action",
        filter: false,
        width:100,
        cellClass: 'center-align',
        cellRendererFramework: DeleteiconComponent,
        colId: "edit",
        sortable: false,
       
      }
    ]
    this.showGrid = true;
  }
  // get calls when grid is loaded and use to check the information related to grid;
  onGridReady(params) {
    this.gridApiOptionA = params.api;
    this.gridColApiTarget = params.columnApi;
    let countRow = this.gridApiOptionA.getDisplayedRowAtIndex(0);
    countRow=countRow==undefined?0:1;
    this.pinnedBottomRowDataTarget = createPinnedRow(this.referenceTotalRow, this.totalRowOptionA(),countRow);
  
    params.api.setPinnedBottomRowData(this.pinnedBottomRowDataTarget)
    let rowCnt = this.gridApiOptionA.getDisplayedRowCount();
    if (rowCnt >= 5) {
      this.gridApiOptionA.setDomLayout('normal');
      document.getElementById('tgtSceDrtCost').style.height = '269px';
    };
  }
  // this function is called when any cell in the row is clicked;
  onRowClicked($event) {
    let rowCount = this.gridApiOptionA.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApiOptionA.setDomLayout('autoHeight');
      document.getElementById('tgtSceDrtCost').style.height = null;
    };
  };
  // called when a cell is changed in a grid;
  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    let countRow = this.gridApiOptionA.getDisplayedRowAtIndex(0);
    countRow=countRow==undefined?0:1;
    this.pinnedBottomRowDataTarget = createPinnedRow(this.referenceTotalRow, this.totalRowOptionA(),countRow);
    this.gridApiOptionA.setPinnedBottomRowData(this.pinnedBottomRowDataTarget);
    if (params != 1) {
      if (params.data.DirectCostId != 0) {
        let isPushed = false;
        if (this.modifiedRows.length == 0) {
          this.modifiedRows.push(params.data)
          isPushed = true;
        }
        else {
          // same row changed;
          this.modifiedRows.forEach(row => {
            if (row.DirectCostId == params.data.DirectCostId) {
              isPushed = true;
            }
          });
        }
        // if another row is changed;
        if (!isPushed) {
          this.modifiedRows.push(params.data)
        }
      }
    }
  }
  // called when user click on add row icon;
  AddNewRow() {
    this.validateAddOptionA();
    let rowCount = this.gridApiOptionA.getDisplayedRowCount()
    if (rowCount == 5) {
      this.gridApiOptionA.setDomLayout('normal');
      document.getElementById('tgtSceDrtCost').style.height = '269px';
    }
  }
  // this validates the row before adding new row, check whether last row follow all validations;
  validateAddOptionA() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApiOptionA.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApiOptionA.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.newComponentIdA=this.newComponentIdA-1;
      this.hasUnsavedChanges = true;
      this.gridApiOptionA.applyTransaction({
        add: [{
          OptionAScenerioTitle: "",
          DirectCostId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionIdOptionA,
          ComponentId: this.newComponentIdA,
          material1: null,
          labor1: null,
          overhead: null,
          product: null,
          material2: null,
          labor2: null,
          travel: null,
          service: null,
          specialfactory: null,
          local: null,
          others: null,
          special: null,
          totaldirectcost: null,
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: this.ModifiedBy,
          ModifiedOn: "2021-04-13T15:16:43.223",
          IsEditable: this.isEditable
        }], addIndex: 0
      })
    } else {
      let data = this.gridApiOptionA.getDisplayedRowAtIndex(0).data;
      let checked = this.regex.test(data.OptionAScenerioTitle)
      if (data.OptionAScenerioTitle === "Add Component Here" || data.OptionAScenerioTitle === "" || !checked) {
        error.push({ Component: errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.newComponentIdA=this.newComponentIdA-1;
        this.gridApiOptionA.applyTransaction({
          add: [{
            OptionAScenerioTitle: "",
            DirectCostId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionIdOptionA,
            ComponentId: this.newComponentIdA,
            material1: null,
            labor1: null,
            overhead: null,
            product: null,
            material2: null,
            labor2: null,
            travel: null,
            service: null,
            specialfactory: null,
            local: null,
            others: null,
            special: null,
            totaldirectcost: null,
            IsActive: true,
            CreatedBy: this.ideaSubmitterId,
            CreatedOn: "2021-04-13T15:16:43.223",
            ModifiedBy: this.ModifiedBy,
            ModifiedOn: "2021-04-13T15:16:43.223",
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      } else {
        this.openAlertDialog(error)

      }
    }
  }
  // used to display popup error message;
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  canDeactivate() {
    return this.hasUnsavedChanges;
  }
  // gets called to resize the Grid;
  onGridTgtSizeChanged(params) {
    this.gridApiOptionA = params.api;
    this.gridApiOptionA.sizeColumnsToFit();
  }
  //calculate the total of all row for the Row - "Total" in the Target scenerio table;
  totalRowOptionA() {
    let material1 = 0;
    let labor1 = 0;
    let overhead = 0;
    let product = 0;
    let material2 = 0;
    let labor2 = 0;
    let travel = 0;
    let service = 0;
    let specialfactory = 0;
    let local = 0;
    let others = 0;
    let special = 0;
    let totaldirectcost = 0;
    this.gridApiOptionA.forEachNode(function (node) {
      material1 = Number(node.data.material1) + material1;
      labor1 = Number(node.data.labor1) + labor1;
      overhead = Number(node.data.overhead) + overhead;
      product = Number(node.data.product) + product;
      material2 = Number(node.data.material2) + material2;
      labor2 = Number(node.data.labor2) + labor2;
      travel = Number(node.data.travel) + travel;
      service = Number(node.data.service) + service;
      specialfactory = Number(node.data.specialfactory) + specialfactory;
      local = Number(node.data.local) + local;
      others = Number(node.data.others) + others;
      special = Number(node.data.special) + special;
      totaldirectcost = Number(node.data.totaldirectcost) + totaldirectcost;
    })
    return [material1, labor1, overhead, product, material2, labor2, travel, service, specialfactory, local, others, special, totaldirectcost];
  }
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark !=null && this.remark!=undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
  // saves  all the data after doing mandatory validations;
  submit(event?: any) {
    this.finalErrorOptionA = [];
    this.submitted = true;
    let allOptionARowData = this.allOptionARowData();  
    let remarkPresent = this.checkRemarkChange(); 
    if (allOptionARowData == null) {
      let alertBoxError = [];
      alertBoxError = this.finalErrorOptionA;
      this.modifiedRows = this.modifiedRows.filter(newRow => newRow.DirectCostId != 0)
      this.openAlertDialog(alertBoxError);
      this.submitted = false;
    } else {
      if (allOptionARowData.length != 0 || remarkPresent) {
        let combinedDataToSend = this.dataToSend(allOptionARowData);        
        let responseA: any;       
        return this.directCostService.postAllData(combinedDataToSend).toPromise().then((response) => {
          responseA = response;         
          let successCode = responseA.StatusCode;
          let errorCode = responseA.Data.ErrorCode;
          let errorMsgeFromBack = responseA.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.newComponentIdA=0;
              this.hasUnsavedChanges = false;
              if (event != undefined) {
                this.router.navigate(['/manageIdea'])
              }
              return true;
            }
            else {
              //Show errorCode and errorMessage in the UI
              this.modifiedRows = this.modifiedRows.filter(newRow => newRow.DirectCostId != 0);
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          } else {
            //Show errorCode and errorMessage in the UI
            this.modifiedRows = this.modifiedRows.filter(newRow => newRow.DirectCostId != 0);
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }        
        }, (error) => {   
          this.modifiedRows = this.modifiedRows.filter(newRow => newRow.DirectCostId != 0)                  
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.submitted = false;
          return false;
        }
        )
      } else if (allOptionARowData.length == 0) {       
        this.hasUnsavedChanges = false;
        if (event != undefined) {
          this.router.navigate(['/manageIdea']);
        }
        return true;
      }

    }
  }
  // this will fetch all the data present in the grid of the Target Scenerio;
  allOptionARowData() {
    let rowData = [];
    this.gridApiOptionA.forEachNode((node) => { rowData.push(node.data) });
    let error = this.vallidateAllRow(rowData);
    if (error) {
      return null;
    } else {
      rowData.forEach((row) => {
        if (row.DirectCostId == 0) {
          this.modifiedRows.push(row)
        }
      })
      return this.modifiedRows;
    }
  }
  // it will vallidate the all target scenerio data befor sending it to the dataBase;
  vallidateAllRow(rowData) {
    let countError = 0;
    for (let i of rowData) {
      let checked = this.regex.test(i.OptionAScenerioTitle);
      if (i.OptionAScenerioTitle == undefined || i.OptionAScenerioTitle == null || i.OptionAScenerioTitle == "" || !checked) {
        if (countError == 0) {
          this.finalErrorOptionA.push({ Component: messages.cellEmptyErrorMesssage })
        }
        countError += 1;
      }
    }
    if (this.finalErrorOptionA.length) {
      return true;
    } else {
      return false;
    }
  }
  //Converts the vallidated data which is to be send in the database into require DTO; 
  dataToSend(OptionAData) {
    let IdeaId = this.ideaId;
    let DirectCosts = [];
    let DirectCostSums: any;
    let combinedData: any;
    for (let j of OptionAData) {
      let modifierId = null
      if (j.DirectCostId != 0) {
        modifierId = this.ideaSubmitterId;
      }

      DirectCosts.push({
        DirectCostId: j.DirectCostId,
        IdeaId: j.IdeaId,
        SolutionId: j.SolutionId,
        ComponentId: j.ComponentId,
        ComponentTitle: j.OptionAScenerioTitle.trim(),
        Prdt_Material: Number(j.material1) == 0 ? null : Number(j.material1),
        Prdt_Labor: Number(j.labor1) == 0 ? null : Number(j.labor1),
        Prdt_Overhead: Number(j.overhead) == 0 ? null : Number(j.overhead),
        Prdt_Total: Number(j.product),
        Svc_Material: Number(j.material2) == 0 ? null : Number(j.material2),
        Svc_Labor: Number(j.labor2) == 0 ? null : Number(j.labor2),
        Svc_Travel: Number(j.travel) == 0 ? null : Number(j.travel),
        Svc_Total: Number(j.service),
        Factory: Number(j.specialfactory) == 0 ? null : Number(j.specialfactory),
        Local: Number(j.local) == 0 ? null : Number(j.local),
        Other: Number(j.others) == 0 ? null : Number(j.others),
        SpclCostTotal: Number(j.special),
        GrandTotal: Number(j.totaldirectcost),
        IsActive: j.IsActive,
        CreatedBy: j.CreatedBy,
        CreatedOn: j.CreatedOn,
        ModifiedBy: modifierId,
        ModifiedOn: j.ModifiedOn
      })

    }
    let optionADirectCostSumId = this.optionADirectCostSumId;
    let solutionIdOptionA = this.solutionIdOptionA;
    this.optionATotalRowData = this.gridApiOptionA.pinnedRowModel.pinnedBottomRows[0].data;
    if (OptionAData.length == 0) {
      DirectCostSums = null;
    } else {
      DirectCostSums = {
        DirectCostSumId: optionADirectCostSumId,
        IdeaId: IdeaId,
        SolutionId: solutionIdOptionA,
        Prdt_MaterialSum: this.optionATotalRowData.material1,
        Prdt_LaborSum: this.optionATotalRowData.labor1,
        Prdt_OverheadSum: this.optionATotalRowData.overhead,
        Prdt_GrandSum: this.optionATotalRowData.product,
        Svc_MaterialSum: this.optionATotalRowData.material2,
        Svc_LaborSum: this.optionATotalRowData.labor2,
        Svc_TravelSum: this.optionATotalRowData.travel,
        Svc_GrandSum: this.optionATotalRowData.service,
        FactorySum: this.optionATotalRowData.specialfactory,
        LocalSum: this.optionATotalRowData.local,
        OtherSum: this.optionATotalRowData.others,
        SpclCostGrandSum: this.optionATotalRowData.special,
        GrandSum: this.optionATotalRowData.totaldirectcost,
        IsActive: true,
        CreatedBy: this.ideaSubmitterId,
        CreatedOn: "2021-04-13T15:16:43.223",
        ModifiedBy: this.ideaSubmitterId,
        ModifiedOn: "2021-04-13T15:16:43.223"
      }
    }
    let Remarks: any;
    if (this.remark !=null && this.remark!=undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remarks = {
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: solutionIdOptionA,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.DirectCost
        }
      } else {
        Remarks = null;
      }
    } else {
      Remarks = null;
    }
    combinedData = [{ DirectCosts: DirectCosts, DirectCostSums: DirectCostSums, Remarks: Remarks, IdeaId: IdeaId, SolutionId: solutionIdOptionA, UserId: this.ideaSubmitterId, BusinessAreaId: this.businessAreaId, BusinessLineId: this.businessLineId }];
    return combinedData;
  }
  // get called when user click on cancel and exit;
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }
  // move user to back page;
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }
  // moves user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }
};
// creates pinned bottom row which contains "Total" and "change to Reference" row;
function createPinnedRow(totalRowOptionA, prefix, countRow) {  
  let result = [];
  result.push({
    OptionAScenerioTitle: 'Total',
    material1: prefix[0],
    labor1: prefix[1],
    overhead: prefix[2],
    product: prefix[3],
    material2: prefix[4],
    labor2: prefix[5],
    travel: prefix[6],
    service: prefix[7],
    specialfactory: prefix[8],
    local: prefix[9],
    others: prefix[10],
    special: prefix[11],
    totaldirectcost: prefix[12]
  });
  if(countRow==0){
    result.push({
      OptionAScenerioTitle: 'Change to reference',
      material1: 0,
      labor1: 0,
      overhead:0,
      product: 0,
      material2: 0,
      labor2: 0,
      travel: 0,
      service: 0,
      specialfactory:0,
      local: 0,
      others: 0,
      special: 0,
      totaldirectcost: 0
    })
  }else if(countRow==1){
    result.push({
      OptionAScenerioTitle: 'Change to reference',
      material1: totalRowOptionA.Prdt_MaterialSum - prefix[0],
      labor1: totalRowOptionA.Prdt_LaborSum - prefix[1],
      overhead: totalRowOptionA.Prdt_OverheadSum - prefix[2],
      product: totalRowOptionA.Prdt_GrandSum - prefix[3],
      material2: totalRowOptionA.Svc_MaterialSum - prefix[4],
      labor2: totalRowOptionA.Svc_LaborSum - prefix[5],
      travel: totalRowOptionA.Svc_TravelSum - prefix[6],
      service: totalRowOptionA.Svc_GrandSum - prefix[7],
      specialfactory: totalRowOptionA.FactorySum - prefix[8],
      local: totalRowOptionA.LocalSum - prefix[9],
      others: totalRowOptionA.OtherSum - prefix[10],
      special: totalRowOptionA.SpclCostGrandSum - prefix[11],
      totaldirectcost: totalRowOptionA.GrandSum - prefix[12]
    })
  }  
 
  return result;
}
// calculates "Direct product cost";
function directProductCost(params) {
  params.data.product = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead);
  if (params.data.product == 0 && params.data.OptionAScenerioTitle != "Total" && params.data.OptionAScenerioTitle != 'Change to reference') {
    params.data.product = null;
  }
  return params.data.product;
};
// calculates "service costs"; 
function directServiceCost(params) {
  params.data.service = Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel);
  if (params.data.service == 0 && params.data.OptionAScenerioTitle != "Total" && params.data.OptionAScenerioTitle != 'Change to reference') {
    params.data.service = null;
  }
  return params.data.service;
};
// calculates "special direct cost";
function specialDirectCost(params) {
  params.data.special = Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
  if (params.data.special == 0 && params.data.OptionAScenerioTitle != "Total" && params.data.OptionAScenerioTitle != 'Change to reference') {
    params.data.special = null;
  }
  return params.data.special;
};
// calculates "total direct cost";
function totalDirectCost(params) {
  params.data.totaldirectcost = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead) + Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel) + Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
  if (params.data.totaldirectcost == 0 && params.data.OptionAScenerioTitle != "Total" && params.data.OptionAScenerioTitle != 'Change to reference') {
    params.data.totaldirectcost = null;
  }
  return params.data.totaldirectcost;
};
// used to format the cell containing currency value to german format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
};