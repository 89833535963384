<button
  (click)="invokeDeleteMethod(params)"
  *ngIf="delVisible"
  style="padding-top: 6px"
  title="Delete"
>
  <img *ngIf='!showRemoveIcon && userNotDeleted'
    src="../assets/icons/delete.svg"
    alt="DeleteIcon"
    width="25"
    height="25"
    style="vertical-align: top"
  />
  <img *ngIf='showRemoveIcon'
  src="../assets/icons/userSoftDelete.svg"
  alt="CloseIcon"
  width="24"
  height="24"
  style="vertical-align: top; margin-top: 5px;"
/>
</button>
