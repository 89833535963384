<app-progress-spinner *ngIf="showPinner"></app-progress-spinner>
<form [formGroup]="pageForm" (ngSubmit)="applyFilter(1)">
  <div class="container">
    <div class="card" style="height: 100%; width: 100%">
      <div class="card-header label">Change Log</div>
      <div class="card-body" style="padding: 10px">
        <!-- Page -->
        <div class="form-row">
          <!-- User -->
          <div class="form-group" class="col-md-1 col-lg-1 label-name">
            <label for="user" class="label">User </label>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="textfieldAudit js-textfield textfield-with-prefix">
              <form
                [formGroup]="userPeoplePicker"
                style="width: 100%; position: absolute"
                (click)="autofocus('res')"
              >
                <mat-form-field
                  appearance="legacy"
                  class="myPanel no-underline"
                >
                  <mat-select
                    style="
                      font-size: 0.7rem;
                      padding-left: 10px;
                      position: relative;
                      top: -17px;
                    "
                    aria-label="userPeoplePicker"
                    formControlName="userPeoplePicker"
                    (closed)="clearSearch('user')"
                    (click)="autofocus('user')"
                  >
                    <mat-form-field
                      [floatLabel]="'never'"
                      class="no-underline no-wrapper sticky-search"
                    >
                      <mat-label class="search-label"
                        >Search Email...</mat-label
                      >
                      <input
                        role="textbox"
                        style="
                          padding-left: 10px;
                          position: relative;
                          top: -2px;
                          border: 1px solid blue;
                          box-shadow: 0px 0px 6px;
                          border-radius: 2px;
                          width: 99%;
                          height: 20px;
                          bottom: 4px;
                          align-items: center;
                        "
                        matInput
                        #inputUserId
                        type="text"
                        [formControl]="searchInputUser"
                        (input)="fetchUserData($event, false, 'user')"
                        autocomplete="off"
                      />
                    </mat-form-field>
                    <mat-option class="mat-option-hide" disabled></mat-option>
                    <mat-select-trigger
                      role="textbox"
                      aria-label="peoplePicker"
                    >
                      <span *ngIf="userPeoplePicker.value">
                        {{
                          userPeoplePicker.get("userPeoplePicker").value
                            ? userPeoplePicker.get("userPeoplePicker").value
                                .FullName
                            : ""
                        }}
                      </span>
                    </mat-select-trigger>
                    <mat-option
                      class="font-control"
                      *ngFor="let user of userSearchData"
                      [value]="user"
                      (click)="assignValueId(user, 'user')"
                    >
                      {{ user.Email }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div class="col-md-1 col-lg-1">
            <button (click)="resetUser()" type="button">
              <img
                alt="adminIcon"
                src="../../../assets/icons/reload.svg"
                width="24"
                height="24"
              />
            </button>
          </div>
          <!-- User End -->

          <!-- Date -->
          <div class="form-group" class="col-md-1 col-lg-1 label-name">
            <label for="user" class="label">From </label>
          </div>
          <div class="col-md-2 col-lg-2">
            <input
              class="auditDate auditDateInvalid"
              type="text"
              aria-label="Datepicker"
              [matDatepicker]="dateFrom"
              placeholder=" mm/dd/yyyy"
              formControlName="dateFrom"
            />
            <mat-datepicker-toggle
              class="datePicker"
              matSuffix
              [for]="dateFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateFrom></mat-datepicker>
          </div>
          <div class="form-group" class="col-md-1 col-lg-1 label-name">
            <label for="user" class="label">To </label>
          </div>
          <div class="col-md-2 col-lg-2">
            <input
              class="auditDate auditDateInvalid"
              type="text"
              [min]="mindate"
              aria-label="Datepicker"
              [matDatepicker]="dateTo"
              placeholder="mm/dd/yyyy"
              formControlName="dateTo"
            />
            <mat-datepicker-toggle
              class="datePicker"
              matSuffix
              [for]="dateTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #dateTo></mat-datepicker>
          </div>
        </div>
        <!-- Date End -->
        <div class="form-row">
          <div class="form-group" class="col-md-12 col-lg-12 label-name">
            <button
              [disabled]="disableAppyFilter"
              class="button button--small"
              type="submit"
            >
              Apply Filter
            </button>
            &nbsp;
            <button
              [disabled]="disableRemoveFltr"
              class="button button--small"
              type="button"
              (click)="removeFilter(2)"
            >
              Remove Filter
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 paginator">
          <!-- page size dropdown -->
          <div *ngIf="showGrid">
            <label for="pageSize"
              >Page Size:
              <select
                (change)="onPageSizeChanged()"
                id="pageSize"
                aria-label="Pages"
              >
                <option value="10" selected="">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="all">All</option>
              </select>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" *ngIf="showGrid">
          
          <ag-grid-angular
            id="auditPageId"
            style="width: 100%; height: 560px; border: 1px solid #e6e6e6"
            class="ag-theme-alpine grid"
            [rowData]="apiAuditData"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefinition"
            [popupParent]="popupParent"
            (gridReady)="gridReady($event)"
            (gridSizeChanged)="onGridSizeChanged($event)"
            [pagination]="true"
            [animateRows]="true"
            [paginationPageSize]="paginationPageSize"
            [excelStyles]="excelStyles"
            (filterChanged)="onFilterChanged($event)"
            [enableBrowserTooltips]="true"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <!-- Buttons -->
    <div class="row" style="margin-top: 10px">
      <div class="offset-4 col-md-8">
        <div
          class="buttonStyle"
          style="float: right; margin-right: 5px; margin-bottom: 20px"
        >
          <button
            class="button button--small"
            type="button"
            (click)="onClickBack()"
          >
            Back</button
          >&nbsp;
          <button
            type="button"
            [disabled]="disableDownload"
            class="button button--small"
            (click)="downloadGrid()"
          >
            Download</button
          >&nbsp;
          <button
            type="button"
            class="button button--small"
            (click)="submit('exit')"
          >
            Exit
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
