<app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
<div *ngIf="showGrid" class="container">
    <div class="col-md-12 col-lg-12 label-header">
        <label class="label-headercolor">Option B</label>
    </div>
    <ag-grid-angular *ngIf="showGrid" style="width: 100%; border: 1px solid #e6e6e6;" id="reference"
        class="ag-theme-alpine" [columnDefs]="columnDefinationReference" [rowData]="rowDataReference"
        [getRowHeight]="getRowHeightTar" [rowClassRules]="rowClassRulesTar"
        [defaultColDef]="defaultProperties" [headerHeight]="headerHeightRef" [rowStyle]="rowStyle"
        [frameworkComponents]="frameworkComponents" [domLayout]="domLayout" [enableBrowserTooltips]="true"
        (gridReady)="onGridReadyReference($event)" (cellValueChanged)="onCellValueChangedReference($event)"
        (gridSizeChanged)="onGridTgtSizeChanged($event)" >
    </ag-grid-angular>
     <!-- Remark Changes -->
     <div *ngIf="showGrid" class="row row-height remark_style"  >
        <div class="col-md-3 col-lg-3">
          <label>Additional Details </label>
        </div>
        <div class="col-md-9 col-lg-9">
          <textarea
            id="txtareaRemarks"
            style="font-size: 0.7rem; width: 100%"
            aria-label="Remarks_Target"
            [(ngModel)]="remark"
            [disabled]="!isEditable"
            (input)="checkRemarkChange()"
            cols="75"
            rows="3"
            maxlength="1500"
            wrap="hard"
          ></textarea>
        </div>
      </div>

    <div class="row" style="margin-top: 100px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle">
                <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" type="submit" (click)="onClickBack()">Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" type="submit" (click)="onClickNext()">Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)" type="submit">Save & Exit</button>
            </div>
        </div>
    </div>
</div>