<app-progress-spinner *ngIf="!showIbase || submitted"></app-progress-spinner>
<div *ngIf="showIbase" class="container">
  <div class="label-header">
    <label class="label-headercolor">Target Scenario </label>
    <label class="label-headercolor" style="margin-left: 30px"
      >Attachment rate of productivity measure into existing IBase -%</label
    >
  </div>
  <ag-grid-angular
    *ngIf="showIbase"
    style="width: 100%; border: 1px solid #e6e6e6"
    class="ag-theme-alpine"
    id="tarIbase"
    [columnDefs]="tarColumnDefs"
    [rowData]="tarScenerioRowdata"
    [defaultColDef]="defaultTarColDef"
    [domLayout]="domLayout"
    [suppressRowTransform]="true"
    [headerHeight]="headerHeight"
    [rowHeight]="rowHeight"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridSizeChanged($event)"
    [frameworkComponents]="frameworkComponents"
    (cellValueChanged)="onCellValueChanged($event)"

    (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
  >
  </ag-grid-angular>
  <!-- Remark Changes -->
  <div *ngIf="showIbase" class="row row-height remark_style">
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea
        id="txtareaRemarks"
        style="font-size: 0.7rem; width: 100%"
        aria-label="Remarks_Target"
        [(ngModel)]="remark"
        [disabled]="!isEditable"
        (input)="checkRemarkChange()"
        cols="75"
        rows="3"
        maxlength="1500"
        wrap="hard"
      ></textarea>
    </div>
  </div>
  <div class="row" style="margin-top: 100px">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle" style="margin-right: 0px">
        <button class="button button--small" (click)="onClickCancel()">
          Cancel & Exit</button
        >&nbsp;
        <button
          class="button button--small"
          [disabled]="submitted"
          (click)="onClickBack()"
        >
          Back</button
        >&nbsp;
        <button
          class="button button--small"
          (click)="onClickNext()"
          [disabled]="submitted"
          type="submit"
        >
          Next</button
        >&nbsp;
        <button
          class="button button--small"
          [disabled]="submitted || !isEditable"
          (click)="submit($event)"
          type="submit"
        >
          Save & Exit
        </button>
      </div>
    </div>
  </div>
</div>
