<button
  (click)="copyData(params)"
  title="Copy"
  *ngIf="isCopyVisible"
  style="padding-right: 10px"
>
  <img src="../assets/icons/copy.svg" height="22" width="22" alt="Copy Icon" />
</button>
<button (click)="delData(params)" title="Delete" *ngIf="isDelVisible">
  <img
    src="../assets/icons/delete.svg"
    height="25"
    width="25"
    alt="DeleteIcon"
  />
</button>
