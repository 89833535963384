import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductdataService {

  readonly baseUrl = environment.baseURL;
  public urlGetProductData = "api/Master/GetProductDetails";
  public urlInsertProductData = "api/Master/InsertUpdateDeleteProduct";

  constructor(private http:HttpClient) { }
  getProductData(businessAreaId?: number, businessLineId?: number):Observable<any>   {
    return this.http.get<any>(this.baseUrl+this.urlGetProductData+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError))
  }
  postAllData(data):Observable<any>{
    return this.http.post<any>(this.baseUrl + this.urlInsertProductData,data).pipe(
      retry(1),
      catchError(this.handleError))
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
