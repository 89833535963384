export class Ibase{
    IbaseID : number;
    ProductId : number;
    ProductTitle: string;
    IdeaID : number;
    SolutionId : number;
    Unit : number;
    ConvRateYear1: number;
    ConvRateYear2: number;
    ConvRateYear3: number;
    ConvRateYear4: number;
    ConvRateYear5: number;
    ConvRateYear6: number;
    ConvRateYear7: number;
    ConvRateYear8: number;
    ConvRateYear9: number;
    ConvRateYear10: number;
    ConvRateYear11: number;
    ConvRateYear12: number;
    ConvRateYear13: number;
    ConvRateYear14: number;
    ConvRateYear15: number;
    ConvRateYear16: number;
    ConvRateYear17: number;
    ConvRateYear18: number;
    ConvRateYear19: number;
    ConvRateYear20: number;
    ImpactRateYear1: number;
    ImpactRateYear2: number;
    ImpactRateYear3: number;
    ImpactRateYear4: number;
    ImpactRateYear5: number;
    ImpactRateYear6: number;
    ImpactRateYear7: number;
    ImpactRateYear8: number;
    ImpactRateYear9: number;
    ImpactRateYear10: number;
    ImpactRateYear11: number;
    ImpactRateYear12: number;
    ImpactRateYear13: number;
    ImpactRateYear14: number;
    ImpactRateYear15: number;
    ImpactRateYear16: number;
    ImpactRateYear17: number;
    ImpactRateYear18: number;
    ImpactRateYear19: number;
    ImpactRateYear20: number;
    IsActive: boolean;
    CreatedBy: number;
    CreatedOn: Date;
    Headeryears:string;
}