import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { messages } from '../popUpMessages/messages';
import { MatDialog } from '@angular/material/dialog';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-managedata',
  templateUrl: './managedata.component.html',
  styleUrls: ['./managedata.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ManagedataComponent {
  help: boolean = true;
  userManagement: boolean = false;
  masterData: boolean = false;
  mdUnits: boolean = false;
  productManagement: boolean = false;
  userReplacement: boolean = false;
  actuals: boolean = false;
  periodLock:boolean = false;
  fxtranslation:boolean = false;

  //variables (flag) to check whether the changes are saved or not in the Admin changes -
  //before moving to other admin pages;
  public unsavedChanges: boolean = false;
  public unsavedPage: string = null;
  public calledPage: string = null;

  public currentPageVal = "";
  public navigationMessage: string = messages.admin.navigationMessage;
  pageNameArr = ['help', 'userManagement', 'masterData', 'mdUnits', 'productManagement', 'userReplacement', 'actuals','periodLock','fxtranslation'];

  // used to control the shifting of the tabs;
  @ViewChild('myTab') myTab: ElementRef<HTMLElement>;
  @ViewChild('userManagementTab') userManagementTab: ElementRef<HTMLElement>;
  @ViewChild('helpTab') helpTab: ElementRef<HTMLElement>;
  @ViewChild('userReplacementTab') userReplacementTab: ElementRef<HTMLElement>;
  @ViewChild('masterDataTab') masterDataTab: ElementRef<HTMLElement>;
  @ViewChild('MDunitTab') MDunitTab: ElementRef<HTMLElement>;
  @ViewChild('productManagementTab') productManagementTab: ElementRef<HTMLElement>;
  @ViewChild('actualsTab') actualsTab: ElementRef<HTMLElement>;
  @ViewChild('periodLockTab') periodLockTab: ElementRef<HTMLElement>;
  @ViewChild('fxtranslation') FxTranslationTab: ElementRef<HTMLElement>;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedServiceService) { }

  // ngOnInit(): void {
  // }

  // recieve the emited data from all the admin page;
  dataFromChild(valObj, pageName) {    
    let params = valObj.unsavedChanges;
    let saveButtonClick = valObj.saveButtonClick;

    this.unsavedPage = pageName;
    this.unsavedChanges = params;

    this[this.unsavedPage] = params;
    if (!params && !saveButtonClick) {

      this.openAdminPage(this.calledPage, "functionCall");
      this.changeTab(this.calledPage, pageName);
    }
    else if (saveButtonClick) {
      this.openAdminPage(pageName, "functionCall");
    }
  }

  // route user to home page;
  goToHomePage() {
    this.router.navigate(["/home"]);
  }

  // current opened page;
  currentPage() {
    for (let i of this.pageNameArr) {
      if (this[i]) {
        this.currentPageVal = i;
      }
    }
  }

  // open the particular admin page and check all the saving and validations before change;
  openAdminPage(pageName, insideCall?: any) {
    if (this.calledPage != pageName || insideCall != undefined) {
      this.currentPage();
      // Stores the page name for later use;
      this.calledPage = pageName;
      if (this.unsavedChanges) {
        this[pageName] = false;
        this.openDialogBox(pageName);
      } else {
        this[pageName] = true;
        for (let i of this.pageNameArr) {
          if (i != pageName) {
            this[i] = false;
          }
        }
      }
    }
  }

  // open dialog to take user's concern about any unsaved changes in the following admin pages;
  openDialogBox(pageName) {
    const dialogRef = this.dialog.open(DialogComponent, { data: { message: this.navigationMessage, okBtn: "Yes", cancelBtn: "No" } });
    dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        this[this.unsavedPage] = false;
        this[pageName] = true;
        this.unsavedChanges = false;

      } else if (result) {
        this.changeTab(this.unsavedPage, pageName);
        this.sharedService.saveAdimPage(this.unsavedPage);
      }
    })
  }


  // changes the tab as per requirement by changing the style(class) for element;
  changeTab(focsdPage, UnfocsdPage) {
    let tabId = focsdPage + "Tab";
    let unsavedPageID = document.getElementById(tabId);
    unsavedPageID.classList.remove('nav-link');   //remove the class
    unsavedPageID.classList.add('nav-link', 'active');   //add the class

    let currentTab = UnfocsdPage + "Tab";
    let currentPageID = document.getElementById(currentTab);
    currentPageID.classList.remove('nav-link', 'active');   //remove the class
    currentPageID.classList.add('nav-link');   //add the class
  }
}







