import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { ProductdataService } from 'src/app/services/productdata.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DeleteiconComponent } from '../../deleteIconNormalGrid/deleteicon.component';

@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductManagementComponent implements OnInit {

  public rowData: any;
  public columnDefs: any;
  public defaultColDef: any;
  public frameworkComponents: any;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;

  public alertErrorBox = [];
  public modifiedProductRows = [];
  public errorMessage = []
  public submitResponse: any;
  public postData: any;
  public loggedInUser: any;

  public showProduct: boolean = false;
  public getProductResponse: any;

  public parentIdDropdown: any[];
  public productTypeDropdown: any;
  public showSpinner: boolean = false;
  public deletedProduct = [];

  // changes;
  public adminSubscription:any;
  public prodctMangmntChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();


  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";

  constructor(
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private toast: ToasterService,
    private productService: ProductdataService
  ) { }

  ngOnInit(): void {
    this.deletedProduct = [];
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v,i,a)=>a.findIndex(v2=>(v2.BusinessAreaId===v.BusinessAreaId))===i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
        }
      }
    )
    //changes;
    this.adminSubscription=  this.sharedService.productManagement.subscribe(() => {
      this.submit();
    })
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.productManagementDeletedRows.subscribe(
      res => {
        this.deletedProduct = [];
        if (res.ProductId != 0) {
          // changes;
          let valToEmit = {
            unsavedChanges: true,
            saveButtonClick: false
          }

          this.unSavedChanges.emit(valToEmit);
          // end;
          let isPushed = false;
          res.IsActive = false;
          if (this.modifiedProductRows.length == 0) {
            this.modifiedProductRows.push(res)
            isPushed = true;
          }
          else {
            this.modifiedProductRows.forEach(row => {
              if (row.ProductId == res.ProductId) {
                isPushed = true;
              }
            });
          }
          if (!isPushed) {
            this.modifiedProductRows.push(res)
          }

          this.showSpinner = true;
          let deletedGroup = this.deleteChild(res);
          this.delPrdtFrmMdfdRw(deletedGroup);
        }
      }
    )
    this.bindProductData();
  }

  // makes isActive=false of all children of deleted product;
  deleteChild(params) {
    for (let i of this.rowData) {
      if (i.ParentId == params.ProductId) {
        i.IsActive = false;
        this.deletedProduct.push(i)
        this.deleteChild(i);
      }
    }
    return this.deletedProduct;
  }

  // it modifies the the modifiedrow by making isActive= false of the deleted product;
  delPrdtFrmMdfdRw(rowArray) {
    for (let res of rowArray) {
      if (res.ProductId != 0) {
        let isPushed = false;
        if (this.modifiedProductRows.length == 0) {
          this.modifiedProductRows.push(res)
          isPushed = true;
        }
        else {
          this.modifiedProductRows.forEach(row => {
            if (row.ProductId == res.ProductId) {
              row.IsActive = res.IsActive;
              isPushed = true;
            }
          });
        }
        if (!isPushed) {
          this.modifiedProductRows.push(res)
        }
      }
    }
    this.showSpinner = false;
  }

  updateBusinessLine() {
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.bindProductData();
  }

  updateProductList() {
    this.bindProductData();
  }

  // makes a GET call and arrange DTO as required in UI;
  bindProductData() {
    this.productService.getProductData(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((response) => {
      this.getProductResponse = response;
      let successCode = this.getProductResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getProductResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        //Root is given to those product which doesn't come under any other product;
        let masterData = [{ id: 0, name: 'ROOT' }];
        // this loop collect all product for the dropdown;
        for (let i of this.getProductResponse.Data) {
          i["ParentTitle"] = "";
          if (i.ProductType != 'version' && i.IsActive) {
            let obj = {
              id: 0,
              name: ""
            };
            obj["id"] = i.ProductId;
            obj["name"] = i.ProductTitle;
            masterData.push(obj);
          }
        }
        // this loop match the parent id with the product id and asign same to the dropdown;
        for (let i of this.getProductResponse.Data) {
          for (let j of masterData) {
            if (j.id === i.ParentId) {
              i["ParentTitle"] = j.name;
              i["ParentModel"] = {
                id: i.ParentId,
                name: i.ParentTitle
              }
            }
          }
        }

        //matching business ids with name
        for (let i of this.getProductResponse.Data) {
          i["BusinessArea"] = {
            id: i.BusinessAreaId,
            name: this.roleBusinessArea.find(ba => ba.BusinessAreaId == i.BusinessAreaId).BusinessAreaName
          }

          i["BusinessLine"] = {
            id: i.BusinessLineId,
            name: this.roleBusinessLine.find(ba => ba.BusinessLineId == i.BusinessLineId).BusinessLineName
          }

        }

        this.parentIdDropdown = masterData;
        this.rowData = this.getProductResponse.Data;
        this.productTypeDropdown = ['product', 'version'];
        this.loadProductGrid();

      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    })
  }

  loadProductGrid() {
    this.columnDefs = [
      {
        headerName: "Product Title",
        field: "ProductTitle",
        tooltipField: "ProductTitle",
        headerTooltip: "Product Title",
        editable: true,
        cellStyle: borderStyle
      },
      {
        headerName: "Parent",
        field: "ParentModel",
        tooltipField: "ParentModel.name",
        headerTooltip: "Parent",
        cellStyle: (params) => (params.data.ParentModel.id == null) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellEditor: 'agRichSelectCellEditor',
        singleClickEdit: true,
        cellRenderer: parentName,
        keyCreator: function (params) {
          return params.name;
        },
        cellEditorParams: {
          cellRenderer: parentName,
          values: this.parentIdDropdown
        },
        editable: true
      },
      {
        headerName: "Type",
        field: "ProductType",
        tooltipField: "ProductType",
        headerTooltip: "type",
        editable: function (params) {
          if (params.node.data.ProductId != 0) {
            return false;
          }
          else {
            return true;
          }
        },
        cellStyle: borderStyle,
        cellEditor: 'agRichSelectCellEditor',
        singleClickEdit: true,
        cellRenderer: selectProductType,
        cellEditorParams: {
          values: this.productTypeDropdown
        },
      },
      {
        headerName: "Business Area",
        headerTooltip: "Business Area",
        field: "BusinessArea",
        editable: false,
        tooltipField: "BusinessArea",
        cellRenderer: businessAreaName,
      },
      {
        headerName: "Business Line",
        headerTooltip: "Business Line",
        field: "BusinessLine",
        editable: false,
        tooltipField: "BusinessLine",
        cellRenderer: businessLineName,
      },
      {
        headerName: "Action",
        headerTooltip: "Action",
        cellRendererFramework: DeleteiconComponent,
        editable: false,
        filter: false,
      }
    ]
    this.defaultColDef = {
      editable: true,
      singleClickEdit: true
    };
    this.domLayout = 'autoHeight';
    this.showProduct = true;
  }

  onCellValueChanged(params) {
    // changes;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }

    this.unSavedChanges.emit(valToEmit);
    // end;
    if (params.data.ProductId != 0) {
      let isPushed = false;
      if (this.modifiedProductRows.length == 0) {
        params.data.ModifiedBy = this.loggedInUser.UserId;
        params.data.ModifiedOn = new Date().toISOString();
        this.modifiedProductRows.push(params.data)
        isPushed = true
      }
      else {
        this.modifiedProductRows.forEach(row => {
          if (row.ProductId == params.data.ProductId) {
            params.data.ModifiedBy = this.loggedInUser.UserId;
            params.data.ModifiedOn = new Date().toISOString();
            isPushed = true;
          }
        });
      }
      if (!isPushed) {
        this.modifiedProductRows.push(params.data);
      }
    }
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  AddNewProduct() {
    if (this.validateNewProduct()) {
      this.gridApi.applyTransaction({
        add: [{
          ProductId: 0,
          ProductTitle: null,
          ParentModel: {
            name: "Please Select",
            id: null
          },
          ParentId: null,
          ParentTitle: null,
          ProductType: null,
          BusinessArea: {
            name: this.roleBusinessArea.find(ba => ba.BusinessAreaId == Number(this.selectBusinessArea)).BusinessAreaName,
            id: Number(this.selectBusinessArea)
          },
          BusinessLine: {
            name: this.roleBusinessLine.find(bl => bl.BusinessLineId == Number(this.selectBusinessLine)).BusinessLineName,
            id: Number(this.selectBusinessLine)
          },
          IsActive: true,
          CreatedBy: this.loggedInUser.UserId,
          CreatedOn: new Date().toISOString(),
          ModifiedBy: null,
          ModifiedOn: null
        }],
        addIndex: 0 // To add the new row at top of the grid
      })
    }
  }

  validateNewProduct() {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = []
    let firstRow = this.gridApi.getDisplayedRowAtIndex(0);
    if (firstRow == undefined) {

      if (this.selectBusinessArea == "" || this.selectBusinessArea == null) {
        error.push({ "Business Area": errorMessage });
      }

      if (this.selectBusinessLine == "" || this.selectBusinessLine == null) {
        error.push({ "Business Line": errorMessage });
      }

      if (error.length == 0) {
        return true;
      }
      else if (error.length != 0) {
        this.openAlertDialog(error);
        return false;
      }
    }
    else {
      let regex = new RegExp(/\^s*$/);
      if (firstRow.data.ProductTitle == "" || firstRow.data.ProductTitle == null || regex.test(firstRow.data.ProductTitle)) {
        error.push({ "Product Title": errorMessage })
      }
      if (firstRow.data.ParentModel.id == null) {
        error.push({ "Parent": errorMessage })
      }
      if (firstRow.data.ProductType == "" || firstRow.data.ProductType == null) {
        error.push({ "Type": errorMessage })
      }
      if (error.length == 0) {
        return true;
      }
      else if (error.length != 0) {
        this.openAlertDialog(error);
        return false;
      }
    }
    return false;
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  submit(event?: any) {
    if (this.validateAllProducts()) {
      this.postData = this.dataTosend();
      if (this.postData.length != 0) {
        this.productService.postAllData(this.postData).subscribe(
          (res: any) => {
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.modifiedProductRows = [];
                this.deletedProduct = [];
                // changes;
                let valToEmit = {
                  unsavedChanges: false,
                  saveButtonClick: event == undefined ? false : true
                }

                this.unSavedChanges.emit(valToEmit);
                // end;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
                // loading the grid data after successfull save to display current changes in dropdown;
                this.bindProductData();
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer);
                return false;
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              return false;
            }
          },
          (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })
      } else {
        // changes;
        let valToEmit = {
          unsavedChanges: false,
          saveButtonClick: event == undefined ? false : true
        }

        this.unSavedChanges.emit(valToEmit);
        // end; 
      }
    }
  }

  validateAllProducts() {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let error = []
    let regex = new RegExp(/\^s*$/);
    this.gridApi.forEachNode((row) => {
      if (row.data.ProductTitle == "" || row.data.ProductTitle == null || regex.test(row.data.ProductTitle)) {
        error.push({ "Product Title": errorMessage })
      }
      if (row.data.ParentModel.id == null) {
        error.push({ "Parent": errorMessage })
      }
      if (row.data.ProductType == "" || row.data.ProductType == null) {
        error.push({ "Type": errorMessage })
      }
    });
    if (error.length == 0) {
      return true;
    }
    else if (error.length != 0) {
      this.openAlertDialog(error);
      return false;
    }
  }

  dataTosend() {
    let newProductRows = [];
    this.gridApi.forEachNode(
      (row) => {
        if (row.data.ProductId == 0) {
          newProductRows.push(row.data);
        }
      }
    )
    this.modifiedProductRows = this.modifiedProductRows.concat(newProductRows);
    let dataToSend = [];
    for (let i of this.modifiedProductRows) {
      let obj = {
        CreatedBy: i.CreatedBy,
        CreatedOn: i.CreatedOn,
        IsActive: i.IsActive,
        ModifiedBy: i.ModifiedBy,
        ModifiedOn: i.ModifiedOn,
        ParentId: i.ParentModel.id,
        ParentTitle: i.ParentModel.name,
        ProductId: i.ProductId,
        ProductTitle: i.ProductTitle,
        ProductType: i.ProductType,
        BusinessAreaId: Number(this.selectBusinessArea),
        BusinessLineId: Number(this.selectBusinessLine)
      }
      dataToSend.push(obj);
    }
    return dataToSend;
  }

  onClickCancel() {
    // changes;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.unSavedChanges.emit(valToEmit);

    // end;
    this.bindProductData();
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
       this.adminSubscription.unsubscribe()
     }
 }
}

const parentName = (params) => params.value.name;
const businessAreaName = (params) => params.value.name;
const businessLineName = (params) => params.value.name;

function borderStyle(params) {
  let regex = new RegExp(/\^s*$/);
  if (params.value == null || (params.value == "" && params.value != 0) || regex.test(params.value)) {
    return { borderLeft: "5px solid #a94442" }
  }
  else {
    return { borderLeft: "" }
  }
}

function selectParentId(params) {
  let result = params.value
  return result
}

function selectProductType(params) {
  let result = params.value
  return result
}
