import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActualUnitsService {

  constructor(private http : HttpClient) { }
  private urlGetActualUnits:string = environment.baseURL + "api/ActualUnit/GetActualUnitDetails";
  private urlInsertUpdateActualUnit:string = environment.baseURL + "api/ActualUnit/InsertUpdateActualUnit";

  //This is used to get ActualUnit data from Backend
  getAllActualUnits(businessAreaId?: number , businessLineId?: number): Observable<any>{
    return this.http.get<any>(this.urlGetActualUnits+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  //This is used to insert and Update the Actual Unit data
  updateData(data): Observable<any>{
    const httpOptions= {
      headers : new HttpHeaders({'Content-Type' : 'application/json'})
    }
    return this.http.post<any>(this.urlInsertUpdateActualUnit,data,httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
