import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PlcSummaryService } from 'src/app/services/plc-summary.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NumericEditorComponent } from '../../non-plc/costsavings/numeric-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { MenuService } from 'src/app/services/menu.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';


let countryCode;
@Component({
  selector: 'app-plc-summary',
  templateUrl: './plc-summary.component.html',
  styleUrls: ['./plc-summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlcSummaryComponent implements OnInit, Dirty {
  public show: boolean = true;
  public show1: boolean = true;
  public show2: boolean = true;
  public show3: boolean = true;
  public dataSource: any;
  public subdataSource: any;

  public hideOptA: boolean = true;
  public hideOptB: boolean = true;
  public hideOptC: boolean = true;
  public hideYears: boolean = true;
  public hideYears1: boolean = true;
  public hideYears2: boolean = true;
  public hideYears3: boolean = true;

  public rowData: any;
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public headerHeightRef: number;
  public successMessage: any;
  public errorMessage: any;

  public PlcRowHeight: any;
  public PlcHeaderHeight: any;

  public getRowStyle: any;
  public rowStyle: any;

  public loginUserDetails: any;
  public ideaId: any;
  public getResponse: any;
  public showPlcSummaryCosts = false;
  public showPlcSummaryOPW = false;
  public frameworkComponents;
  public hasUnsavedChanges: boolean = false;
  public modifiedPlcOpwRows = [];
  public alertErrorBox = [];
  public ideaSubmitterId: number = 0;
  public submitted: boolean = false;
  public gridOPWStoredData: any[];
  public submitResponse: any;
  public isEditable: boolean = false;
  public showOPWGrid: boolean = false;
  public errorMessageUnmatch = [];

  public getPlcResponse: any;
  public productCostOptions: any = {};
  public changeinPlcOptions: any = {};
  public graph1Data: any = {};
  public graph2Data: any = {};

  public plcGridApi: any;
  public plcGridColumnApi: any;
  public aggRowData: any;
  public ChangeInPlcRowData: any;
  public ChangeInPlcColumnDefs: any;
  public defaultPlcColDef: any;
  public PLCSummaryAccData: any;
  public PLCSummaryChangeData: any;

  public showPLCGrid: boolean = false;
  public showPLCGraph: boolean = false;
  public showProductGraph: boolean = false;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;
  public priorActualUnitData = {};
  public isEditableMonth = {};

  constructor(
    private sharedService: SharedServiceService,
    private plcSummaryService: PlcSummaryService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private toast: ToasterService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService) {

    this.frameworkComponents = {
      numericEditor: NumericEditorComponent,
    };

    // Grid column structure and properties;
    this.ChangeInPlcColumnDefs = [
      {
        headerName: 'Scenario', field: 'Description', valueFormatter: scenarioName, tooltipValueGetter: scenarioName,
        headerTooltip: 'Scenario', width: 100, suppressSizeToFit: true,
        cellStyle: params => {
          if (params.data.Description !== 'Change in PLC vs. Reference acc.') {
            return { 'margin-left': '15px' };
          }
          return null;
        }, cellClass: 'plcChange'
      },
    ]

    this.defaultPlcColDef = {
      editable: false,
      sortable: false,
    }

    this.defaultColDef = {
      singleClickEdit: true,
      suppressMovable: true,
      sortable: false,
    }

    this.domLayout = 'autoHeight';
    this.headerHeight = 52;

    this.PlcRowHeight = 30;
    this.PlcHeaderHeight = 40;
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  ngOnInit(): void {
    this.errorMessage = [];
    //Get IdeaId;
    this.ideaId = this.localStorageService.get('ideaId');

    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {
              this.ideaSubmitterId = this.userInOrg.UserId;

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadPLCOPWSummaryData();
            }
          })
        })
      }
    })

    //Get Country Code;
    countryCode = this.localStorageService.get('countryCode');
    // Checks whether the field is editable or not;


    // perform GET call to fetch All Summary data from db;
    this.plcSummaryService.getPLCSummaryData(this.ideaId).subscribe((data) => {
      this.getPlcResponse = data;
      let successCode = this.getPlcResponse.StatusCode;
      this.PLCSummaryAccData = this.getPlcResponse.Data.PLCSummaryAccData;
      this.PLCSummaryChangeData = this.getPlcResponse.Data.PLCSummaryChangeData;

      if (successCode == 200) {
        this.BindPLCSummaryAccGraphData(this.PLCSummaryAccData);
        let headerYears = [];
        for (let i = 0; i < 10; i++) {
          headerYears.push(this.PLCSummaryChangeData[0].StartYear + i);
        }
        setTimeout(() => {
          this.setPlcGridData(headerYears);
        }, 1000)
        return true;
      }
      else {
        let errorMessage = serverMessage.serverErrorMessage;
        let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
        this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
        return false;
      }
    }, (error) => {
      this.errorMessage.push(error)
      this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
      return false;
    })
  }

  loadPLCOPWSummaryData() {
    // perform GET call to fetch OPW-Productivity data from db;
    this.plcSummaryService.getPLCSummaryOPWProductivity(this.ideaId).subscribe(
      (data: any) => {
        this.gridOPWStoredData = [];
        let getOPWResponse = data;
        let successCode = getOPWResponse.StatusCode;
        if (successCode != 200) {
          let errorMessage = serverMessage.serverErrorMessage;
          let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
          this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
          return false;
        }
        else if (successCode == 200) {
          this.sharedService.getIdeaIdAndtitle();
          if (getOPWResponse.Data[0].MonthColumns != null && getOPWResponse.Data[0].MonthColumns != "") {
            let monthData = getOPWResponse.Data[0].MonthColumns.split(",");
            this.gridOPWStoredData = this.bindOpwproductivityData(getOPWResponse.Data);
            this.setOPWGridData(monthData);
          }
          else {
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
            return false;
          }
        }
      }, (error) => {
        this.errorMessage.push(error);
        this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
        return false;
      })
  }

  checkOptionSelected(obj) {
    obj.forEach(element => {
      if (element == MSolutionType.OptionA) {
        this.hideOptA = false;
      }
      if (element == MSolutionType.OptionB) {
        this.hideOptB = false;
      }
      if (element == MSolutionType.OptionC) {
        this.hideOptC = false;
      }
    })
  }

  // organize the db data according with the table structure;
  bindOpwproductivityData(obj) {
    let opwProductivityData = [];
    let _bindData :any;

    let opwProductivityRowData = obj; let opwOptionSelected: any;
    if (opwProductivityRowData != null && opwProductivityRowData.length > 0) {
      opwOptionSelected = opwProductivityRowData[0].OptionSelected;
    }
    opwProductivityRowData.forEach(function (value, key) {
      if (value.SolutionId == 1 || value.SolutionId == 2 || value.SolutionId == 6 ||
        (opwOptionSelected?.includes(value.SolutionId))) {
        _bindData = {
          OpwproductivityId: value.OPWProductivityId,
          IdeaId: value.IdeaId,
          SolutionId: value.SolutionId,
         // Productivity: value.SolutionId == MSolutionType.Actuals ? 'Actual' : value.SolutionId == MSolutionType.TargetScenario ? 'Target Scenario' : value.SolutionId == MSolutionType.OptionA ? 'Option A' : value.SolutionId == MSolutionType.OptionB ? 'Option B' : 'Option C',
          Month1: this.checkNull(value.Month1),
          Month2: this.checkNull(value.Month2),
          Month3: this.checkNull(value.Month3),
          Month4: this.checkNull(value.Month4),
          Month5: this.checkNull(value.Month5),
          Month6: this.checkNull(value.Month6),
          Month7: this.checkNull(value.Month7),
          Month8: this.checkNull(value.Month8),
          Month9: this.checkNull(value.Month9),
          Month10: this.checkNull(value.Month10),
          Month11: this.checkNull(value.Month11),
          Month12: this.checkNull(value.Month12),
          Total: value.Total,
          MonthlyTotal: 0,
          ModifiedBy: this.ideaSubmitterId,
          IsAction: null
        }
        if (value.SolutionId == MSolutionType.Actuals) {
          _bindData.Productivity = 'Actual'
        }
        else if (value.SolutionId == MSolutionType.TargetScenario) {
          _bindData.Productivity = 'Target Scenario'
        }
        else if (value.SolutionId == MSolutionType.OptionA) {
          _bindData.Productivity = 'Option A'
        }
        else if (value.SolutionId == MSolutionType.OptionB) {
          _bindData.Productivity = 'Option B'
        }
        else if (value.SolutionId == MSolutionType.OptionC) {
          _bindData.Productivity = 'Option C'
        }
        opwProductivityData.push(_bindData);
      }
      if (value.SolutionId == 7 && value.OPWProductivityId == 0) {
        this.priorActualUnitData = {
          IdeaId: value.IdeaId,
          SolutionId: value.SolutionId,
          Month1: this.checkNull(value.Month1),
          Month2: this.checkNull(value.Month2),
          Month3: this.checkNull(value.Month3),
          Month4: this.checkNull(value.Month4),
          Month5: this.checkNull(value.Month5),
          Month6: this.checkNull(value.Month6),
          Month7: this.checkNull(value.Month7),
          Month8: this.checkNull(value.Month8),
          Month9: this.checkNull(value.Month9),
          Month10: this.checkNull(value.Month10),
          Month11: this.checkNull(value.Month11),
          Month12: this.checkNull(value.Month12),
          Total: value.Total,
          MonthlyTotal: 0,
          ModifiedBy: this.ideaSubmitterId,
          IsActive: null
        }
      }
      if (value.SolutionId == 99 && value.OPWProductivityId == 0) {
        this.isEditableMonth = {
          SolutionId: value.SolutionId,
          Month1: this.checkNull(value.Month1),
          Month2: this.checkNull(value.Month2),
          Month3: this.checkNull(value.Month3),
          Month4: this.checkNull(value.Month4),
          Month5: this.checkNull(value.Month5),
          Month6: this.checkNull(value.Month6),
          Month7: this.checkNull(value.Month7),
          Month8: this.checkNull(value.Month8),
          Month9: this.checkNull(value.Month9),
          Month10: this.checkNull(value.Month10),
          Month11: this.checkNull(value.Month11),
          Month12: this.checkNull(value.Month12),
        }
      }
    }.bind(this));
    return opwProductivityData;
  }

  checkNull(obj) {
    return obj == null || obj == "" ? 0 : obj;
  }

  setOPWGridData(obj) {
    this.columnDefs.push({
      field: 'Productivity', headerTooltip: 'Productivity', editable: false, width: 80, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {
        return params.value;
      }
    })
    obj.forEach((element, index) => {
      if (element != null && element != "") {
        this.columnDefs.push({
          headerName: element,
          headerComponentParams: (element) => {
            if(this.setHeaderLockIcon(element)){
              return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' } ;
            }
            else{
              return {template: '<div>' +element.displayName +'</div>'};
            }
          },
          field: 'Month' + (index + 1), headerTooltip: element,
          tooltipValueGetter: (element) => this.formatToolTip(element),
          cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element),
        });
      }
    })

    this.columnDefs.push({
      headerName: 'Delta monthly roll up vs. Total', headerTooltip: 'Delta monthly roll up vs. Total',
      cellRenderer: CurrencyCellRenderer,
      tooltipValueGetter: CurrencyCellRenderer, field: 'MonthlyTotal', editable: false, valueGetter: sumtotal
    })

    this.columnDefs.push({
      headerName: 'Total', field: 'Total', headerTooltip: 'Total',
      cellRenderer: CurrencyCellRenderer, valueGetter: actualTotal,
      tooltipValueGetter: CurrencyCellRenderer, editable: false
    })

    this.rowData = this.gridOPWStoredData;
    this.showPlcSummaryOPW = true;
    this.showOPWGrid = true;
  }

  formatToolTip(params) {
    if (params.data.SolutionId == 6) {
      let cell = params.colDef.field;
      let newValue = params.value;
      let oldValue = this.priorActualUnitData[cell];
      return "CurrentValue : " + newValue + "  " + " PriorValue :" + oldValue;
    }
    else {
      return params.value;
    }
  }

  setEditable(element) {
    return this.isEditableMonth[element.colDef.field] == 0 || this.isEditableMonth[element.colDef.field] == null ? true : false;
  }

  setHeaderLockIcon(element){
    return this.isEditableMonth[element.column.colId] == 0 || this.isEditableMonth[element.column.colId] == null ? false : true;
  }

  getDecimalFixed(obj) {
    return obj != null && obj != "" ? new Intl.NumberFormat(countryCode).format(obj.toFixed()) : "0";
  }

  setPlcGridData(obj) {
    let columnDefsGroup = []
    let columnDefs = this.plcGridApi.getColumnDefs();
    obj.forEach((element, index) => {
      columnDefs.push({
        headerName: element, field: 'Year' + (index + 1), headerTooltip: element, editable: false,
        tooltipValueGetter: (params) => {
          return params.value;
        },
      })
      if (index == 4) {
        columnDefs.push({
          headerName: 'Total acc. 5 years', field: 'SumOf5Years', headerTooltip: 'Total acc. 5 years', editable: false,
          tooltipValueGetter: (params) => {
            return params.value;
          },
        })
      }

      if (index == 9) {
        columnDefs.push({
          headerName: 'Total acc. 10 years', field: 'SumOf10Years', headerTooltip: 'Total acc. 10 years', editable: false,
          tooltipValueGetter: (params) => {
            return params.value;
          },
        })
      }
    })

    this.plcGridApi.setColumnDefs(columnDefs);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let rowCnt = this.gridApi.getDisplayedRowCount();
    if (rowCnt <= 2) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('plcOPWGrid').style.height = '135px';
    }
    else if (rowCnt > 2) {
      this.gridApi.setDomLayout('autoHeight');
    }
  }

  onChangeInPlcGridReady(params: any) {
    this.plcGridApi = params.api;
    this.plcGridColumnApi = params.columnApi;
  }

  onFirstDataRendered(params) {
    //setTimeout(() => this.gridApi.sizeColumnsToFit(), 500)
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onPlcGridSizeChanged(params) {
    this.plcGridApi = params.api;
    this.plcGridApi.sizeColumnsToFit();
  }

  BindPLCSummaryAccGraphData(obj) {
    let PLCSummaryAccData = [];
    let graph1 = {};
    let startYear;
    let referenceData = [];
    let targetData = [];
    let option_AData = [];
    let option_BData = [];
    let option_CData = [];
    let solution = [];
    PLCSummaryAccData = obj;
    PLCSummaryAccData.forEach(function (value, key) {
      solution = [];
      startYear = value.StartYear;
      let cumulateValue = 0;
      cumulateValue += (value.Year1 ?? value.Year1);
      solution.push(cumulateValue);
      cumulateValue += (value.Year2 ?? value.Year2);
      solution.push(cumulateValue);
      cumulateValue += (value.Year3 ?? value.Year3);
      solution.push(cumulateValue);
      cumulateValue += (value.Year4 ?? value.Year4);
      solution.push(cumulateValue);
      cumulateValue += (value.Year5 ?? value.Year5);
      solution.push(cumulateValue);
      cumulateValue += (value.Year6 ?? value.Year6);
      solution.push(cumulateValue);
      cumulateValue += (value.Year7 ?? value.Year7);
      solution.push(cumulateValue);
      cumulateValue += (value.Year8 ?? value.Year8);
      solution.push(cumulateValue);
      cumulateValue += (value.Year9 ?? value.Year9);
      solution.push(cumulateValue);
      cumulateValue += (value.Year10 ?? value.Year10);
      solution.push(cumulateValue);
      
      if (value.SolutionId == 1) {
        referenceData = solution;
      }
      else if (value.SolutionId == 2) {
        targetData = solution;
      }
      else if (value.SolutionId == 3) {
        option_AData = solution;
      }
      else if (value.SolutionId == 4) {
        option_BData = solution;
      }
      else if (value.SolutionId == 5) {
        option_CData = solution;
      }
    })

    let graph1Data = [];
    referenceData.forEach(function (value, key) {
      graph1 = {};
      graph1["Years"] = Number(startYear + key);
      graph1["Reference"] = referenceData[key];
      graph1["Target"] = targetData[key];
      graph1["Option A"] = option_AData[key];
      graph1["Option B"] = option_BData[key];
      graph1["Option C"] = option_CData[key];
      graph1Data.push(graph1)
    })

    this.productCostOptions = {
      data: graph1Data,
      autoSize: true,
      title: {
        text: 'Product Lifecycle costs acc. cumulative',
        fontSize: 10.4
      },
      padding: {
      },
      series: [{
        xKey: 'Years',
        yKey: 'Reference',
        yName: 'Reference',
        stroke: '#f3622d',
        marker: {
          fill: '#f3622d',
          stroke: 'grey',
        },
        tooltip: {
          renderer: function (params) {
            let regex ='/(?=({3})+(?!))/g';
            return {
              title: 'Reference',
            content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
            };
          },
        },
      },
      {
        xKey: 'Years',
        yKey: 'Target',
        yName: 'Target',
        stroke: '#fba71b',
        marker: {
          fill: '#fba71b',
          stroke: 'grey',
        },
        tooltip: {
          renderer: function (params) {
            let regex ='/(?=({3})+(?!))/g';
            return {
              title: 'Target',
              content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
            };
          },
        },
      },
      {
        xKey: 'Years',
        yKey: 'Option A',
        yName: 'Option A',
        stroke: '#57b757',
        marker: {
          fill: '#57b757',
          stroke: 'grey',
        },
        tooltip: {
          renderer: function (params) {
            let regex ='/(?=({3})+(?!))/g';
            return {
              title: 'Option A',
              content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
            };
          },
        },
      },
      {
        xKey: 'Years',
        yKey: 'Option B',
        yName: 'Option B',
        stroke: '#41a9c9',
        marker: {
          fill: '#41a9c9',
          stroke: 'grey',
        },
        tooltip: {
          renderer: function (params) {
            let regex ='/(?=({3})+(?!))/g';
            return {
              title: 'Option B',
              content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
            };
          },
        },
      },
      {
        xKey: 'Years',
        yKey: 'Option C',
        yName: 'Option C',
        stroke: '#4258c9',
        marker: {
          fill: '#4258c9',
          stroke: 'grey',
        },
        tooltip: {
          renderer: function (params) {
            let regex = '/(?=({3})+(?!))/g';
            return {
              title: 'Option C',
              content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
            };
          },
        },
      },

      ],
      axes: [
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              let regex = '/(?=({3})+(?!))/g';
              return params.value.toString().replace(regex, ",");
            }, fontSize: 10.5
          },
        },
        {
          type: 'number',
          position: 'bottom',
          label: {
            formatter: function (params) {
              return (params.value);
            }, fontSize: 10.5
          },
        },
      ],
      legend: { fontSize: 10.4, position: 'bottom' }
    }
    this.showProductGraph = true;
    this.bindPLCSummaryChangeGraphData(this.PLCSummaryChangeData);
  }

  bindPLCSummaryChangeGraphData(obj) {
    let PLCSummaryChangeData = [];
    let graph2 = {};
    let startYear;
    let targetData = [];
    let option_AData = [];
    let option_BData = [];
    let option_CData = [];
    let solution = [];
    let keys = [];
    PLCSummaryChangeData = obj;
    PLCSummaryChangeData.forEach(function (value, key) {
      if (value.Description == "Change in PLC vs. Reference acc.") {
        solution = [];
        startYear = value.StartYear;
        let cumulateValue = 0;
        cumulateValue += (value.Year1 ?? value.Year1);
        solution.push(cumulateValue);
        cumulateValue += (value.Year2 ?? value.Year2);
        solution.push(cumulateValue);
        cumulateValue += (value.Year3 ?? value.Year3);
        solution.push(cumulateValue);
        cumulateValue += (value.Year4 ?? value.Year4);
        solution.push(cumulateValue);
        cumulateValue += (value.Year5 ?? value.Year5);
        solution.push(cumulateValue);
        cumulateValue += (value.Year6 ?? value.Year6);
        solution.push(cumulateValue);
        cumulateValue += (value.Year7 ?? value.Year7);
        solution.push(cumulateValue);
        cumulateValue += (value.Year8 ?? value.Year8);
        solution.push(cumulateValue);
        cumulateValue += (value.Year9 ?? value.Year9);
        solution.push(cumulateValue);
        cumulateValue += (value.Year10 ?? value.Year10);
        solution.push(cumulateValue);
        // sol  ution.push(value.Year11 == null ? 0 : value.Year11);
        // solution.push(value.Year12 == null ? 0 : value.Year12);
        // solution.push(value.Year13 == null ? 0 : value.Year13);
        // solution.push(value.Year14 == null ? 0 : value.Year14);
        // solution.push(value.Year15 == null ? 0 : value.Year15);
        // solution.push(value.Year16 == null ? 0 : value.Year16);
        // solution.push(value.Year17 == null ? 0 : value.Year17);
        // solution.push(value.Year18 == null ? 0 : value.Year18);
        // solution.push(value.Year19 == null ? 0 : value.Year19);
        // solution.push(value.Year20 == null ? 0 : value.Year20);

        if (value.SolutionId == 2) {
          targetData = keys = solution;
        }
        else if (value.SolutionId == 3) {
          option_AData = keys = solution;
        }
        else if (value.SolutionId == 4) {
          option_BData = keys = solution;
        }
        else if (value.SolutionId == 5) {
          option_CData = keys = solution;
        }
      }
    })

    let graph2Data = [];

    keys.forEach(function (value, key) {
      graph2 = {};
      graph2["Years"] = Number(startYear + key);
      graph2["Target"] = targetData[key];
      graph2["Option A"] = option_AData[key];
      graph2["Option B"] = option_BData[key];
      graph2["Option C"] = option_CData[key];
      graph2Data.push(graph2)
    })
    this.changeinPlcOptions = {
      data: graph2Data,
      autoSize: true,
      title: {
        text: 'Change in PLC vs. Reference acc. cumulative',
        fontSize: 10.4
      },
      padding: {
      },
      series: [
        {
          xKey: 'Years',
          yKey: 'Target',
          yName: 'Target',
          stroke: '#fba71b',
          marker: {
            fill: '#fba71b',
            stroke: 'grey',
          },
          tooltip: {
            renderer: function (params) {
              let regex = '/(?=({3})+(?!))/g';
              return {
                title: 'Target',
                content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
              };
            },
          },
        },
        {
          xKey: 'Years',
          yKey: 'Option A',
          yName: 'Option A',
          stroke: '#57b757',
          marker: {
            fill: '#57b757',
            stroke: 'grey',
          },
          tooltip: {
            renderer: function (params) {
              let regex = '/(?=({3})+(?!))/g';
              return {
                title: 'Option A',
                content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
              };
            },
          },
        },
        {
          xKey: 'Years',
          yKey: 'Option B',
          yName: 'Option B',
          stroke: '#41a9c9',
          marker: {
            fill: '#41a9c9',
            stroke: 'grey',
          },
          tooltip: {
            renderer: function (params) {
              let regex ='/(?=({3})+(?!))/g';
              return {
                title: 'Option B',
                content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
              };
            },
          },
        },
        {
          xKey: 'Years',
          yKey: 'Option C',
          yName: 'Option C',
          stroke: '#4258c9',
          marker: {
            fill: '#4258c9',
            stroke: 'grey',
          },
          tooltip: {
            renderer: function (params) {
              let regex = '/(?=({3})+(?!))/g';
              return {
                title: 'Option C',
                content: params.xValue + ": " + params.yValue.toFixed(2).toString().replace(regex, ","),
              };
            },
          },
        },
      ],
      axes: [
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              let regex = '/(?=({3})+(?!))/g';
              return params.value.toString().replace(regex, ",");
            }, fontSize: 10.5
          },
        },
        {
          type: 'number',
          position: 'bottom',

          label: {
            formatter: function (params) {
              return params.value;
            }, fontSize: 10.5
          },
        },
      ],
      line: {
        highlightStyle: {
          fill: undefined,
          stroke: undefined,
        }
      },
      legend: { fontSize: 10.4, position: 'bottom' }
    }
    this.showPLCGraph = true;
    this.bindPLCSummaryChangeData(this.PLCSummaryChangeData);
  }

  bindPLCSummaryChangeData(obj) {
    let rowData = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowData = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {

      dataBind = {
        SolutionId: value.SolutionId,
        Description: value.Description,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        SumOf5Years: this.getDecimalFixed(value.Year1 + value.Year2 + value.Year3 + value.Year4 + value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        SumOf10Years: this.getDecimalFixed(value.Year1 + value.Year2 + value.Year3 + value.Year4 + value.Year5 + value.Year6 + value.Year7 + value.Year8 + value.Year9 + value.Year10),
        // Year11: this.getDecimalFixed(value.Year11),
        // Year12: this.getDecimalFixed(value.Year12),
        // Year13: this.getDecimalFixed(value.Year13),
        // Year14: this.getDecimalFixed(value.Year14),
        // Year15: this.getDecimalFixed(value.Year15),
        // Year16: this.getDecimalFixed(value.Year16),
        // Year17: this.getDecimalFixed(value.Year17),
        // Year18: this.getDecimalFixed(value.Year18),
        // Year19: this.getDecimalFixed(value.Year19),
        // Year20: this.getDecimalFixed(value.Year20),
      }
      rowData.push(dataBind);
    }.bind(this));
    this.ChangeInPlcRowData = rowData
    this.showPLCGrid = true;
    return rowData;
  }


  submit(event?: any) {
    this.submitted = true;
    let response = null;
    this.showPlcSummaryCosts = false; this.showPlcSummaryOPW = false;
    let checkValidation = this.validatePLCOPWData();
    if (checkValidation) {
      if (this.modifiedPlcOpwRows != null && this.modifiedPlcOpwRows.length != 0) {
        let sendData = {
          BusinessAreaId: this.businessAreaId,
          BusinessLineId: this.businessLineId,
          plcOPWProductivityData: this.modifiedPlcOpwRows
        };
        response = this.plcSummaryService.postPLCSummaryOPWProductivityData(sendData).toPromise().then((response) => {
          this.submitResponse = response;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          if (successCode != 200 || errorCode != 0) {
            this.submitted = false;
            let errorMessage = serverMessage.dataNotSaveMessage;
            let valueAlert = { [errorMessage]: serverMessage.warning };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
            return false;
          }
          else if (successCode == 200 && errorCode == 0) {
            this.submitted = false;
            this.showPlcSummaryCosts = true; this.showPlcSummaryOPW = true;
            this.toast.notify(serverMessage.dataSaveMessage, "success")
            if (event != undefined) {
              this.router.navigate(['/manageIdea'])
            }
            this.hasUnsavedChanges = false;
            return true;
          }
        }, (error) => {
          this.submitted = false;
          this.errorMessage.push(error)
          this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
          return false;
        })
      }
      else {
        this.submitted = false;
        this.showPlcSummaryCosts = true; this.showPlcSummaryOPW = true;
        this.router.navigate(['/manageIdea']);
      }
    }
    else {
      this.submitted = false;
      this.showPlcSummaryCosts = true; this.showPlcSummaryOPW = true;
    }
    return response;
  }

  validatePLCOPWData() {
    let flag = 0;
    this.errorMessageUnmatch = [];
    this.modifiedPlcOpwRows.forEach(node => {
      if (node.SolutionId != MSolutionType.Actuals) {
        if (node.MonthlyTotal != 0) {
          flag += 1;
          this.errorMessageUnmatch.push({ [node.Productivity]: messages.PLCSummary.validationOPWTotalMessage })
        }
      }
      node.ModifiedBy = this.ideaSubmitterId;
    })
    if (flag !== 0) {
      this.openAlertDialog(this.errorMessageUnmatch);
      this.submitted = false;
      return false;
    }
    return true;
  }

  displayErrorMessage() {
    this.submitted = false;
    let errorMessage = messages.PLCSummary.validationOPWTotalMessage;
    let valueAlert = { [errorMessage]: "Warning !" };
    this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
  }

  onCellValueChangedPlcSummary(params: any) {
    this.hasUnsavedChanges = true;
    if (params.data.OpwproductivityId != 0) {
      let isPushed = false;
      if (this.modifiedPlcOpwRows.length == 0) {
        this.modifiedPlcOpwRows.push(params.data)
        isPushed = true
      }
      else {
        this.modifiedPlcOpwRows.forEach(row => {
          if (row.OpwproductivityId == params.data.OpwproductivityId) {
            isPushed = true
          }
        })
      }
      if (!isPushed) {
        this.modifiedPlcOpwRows.push(params.data)
      }
    }
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea']);
  }

  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }

  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  showSpinner() {
    if (this.showPLCGrid && this.showOPWGrid && this.showPLCGraph)
      return false;
    else return true;
  }

  checkRowStyle(row) {
    if (row == 0.00 || row == 12.10 || row == 23.00)
      return true;
    else
      return false;
  }

  checkSubRowStyle(row) {
    if (row == 1.00 || row == 5.00 || row == 9.00 || row == 12.20 || row == 16.10)
      return true;
    else
      return false;
  }
}

function sumtotal(params: any) {
  if (params.data.SolutionId != 6) {
    params.data.MonthlyTotal = Number(params.data.Month1 ?? params.data.Month1) + Number(params.data.Month2 ?? params.data.Month2) + Number(params.data.Month3 ?? params.data.Month3) +
      Number(params.data.Month4 ?? params.data.Month4) + Number(params.data.Month5 ?? params.data.Month5) + Number(params.data.Month6 ?? params.data.Month6) +
      Number(params.data.Month7 ?? params.data.Month7) + Number(params.data.Month8 ?? params.data.Month8) + Number(params.data.Month9 ?? params.data.Month9) +
      Number(params.data.Month10 ?? params.data.Month10) + Number(params.data.Month11 ?? params.data.Month11) + Number(params.data.Month12 ?? params.data.Month12);
    params.data.MonthlyTotal = params.data.Total - params.data.MonthlyTotal;
  }
  else {
    params.data.MonthlyTotal = 0;
  }
  return params.data.MonthlyTotal;
}

function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
}

function scenarioName(params: any) {
  if (params.data.SolutionId == '2' && params.data.Description == "Change in PLC vs. Reference acc.") {
    return 'Target'
  }
  else if (params.data.SolutionId == '3' && params.data.Description == "Change in PLC vs. Reference acc.") {
    return 'Option A'
  }
  else if (params.data.SolutionId == '4' && params.data.Description == "Change in PLC vs. Reference acc.") {
    return 'Option B'
  }
  else if (params.data.SolutionId == '5' && params.data.Description == "Change in PLC vs. Reference acc.") {
    return 'Option C'
  }
  else {
    return params.data.Description
  }
}

function actualTotal(params) {
  if (params.data.Productivity == "Actual") {
    let totalVal = 0;
    for (let i = 1; i < 13; i++) {
      let cellValue = Number(params.data["Month" + i]).toFixed(0);
      totalVal = totalVal + Number(cellValue);
    }
    params.data.Total = totalVal;
  }
  else {
    params.data.Total = Number(parseFloat(params.data.Total).toFixed(0));
  }
  return params.data.Total;
}