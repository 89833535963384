import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlcPartUsedService {

  urlGet=environment.baseURL+"api/PartsUsed/GetPartsUsedDetails";
  urlPost=environment.baseURL+"api/PartsUsed/InsertUpdatePartsUsed";
  constructor(private http: HttpClient) { }
  getAllData(ideaId,solutionId){
    return this.http.get(this.urlGet+"?IdeaId="+ideaId+"&SolutionId="+solutionId);
  }
  postData(data){
    return this.http.post(this.urlPost,data)
  }
}
