import { Component, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddDeleteComponent } from './add-delete/add-delete.component';
import { FormBuilder } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { CostsavingdetailsService } from "../../../services/costsavingdetails.service";
import { DoublingEditorComponent } from './doubling-editor.component';
import { MSolutionType } from "../../../enum/MSolutionType";
import { MExpenseType } from "../../../enum/MExpenseType";
import { ToasterService } from 'src/app/services/toaster.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { NumericEditorComponent } from './numeric-editor.component';
import 'ag-grid-enterprise';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '../../../alert-dialog/alert-dialog.component';
import { serverMessage } from '../../../popUpMessages/serverMessage';
import { messages } from '../../../popUpMessages/messages';
import { MenuService } from 'src/app/services/menu.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';
//Defined Global variables
let countryCode;

@Component({
  selector: 'app-costsavings',
  templateUrl: './costsavings.component.html',
  styleUrls: ['./costsavings.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CostsavingsComponent implements Dirty {

  //Bind Form
  public dataBind = [];
  public costType: any[] = [{ CostTypeId: 0, CostTypeTitle: "Please select" }];
  public formData = {};
  public responsePost: any;
  public errorMessageApi = [];
  public loginUserDetails: any;

  //Bind Variables
  public refCostGridApi: any;
  public tgtCostGridApi: any;
  public gridColumnApi: any;

  public refcolumnDefs: any;
  public tgtcolumnDefs: any;
  public defaultColDef: any;
  public autoGroupRefCostColumnDef: any;
  public autoGroupTgtCostColumnDef: any;

  public refCostRowData: any;
  public tgtCostRowData: any;
  public remarkRowData: any;
  public newItemCount: any;
  public rowSelection: any;
  public domLayout: any;
  public rowData: any;
  public showGrid = false;
  public frameworkComponents;
  public savingsVSreferenceValue: any = 0;

  //Remarks Variables
  public RemarksId: any;
  public Remarks: string = null;
  public ideaId: number;
  public countryCode: any;

  //NonPLC Summary Variables
  public NonPLCSummaryId: any;
  public hasUnsavedChanges: boolean = false;

  //Variable with values
  public oneTimeCostHeaderName: string = "One time costs/ Process costs";
  public recurringExpHeaderName: string = "Recurring Expenses";
  public isChangeinRefGrid: boolean = false;
  public isChangeinTarGrid: boolean = false;
  public isChangeRemarks: any;
  public arrayRefGridData = [];
  public arrayTgtGridData = [];
  public arrayDelRefGridData = [];
  public arrayDelTgtGridData = [];

  public submitted: boolean = false;
  public isEditable: boolean = false;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  //Bind constructor

  public arraySavNRefCal = [];

  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private CostsavingdetailsService: CostsavingdetailsService,
    private ToasterService: ToasterService,
    private LocalStorageService: LocalStorageService,
    private sharedService: SharedServiceService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService
  ) {
  }

  ngOnInit(): void {
    //Get current loging user details
    this.sharedService.loggedInUser.subscribe(
      (user) => {
        this.loginUserDetails = user;
      }
    );

    this.sharedService.getIdeaIdAndtitle();
    //Get IdeaId
    this.ideaId = this.LocalStorageService.get('ideaId');

    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              //Load Ag-Grid data
              this.loadCostSavingData(this.businessAreaId, this.businessLineId);
            }
          })
        })
      }
    })

  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if (pageLockMessage != "") {
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  loadCostSavingData(areaId, lineId) {
    this.CostsavingdetailsService.getCostType(areaId, lineId).subscribe((data: any) => {
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {
        let responseData;
        responseData = data;
        responseData.Data.forEach(element => {
          this.costType.push({ CostTypeId: element.CostTypeId, CostTypeTitle: element.CostTypeTitle });
        });

        //Reference and Target ag-Grid
        this.bindRefAgGridData();
        setTimeout(() => {
          this.bindTgtAgGridData();
        }, 100);
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  //Load ag-Grid 
  loadAgGrid() {
    //Bind ag-grids
    this.showGrid = true;

    //------------------------------------- Reference Scenario----------------------------------------- 
    this.refcolumnDefs = [
      {
        field: 'agGridId',
        hide: true,
      },
      {
        field: 'CostSavingDetailId',
        hide: true,
      },
      {
        field: 'refCostHeader',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: "Cost Type",
        field: 'CostType',
        headerTooltip: "Cost Type",
        tooltipField: 'CostType',
        hide: true,
        suppressSorting: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.costType.map(x => x.CostTypeTitle),
          cellRenderer: function (params) {
            return params.value.CostTypeId;
          }
        },

        singleClickEdit: true,
        // cellStyle: cellStyle,
      },
      {
        headerName: 'Labour Rate',
        headerTooltip: "Labour Rate",
        field: 'LabourRate',
        cellEditor: 'doublingEditor',
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
        //tooltipField: 'LabourRate',
        tooltipValueGetter: CurrencyCellRenderer
      },
      {
        headerName: 'Effort(hrs per unit)',
        headerTooltip: "Effort(hrs per unit)",
        field: 'Effort',
        //tooltipField: 'Effort',
        tooltipValueGetter: CurrencyCellRenderer,
        cellEditor: 'doublingEditor',
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      },
      {
        headerName: 'Total Effort',
        headerTooltip: "Total Effort",
        field: 'TotalEffort',
        editable: false,
        //tooltipField: 'TotalEffort',
        valueGetter: funTotalEffort,
        tooltipValueGetter: CurrencyCellRenderer,
        cellStyle: { 'background-color': '#cccccc', 'font-weight': 'bold' },
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: 'Costs abs. per unit',
        field: 'CostsUnit',
        headerTooltip: "Costs abs. per unit",
        //tooltipField: 'CostsUnit',
        tooltipValueGetter: CurrencyCellRenderer,
        cellEditor: 'doublingEditor',
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      },
      {
        field: 'Total',
        headerTooltip: "Total",
        valueGetter: funTotal,
        //tooltipField: 'Total',
        tooltipValueGetter: CurrencyCellRenderer,
        editable: false,
        cellStyle: { 'background-color': '#cccccc', 'font-weight': 'bold' },
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Action",
        headerTooltip: "Action",
        filter: false,
        cellRendererFramework: AddDeleteComponent,
        colId: "edit",
        sortable: false,
        editable: false,
        width: 50,
        suppressSizeToFit: true
      }
    ]

    //------------------------------------- Target Scenario----------------------------------------- 
    this.tgtcolumnDefs = [
      {
        field: 'agGridId',
        hide: true,
      },
      {
        field: 'CostSavingDetailId',
        hide: true,
      },
      {
        field: 'tgtCostHeader',
        rowGroup: true,
        hide: true,

        // width: 100,
        // suppressSizeToFit: true
      },
      {
        headerName: "Cost Type",
        headerTooltip: "Cost Type",
        field: 'CostType',
        tooltipField: 'CostType',
        suppressSorting: true,
        hide: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.costType.map(x => x.CostTypeTitle),
          cellRenderer: function (params) {
            return params.value.CostTypeId;
          }
        },
        singleClickEdit: true,
        // cellStyle: cellStyle,
      },
      {
        headerName: 'Labour Rate',
        headerTooltip: "Labour Rate",
        field: 'LabourRate',
        //tooltipField: 'LabourRate',
        tooltipValueGetter: CurrencyCellRenderer,
        cellEditor: 'doublingEditor',
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      },
      {
        headerName: 'Effort(hrs per unit)',
        headerTooltip: "Effort(hrs per unit)",
        field: 'Effort',
        //tooltipField: 'Effort',
        tooltipValueGetter: CurrencyCellRenderer,
        cellEditor: 'doublingEditor',
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      },
      {
        headerName: 'Total Effort',
        headerTooltip: "Total Effort",
        field: 'TotalEffort',
        //tooltipField: 'TotalEffort',
        tooltipValueGetter: CurrencyCellRenderer,
        editable: false,
        valueGetter: funTotalEffort,
        cellStyle: { 'background-color': '#cccccc', 'font-weight': 'bold' },
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: 'Costs abs. per unit',
        headerTooltip: "Costs abs. per unit",
        field: 'CostsUnit',
        //tooltipField: 'CostsUnit',
        tooltipValueGetter: CurrencyCellRenderer,
        cellEditor: 'doublingEditor',
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer,
        singleClickEdit: true,
      },
      {
        headerName: 'Total',
        headerTooltip: "Total",
        field: 'Total',
        editable: false,
        tooltipValueGetter: CurrencyCellRenderer,
        //tooltipField: 'Total',
        valueGetter: funTotal,
        cellStyle: { 'background-color': '#cccccc', 'font-weight': 'bold' },
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer
      },

      {
        headerName: 'Affected units',
        headerTooltip: "Affected units",
        field: 'AffectedUnits',
        //tooltipField: 'AffectedUnits',
        tooltipValueGetter: CurrencyCellRenderer,
        cellStyle: cellStyle1,
        cellEditor: 'NumericEditor',
        singleClickEdit: true,
        cellRenderer: CurrencyCellRenderer,
        editable: params => {
          if (params.data.tgtCostHeader == this.oneTimeCostHeaderName)
            return false;
          else {
            if (this.isEditable) {
              return true;
            }
            else {
              return false;
            }
          }
        },
      },
      {
        field: 'TotalCostAcc',
        headerTooltip: "Total Cost Acc",
        //tooltipField: 'TotalCostAcc',
        tooltipValueGetter: CurrencyCellRenderer,
        valueGetter: funTotalCost,
        editable: false,
        cellStyle: { 'background-color': '#cccccc', 'font-weight': 'bold' },
        aggFunc: function (data: any) {
          let sum = 0;
          data.forEach(function (value: any) {
            if (value) {
              sum = sum + parseFloat(value);
            }
          });
          if (!sum) { return null; }
          return sum;
        },
        cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Action",
        headerTooltip: "Action",
        filter: false,
        editable: false,
        cellRendererFramework: AddDeleteComponent,
        colId: "edit",
        sortable: false,
        width: 50,
        suppressSizeToFit: true
      },
    ]

    this.rowSelection = 'single';
    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,
      editable: this.isEditable
    };

    this.autoGroupRefCostColumnDef = {
      headerName: 'Cost Items',
      field: 'refCostTitle',
      headerTooltip: "Cost Items",
      tooltipField: 'refCostTitle',
      // valueFormatter: formatString,
      cellStyle: cellStyle2,
      singleClickEdit: true,
      editable: this.isEditable,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '255',
        cols: '40',
        rows: '5'
      },
      valueSetter: params => {
        params.data.refCostTitle = params.newValue ? params.newValue.trim() : params.newValue;
        return params.data.refCostTitle
      },
      width: 500,
      //suppressSizeToFit: true,
      // cellRendererParams: {
      //   suppressCount: true
      // },
      // valueSetter: params => {
      //   params.data.refCostTitle = params.newValue.substr(0, 255);
      // },
    };
    this.autoGroupTgtCostColumnDef = {
      headerName: 'Cost Items',
      field: 'tgtCostTitle',
      headerTooltip: "Cost Items",
      // valueFormatter: formatString,
      tooltipField: 'tgtCostTitle',
      singleClickEdit: true,
      editable: this.isEditable,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: '255',
        cols: '40',
        rows: '5'
      },
      cellStyle: cellStyle2,
      width: 500,
      // suppressSizeToFit: true,
      // cellRendererParams: {
      //   suppressCount: true
      // },
      valueSetter: params => {
        params.data.tgtCostTitle = params.newValue ? params.newValue.trim() : params.newValue;
        return params.data.tgtCostTitle
      },
    };
    this.domLayout = 'autoHeight';

    this.frameworkComponents = {
      doublingEditor: DoublingEditorComponent,
      NumericEditor: NumericEditorComponent,
    };
  }

  onGridRefSizeChanged(params) {
    this.refCostGridApi = params.api;
    this.refCostGridApi.sizeColumnsToFit();
  }

  onGridTgtSizeChanged(params) {
    this.tgtCostGridApi = params.api;
    this.tgtCostGridApi.sizeColumnsToFit();
  }

  onCellClicked(event) {
    this.sharedService.invokeFirstComponentFunction.subscribe((data) => {
      this.calSavingsReference();
      if (data.CostSavingDetailId != null) {
        this.hasUnsavedChanges = true;
        if (data.SolutionId == MSolutionType.ReferenceScenario) {
          this.isChangeinRefGrid = true;
          if (this.arrayRefGridData.filter(x => x.CostSavingDetailId == data.CostSavingDetailId && !x.IsActive).length == 0) {
            this.fillGridDataInArray(this.arrayDelRefGridData, data, data.SolutionId, false);
          }
        } else {
          this.isChangeinTarGrid = true;
          if (this.arrayTgtGridData.filter(x => x.CostSavingDetailId == data.CostSavingDetailId && !x.IsActive).length == 0) {
            this.fillGridDataInArray(this.arrayDelTgtGridData, data, data.SolutionId, false);
          }
        }
      }
    });
  }

  //Call function on Cell value change
  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    //To check, Whether changer occure or not
    if (params.data.agGridId == "refCostSavingGrid") {
      this.isChangeinRefGrid = true;
      if (params.oldValue != params.newValue) {
        params.data.IsAction = true;
        this.refCostGridApi.expandAll();
      }
    } else {
      this.isChangeinTarGrid = true;
      if (params.oldValue != params.newValue) {
        params.data.IsAction = true;
        this.tgtCostGridApi.expandAll();
      }
    }

    if (params.column.colDef.field == "refCostTitle" || params.column.colDef.field == "tgtCostTitle") {
      if (params.value != 'Add title') {
        cellStyle(params);
        cellStyle1(params)
      }
    }

    if (params.column.colDef.field != "refCostTitle" && params.column.colDef.field != "tgtCostTitle") {
      this.calSavingsReference();
    }
  }

  fillRemarks(event?: any) {
    this.hasUnsavedChanges = true;
  }

  //Reference Scenerio
  onGridRefCostReady(params: any) {
    this.refCostGridApi = params.api;
    setTimeout(() => {
      this.refCostGridApi.expandAll();
      this.calSavingsReference();
    }, 1000);
  }

  bindRefAgGridData() {
    this.CostsavingdetailsService.getCostSavingByIdeaNSolutionId({ 'ideaId': this.ideaId, 'solutionId': MSolutionType.ReferenceScenario, 'userId': this.userInOrg.UserId }).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;
          if (responseData.Data.CostSaving.length > 0) {
            this.refCostRowData = [];
            if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['One time costs/ Process costs']).length > 0 && responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['Recurring Expenses']).length > 0) {
              this.refCostRowData = this.bindRefCostAgGridData(data);
            } else {
              if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['One time costs/ Process costs']).length > 0) {
                this.refCostRowData = this.refCostRowData.concat(this.bindRefCostAgGridData(data));
              } else {
                this.refCostRowData.push({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.oneTimeCostHeaderName, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null });
              }

              if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['Recurring Expenses']).length > 0) {
                this.refCostRowData = this.refCostRowData.concat(this.bindRefCostAgGridData(data));
              } else {
                this.refCostRowData.push({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.recurringExpHeaderName, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null });
              }
            }
          } else {
            this.refCostRowData = [
              { agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.oneTimeCostHeaderName, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null },
              { agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.recurringExpHeaderName, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null },
            ];
          }
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      }, (error) => {
        // this.sharedService.getErrorMessage(error);
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  bindRefCostAgGridData(objRefData) {
    let _bindData = {};
    let refCostRowData = [];
    objRefData.Data.CostSaving.forEach(function (value, key) {
      if (value.SolutionId == MSolutionType.ReferenceScenario) {
        _bindData = {
          IdeaId: this.ideaId,
          SolutionId: value.SolutionId,
          agGridId: "refCostSavingGrid",
          CostSavingDetailId: value.CostSavingDetailId,
          refCostHeader: value.ExpenseTypeId == MExpenseType['One time costs/ Process costs'] ? this.oneTimeCostHeaderName : this.recurringExpHeaderName,
          refCostTitle: value.Title,
          CostType: this.costType.filter(x => x.CostTypeId == value.CostTypeId)[0].CostTypeTitle,
          LabourRate: value.LabourRate,
          Effort: value.Efforts,
          TotalEffort: value.TotalEfforts,
          CostsUnit: value.CostPerUnit,
          Total: value.TotalCostPerUnit,
          AffectedUnits: 0,
          TotalCostAcc: 0,
          IsActive: value.IsActive,
          IsAction: null,
          IsEditable: this.isEditable
        }
      }
      refCostRowData.push(_bindData);
    }.bind(this));
    return refCostRowData;
  }

  //Target Scenerio
  onGridTgtCostReady(params: any) {
    this.tgtCostGridApi = params.api;
    setTimeout(() => {
      this.tgtCostGridApi.expandAll();
    }, 1000);
  }

  bindTgtAgGridData() {
    this.CostsavingdetailsService.getCostSavingByIdeaNSolutionId({ 'ideaId': this.ideaId, 'solutionId': MSolutionType.TargetScenario, 'userId': this.userInOrg.UserId }).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;
          if (responseData.Data.CostSaving.length > 0) {
            this.tgtCostRowData = [];
            if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['One time costs/ Process costs']).length > 0 && responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['Recurring Expenses']).length > 0) {
              this.tgtCostRowData = this.bindTgtCostAgGridData(data);
            } else {
              if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['One time costs/ Process costs']).length > 0) {
                this.tgtCostRowData = this.tgtCostRowData.concat(this.bindTgtCostAgGridData(data));
              } else {
                this.tgtCostRowData.push({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.oneTimeCostHeaderName, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null });
              }

              if (responseData.Data.CostSaving.filter(x => x.ExpenseTypeId == MExpenseType['Recurring Expenses']).length > 0) {
                this.tgtCostRowData = this.tgtCostRowData.concat(this.bindTgtCostAgGridData(data));
              } else {
                this.tgtCostRowData.push({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.recurringExpHeaderName, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null });
              }
            }
          } else {
            this.tgtCostRowData = [
              { agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.oneTimeCostHeaderName, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null },
              { agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.recurringExpHeaderName, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsAction: null },
            ];

          }

          //Bind Remark data
          if (responseData.Data.Remarks != null) {
            this.RemarksId = responseData.Data.Remarks.RemarkId;
            this.Remarks = responseData.Data.Remarks.Remark;
            this.isChangeRemarks = responseData.Data.Remarks.Remark;
          } else {
            this.RemarksId = null;
            this.Remarks = null;
            this.isChangeRemarks = null;
          }

          //Load Ag-Grid
          this.loadAgGrid();
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      }, (error) => {
        // this.sharedService.getErrorMessage(error);
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  calSavingsReference() {
    this.arraySavNRefCal = [];
    let RefOneTimeCostTotal = 0;
    let TarOneTimeCostTotal = 0;
    let refRowData = [];
    let tarRowData = [];

    if (this.refCostGridApi != undefined) {
      this.refCostGridApi.forEachNode((rowNode, index) => {
        rowNode['IsEditable'] = this.isEditable;
        if (index == 0 && rowNode.aggData != undefined && rowNode.aggData.Total != undefined) {
          RefOneTimeCostTotal = parseFloat(rowNode.aggData.Total);;
        }
      });
    }
    if (this.tgtCostGridApi != undefined) {
      this.tgtCostGridApi.forEachNode((rowNode, index) => {
        rowNode['IsEditable'] = this.isEditable;
        if (index == 0 && rowNode.aggData != undefined && rowNode.aggData.Total != undefined) {
          TarOneTimeCostTotal = parseFloat(rowNode.aggData.Total);
        }
      });
    }

    //Add Ref scenario, Tar Scenario Total column data
    this.arraySavNRefCal.push(RefOneTimeCostTotal - TarOneTimeCostTotal);

    if (this.refCostGridApi != undefined) {
      
       refRowData = [];
      this.refCostGridApi.forEachLeafNode((rowNodeRef, indexRef) => {
        if (rowNodeRef.data.refCostHeader == this.recurringExpHeaderName && rowNodeRef.data.CostType.toLowerCase() != "Please select".toLowerCase()) {
          refRowData.push({ 'Total': rowNodeRef.data.Total });
        }
      });
    }
    if (this.tgtCostGridApi != undefined) {
       tarRowData = [];
      this.tgtCostGridApi.forEachLeafNode((rowNodeTar, indexRef) => {
        if (rowNodeTar.data.tgtCostHeader == this.recurringExpHeaderName && rowNodeTar.data.CostType.toLowerCase() != "Please select".toLowerCase()) {
          tarRowData.push({ 'Total': rowNodeTar.data.Total, 'AffectedUnits': parseInt(rowNodeTar.data.AffectedUnits) >= 0 ? parseInt(rowNodeTar.data.AffectedUnits) : 1 });
        }
      });
    }
    //Calculate Ref scenario, Tar Scenario Recurring expense data
    if (refRowData.length == tarRowData.length) {
      for (let i = 0; i < refRowData.length; i++) {
        for (let j = 0; j < tarRowData.length; j++) {
          if (i == j && tarRowData[i].AffectedUnits != null) {
            this.arraySavNRefCal.push((refRowData[i].Total - tarRowData[i].Total) * parseInt(tarRowData[i].AffectedUnits));
            break;
          }
        }
      }
    } else if (refRowData.length > tarRowData.length) {
      for (let i = 0; i < refRowData.length; i++) {
        if (i > (tarRowData.length - 1)) {
          this.arraySavNRefCal.push((refRowData[i].Total - 0) * 1);
        } else {
          for (let j = 0; j < tarRowData.length; j++) {
            if (i == j && tarRowData[i].AffectedUnits != null) {
              this.arraySavNRefCal.push((refRowData[i].Total - tarRowData[i].Total) * parseInt(tarRowData[i].AffectedUnits));
              break;
            }
          }
        }
      }
    } else if (tarRowData.length > refRowData.length) {
      for (let j = 0; j < tarRowData.length; j++) {
        if (j > (refRowData.length - 1)) {
          this.arraySavNRefCal.push((0 - tarRowData[j].Total) * parseInt(tarRowData[j].AffectedUnits));
        } else {
          for (let i = 0; i < refRowData.length; i++) {
            if (i == j && tarRowData[i].AffectedUnits != null) {
              this.arraySavNRefCal.push((refRowData[i].Total - tarRowData[i].Total) * parseInt(tarRowData[i].AffectedUnits));
              break;
            }
          }
        }
      }
    }

    let arraySum = 0;
    if (this.arraySavNRefCal.length == 1) {
      arraySum = this.arraySavNRefCal[0];
    } else {
      arraySum = Number((this.arraySavNRefCal.reduce((acc, cur) => acc + Number(cur), 0)).toFixed(2));
    }
    this.savingsVSreferenceValue = new Intl.NumberFormat(countryCode).format(arraySum);
  }

  bindTgtCostAgGridData(objTarData) {
    let _bindData = {};
    let tgtCostRowData = [];

    objTarData.Data.CostSaving.forEach(function (value, key) {
      if (value.SolutionId == MSolutionType.TargetScenario) {
        _bindData = {
          IdeaId: this.ideaId,
          SolutionId: value.SolutionId,
          agGridId: "tgtCostSavingGrid",
          CostSavingDetailId: value.CostSavingDetailId,
          tgtCostHeader: value.ExpenseTypeId == MExpenseType['One time costs/ Process costs'] ? this.oneTimeCostHeaderName : this.recurringExpHeaderName,
          tgtCostTitle: value.Title,
          CostType: this.costType.filter(x => x.CostTypeId == value.CostTypeId)[0].CostTypeTitle,
          LabourRate: value.LabourRate,
          Effort: value.Efforts,
          TotalEffort: value.TotalEfforts,
          CostsUnit: value.CostPerUnit,
          Total: value.TotalCostPerUnit,
          AffectedUnits: value.AffectedUnits,
          TotalCostAcc: value.TotalCostAcc,
          IsActive: value.IsActive,
          IsAction: null,
          IsEditable: this.isEditable
        }
      }
      tgtCostRowData.push(_bindData);
    }.bind(this));
    return tgtCostRowData;
  }


  concatDelNAddUpdateData(refAgGridData, tgtAgGridData) {
    if ((this.arrayDelRefGridData.length == 0 && this.arrayDelTgtGridData.length == 0) || this.arrayDelRefGridData.length > 0 ||
      this.arrayDelTgtGridData.length > 0 || (this.arrayDelRefGridData.length > 0 && this.arrayDelTgtGridData.length > 0)) {
      if (refAgGridData != undefined && tgtAgGridData != undefined) {
        refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
        tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
        return [refAgGridData, tgtAgGridData];
      }
      else if (refAgGridData != undefined) {
        refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
        return [refAgGridData];
      }
      else if (tgtAgGridData != undefined) {
        tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
        return [tgtAgGridData];
      }
    }
    // else if (this.arrayDelRefGridData.length > 0 && this.arrayDelTgtGridData.length > 0) {
    //   if (refAgGridData != undefined && tgtAgGridData != undefined) {
    //     refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
    //     tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
    //     return [refAgGridData, tgtAgGridData];
    //   }
    // }
    // else if (this.arrayDelRefGridData.length > 0) {
    //   if (refAgGridData != undefined && tgtAgGridData != undefined) {
    //     refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
    //     tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
    //     return [refAgGridData, tgtAgGridData];
    //   }
    //   else if (refAgGridData != undefined) {
    //     refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
    //     return [refAgGridData];
    //   }
    //   else if (tgtAgGridData != undefined) {
    //     tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
    //     return [tgtAgGridData];
    //   }
    // } 
    // else if (this.arrayDelTgtGridData.length > 0) {
    //   if (refAgGridData != undefined && tgtAgGridData != undefined) {
    //     refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
    //     tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
    //     return [refAgGridData, tgtAgGridData];
    //   }
    //   else if (refAgGridData != undefined) {
    //     refAgGridData.CostSaving = refAgGridData.CostSaving.concat(this.arrayDelRefGridData);
    //     return [refAgGridData];
    //   }
    //   else if (tgtAgGridData != undefined) {
    //     tgtAgGridData.CostSaving = tgtAgGridData.CostSaving.concat(this.arrayDelTgtGridData);
    //     return [tgtAgGridData];
    //   }
    // }
  }

  //Save Cost && Saving data
  submit(event?: any) {
    this.submitted = true;
    let refAgGridData: any;
    let tgtAgGridData: any;
    let remarksTAData: any;
    let formResponse = null;



    if (this.isChangeinRefGrid) {
      refAgGridData = this.getReferenceGridData();
    }

    if (refAgGridData != 'error' && this.isChangeinTarGrid) {
      tgtAgGridData = this.getTargetGridData();
    }

    if (!(this.isChangeinRefGrid && this.isChangeinTarGrid) && this.Remarks != null && (this.isChangeRemarks != this.Remarks)) {
      tgtAgGridData = this.getRemarkData();
    }

    if (refAgGridData != 'error' && tgtAgGridData != 'error') {
      if (refAgGridData == undefined && tgtAgGridData == undefined && (this.isChangeRemarks == this.Remarks)) {
        if (event != undefined) {
          this.router.navigate(['/manageIdea'])
        }
      } else {
        formResponse = this.postFormData(event, this.concatDelNAddUpdateData(refAgGridData, tgtAgGridData));
      }
    }
    return formResponse;
  }


  getReferenceGridData() {
    let referenceGridData: any;
    let regex = new RegExp(/\^s*$/);
    let error = [];
    this.arrayRefGridData = [];
    this.refCostGridApi.forEachLeafNode((rowNode, index) => {
      if (rowNode.data.IsAction != null) {
        if (referenceGridData != 'error') {
          if (regex.test(rowNode.data.refCostTitle) || rowNode.data.refCostTitle == undefined ||
            rowNode.data.refCostTitle == "" || rowNode.data.refCostTitle.toLowerCase() == "Add Title".toLowerCase()) {
            referenceGridData = 'error';
            error.push({ "Title": messages.costAndSaving.fillRefCostTypeValidationMessage });
            this.arrayRefGridData = [];
          }
          else {
            this.fillGridDataInArray(this.arrayRefGridData, rowNode.data, MSolutionType.ReferenceScenario, true);
          }
        }
      }
    });

    if (referenceGridData != 'error') {
      referenceGridData = this.getAgGridData(this.arrayRefGridData, MSolutionType.ReferenceScenario);
    }
    if (error.length > 0) {
      this.openAlertDialog(error)
    }
    return referenceGridData;
  }


  getTargetGridData() {
    let error = [];
    let targetGridData: any;
    let regex = new RegExp(/\^s*$/);
    this.arrayTgtGridData = [];
    this.tgtCostGridApi.forEachLeafNode((rowNode, index) => {
      if (rowNode.data.IsAction != null) {
        if (targetGridData != 'error') {
          if (regex.test(rowNode.data.tgtCostTitle) || rowNode.data.tgtCostTitle == undefined ||
            rowNode.data.tgtCostTitle == "" || rowNode.data.tgtCostTitle.toLowerCase() == "Add Title".toLowerCase()) {
            targetGridData = 'error';
            error.push({ "Title": messages.costAndSaving.fillTarCostTypeValidationMessage });
            this.arrayTgtGridData = [];
          }
          if ((rowNode.data.AffectedUnits == "" || rowNode.data.AffectedUnits == null) && rowNode.data.AffectedUnits != 0
            && rowNode.data.tgtCostHeader == 'Recurring Expenses') {
            targetGridData = 'error';
            error.push({ "Affected Units": messages.costAndSaving.fillTarCostTypeValidationMessage })
            this.arrayTgtGridData = [];
          }
          else {
            this.fillGridDataInArray(this.arrayTgtGridData, rowNode.data, MSolutionType.TargetScenario, true);
          }
        }
      }
    });

    if (targetGridData != 'error') {
      targetGridData = this.getAgGridData(this.arrayTgtGridData, MSolutionType.TargetScenario);
    }
    if (error.length > 0) {
      this.openAlertDialog(error)
    }
    return targetGridData;
  }


  getRemarkData() {
    let remarksData: any;
    if ((this.isChangeRemarks != this.Remarks.trim())) {
      remarksData = this.getAgGridData(this.arrayTgtGridData, MSolutionType.TargetScenario);
    }
    return remarksData;
  }


  fillGridDataInArray(objCostSavingData, objData, solutionId, isActive) {
    let objCost: any;
    objCost =
    {
      CostSavingDetailId: objData.CostSavingDetailId ?? 0,
      IdeaId: this.ideaId,
      SolutionId: solutionId,
      Title: solutionId == MSolutionType.ReferenceScenario ? objData.refCostTitle : objData.tgtCostTitle,
      CostTypeId: objData.CostType.toLowerCase() == "Please select".toLowerCase() ? 0 : this.costType.filter(x => x.CostTypeTitle == objData.CostType)[0].CostTypeId,
      //ExpenseTypeId: solutionId == MSolutionType.ReferenceScenario ? (objData.refCostHeader == this.oneTimeCostHeaderName ? MExpenseType['One time costs/ Process costs'] : MExpenseType['Recurring Expenses']) : (objData.tgtCostHeader == this.oneTimeCostHeaderName ? MExpenseType['One time costs/ Process costs'] : MExpenseType['Recurring Expenses']),
      LabourRate: objData.LabourRate == null ? null : parseFloat(objData.LabourRate),
      Efforts: objData.Effort == null ? null : parseFloat(objData.Effort),
      TotalEfforts: objData.TotalEffort == null ? null : parseFloat(objData.TotalEffort),
      CostPerUnit: objData.CostsUnit == null ? null : parseFloat(objData.CostsUnit),
      TotalCostPerUnit: objData.Total == null ? null : parseFloat(objData.Total),
      AffectedUnits: objData.AffectedUnits == null ? null : parseInt(objData.AffectedUnits),
      TotalCostAcc: objData.TotalCostAcc == null ? null : parseFloat(objData.TotalCostAcc),
      IsActive: isActive,
      CreatedBy: this.userInOrg.UserId,
      ModifiedBy: this.userInOrg.UserId,
    }
    if (solutionId == MSolutionType.ReferenceScenario) {
      if (objData.refCostHeader == this.oneTimeCostHeaderName) {
        objCost.ExpenseTypeId = MExpenseType['One time costs/ Process costs']

      }
      else {
        objCost.ExpenseTypeId = MExpenseType['Recurring Expenses']
        // objCostSavingData.push({
        //   ExpenseTypeId: MExpenseType['Recurring Expenses']
        // })
      }
    }
    else {
      if (objData.tgtCostHeader == this.oneTimeCostHeaderName) {
        objCost.ExpenseTypeId = MExpenseType['One time costs/ Process costs']
        // objCostSavingData.push({
        //   ExpenseTypeId: MExpenseType['One time costs/ Process costs']
        // })
      }
      else {
        objCost.ExpenseTypeId = MExpenseType['Recurring Expenses']
        // objCostSavingData.push({
        //   ExpenseTypeId: MExpenseType['Recurring Expenses']
        // })
      }
    }
    objCostSavingData.push(objCost);
  }


  getAgGridData(costSavingGridData, solutionType) {
    this.formData = {
      CostSaving: costSavingGridData,
      IdeaId: this.ideaId,
      SolutionId: solutionType,
      UserId: this.userInOrg.UserId,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId,
      Remarks:
        this.isChangeRemarks == this.Remarks ? null : {
          RemarkId: this.RemarksId ?? 0,
          IdeaId: this.ideaId, SolutionId: MSolutionType.TargetScenario, Remark: this.isChangeRemarks != this.Remarks ? this.Remarks.trim() : null, RemarkTypeId: EnumRemarkType.CostSaving,
          IsActive: true, CreatedBy: this.userInOrg.UserId, ModifiedBy: this.userInOrg.UserId
        }
    }
    return this.formData;
  }


  postFormData(event, postRefNTarGridData) {
    let response = this.CostsavingdetailsService.postCostSavingDetailData(postRefNTarGridData).toPromise().then(
      (response) => {

        this.responsePost = response
        let successCode = this.responsePost.StatusCode;
        let errorCode = this.responsePost.Data.ErrorCode;
        let errorMsgeFromBack = this.responsePost.Data.Message;

        //Check if successCode is ok i.e 200
        if (successCode == 200) {
          //check if errorCode in the response is 0 then everythig is fine.
          if (errorCode == 0) {
            this.ToasterService.notify(serverMessage.dataSaveMessage, "success");
            this.hasUnsavedChanges = false;
            if (event != undefined) {
              this.router.navigate(['/manageIdea'])
            }
            return true;
          }
          //If the error code in the resposne is not 0, then display the error in UI.
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;

          }
        }
        //Going in else means that success code is not. ie. Http Status from server is not ok.
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
          this.submitted = false;
          return false;
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        this.submitted = false;
        return false;
      }
    )
    return response;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error });
    this.submitted = false;
  }

  displayErrorMessage(errorType, errorMessage) {
    //If error come, Clear data from Reference and Target array
    this.arrayRefGridData = [];
    this.arrayTgtGridData = [];

    //If error come, Display error message
    let alertBoxError = [];
    if (errorType == 1) {
      alertBoxError.push({ "Cost Type": errorMessage })
    } else {
      alertBoxError.push({ "Title": errorMessage })
      alertBoxError.push({ "Cost Type": errorMessage })
    }
    this.dialog.open(AlertDialogComponent, { data: alertBoxError })
    this.submitted = false
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea']);
  }
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }
}

function cellStyle(params) {
  let regex = new RegExp(/^\\s*$/);
  if (params.data != undefined) {
    if (params.data.agGridId == "refCostSavingGrid") {
      return (!regex.test(params.data.refCostTitle)
        && params.data.refCostTitle !== 'Add title' && params.data.refCostTitle !== "") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' };
    }
    else {
      return (!regex.test(params.data.tgtCostTitle) && params.data.tgtCostTitle !== 'Add title' && params.data.tgtCostTitle !== "")
        || ((params.data.AffectedUnits != null && params.data.AffectedUnits != "")) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' };
    }
  }
}
function cellStyle1(params) {
  let regex = new RegExp(/\^s*$/);
  if (params.data != undefined) {
    if (params.data.agGridId == "tgtCostSavingGrid") {
      return !regex.test(params.data.tgtCostTitle) && params.data.tgtCostHeader == "Recurring Expenses"
        && (params.data.tgtCostTitle !== 'Add title' && params.data.tgtCostTitle !== "") && (params.data.AffectedUnits !== 0 && (params.data.AffectedUnits == null || params.data.AffectedUnits == "")) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' };
    }
  }
}
function cellStyle2(params) {
  let regex = new RegExp(/\^s*$/);
  if (params.data != undefined) {
    if (params.data.agGridId == "refCostSavingGrid") {
      return (regex.test(params.data.refCostTitle))
        && (params.data.refCostTitle !== 'Add title' || params.data.refCostTitle !== "") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' };
    }
    else {
      return (regex.test(params.data.tgtCostTitle) || params.data.tgtCostTitle == 'Add title' || params.data.tgtCostTitle == "") &&
        (params.data.AffectedUnits != null && params.data.AffectedUnits != "") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' };
    }
  }
}



function CurrencyCellRenderer(params: any) {
  if (params.value == 0)
    return params.value;

  return params.value == null || params.value == undefined || params.value == "" ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
};

function funTotalEffort(params: any) {
  if (params.data.LabourRate != undefined && params.data.Effort != undefined) {
    params.data.TotalEffort = parseFloat(params.data.LabourRate) * parseFloat(params.data.Effort)
    return parseFloat(params.data.TotalEffort).toFixed(2);
  }
  // return;
};

function funTotal(params: any) {
  if (params.data.TotalEffort != undefined && params.data.CostsUnit == undefined) {
    if (params.data.LabourRate == null || params.data.Effort == null) {
      params.data.Total = params.data.CostsUnit == null || isNaN(params.data.CostsUnit) ? null : parseFloat(params.data.CostsUnit)
      return params.data.Total
    } else {
      params.data.Total = parseFloat(params.data.CostsUnit ?? 0) + parseFloat(params.data.TotalEffort)
      return parseFloat(params.data.Total).toFixed(2);
    }
  }
  if (params.data.CostsUnit != undefined) {
    if (params.data.LabourRate == null || params.data.Effort == null) {
      params.data.Total = params.data.CostsUnit == null || isNaN(params.data.CostsUnit) ? null : parseFloat(params.data.CostsUnit)
      return params.data.Total
    } else {
      params.data.Total = parseFloat(params.data.CostsUnit ?? 0) + parseFloat(params.data.TotalEffort)
      return parseFloat(params.data.Total).toFixed(2);
    }
  }
  //return;
};

function funTotalCost(params: any) {
  if (parseInt(params.data.AffectedUnits) != 0 && params.data.tgtCostHeader == "One time costs/ Process costs" && (params.data.AffectedUnits == undefined || params.data.AffectedUnits == null ||
    params.data.AffectedUnits == "" || isNaN(params.data.AffectedUnits))) {
    return params.data.Total;
  }
  if (parseInt(params.data.AffectedUnits) != 0 && params.data.tgtCostHeader == "Recurring Expenses" && (params.data.AffectedUnits == undefined || params.data.AffectedUnits == null ||
    params.data.AffectedUnits == "" || isNaN(params.data.AffectedUnits))) {
    params.data.TotalCostAcc = null;
    return params.data.TotalCostAcc;
  } else {
    params.data.TotalCostAcc = parseInt(params.data.AffectedUnits) * parseFloat(params.data.Total ?? 0);
    if (params.data.TotalCostAcc == 0) {
      return params.data.TotalCostAcc;
    }
    return parseFloat(params.data.TotalCostAcc).toFixed(2);
  }
}
