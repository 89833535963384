import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  public user:any;

  constructor(
    private localStorage:LocalStorageService,
    private sharedService:SharedServiceService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.localStorage.set("ideaId",null);
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(user=>{
      this.user=user;
    })
  }
  goToHomePage(){
    this.router.navigate(['/home'])
  }
}
