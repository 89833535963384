<button (click)="delData(params)" *ngIf="delVisible" title="Delete">
  <img
    alt="DeleteIcon"
    src="../assets/icons/delete.svg"
    width="25"
    height="25"
  />
</button>

<button (click)="addData(params)" *ngIf="addVisible" title="Add">
  <img alt="AddIcon" src="../assets/icons/add.svg" width="25" height="25" />
</button>
