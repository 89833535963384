import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { DeleteiconComponent } from 'src/app/pages/deleteIconNormalGrid/deleteicon.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ProcessCostService } from '../../../../services/process-cost.service'
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { GridDatepickerComponent } from 'src/app/pages/grid-datepicker/grid-datepicker.component';
import { DatePipe } from '@angular/common';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { DoublingEditorComponent } from 'src/app/pages/non-plc/costsavings/doubling-editor.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { Dirty } from 'src/app/interface/dirty-interface';
import { ContactDropdownComponent } from 'src/app/contact-dropdown/contact-dropdown.component';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';
let countryCode;
@Component({
  selector: 'app-subdetails',
  templateUrl: './subdetails.component.html',
  styleUrls: ['./subdetails.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SubdetailsComponent implements OnInit, Dirty {
  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;
  // public postCombinedData: any;

  public isCellChanged: boolean = false;
  public isEditable: boolean = false;
  public subProcessCostRowData: any;
  public columnDefs: any;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public pinnedBottomData: any;
  public subProcessCostId: any;
  public solutionId: any;
  public hasUnsavedChanges: boolean = false;
  public ideaId: any;
  public dataRec: any;
  public popupParent: any;
  public submitResponse: any;
  public errorMessage = [];
  public frameworkComponents;
  public scenarioHeader;
  public subProcessCostOnlyTotalRowData: any;

  public submitRowData: any[];
  public getResponse: any;
  public loginUserDetails: any;
  public showGrid = false;
  public modifiedSubProcessRows = [];
  public ideaSubmitterId: number = 0;
  public regex = new RegExp(/[^\\s]+/);
  public submitted: boolean = false;
  public defaultColDef: any;

  public isResponsible = false;
  public rowIndex: any;

  public getSubprocessResponse: any;
  public subProcessTitle: any;
  public subProcessId: any;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;
  public ryStartDate: any;
  public inputOccurence: any;

  public error = []
  constructor(
    private toast: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private processCostService: ProcessCostService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService) {
    this.popupParent = document.querySelector('body');
  }

  // this will listen to the key pressed while editing the grid and perform defined actions;
  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let KeyboardEvent: any = event;
    if (KeyboardEvent) {
      let keyCode = KeyboardEvent.keyCode;
      // keyCode = 27 is for the "Escape Key";
      if (keyCode == 27) {
        this.gridApi.stopEditing(false);
      }
      // keyCode = 27 is for the "Tab Key";
      else if (keyCode == 9) {
        let currentColumn = [];
        if (currentColumn.length != 0) {
          let field = currentColumn[0].column.colDef.field;

          if (field == "Responsible") {
            this.isResponsible = true;
            let rowIndex = currentColumn[0].rowIndex;
            this.rowIndex = rowIndex;
            this.gridApi.setFocusedCell(rowIndex, 'Responsible');
          } else if (field == "InputOTOccurrence") {
            let rowIndex = currentColumn[0].rowIndex;
            this.gridApi.setFocusedCell(rowIndex, 'InputOTOccurrence');
          } else {
            this.gridApi.stopEditing(false);
          }
        }
        else {
          if (this.isResponsible) {
            this.gridApi.setFocusedCell(this.rowIndex, 'InputOTOccurrence');
            this.isResponsible = false;
            this.rowIndex = null;
          }
        }
      }
    }
  }

  ngOnInit(): void {

    // this will get emitted when ever a row is deleted from the grid;
    this.sharedService.subprocessDeletedRows.subscribe(
      res => {
        this.hasUnsavedChanges = true;
        this.checkForScroll()
        if (res.SubProcessDetailId != 0) {
          this.isCellChanged = true;
          let isPushed = false;
          res.IsActive = false;
          if (this.modifiedSubProcessRows.length == 0) {
            this.modifiedSubProcessRows.push(res);
            isPushed = true;
          }
          else {
            this.modifiedSubProcessRows.forEach(row => {
              if (row.SubProcessDetailId == res.SubProcessDetailId) {
                isPushed = true;
              }
            });
          }
          if (!isPushed) {
            this.modifiedSubProcessRows.push(res);
          }
        }
      }
    )

    this.sharedService.getIdeaIdAndtitle();
    this.ideaId = this.localStorageService.get('ideaId');

    this.route.paramMap.subscribe(paramMap => {
      this.subProcessCostId = paramMap.get('id');
      this.solutionId = paramMap.get('solutionId');
    })

    // this service will update the Total calculation  row and insert it into the modified row category;
    this.sharedService.subProcessTotal.subscribe((response) => {
      this.onCellValueChangedSubProcess(1);
      if (response.SubProcessDetailId != 0) {
        response.IsActive = false;
        let isPushed = false
        if (this.modifiedSubProcessRows.length == 0) {
          this.modifiedSubProcessRows.push(response)
          isPushed = true
        }
        else {
          this.modifiedSubProcessRows.forEach(row => {
            if (row.SubProcessDetailId == response.SubProcessDetailId) {
              isPushed = true
            }
          })
        }
        if (!isPushed) {
          this.modifiedSubProcessRows.push(response)
        }
      }
    })
    if (this.solutionId == MSolutionType.ReferenceScenario) {
      this.scenarioHeader = MSolutionType[MSolutionType.ReferenceScenario];
      this.scenarioHeader = this.scenarioHeader.replace(/([A-Z])/g, ' $1').trim();
    }
    else if (this.solutionId == MSolutionType.TargetScenario) {
      this.scenarioHeader = MSolutionType[MSolutionType.TargetScenario];
      this.scenarioHeader = this.scenarioHeader.replace(/([A-Z])/g, ' $1').trim();
    }
    else if (this.solutionId == MSolutionType.OptionA) {
      this.scenarioHeader = MSolutionType[MSolutionType.OptionA];
      this.scenarioHeader = this.scenarioHeader.replace(/([A-Z])/g, ' $1').trim();
    }
    else if (this.solutionId == MSolutionType.OptionB) {
      this.scenarioHeader = MSolutionType[MSolutionType.OptionB];
      this.scenarioHeader = this.scenarioHeader.replace(/([A-Z])/g, ' $1').trim();
    }
    else if (this.solutionId == MSolutionType.OptionC) {
      this.scenarioHeader = MSolutionType[MSolutionType.OptionC];
      this.scenarioHeader = this.scenarioHeader.replace(/([A-Z])/g, ' $1').trim();
    }

    countryCode = this.localStorageService.get('countryCode');

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {
              this.ideaSubmitterId = this.userInOrg.UserId;

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadSubProcessCost();
            }
          })
        })
      }
    })
  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if (pageLockMessage != "") {
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  getSubprocessTitle() {
    this.processCostService.getProcessCostData(this.ideaId, this.solutionId).subscribe((data) => {
      this.getSubprocessResponse = data;
      let successCode = this.getSubprocessResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getSubprocessResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.getSubprocessResponse.Data.forEach(element => {
          if (element.SubProcessID == this.subProcessId) {
            this.subProcessTitle = element.SubProcessTitle;
          }

        });;
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    })
  }

  // This will perform the "Get" api call to fecth alllthe data of the Subprocess cost grid;
  loadSubProcessCost() {
    this.errorMessage = [];
    this.processCostService.getSubProcessCostData(this.ideaId, this.solutionId, this.subProcessCostId).subscribe((data) => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.dataRec = this.getResponse.Data;
        this.getSubprocessTitle();
        this.subProcessCostRowData = this.dataRec.ProcessCostDetails;
        this.subProcessId = this.subProcessCostRowData[0].SubProcessId;
        let flagPopupmessage = false;
        for (let i of this.subProcessCostRowData) {

          i["IsEditable"] = this.isEditable;
          // inserting a email field to perform the people picker activity;
          i["Email"] = i.ResponsibleEmail;
          i["InputOTOccurrence"] = this.datePipe.transform(i["InputOTOccurrence"], 'yyyy');
          i["InputRYStartDate"] = this.datePipe.transform(i["InputRYStartDate"], 'yyyy');
          // if(i["InputOTOccurrence"] == 2999 || i["InputRYStartDate"] == 2999)
          // {
          //   flagPopupmessage = true;
          // }
        }
        // if(flagPopupmessage == true)
        // {
        //   this.checkYearValidation()
        // }
        

        //for remark GET;
        this.remarkDataRecieved = this.dataRec.Remark;
        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }

        this.loadGridStructure();
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    })
  }

  // this method to show the warning message when the InputRYStartDate == 2999
  checkYearValidation()
  {
    this.openAlertDialog([{ [messages.startDatePopUpMessage]: serverMessage.warning }]);
  
  }

  // this both function will reset the height of row whenever therte is dynamic change in thw data of the cell of the grid;
  onColumnResized(params) {
    params.api.resetRowHeights();
  }
  onColumnVisible(params) {
    params.api.resetRowHeights();
  }

  // this will load Grid Structure;
  loadGridStructure() {
    this.columnDefs = [
      {
        headerName: "Process Costs",
        headerTooltip: "Process Costs",
        children: [
          {
            headerName: "Subprocess", field: 'SubProcessDetailTitle', tooltipField: 'SubProcessDetailTitle', width: 150, headerTooltip: "Subprocess",
            cellStyle: (params) => (params.node.data.SubProcessDetailTitle != 'Total' && !params.node.data.IsMaster && (params.data.SubProcessDetailTitle == null || params.data.SubProcessDetailTitle == '' || !this.regex.test(params.data.SubProcessDetailTitle))) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
            suppressSizeToFit: true, editable: function (params) {
              return !params.node.data.IsMaster && params.node.data.IsEditable
            }, cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {
              maxLength: '255',   // override the editor defaults
              cols: '40',
              rows: '5'
            }, singleClickEdit: true
          },
          { headerName: "", field: 'Content', tooltipField: "Content", cellRenderer: getInformation, width: 20, suppressSizeToFit: true },
          {
            headerName: "Labour rate [abs/h]", field: 'LabourRate', headerTooltip: "Labour rate [abs/h]",
            tooltipValueGetter: CurrencyCellRenderer,
            singleClickEdit: true, cellRenderer: CurrencyCellRenderer, cellEditor: 'doublingEditor', cellClass: 'numCell-align', editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable }
          },
          {
            headerName: "Responsible", field: 'Responsible', headerTooltip: "Responsible",
            tooltipField: "Responsible",
            cellEditor: 'contactDropdownEditor',
            singleClickEdit: true,
            editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable },
            cellRenderer: function (param) {
              if (param.data.Responsible != null) {
                let multilineVal = param.data.Responsible.split(";").join('<br/>');
                return multilineVal;
              } else {
                return param.data.Responsible;
              }
            },
          },
        ]
      },
      {
        headerName: "Input",
        headerTooltip: "Input",
        headerClass: "input-header-class right-border",
        children: [
          {
            headerName: "One Time",
            headerClass: "left-border",
            headerTooltip: "One Time",
            children: [
              {
                headerName: "Occurrence [year]",
                headerClass: "left-border",
                field: 'InputOTOccurrence',
                headerTooltip: "Occurrence [year]",
                //tooltipValueGetter: this.dateRenderer,
                //cellClass: "left-border",
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable },
                singleClickEdit: true,
                cellEditor: 'datePicker',
                //cellRenderer: this.dateRenderer,
                width: 100,
                suppressSizeToFit: true,
                cellStyle: (params) => (params.node.data.InputOTOccurrence == null && (params.node.data.InputOTCost != null || params.node.data.InputOTEffort != null)) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '1px black solid ' }
              },
              {
                headerName: "Effort [h]", cellClass: 'numCell-align', singleClickEdit: true,
                field: 'InputOTEffort', headerTooltip: "Effort [h]", cellEditor: 'doublingEditor',
                tooltipValueGetter: CurrencyCellRenderer,
                cellRenderer: CurrencyCellRenderer,
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable }
              },
              { headerName: "Cost [abs]", headerTooltip: "Cost [abs]", cellClass: 'numCell-align', singleClickEdit: true, field: 'InputOTCost', cellEditor: 'doublingEditor', cellRenderer: CurrencyCellRenderer, tooltipValueGetter: CurrencyCellRenderer, editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable } },
            ]
          },
          {
            headerName: "Recurring/year",
            headerTooltip: "Recurring/year",
            headerClass: "left-border right-border",
            children: [
              {
                headerName: "Start Date [year]",
                headerClass: "left-border",
                headerTooltip: "Start Date [year]",
                //cellClass: "left-border",
                field: 'InputRYStartDate',
                //tooltipValueGetter: this.dateRenderer,
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable },
                singleClickEdit: true,
                cellEditor: "datePicker",
                //cellRenderer: this.dateRenderer,
                width: 100,
                suppressSizeToFit: true,
                cellStyle: (params) => (params.node.data.InputRYStartDate == null && (params.node.data.InputRYCost != null || params.node.data.InputRYEffort != null)) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '1px black solid ' }
              },
              {
                headerName: "Lifetime [year]",
                field: 'InputRYLifetime',
                headerTooltip: "Lifetime [year]",
                cellEditor: 'agRichSelectCellEditor',
                tooltipValueGetter: CurrencyCellRenderer,
                cellEditorParams: {
                  values: ['Please Select', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                },
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable },
                singleClickEdit: true,
              },
              {
                headerName: "Effort [h]", cellClass: 'numCell-align',
                singleClickEdit: true, field: 'InputRYEffort',
                headerTooltip: "Effort [h]",
                tooltipValueGetter: CurrencyCellRenderer,
                cellEditor: 'doublingEditor',
                cellRenderer: CurrencyCellRenderer,
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable }
              },
              {
                headerName: "Cost [abs]", cellClass: 'numCell-align right-border',
                singleClickEdit: true, cellEditor: 'doublingEditor',
                headerTooltip: "Cost [abs]",
                cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
                field: 'InputRYCost',
                tooltipValueGetter: CurrencyCellRenderer,
                editable: (params: any) => { return params.node.data.SubProcessDetailTitle !== "Total" && params.node.data.IsEditable }
              },
            ]
          },
        ]
      },
      {
        headerName: "Output",
        headerTooltip: "Output",
        headerClass: "output-header-class",
        children: [
          {
            headerName: "One Time Total", cellClass: 'colorTotal numCell-align', field: 'OutputOTTotal',
            tooltipValueGetter: CurrencyCellRenderer, headerTooltip: "One Time Total",
            cellRenderer: CurrencyCellRenderer, valueGetter: CalcOneTimeTotal
          },
          {
            headerName: "Recurring /year Total", cellClass: 'colorTotal numCell-align', field: 'OutputRYTotal', headerTooltip: "Recurring /year Total",
            tooltipValueGetter: CurrencyCellRenderer,
            cellRenderer: CurrencyCellRenderer, valueGetter: CalcOutputRyTotal
          },
          {
            headerName: "Recurring over lifetime Total", cellClass: 'colorTotal numCell-align', field: 'OutputRLTotal',
            tooltipValueGetter: CurrencyCellRenderer, headerTooltip: "Recurring over lifetime Total",
            cellRenderer: CurrencyCellRenderer, valueGetter: CalcOutputRlTotal
          },
          {
            headerName: "Over Lifetime", cellClass: 'colorTotal numCell-align', field: 'OutputFinalTotal',
            tooltipValueGetter: CurrencyCellRenderer, headerTooltip: "Over Lifetime",
            cellRenderer: CurrencyCellRenderer, valueGetter: CalcOutputLifetimeTotal
          },
          {
            headerName: "Action",
            headerTooltip: "Action",
            filter: false,
            cellClass: 'center-align',
            cellRendererFramework: DeleteiconComponent,
            colId: "edit",
            sortable: false,
            suppressSizeToFit: true,
            width: 50
          }
        ]
      },
    ];
    this.domLayout = 'autoHeight';
    this.headerHeight = 60;
    this.defaultColDef = {
      suppressMovable: true,
      sortable: false,
      // this below two property is used to wrap the text of the responsible field in a cell by increasing th height of the cell;
      wrapText: true,
      autoHeight: true,
    }
    this.rowClassRules = {
      'colorTotal': function (params: any) {
        return params.data.SubProcessDetailTitle === "Total"
      },
    }
    // this is used to add the ecternal component in the grid cells to perform different activity;
    this.frameworkComponents = {
      datePicker: GridDatepickerComponent, doublingEditor: DoublingEditorComponent,
      contactDropdownEditor: ContactDropdownComponent
    }
    this.showGrid = true;
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  // this is get called when the grid is loaded on the UI;
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setGroupHeaderHeight(25);

    let rowCount = this.gridApi.getDisplayedRowCount();
    if (rowCount > 10) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('subdetails').style.height = '500px';
    }

    setTimeout(() => {
      this.pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([this.pinnedBottomData]);
    }, 500)

    this.sharedService.peoplePickerGridApi.subscribe(api => {
      this.gridApi = api
    })
  }

  checkForScroll() {
    let rowCount = this.gridApi.getDisplayedRowCount();
    if (rowCount <= 10) {
      this.gridApi.setDomLayout('autoHeight');
      document.getElementById('subdetails').style.height = null;
    } else {
      this.gridApi.setDomLayout('normal');
      document.getElementById('subdetails').style.height = '500px';
    }
  }
  onGridRefSubProcessCostSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  onCellValueChangedSubProcess(params: any) {
    // this will reset the row height when there is new value is added in any cell; here it's used for the "Contact" column;
    params.api.resetRowHeights();
    this.gridApi.redrawRows();
    this.isCellChanged = true;
    this.hasUnsavedChanges = true;
    if (params.data.SubProcessDetailId != 0) {
      let isPushed = false;
      if (this.modifiedSubProcessRows.length == 0) {
        this.modifiedSubProcessRows.push(params.data)
        isPushed = true
      }
      else {
        this.modifiedSubProcessRows.forEach(row => {
          if (row.SubProcessDetailId == params.data.SubProcessDetailId) {
            isPushed = true
          }
        });
      }
      if (!isPushed) {
        this.modifiedSubProcessRows.push(params.data)
      }
    }
  }

  generatePinnedBottomData() {
    // generate a row-data with null values
    let result = { "OutputOTEffort": 0, 'OutputOTCost': 0, "OutputOTTotal": 0, "OutputRYEffort": 0, "OutputRYCost": 0, "OutputRYTotal": 0, "OutputRLEffort": 0, "OutputRLCost": 0, "OutputRLTotal": 0, "OutputFinalTotal": 0 }

    return this.calculatePinnedBottomData(result);

  }
  calculatePinnedBottomData(target: any) {

    let columnsWithAggregation = ["OutputOTEffort", "OutputOTCost", "OutputOTTotal", "OutputRYEffort", "OutputRYCost", "OutputRYTotal", "OutputRLEffort", "OutputRLCost", "OutputRLTotal", "OutputFinalTotal"]
    columnsWithAggregation.forEach(element => {

      this.gridApi.forEachNode((rowNode: RowNode) => {
        if (rowNode.data[element]) {
          target[element] += Number(rowNode.data[element]);
        }
      });
    })
    target['SubProcessDetailTitle'] = 'Total';
    return target;
  }

  // will add new row and check if the last row is empty;
  addNewRow() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.gridApi.applyTransaction({
        add: [{
          SubProcessDetailId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionId,
          SubProcessId: this.subProcessCostId,
          SubProcessDetailTitle: null,
          IsMaster: false,
          IsActive: true,
          Content: '',
          LabourRate: null,
          Responsible: null,
          InputOTOccurrence: null,
          InputOTEffort: null,
          InputOTCost: null,
          InputRYStartDate: null,
          InputRYLifetime: null,
          InputRYEffort: null,
          InputRYCost: null,
          OutputOTEffort: null,
          OutputOTCost: null,
          OutputOTTotal: null,
          OutputRYEffort: null,
          OutputRYCost: null,
          OutputRYTotal: null,
          OutputRLEffort: null,
          OutputRLCost: null,
          OutputRLTotal: null,
          OutputFinalTotal: null,
          CreatedBy: this.ideaSubmitterId,
          IsEditable: this.isEditable,

          Email: null,
          ResponsibleEmail: null
        }]
      })
      this.checkForScroll()
    } else {
      let data = this.gridApi.getDisplayedRowAtIndex(count).data;
      let checked = this.regex.test(data.SubProcessDetailTitle)
      if (data.SubProcessDetailTitle === null || data.SubProcessDetailTitle === "" || !checked) {
        error.push({ SubProcess: errorMessage })
      }
      if (data.InputOTOccurrence == null && (data.InputOTCost != null || data.InputOTEffort != null)) {
        error.push({ Occurrence: errorMessage });
      }
      if (data.InputRYStartDate == null && (data.InputRYCost != null || data.InputRYEffort != null)) {
        error.push({ StartDate: errorMessage });
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.gridApi.applyTransaction({
          add: [{
            SubProcessDetailId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionId,
            SubProcessId: this.subProcessCostId,
            SubProcessDetailTitle: null,
            IsMaster: false,
            IsActive: true,
            Content: '',
            LabourRate: null,
            Responsible: null,
            InputOTOccurrence: null,
            InputOTEffort: null,
            InputOTCost: null,
            InputRYStartDate: null,
            InputRYLifetime: null,
            InputRYEffort: null,
            InputRYCost: null,
            OutputOTEffort: null,
            OutputOTCost: null,
            OutputOTTotal: null,
            OutputRYEffort: null,
            OutputRYCost: null,
            OutputRYTotal: null,
            OutputRLEffort: null,
            OutputRLCost: null,
            OutputRLTotal: null,
            OutputFinalTotal: null,
            CreatedBy: this.ideaSubmitterId,
            IsEditable: this.isEditable,

            Email: null,
            ResponsibleEmail: null
          }]
        })
        this.checkForScroll()
      } else {
        this.openAlertDialog(error)
      }
    }
  }
  submit(event?: any) {
    // this will close all the editing cell before saving;
    this.gridApi.stopEditing(true);
    this.submitted = true;
    let response = null;
    this.submitRowData = [];
    this.errorMessage = [];
    let checkValidation = this.validateSubProcessData();
    if (checkValidation) {
      if (this.hasUnsavedChanges) {
        let postdata = this.dataToSend();

        response = this.processCostService.postAllData(postdata).toPromise().then((response) => {
          this.submitResponse = response;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              if (this.isCellChanged) {
                this.toast.notify(serverMessage.dataSaveMessage, "success");
              }
              this.hasUnsavedChanges = false;
              if (event == "save&exit") {
                this.router.navigate(['/manageIdea'])
              }
              else if (event == "back") {
                this.checkNavigation();
              }
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          } else {
            //Show errorCode and errorMessage in the UI             
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.submitted = false;
          return false;
        })
      } else {
        this.hasUnsavedChanges = false;
        if (event == "save&exit") {
          this.router.navigate(['/manageIdea'])
        }
        else if (event == "back") {
          this.checkNavigation();
        }
        return true;
      }
    }
    return response;
  }

  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.checkNavigation();
  }

  onClickBack() {
    if (this.isEditable) {
      this.submit("back");
    } else {
      this.hasUnsavedChanges = false;
      this.checkNavigation();
    }
  }

  checkNavigation() {
    if (this.solutionId == MSolutionType.ReferenceScenario) {
      this.router.navigate(['/plc-processcost/refscenario'])
    } else if (this.solutionId == MSolutionType.TargetScenario) {
      this.router.navigate(['/plc-processcost/refscenario'])
    } else if (this.solutionId == MSolutionType.OptionA) {
      this.router.navigate(['/plc-processcost/optiona'])
    } else if (this.solutionId == MSolutionType.OptionB) {
      this.router.navigate(['/plc-processcost/optionb'])
    } else if (this.solutionId == MSolutionType.OptionC) {
      this.router.navigate(['/plc-processcost/optionc'])
    }
  }

  dataToSend() {
    let totalOutputOTCost = 0, totalOutputOTEffort = 0, totalOutputRLCost = 0, totalOutputRLEffort = 0, totalOutputRYCost = 0, totalOutputRYEffort = 0;
    this.subProcessCostOnlyTotalRowData = this.gridApi.pinnedRowModel.pinnedBottomRows[0].data;
    this.gridApi.forEachNode(node => {
      totalOutputOTCost += Number(CalcOutputOtCost(node));
      totalOutputOTEffort += Number(CalcOutputOtEffort(node));
      totalOutputRLCost += Number(CalcOutputRlCost(node));
      totalOutputRLEffort += Number(CalcOutputRlEffort(node));
      totalOutputRYCost += Number(CalcOutputRyCost(node));
      totalOutputRYEffort += Number(CalcOutputRyEffort(node));

      if (node.data.SubProcessDetailId == 0) {
        this.modifiedSubProcessRows.push(node.data)
      }
      if (node.data.IsMaster) {
        let isPushed = false;
        if (this.modifiedSubProcessRows.length == 0) {
          this.modifiedSubProcessRows.push(node.data)
          isPushed = true
        }
        else {
          this.modifiedSubProcessRows.forEach(row => {
            if (row.SubProcessDetailId == node.data.SubProcessDetailId) {
              isPushed = true
            }
          });
        }
        if (!isPushed) {
          this.modifiedSubProcessRows.push(node.data)
        }
      }
    });
    this.modifiedSubProcessRows.forEach(node => {


      node.OutputOTCost = Number(totalOutputOTCost);
      node.OutputOTEffort = Number(totalOutputOTEffort);
      node.OutputRLCost = Number(totalOutputRLCost);
      node.OutputRLEffort = Number(totalOutputRLEffort);
      node.OutputRYCost = Number(totalOutputRYCost);
      node.OutputRYEffort = Number(totalOutputRYEffort);
      node.OutputOTTotal = Number(this.subProcessCostOnlyTotalRowData.OutputOTTotal);
      node.OutputRLTotal = Number(this.subProcessCostOnlyTotalRowData.OutputRLTotal);
      node.OutputRYTotal = Number(this.subProcessCostOnlyTotalRowData.OutputRYTotal);
      node.OutputFinalTotal = Number(this.subProcessCostOnlyTotalRowData.OutputFinalTotal);
      let inputOTOccurrenceDate = new Date("01/02/" + node.InputOTOccurrence);
      let inputRYStartDate = new Date("01/02/" + node.InputRYStartDate)
      node.InputOTOccurrence = node.InputOTOccurrence !== null ? inputOTOccurrenceDate : null;
      node.InputRYStartDate = node.InputRYStartDate !== null ? inputRYStartDate : null;
      if (node.InputRYLifetime == "Please Select") {
        node.InputRYLifetime = null;
      }
      node.CreatedBy = this.ideaSubmitterId;
      this.submitRowData.push(node);
    });
    this.submitRowData.forEach((obj) => {
      obj.ResponsibleEmail = obj.Email;
      delete (obj.Email);
    })

    // Remarks changes: While POST;
    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: this.solutionId,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.ProcessCost,
          SubProcessId: this.subProcessCostId,
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }

    // this is the combined data which will get send in the POST;
    let postCombinedData = {
      IdeaId: this.ideaId,
      ProcessCostDetails: this.submitRowData,
      Remark: Remark,
      UserId: this.ideaSubmitterId,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId
    }
    return postCombinedData;
  }

  validateSubProcessData() {
    this.error = [];
    this.submitRowData = [];
    let errorMessage = messages.cellEmptyErrorMesssage, errorLengthMessage = messages.cellLengthErrorMessage;
    let countSubprocsError = 0;
    let occurenceCount = 0;
    let startdateCount = 0;
    let validateErrorMessage = messages.ProcessCostvalidationMessage;
    this.gridApi.forEachNode(node => {
      let checked = this.regex.test(node.data.SubProcessDetailTitle)
      if (node.data.SubProcessDetailTitle == "" || node.data.SubProcessDetailTitle == null || !checked) {
        countSubprocsError += 1;
        if (countSubprocsError == 1) {
          this.error.push({ SubProcess: errorMessage })
        }
      }
      else if (node.data.SubProcessDetailTitle.length > 255) {
        this.error.push({ SubProcess: errorLengthMessage + "255" })
      }

      if (node.data.InputOTOccurrence == null && (node.data.InputOTCost != null || node.data.InputOTEffort != null)) {
        occurenceCount += 1
      }
      if (node.data.InputRYStartDate == null && (node.data.InputRYCost != null || node.data.InputRYEffort != null)) {
        startdateCount += 1
      }

    })

    if (occurenceCount > 0) {
      this.error.push({ Occurrence: validateErrorMessage });
    }
    if (startdateCount > 0) {
      this.error.push({ StartDate: validateErrorMessage })
    }
    if (this.error.length != 0) {
      this.openAlertDialog(this.error);
      this.submitted = false;
      return false;
    }
    return true;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // Remarks changes: While Input Changes;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.isCellChanged = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }

  //  setting the rowHeight for the Set Pinned Bottom row("Total");
  getRowHeight(params) {
    return params.data.SubProcessDetailTitle == "Total" ? 30 : params.data.rowHeight;
  }
}
function CalcOutputOtEffort(params: any) {
  params.data.OutputOTEffort = 0;

  if (params.data.InputOTEffort != undefined && params.data.LabourRate != undefined && params.data.InputOTOccurrence != undefined
    && params.data.InputOTOccurrence != null && params.data.InputOTOccurrence != "" && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputOTEffort = Number(params.data.InputOTEffort) * Number(params.data.LabourRate);
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputOTEffort += node.data.OutputOTEffort })
  }
  else {
    params.data.OutputOTEffort = 0
  }
  return params.data.OutputOTEffort.toFixed(2);
}
function CalcOutputOtCost(params: any) {
  params.data.OutputOTCost = 0
  if (params.data.InputOTCost != undefined && params.data.InputOTOccurrence != undefined
    && params.data.InputOTOccurrence != null && params.data.InputOTOccurrence != "" && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputOTCost = Number(params.data.InputOTCost)
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputOTCost += node.data.OutputOTCost })
  }
  else {
    params.data.OutputOTCost = 0
  }
  return params.data.OutputOTCost.toFixed(2);
}

function CalcOneTimeTotal(params: any) {

  params.data.OutputOTTotal = 0;
  let effortTotal = 0, costTotal = 0;
  if (params.data.InputOTEffort != undefined && params.data.LabourRate != undefined) {
    effortTotal = Number(params.data.InputOTEffort) * Number(params.data.LabourRate)
  }
  if (params.data.InputOTCost) {
    costTotal = Number(params.data.InputOTCost);
  }
  if (params.data.SubProcessDetailTitle != "Total" && params.data.InputOTOccurrence != undefined
    && params.data.InputOTOccurrence != null && params.data.InputOTOccurrence != "") {
    params.data.OutputOTTotal = effortTotal + costTotal;
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputOTTotal += node.data.OutputOTTotal })
  }
  else {
    params.data.OutputOTTotal = 0;
  }

  return params.data.OutputOTTotal.toFixed(2);
}
function CalcOutputRyEffort(params: any) {
  params.data.OutputRYEffort = 0
  if (params.data.InputRYEffort != undefined && params.data.LabourRate != undefined && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != ""
    && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputRYEffort = Number(params.data.InputRYEffort) * Number(params.data.LabourRate);
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRYEffort += node.data.OutputRYEffort })
  }
  else {
    params.data.OutputRYEffort = 0
  }
  return params.data.OutputRYEffort.toFixed(2);
}


function CalcOutputRyCost(params: any) {
  params.data.OutputRYCost = 0
  if (params.data.InputRYCost != undefined && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != "" && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputRYCost = Number(params.data.InputRYCost)
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRYCost += node.data.ryCost })
  }
  else {
    params.data.OutputRYCost = 0
  }
  return params.data.OutputRYCost.toFixed(2);
}

function CalcOutputRyTotal(params: any) {
  params.data.OutputRYTotal = 0;
  let effortTotal = 0, costTotal = 0;
  if (params.data.InputRYEffort != undefined && params.data.LabourRate != undefined) {
    effortTotal = Number(params.data.InputRYEffort) * Number(params.data.LabourRate);
  }
  if (params.data.InputRYCost) {
    costTotal = Number(params.data.InputRYCost);
  }
  if (params.data.SubProcessDetailTitle != "Total" && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != "") {
    params.data.OutputRYTotal = effortTotal + costTotal
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRYTotal += node.data.OutputRYTotal })
  }
  else {
    params.data.OutputRYTotal = 0;
  }
  return params.data.OutputRYTotal.toFixed(2);
}
function CalcOutputRlEffort(params: any) {
  params.data.OutputRLEffort = 0;
  if (params.data.InputRYEffort != undefined && params.data.LabourRate != undefined && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != ""
    && params.data.InputRYLifetime != null && params.data.InputRYLifetime != "Please Select" && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputRLEffort = Number(params.data.InputRYEffort) * Number(params.data.LabourRate) * Number(params.data.InputRYLifetime)
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRLEffort += node.data.OutputRLEffort })
  }
  else {
    params.data.OutputRLEffort = 0;
  }
  return params.data.OutputRLEffort.toFixed(2);

}
function CalcOutputRlCost(params: any) {
  params.data.OutputRLCost = 0;
  if (params.data.InputRYCost != undefined && params.data.InputRYLifetime != null && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != "" && params.data.InputRYLifetime != "Please Select" && params.data.SubProcessDetailTitle != "Total") {
    params.data.OutputRLCost = Number(params.data.InputRYCost) * Number(params.data.InputRYLifetime)
  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRLCost += node.data.OutputRLCost })
  }
  else {
    params.data.OutputRLCost = 0
  }
  return params.data.OutputRLCost.toFixed(2);
}



function CalcOutputRlTotal(params: any) {
  params.data.OutputRLTotal = 0
  let effortTotal = 0, costTotal = 0;
  if (params.data.InputRYEffort != undefined && params.data.LabourRate != undefined && params.data.InputRYLifetime != null && params.data.InputRYLifetime != "Please Select") {
    effortTotal = Number(params.data.InputRYEffort) * Number(params.data.LabourRate) * Number(params.data.InputRYLifetime)
  }
  if (params.data.InputRYCost != undefined && params.data.InputRYLifetime != null && params.data.InputRYLifetime != "Please Select") {
    costTotal = Number(params.data.InputRYCost) * Number(params.data.InputRYLifetime);
  }
  if (params.data.SubProcessDetailTitle != "Total" && params.data.InputRYStartDate != undefined
    && params.data.InputRYStartDate != null && params.data.InputRYStartDate != "") {
    params.data.OutputRLTotal = effortTotal + costTotal

  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputRLTotal += node.data.OutputRLTotal })
  }
  else {
    params.data.OutputRLTotal = 0
  }
  return params.data.OutputRLTotal.toFixed(2);

}


function CalcOutputLifetimeTotal(params: any) {
  params.data.OutputFinalTotal = 0

  if (params.data.OutputOTTotal != undefined && params.data.OutputRLTotal != undefined && params.data.SubProcessDetailTitle != "Total") {

    params.data.OutputFinalTotal = Number(params.data.OutputOTTotal) + Number(params.data.OutputRLTotal)

  }
  else if (params.data.SubProcessDetailTitle == "Total") {
    params.api.forEachNode((node: any) => { params.data.OutputFinalTotal += node.data.OutputFinalTotal })
  }

  else {
    params.data.OutputFinalTotal = 0
  }
  return params.data.OutputFinalTotal.toFixed(2);

}
function getInformation(params) {
  if (params.data.SubProcessDetailTitleDetailTitle != "Total" && params.data.IsMaster) {
    return '<image alt="Information Icon" src="../assets/icons/information.svg" height="14" width="14" ' +
      'style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">'
  }
  return null;
}

function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
};

