<app-progress-spinner *ngIf="!showGrid || submitted"></app-progress-spinner>

<div class="form-row container">
  <div class="form-group col-md-3">
    <label for="actualUnits" class="label">Year</label>
    <select id="year" class="form-control" [(ngModel)]="this.selectedYear" (change)="updateExchangeRate()"
      aria-label="actualUnits">
      <option value="">Please select</option>
      <option *ngFor="let bl of this.headerYears" [value]="bl">
        {{bl}}
      </option>
    </select>
  </div>
</div>
<br>
<div class="container" *ngIf="showGrid">
  <div class="card" style="height: 100%">
        <div class="label-header" style="width: 100%; margin-top: 0px">
              <label class="label-headercolor">FX Translation</label>
        </div>

      <div class="card-body" style="padding: 10px">
        <ag-grid-angular id="fxTranslationGrid" style="width: 100%; height: 720px" class="ag-theme-alpine"
          [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
          (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)"
          (gridSizeChanged)="onGridSizeChanged($event)" [frameworkComponents]="frameworkComponents"
          [enableBrowserTooltips]="true" (firstDataRendered)="onFirstDataRendered($event)"
          (displayedColumnsChanged)="onDisplayedColumnsChanged($event)" [suppressRowTransform]="true">
        </ag-grid-angular>
      </div>
    </div>