<app-progress-spinner *ngIf="!showform || submitted"></app-progress-spinner>
<div class="container">
  <form *ngIf="showform" [formGroup]="categorizationForm" (ngSubmit)="submit($event)">
    <!-- Code for Measure Categorization -->
    
    <!-- Code for OPW Measure Classification -->
    <div class="container">
      <div class="card" style="margin-top: -0px">
        <div class="card-header label">OPW Measure Classification</div>
        <div class="card-body" style="padding-top: 0px; padding-left: 2px">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="ddlAction" class="label">Actively driven action?</label>
              <button title={{ActivelyDrivenHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlAction" class="form-control catInvalid" (change)="onSelectedAction($event)"
                formControlName="IsActivelyDriven" aria-label="IsActivelyDriven">
                <option value="">Please select</option>
                <option *ngFor="let action of Action" [value]="action.id">
                  {{ action.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="ddlEBIT" class="label">Direct impact on adjusted EBIT?</label>
              <button title={{DirectImpactOnEBITHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlEBIT" class="form-control catInvalid" (change)="onSelectedAction($event)"
                formControlName="IsDirectImpactOnEbit" aria-label="IsDirectImpactOnEbit">
                <option value="">Please select</option>
                <option *ngFor="let ebit of EBIT" [value]="ebit.id">
                  {{ ebit.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="ddlImpact" class="label">Duration of impact?</label>
              <button title={{DurationOfImpactHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlImpact" class="form-control catInvalid" (change)="onSelectedAction($event)"
                formControlName="IsImpactPermanent" aria-label="IsImpactPermanent">
                <option value="">Please select</option>
                <option *ngFor="let impact of Impact" [value]="impact.id">
                  {{ impact.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="ddlCost" class="label">Cost reduction vs. reference period?</label>
              <button title={{CostReductionVsReferencePeriodHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlCost" class="form-control catInvalid" (change)="onSelectedCost($event)"
                formControlName="IsCostReductionRequired" aria-label="IsCostReductionRequired">
                <option value="">Please select</option>
                <option *ngFor="let cst of Cost" [value]="cst.id">
                  {{ cst.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="ddlRatio" class="label">Improvement of input/output ratio?</label>
              <button title={{ImprovementOfInputOutputRatioHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlRatio" class="form-control catInvalid" (change)="onSelectedAction($event)"
                formControlName="IsRatioImprovementRequired" aria-label="IsRatioImprovementRequired">
                <option value="">Please select</option>
                <option *ngFor="let rdo of Radio" [value]="rdo.id">
                  {{ rdo.title }}
                </option>
              </select>
            </div>
          </div>

          
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="ddlFixCost" class="label">Fix/var. costs affected?</label>
              <button title={{CostAffectedHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlFixCost" class="form-control" (change)="onSelectedAction($event)"
                aria-label="IsFixCostAffected" [ngClass]="{ catInvalid: !isRequiredCostReduction }"
                formControlName="IsFixCostAffected" [attr.disabled]="isRequiredCostReduction ? 'disabled' : null">
                <option value="">Please select</option>
                <option *ngFor="let FCost of FixCost" [value]="FCost.id">
                  {{ FCost.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="ddlRevenueIncrease" class="label">Revenue increase?</label>
              <button title={{RevenueIncreaseHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlRevenueIncrease" class="form-control" (change)="onSelectedAction($event)"
                aria-label="IsRevenueIncreased" [ngClass]="{ catInvalid: !isRequiredCostReduction }"
                formControlName="IsRevenueIncreased" [attr.disabled]="isRequiredCostReduction ? 'disabled' : null">
                <option value="">Please select</option>
                <option *ngFor="let RIncrease of RevenueIncrease" [value]="RIncrease.id">
                  {{ RIncrease.title }}
                </option>
              </select>
            </div>
          </div>

          
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="ddlPVO" class="label-prewrap" style="height: 33px; display: flex; align-items: center;">Does
                the action have an impact on the purchasing volume
                (PVO)?

                <button title={{PurchasingVolumeImpactHelpText}} (click)="false">
                  <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                    style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
                </button>
              </label>
              <select id="ddlPVO" class="form-control catInvalid" (change)="onPVOSelectedAction($event.target.value)"
                aria-label="IsPvoImpacted" formControlName="IsPvoImpacted">
                <option value="">Please select</option>
                <option *ngFor="let PV of PVO" [value]="PV.id">
                  {{ PV.title }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="ddlPVOMaterials" class="label-prewrap"
                style="height: 33px; display: flex; align-items: center;">Is the impact on the PVO due to a price change
                without
                modification of materials/services?
              </label>
              <select id="ddlPVOMaterials" class="form-control" aria-label="IsPvoImpactRequired"
                [ngClass]="{ catInvalid1: categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('IsPvoImpactRequired').value == '' }"
                (change)="onSelectedAction($event.target.value)" formControlName="IsPvoImpactRequired"
                [attr.disabled]="!PVOMaterials1 ? 'disabled' : null">
                <option value="">Please select</option>
                <option *ngFor="let PVOM of PVOMaterials" [value]="PVOM.id">
                  {{ PVOM.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="ddlCommoditiyHierarchy" class="label">Commodity
              </label>
              <button title={{CommodityHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select class="form-control" name="ddlCommoditiyHierarchy"
                (change)="SubCommoditiyHierarchy($event.target.value)" formControlName="CommodityId"
                aria-label="CommodityId" [attr.disabled]="!PVOMaterials1 ? 'disabled' : null"
                [ngClass]="{ catInvalid1: categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('CommodityId').value == '0' }">
                <option *ngIf="setCommodityValue == null" value="">
                  Please select
                </option>
                <option *ngIf="setCommodityValue == 0" value="0">
                  Please select
                </option>
                <option *ngFor="let CH of MCommoditiyHierarchy.Data" [value]="CH.CommodityId">
                  {{ CH.CommodityTitle }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-6">
              <label for="ddlSubCommoditiyHierarchy" class="label">Sub Commodity</label>
              <button title={{SubCommodityHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <select id="ddlSubCommoditiyHierarchy" class="form-control"
                (change)="SubCommoditiyEvent($event.target.value)" aria-label="SubCommodityId"
                formControlName="SubCommodityId" [attr.disabled]="!PVOMaterials1 ? 'disabled' : null"
                [ngClass]="{ catInvalid1: categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('SubCommodityId').value == '0' }">
                <option *ngIf="setCommodityValue == null" value="">
                  Please select
                </option>
                <option *ngIf="setCommodityValue == 0" value="0">
                  Please select
                </option>
                <option *ngFor="let SCH of MSubCommoditiyHierarchy" [value]="SCH.SubCommodityId">
                  {{ SCH.SubCommodityTitle }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="opwFilter" class="label">OPW Filter
              </label>
              <button title={{OPWFilterHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <mat-select class="form-control" name="opwFilter" (selectionChange)="opwFilterChange($event.value)"
                formControlName="OpwFilterId" aria-label="OpwFilterId" [(Value)]="SelectedOPWFilterId" [(ngModel)]="SelectedOPWFilterId"
                placeholder="Please select" multiple>
                <mat-option #option1 *ngFor="let OF of MOPWFilter" [value]="OF.OPWFilterId" [disabled]="isDisable(OF.OPWFilterId)">
                  {{ OF.OPWFilterTitle }}
                </mat-option>
              </mat-select>
            </div>

            <div class="form-group col-md-4">
              <label for="opwSubFilter" class="label">OPW Sub Filter</label>
              <button title={{OPWSubFilterHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <mat-select id="opwSubFilter" class="form-control" aria-label="OpwSubFilterId"
                formControlName="OpwSubFilterId" placeholder="Please select" [(ngModel)]="SelectedOPWSubFilterId"
                multiple [ngClass]="{ catInvalid1: checkAtleastOneVAMSubFilterSelected() }">
                <mat-optgroup *ngFor="let group of MOPWSubFilter" [label]="group.name">
                  <mat-option *ngFor="let item of group.subFilter" [value]="item.OPWSubFilterId" [disabled] = "group.name == 'VAM action P&L effect' && checkLength() && checkSelected(item.OPWSubFilterId)" 
                  title={{item.OPWSubFilterTitle}}>
                    {{ item.OPWSubFilterTitle }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </div>

            <div class="form-group col-md-4">
              <mat-label for="opwTag" class="label-prewrap">OPW Tag</mat-label>
              <button title={{OPWTagHelpText}} (click)="false">
                <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
                  style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
              </button>
              <mat-select id="opwTag" class="form-control" aria-label="OpwTagId" formControlName="OpwTagId"
                placeholder="Please select" multiple>
                <mat-option *ngFor="let OT of MOPWTag" [value]="OT.OPWTagId">
                  {{ OT.OPWTagTitle }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-md-12 label" style="background-color: #CF4B00; color: #ffffff; margin-top: 15px">
            OPW Lever (filled in automatically)
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="label-prewrap">OPW Classification</label>
              <input id="txtOPWClassification" class="form-control" type="text" formControlName="OPWClassification"
                aria-label="OPWClassification" disabled />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label class="label-prewrap">Action Type</label>
              <input id="txtActionType" class="form-control" type="text" formControlName="ActionType"
                aria-label="ActionType" disabled />
            </div>
            <div class="form-group col-md-4">
              <label class="label-prewrap">Lever</label>
              <input id="txtLeverType" class="form-control" formControlName="Lever" type="text" aria-label="Lever"
                disabled />
            </div>
            <div class="form-group col-md-4">
              <label class="label-prewrap">Type of Impact </label>
              <input id="txtImpactType" class="form-control" type="text" formControlName="ImpactType"
                aria-label="ImpactType" disabled />
            </div>
          </div>
          <div class="col-md-12 label" style="background-color: #CF4B00; color: #ffffff">
            OPW Reporting Levers (filled in automatically, relevant for OPW migrated measures only)
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 3</label>
              <input id="level3" class="form-control" type="text" formControlName="Level3" aria-label="LevelThree"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 4</label>
              <input id="level4" class="form-control" formControlName="Level4" type="text" aria-label="LevelFour"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 5</label>
              <input id="level5" class="form-control" type="text" formControlName="Level5" aria-label="LevelFive"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 6</label>
              <input id="level6" class="form-control" type="text" formControlName="Level6" aria-label="LevelSix"
                disabled />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 7</label>
              <input id="level7" class="form-control" formControlName="Level7" type="text" aria-label="LevelSeven"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 8</label>
              <input id="level8" class="form-control" type="text" formControlName="Level8" aria-label="LevelEight"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 9</label>
              <input id="level9" class="form-control" type="text" formControlName="Level9" aria-label="LevelNine"
                disabled />
            </div>
            <div class="form-group col-md-3">
              <label class="label-prewrap">Level 10</label>
              <input id="level10" class="form-control" type="text" formControlName="Level10" aria-label="LevelTen"
                disabled />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label class="label-prewrap">Level 11</label>
              <input id="level11" class="form-control" type="text" formControlName="Level11" aria-label="LevelEleven"
                disabled />
            </div>
            <div class="form-group col-md-4">
              <label class="label-prewrap">Level 12</label>
              <input id="level12" class="form-control" type="text" formControlName="Level12" aria-label="LevelTwelve"
                disabled />
            </div>
            <div class="form-group col-md-4">
              <label class="label-prewrap">Level 13</label>
              <input id="level13" class="form-control" type="text" formControlName="Level13" aria-label="LevelThirteen"
                disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- remark changes -->
  <div *ngIf="showform" class="row row-height remark_style">
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea id="txtareaRemarks" style="font-size: 0.7rem; width: 98%" aria-label="Remarks_Target"
        [(ngModel)]="remark" [disabled]="!isEditable" (input)="checkRemarkChange()" cols="75" rows="3" maxlength="1500"
        wrap="hard"></textarea>
    </div>
  </div>
  <div *ngIf="showform" class="row" style="margin-top: 100px">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle" style="margin-bottom: 15px; margin-right: 12px">
        <button class="button button--small" (click)="onClickCancel()">
          Cancel & Exit</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
          Back</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
          Next</button>&nbsp;
        <button class="button button--small" type="submit" [disabled]="submitted || !isEditable"
          (click)="submit('save&exit')">
          Save & Exit
        </button>
      </div>
    </div>
  </div>
</div>
<!-- error block -->
<!-- <div>
  <span *ngIf="
      categorizationForm.get('MeasureTypeId').invalid;
      else other_MeasureType
    ">
    {{ getErrorMessage("Its a Required field", "Measure Type", "false") }}</span>
  <ng-template #other_MeasureType>{{
    getErrorMessage("", "Measure Type", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('MeasureCategoryId').invalid;
      else other_MeasureCategory
    ">
    {{
    getErrorMessage("Its a Required field", "Measure Category", "false")
    }}</span>
  <ng-template #other_MeasureCategory>{{
    getErrorMessage("", "Measure Category", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('CurrencyId').invalid;
      else other_Currency
    ">
    {{
    getErrorMessage("Its a Required field", "Currency", "false")
    }}</span>
  <ng-template #other_Currency>{{
    getErrorMessage("", "Currency", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="categorizationForm.get('StartDate').invalid; else other_StartDate">
    {{ getErrorMessage("Its a Required field", "StartDate", "false") }}</span>
  <ng-template #other_StartDate>{{
    getErrorMessage("", "StartDate", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('CostImpactId').invalid;
      else other_CostImpact
    ">
    {{ getErrorMessage("Its a Required field", "Cost Impact", "false") }}</span>
  <ng-template #other_CostImpact>{{
    getErrorMessage("", "Cost Impact", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('MeasureStatusId').invalid;
      else other_MeasureStatus
    ">
    {{ getErrorMessage("Its a Required field", "Status", "false") }}</span>
  <ng-template #other_MeasureStatus>{{
    getErrorMessage("", "Status", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsPlcrequired').invalid;
      else other_IsPlcrequired
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Product Lifecycle Cost assessment",
    "false"
    )
    }}</span>
  <ng-template #other_IsPlcrequired>{{
    getErrorMessage("", "Product Lifecycle Cost assessment", "true")
    }}</ng-template>
</div> -->

<div>
  <span *ngIf="
      categorizationForm.get('IsActivelyDriven').invalid;
      else other_IsActivelyDriven
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Actively driven action?",
    "false"
    )
    }}</span>
  <ng-template #other_IsActivelyDriven>{{
    getErrorMessage("", "Actively driven action?", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsDirectImpactOnEbit').invalid;
      else other_IsDirectImpactOnEbit
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Direct impact on adjusted EBIT?",
    "false"
    )
    }}</span>
  <ng-template #other_IsDirectImpactOnEbit>{{ getErrorMessage("", "Direct impact on adjusted EBIT?", "true") }}
  </ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsImpactPermanent').invalid;
      else other_IsImpactPermanent
    ">
    {{
    getErrorMessage("Its a Required field", "Duration of impact?", "false")
    }}</span>
  <ng-template #other_IsImpactPermanent>{{
    getErrorMessage("", "Duration of impact?", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsCostReductionRequired').invalid;
      else other_IsCostReductionRequired
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Cost reduction vs. reference period?",
    "false"
    )
    }}</span>
  <ng-template #other_IsCostReductionRequired>{{ getErrorMessage("", "Cost reduction vs. reference period?", "true") }}
  </ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsRatioImprovementRequired').invalid;
      else other_IsRatioImprovementRequired
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Improvement of input/output ratio?",
    "false"
    )
    }}</span>
  <ng-template #other_IsRatioImprovementRequired>{{ getErrorMessage("", "Improvement of input/output ratio?", "true") }}
  </ng-template>
</div>

<div>
  <span *ngIf="
      !isRequiredCostReductionVal &&
        categorizationForm.get('IsFixCostAffected').invalid;
      else other_IsFixCostAffected
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Fix/var. costs affected?",
    "false"
    )
    }}</span>
  <ng-template #other_IsFixCostAffected>{{
    getErrorMessage("", "Fix/var. costs affected?", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      !isRequiredCostReductionVal &&
        categorizationForm.get('IsRevenueIncreased').invalid;
      else other_IsRevenueIncreased
    ">
    {{
    getErrorMessage("Its a Required field", "Revenue increase?", "false")
    }}</span>
  <ng-template #other_IsRevenueIncreased>{{
    getErrorMessage("", "Revenue increase?", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      
        categorizationForm.get('IsPvoImpacted').invalid;
      else other_IsPvoimpacted
    ">
    {{
    getErrorMessage(
    "Its a Required
    field",
    "Does the action have an impact on the purchasing volume (PVO)?",
    "false"
    )
    }}</span>
  <ng-template #other_IsPvoimpacted>
    {{
    getErrorMessage(
    "",
    "Does the action have an impact on the purchasing volume (PVO)?",
    "true"
    )
    }}</ng-template>
</div>

<div>
  <span *ngIf="
        categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('IsPvoImpactRequired').value == '';
      else other_IsPvoimpactRequired
    ">
    {{
    getErrorMessage(
    "Its a Required
    field",
    "Is the impact on the PVO due to a price change without modification of
    materials/services?",
    "false"
    )
    }}</span>
  <ng-template #other_IsPvoimpactRequired>
    {{
    getErrorMessage(
    "",
    "Is the impact on the PVO due to a price change without modification of
    materials/services?",
    "true"
    )
    }}
  </ng-template>
</div>

<div>
  <span *ngIf="categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('CommodityId').value == '0';
   else other_Commodity">
    {{ getErrorMessage("Its a Required field", "Commodity", "false") }}</span>
  <ng-template #other_Commodity>{{
    getErrorMessage("", "Commodity", "true")
    }}</ng-template>
</div>

<div>
  <span
    *ngIf="categorizationForm.get('IsPvoImpacted').value == '1' && categorizationForm.get('SubCommodityId').value == '0'; else other_SubCommodity">
    {{
    getErrorMessage("Its a Required field", "Sub Commodity", "false")
    }}</span>
  <ng-template #other_SubCommodity>{{
    getErrorMessage("", "Sub Commodity", "true")
    }}</ng-template>
</div>
<div>
  <span
    *ngIf="checkAtleastOneVAMSubFilterSelected() ; else other_OpwSubFilterId">
    {{
    getErrorMessage("Its a Required field", "OPW Lever VAM was derived.\n Therefore OPW Filter \"VAM action P&L effect\" was selected and requires the selection of a OPW Sub Filter. Please select accordingly.", "false")
    }}</span>
  <ng-template #other_OpwSubFilterId>{{
    getErrorMessage("", "OPW Lever VAM was derived.\n Therefore OPW Filter \"VAM action P&L effect\" was selected and requires the selection of a OPW Sub Filter. Please select accordingly.", "true")
    }}</ng-template>
</div>