import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DeleteiconComponent } from '../../../../pages/deleteIconNormalGrid/deleteicon.component'
import { NonCmplxRefSecService } from '../../../../services/non-cmplx-ref-sec.service'
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../../../../alert-dialog/alert-dialog.component';
import { SharedServiceService } from '../../../../services/shared-service.service'
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { ToasterService } from 'src/app/services/toaster.service';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';
import { EnumComplexityAndRisk } from 'src/app/enum/ComplexityLevel';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { Dirty } from 'src/app/interface/dirty-interface';
import { messages } from 'src/app/popUpMessages/messages';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';
@Component({
  selector: 'app-plc-option-c',
  templateUrl: './plc-option-c.component.html',
  styleUrls: ['./plc-option-c.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlcOptionCComponent implements OnInit, Dirty {
  public regex = new RegExp(/[^\\s]+/);
  public ideaId: any;
  public submitResponse: any;
  public complexityColumndef: any;
  public complexityRowdata: any;
  public headerHeightComplex: any;
  public rowHeight: any;
  public rowStylecomplexity: any;
  public gridApiComplex: any;
  public gridColumnApiComplex: any;
  public riskColumnDef: any;
  public riskRowdata: any;
  public gridApiRisk: any;
  public gridApiColumnRisk: any;
  public rowStyleRisk: any;

  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;

  public errorMessage = [];
  // for total row color
  public rowClassRulesCom;
  public rowClassRulesRisk;
  public defaultProperties: any;
  public domLayout: any;
  // row height for pinned row
  public getRowHeight: any;
  public showGrid = false;
  public popupParent: any;
  public finalErrorComplx = [];
  public finalErrorRisk = [];
  public alertBox = [];
  public solutionId: any;
  public IsPlc = true;
  public hasUnsavedChanges: boolean = false;
  public loginUserDetails: any;

  public pinnedBottomRowDataComplex: any;
  public pinnedBottonRowDataRisk: any;

  public complxLevlDrpDwn: any;
  public riskProbDrpDwn: any;
  public riskRemnProbDrpDwn: any;
  public dataRecieved: any;
  public dataRec: any;
  public cmplxMsterDataRev: any;
  public riskMsterDataProbRv: any;
  public riskMsterDataRemProbRv: any;
  public ComplexityRiskCalc: any;
  public NonPLCSummaryId: any;

  public showComplex = false;
  public showRisk = false;

  public cmplxSurcharge: any;
  public cmplxLevel: any;
  public riskSurcharge: any;
  public riskLevel: any;

  //data store variables
  public arrayComplexGridData = [];
  public arrayRiskGridData = [];
  public isChangeinComplexGrid: boolean = false;
  public isChangeinRiskGrid: boolean = false;
  // genral setting varaibales;
  public refRangeLevelGsId8 = 0;
  public refRangeLevelGsId9 = 0;
  public refRangeLevelGsId10 = 0;
  public tarRangeProbGsId11 = 0;
  public tarRangeProbGsId12 = 0;
  public tarRangeProbGsId13 = 0;
  public generalSettingData: any;

  public submitted: boolean = false;
  public isEditable: boolean = false;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  constructor(
    private refService: NonCmplxRefSecService,
    private toast: ToasterService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private sharedService: SharedServiceService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService) {
    this.popupParent = document.querySelector('body');
  }
  canDeactivate() {
    return this.hasUnsavedChanges;
  }
  ngOnInit(): void {
    //Get current loging user details
    this.sharedService.loggedInUser.subscribe(
      (user) => {
        this.loginUserDetails = user;
      }
    );

    this.sharedService.getIdeaIdAndtitle()

    this.sharedService.plcComplexityRiskComplex.subscribe((data) => {
      if (data.ComplexityRiskId != 0) {
        let isPushed = false;
        data.IsActive = false;
        this.isChangeinComplexGrid = true;
        if (this.arrayComplexGridData.length == 0) {
          this.arrayComplexGridData.push(data);
          isPushed = true;
        }
        else {
          this.arrayComplexGridData.forEach((row) => {
            if (row.ComplexityRiskId == data.ComplexityRiskId) {
              isPushed = true;
            }
          });
        }
        if (!isPushed) {
          this.arrayComplexGridData.push(data);
        }
      }
      this.onCellValueChangedComplex(1);
      this.onRowClickedComplx(1);
    });
    this.sharedService.plcComplexityRiskRisk.subscribe((data) => {
      if (data.ComplexityRiskId != 0) {
        let isPushed = false;
        data.IsActive = false;
        this.isChangeinRiskGrid = true;
        if (this.arrayRiskGridData.length == 0) {
          this.arrayRiskGridData.push(data);
          isPushed = true;
        }
        else {
          this.arrayRiskGridData.forEach((row) => {
            if (row.ComplexityRiskId == data.ComplexityRiskId) {
              isPushed = true;
            }
          });
        }
        if (!isPushed) {
          this.arrayRiskGridData.push(data);
        }
      }
      this.onCellValueChangedRisk(1);
      this.onRowClickedRisk(1);
    });

    this.ideaId = this.localStorageService.get('ideaId');
    this.solutionId = MSolutionType.OptionC;

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadMstrComplxlvl();
            }
          })
        })
      }
    })

  };

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if(pageLockMessage != ""){
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  // Add Row to the Complexity Assesment Gird;
  AddNewRowRef() {
    this.validateRowComplx();
    let rowCount = this.gridApiComplex.getDisplayedRowCount()
    if (rowCount == 5) {
      this.gridApiComplex.setDomLayout('normal');
      document.getElementById('refSceComplexGrid').style.height = '300px';
    };
  };
  // Add Row to the Risk Assesment Gird;
  AddNewRowRisk() {
    this.validateRowRisk();
    let rowCount = this.gridApiRisk.getDisplayedRowCount()
    if (rowCount == 5) {
      this.gridApiRisk.setDomLayout('normal');
      document.getElementById('refSceRiskGrid').style.height = '300px';
    };

  };
  // Validate Last Added row of Complexity Grid and if validation passes it adds a row;
  public validateRowComplx() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApiComplex.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApiComplex.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.gridApiComplex.applyTransaction({
        add: [{
          CrtitlePlc: "",
          levelPlc: { name: "Please Select", id: null },
          Crcomment: "",
          ComplexityRiskId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionId,
          CrlevelId: null,
          CrremainingProbability: null,
          Type: "C",
          IsPlc: this.IsPlc,
          Probability: null,
          IsActive: true,
          IsEditable: this.isEditable
        }], addIndex: 0
      })

    } else {
      let data = this.gridApiComplex.getDisplayedRowAtIndex(0).data;
      let checked = this.regex.test(data.CrtitlePlc)
      if (data.CrtitlePlc === "Add complexitiy item here,If applicable" || data.CrtitlePlc === "" || !checked) {
        error.push({ Complexitiy: errorMessage })
      }
      if (data.levelPlc.id == null) {
        error.push({ Complexitiy_Level: errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.gridApiComplex.applyTransaction({
          add: [{
            CrtitlePlc: "",
            levelPlc: { name: "Please Select", id: null },
            Crcomment: "",
            ComplexityRiskId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionId,
            CrlevelId: null,
            CrremainingProbability: null,
            Type: "C",
            IsPlc: this.IsPlc,
            Probability: null,
            IsActive: true,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      } else {
        this.openAlertDialog(error)
      }
    }
  }
  // Validate Last Added row of Risk Grid and if validation passes it adds a row;
  public validateRowRisk() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApiRisk.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApiRisk.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.gridApiRisk.applyTransaction({
        add: [{
          CrtitlePlcRisk: "",
          Probability: { name: "Please Select", id: null },
          Crcomment: "",
          remainingPlc: {
            name: "Please Select",
            id: null
          },
          ComplexityRiskId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionId,
          CrlevelId: null,
          CrremainingProbability: null,
          Type: "R",
          IsPlc: this.IsPlc,
          IsActive: true,
          IsEditable: this.isEditable
        }], addIndex: 0
      })
    } else {
      let data = this.gridApiRisk.getDisplayedRowAtIndex(0).data;
      let checkedCrtitlePlcRisk = this.regex.test(data.CrtitlePlcRisk)
      if (data.CrtitlePlcRisk === "Please add risk here, if applicable" || data.CrtitlePlcRisk === "" || !checkedCrtitlePlcRisk) {
        error.push({ Risk: errorMessage })
      }
      if (data.Probability.id == null) {
        error.push({ Probability: errorMessage })
      }

      let checkedCrcomment = this.regex.test(data.Crcomment)
      if (data.Crcomment == "Please Enter" || data.Crcomment == "" || !checkedCrcomment) {
        error.push({
          [messages.complexityAndRisk.validationMitigation]: errorMessage
        })
      }
      if (data.remainingPlc.id == null) {
        error.push({ Remaining_probability_after_mitigation: errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.gridApiRisk.applyTransaction({
          add: [{
            CrtitlePlcRisk: "",
            Probability: { name: "Please Select", id: null },
            Crcomment: "",
            remainingPlc: {
              name: "Please Select",
              id: null
            },
            ComplexityRiskId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionId,
            CrlevelId: null,
            CrremainingProbability: null,
            Type: "R",
            IsPlc: this.IsPlc,
            IsActive: true,
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      } else {
        this.openAlertDialog(error)
      }
    }

  }

  // loads Master Data for  Level column of Complexity Grid;
  public loadMstrComplxlvl() {
    this.refService.getMasterComplxLvl().subscribe((masterLvl) => {
      if (masterLvl == null) {
        return;
      };
      this.cmplxMsterDataRev = masterLvl;
      if (this.cmplxMsterDataRev.StatusCode == 200) {
        this.cmplxMsterDataRev = this.cmplxMsterDataRev.Data;
        let masterData = [];
        for (let i of this.cmplxMsterDataRev) {
          let obj = {};
          obj["name"] = i.LevelTitle;
          obj["id"] = i.LevelId;
          masterData.push(obj);
        }
        this.complxLevlDrpDwn = masterData;
        this.loadMstrRiskProb();
      } else {
        let errorMsgeFromBack = this.cmplxMsterDataRev.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  };
  // loads Master Data for  Probability column of Risk Grid;
  public loadMstrRiskProb() {
    this.refService.getMasterRiskProb().subscribe((masterProb) => {
      if (masterProb == null) {
        return;
      };
      this.riskMsterDataProbRv = masterProb;
      if (this.riskMsterDataProbRv.StatusCode == 200) {
        this.riskMsterDataProbRv = this.riskMsterDataProbRv.Data;
        let masterDataProb = [];
        for (let i of this.riskMsterDataProbRv) {
          let obj = {};
          obj["name"] = i.ProbabilityTitle + '%';
          obj["id"] = i.ProbabilityId;
          masterDataProb.push(obj);
        }
        this.riskProbDrpDwn = masterDataProb;

        this.loadMstrRiskRemnProb();
      } else {
        let errorMsgeFromBack = this.riskMsterDataProbRv.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  };
  // loads Master Data for Remaining Probability column of Risk Grid;
  public loadMstrRiskRemnProb() {
    this.refService.getMasterRiskRemainProb().subscribe((masterRemProb) => {
      if (masterRemProb == null) {
        return;
      }
      this.riskMsterDataRemProbRv = masterRemProb;
      if (this.riskMsterDataRemProbRv.StatusCode == 200) {
        this.riskMsterDataRemProbRv = this.riskMsterDataRemProbRv.Data;
        let masterDataRemProb = [];
        for (let i of this.riskMsterDataRemProbRv) {
          let obj = {};
          obj["name"] = i.ProbabilityTitle + '%';
          obj["id"] = i.ProbabilityId;
          masterDataRemProb.push(obj);
        }
        this.riskRemnProbDrpDwn = masterDataRemProb;
        this.loadGeneralSettingData();
      } else {
        let errorMsgeFromBack = this.riskMsterDataRemProbRv.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  };
  // load general setting data;
  loadGeneralSettingData() {

    this.refService.getSettingData(this.ideaId, 1).subscribe(
      (data) => {
        if (data == null) {
          return;
        }
        this.generalSettingData = data;

        if (this.generalSettingData.StatusCode == 200) {
          this.generalSettingData = this.generalSettingData.Data;

          this.refRangeLevelGsId8 = this.generalSettingData[0].SettingValue;
          this.refRangeLevelGsId9 = this.generalSettingData[1].SettingValue;
          this.refRangeLevelGsId10 = this.generalSettingData[2].SettingValue;
          this.tarRangeProbGsId11 = this.generalSettingData[3].SettingValue;
          this.tarRangeProbGsId12 = this.generalSettingData[4].SettingValue;
          this.tarRangeProbGsId13 = this.generalSettingData[5].SettingValue;



          this.loadGridAllData();

        } else {
          let errorMsgeFromBack = this.generalSettingData.Data.Message;
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      })
  }
  // makes a service call to get all the data, segregate data and assign toh each grid variables;
  public loadGridAllData() {
    let ideaId = this.ideaId;
    let solutionId = this.solutionId;
    let userId = this.userInOrg.UserId;
    this.refService.getAllData(ideaId, solutionId, true, userId).subscribe((dataF) => {
      if (dataF == null) {
        return
      }
      this.dataRec = dataF;
      if (this.dataRec.StatusCode == 200) {

        // for remark GET;
        this.remarkDataRecieved = this.dataRec.Data.Remark;

        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }

        this.ComplexityRiskCalc = this.dataRec.Data.ComplexityRiskCalc
        if (this.ComplexityRiskCalc == null) {
          this.NonPLCSummaryId = 0;
        } else {
          this.NonPLCSummaryId = this.ComplexityRiskCalc.NonPLCSummaryId;
        }
        this.dataRec = this.dataRec.Data.ComplexityRiskRows;
        let complxData = [];
        let riskData = [];
        for (let i of this.dataRec) {
          i["IsEditable"] = this.isEditable;
          if (i.IsPlc == this.IsPlc) {
            if (i.Type == "R") {
              let CrtitlePlcRisk = i.Crtitle;
              delete i.Crtitle;
              i["CrtitlePlcRisk"] = CrtitlePlcRisk
              riskData.push(i);
            } else if (i.Type == "C") {
              let CrtitlePlc = i.Crtitle;
              delete i.Crtitle;
              i["CrtitlePlc"] = CrtitlePlc
              complxData.push(i)
            }
          }
        }

        for (let j of complxData) {
          for (let k of this.complxLevlDrpDwn) {
            if (j.CrlevelId == k.id) {
              delete j.CrlevelId;
              j["levelPlc"] = k;
            }
          }
        }
        this.complexityRowdata = complxData;
        for (let l of riskData) {

          for (let m of this.riskRemnProbDrpDwn) {
            if (l.CrremainingProbability == m.id) {
              delete l.CrremainingProbability;
              l["remainingPlc"] = m;
            }
          }
          for (let n of this.riskProbDrpDwn) {
            if (l.CrlevelId == n.id) {
              delete l.CrlevelId;
              l["Probability"] = n;
            }
          }
        }
        this.riskRowdata = riskData;
        // Loads grid on complete loading of Data;
        this.loadGrid();
      } else {
        let errorMsgeFromBack = this.dataRec.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  };
  // Loads grid on complete loading of Data;
  public loadGrid() {
    // shows Complexx Assesment Grid on Load of data;
    this.showComplex = true;
    // Common properties for both the grid;
    this.domLayout = 'autoHeight';
    this.getRowHeight = params => params.data.CrtitlePlc == 'Total Complexitiy Level' ||
      params.data.CrtitlePlc == 'Complexity Surcharge' || params.data.CrtitlePlcRisk == 'Total Risk Level' ||
      params.data.CrtitlePlcRisk == 'Risk Surcharge' ? 30 : 35;
    // colors the bottom row of Risk Gird;
    this.rowClassRulesRisk = {
      'colorTotal': function (params) {
        let data1 = params.data.CrtitlePlcRisk
        let data2 = params.data.CrtitlePlcRisk
        return data1 === 'Total Risk Level' || data2 === 'Risk Surcharge';
      },
    };
    // colors the bottom row of Complex Grid;
    this.rowClassRulesCom = {
      'colorTotal': function (params) {
        let data1 = params.data.CrtitlePlc
        let data2 = params.data.CrtitlePlc
        return data1 === 'Total Complexitiy Level' || data2 === 'Complexity Surcharge';
      },
    };
    this.defaultProperties = {
      // wrapText: true,
      sortable: false,
      suppressMovable: true,
    };
    // column defination for Complexity Assesment Grid;
    this.complexityColumndef = [
      {
        headerName: "Complexity", headerTooltip: "Complexity", field: "CrtitlePlc", singleClickEdit: true,
        cellStyle: (params) => (params.data.CrtitlePlc == "" || !this.regex.test(params.data.CrtitlePlc) || params.data.CrtitlePlc == undefined) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellEditor: 'agLargeTextCellEditor', tooltipField: "CrtitlePlc",
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },
        autoHeight: true, editable: function (params) {
          if (params.node.data.CrtitlePlc === 'Total Complexitiy Level' || params.node.data.CrtitlePlc === 'Complexity Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Complexity Level", headerTooltip: "Complexity Level", field: "levelPlc", singleClickEdit: true, tooltipField: "levelPlc.name",
        cellStyle: (params) => (params.data.levelPlc.name == "Please Select") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellEditor: 'agRichSelectCellEditor',
        cellRenderer: complxLevelName,
        keyCreator: function (levelPlc) {
          return levelPlc.name;
        },
        cellEditorParams: {
          cellRenderer: complxLevelName,
          values: this.complxLevlDrpDwn
        },
        editable: function (params) {
          if (params.node.data.CrtitlePlc === 'Total Complexitiy Level' || params.node.data.CrtitlePlc === 'Complexity Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Commentary", headerTooltip: "Commentary", field: "Crcomment", width: 252, singleClickEdit: true,
        cellEditor: 'agLargeTextCellEditor', tooltipField: "Crcomment",
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },
        editable: function (params) {
          if (params.node.data.CrtitlePlc === 'Total Complexitiy Level' || params.node.data.CrtitlePlc === 'Complexity Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Action", headerTooltip: "Action",
        filter: false,
        width: 50,
        cellRendererFramework: DeleteiconComponent,
        colId: "edit",
        sortable: false,
      }
    ];
    // shows Risk Assesment Grid on Load of data;
    this.showRisk = true;
    // column defination for Risk Assesment Grid;
    this.riskColumnDef = [
      {
        headerName: "Risk", headerTooltip: "Risk", autoHeight: true, field: "CrtitlePlcRisk",
        cellEditor: 'agLargeTextCellEditor', tooltipField: "CrtitlePlcRisk",
        cellStyle: (params) => (params.data.CrtitlePlcRisk == "" || !this.regex.test(params.data.CrtitlePlcRisk) || params.data.CrtitlePlcRisk == undefined) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },
        singleClickEdit: true,
        editable: function (params) {
          if (params.node.data.CrtitlePlcRisk === 'Total Risk Level' || params.node.data.CrtitlePlcRisk === 'Risk Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Probability", headerTooltip: "Probability", field: "Probability", singleClickEdit: true, width: 100,
        cellEditor: 'agRichSelectCellEditor', tooltipField: "Probability.name",
        cellRenderer: riskProbName,
        cellStyle: (params) => (params.data.Probability.name == "Please Select") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        keyCreator: function (Probability) {
          return Probability.name;
        },
        cellEditorParams: {
          cellRenderer: riskProbName,
          values: this.riskProbDrpDwn
        }, editable: function (params) {
          if (params.node.data.CrtitlePlcRisk === 'Total Risk Level' || params.node.data.CrtitlePlcRisk === 'Risk Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Mitigation", headerTooltip: "Mitigation", field: "Crcomment", singleClickEdit: true,
        cellEditor: 'agLargeTextCellEditor', tooltipField: "Crcomment",
        cellStyle: (params) => (params.node.data.CrtitlePlcRisk != 'Total Risk Level' && params.node.data.CrtitlePlcRisk != 'Risk Surcharge' && (params.data.Crcomment == null || !this.regex.test(params.data.Crcomment) || params.data.Crcomment == '')) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellEditorParams: {
          maxLength: '1000',   // override the editor defaults
          cols: '40',
          rows: '5'
        }, editable: function (params) {
          if (params.node.data.CrtitlePlcRisk === 'Total Risk Level' || params.node.data.CrtitlePlcRisk === 'Risk Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Remaining probability after mitigation", headerTooltip: "Remaining probability after mitigation", width: 120, field: "remainingPlc", tooltipField: "remainingPlc.name",
        singleClickEdit: true, cellEditor: 'agRichSelectCellEditor',
        cellStyle: (params) => (params.data.remainingPlc.name == "Please Select") ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        cellRenderer: riskRemProbName,
        keyCreator: function (remainingPlc) {
          return remainingPlc.name;
        },
        cellEditorParams: {
          cellRenderer: riskRemProbName,
          values: this.riskRemnProbDrpDwn
        },
        editable: function (params) {
          if (params.node.data.CrtitlePlcRisk === 'Total Risk Level' || params.node.data.CrtitlePlcRisk === 'Risk Surcharge') {
            return false;
          }
          else {
            return params.data.IsEditable
          }
        }
      },
      {
        headerName: "Action", headerTooltip: "Action",
        width: 50,
        filter: false,
        cellRendererFramework: DeleteiconComponent,
        colId: "edit",
        sortable: false,
      }
    ];
  };

  onGridRefSceComplexSizeChanged(params) {
    this.gridApiComplex = params.api;
    this.gridApiComplex.sizeColumnsToFit();
  }

  onGridRefSceRiskSizeChanged(params) {
    this.gridApiRisk = params.api;
    this.gridApiRisk.sizeColumnsToFit();
  };

  onGridReady(params) {
    this.gridApiComplex = params.api
    this.gridColumnApiComplex = params.columnApi
    let ttlCmplxlvl = this.totalComplx();
    params.api.setPinnedBottomRowData(createDataCom(ttlCmplxlvl));
    let rowCnt = this.gridApiComplex.getDisplayedRowCount();
    if (rowCnt > 5) {
      this.gridApiComplex.setDomLayout('normal');
      document.getElementById('refSceComplexGrid').style.height = '300px';
    };
  }

  totalComplx() {
    let countHigh = 0;
    let countMedium = 0;
    let countLow = 0;
    let ttlComplxLvl = 0;
    let compSurchrg = 0;
    let countRow = this.gridApiComplex.getDisplayedRowCount()

    if (countRow == 0) {
      this.cmplxLevel = ttlComplxLvl;
    } else {
      this.gridApiComplex.forEachNode(function (node) {
        if (node.data.levelPlc.id == EnumComplexityAndRisk.High) {
          countHigh += 1;
        } else if (node.data.levelPlc.id == EnumComplexityAndRisk.MED) {
          countMedium += 1;
        } else if (node.data.levelPlc.id == EnumComplexityAndRisk.Low) {
          countLow += 1;
        }
      });
      ttlComplxLvl = (countHigh * 2 + countMedium * 1) / ((countHigh + countMedium + countLow) * 2);
      if (!ttlComplxLvl) {
        ttlComplxLvl = 0;
      }
      this.cmplxLevel = ttlComplxLvl;


      if (ttlComplxLvl <= 0.33) {
        compSurchrg = this.refRangeLevelGsId8 * 100;
      } else if (ttlComplxLvl > 0.33 && ttlComplxLvl <= 0.66) {
        compSurchrg = this.refRangeLevelGsId9 * 100;
      }
      else if (ttlComplxLvl > 0.66 && ttlComplxLvl <= 1) {
        compSurchrg = this.refRangeLevelGsId10 * 100;
      }
    }
    ttlComplxLvl = Number(ttlComplxLvl.toFixed(2));
    //1
    compSurchrg = Number(compSurchrg.toFixed(2));
    return { "ttlComplxLvl": ttlComplxLvl, "compSurchrg": compSurchrg }
  };

  onGridReadyRisk(params) {
    this.gridApiRisk = params.api
    let ttlRiskCalculation = this.totalRisk();
    params.api.setPinnedBottomRowData(createDataRisk(ttlRiskCalculation));
    let rowCnt = this.gridApiRisk.getDisplayedRowCount();
    if (rowCnt > 5) {
      this.gridApiRisk.setDomLayout('normal');
      document.getElementById('refSceRiskGrid').style.height = '300px';
    };
  };

  totalRisk() {
    let ttlRisk = []
    let count = 0;
    let total = 0;
    let average = 0;
    let ttlRiskLvl = 0;
    let riskSurchrge = 0;
    let countRow = this.gridApiRisk.getDisplayedRowCount();

    if (countRow == 0) {
      this.riskLevel = ttlRiskLvl;
    } else {
      this.gridApiRisk.forEachNode(function (node) {
        ttlRisk.push(node.data.remainingPlc.name)

      });
      for (let i of ttlRisk) {
        let risk = i.split('%').join('')
        count += 1;
        total = total + Number(risk);
      }
      average = (total / count) / 100;
      if (!average) {
        average = 0;
      }
      this.riskLevel = average;
      ttlRiskLvl = Number(average.toFixed(2));
      if (average <= 0.33) {
        riskSurchrge = this.tarRangeProbGsId11 * 100;
      } else if (average > 0.33 && average <= 0.66) {
        riskSurchrge = this.tarRangeProbGsId12 * 100;
      } else if (average > 0.66 && average < 1) {
        riskSurchrge = this.tarRangeProbGsId13 * 100;
      }
    }
    //2
    riskSurchrge = Number(riskSurchrge.toFixed(2));
    return { "ttlRiskLvl": ttlRiskLvl, "riskSurchrge": riskSurchrge }
  };

  onRowClickedComplx($event) {
    let rowCount = this.gridApiComplex.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApiComplex.setDomLayout('autoHeight');
      document.getElementById('refSceComplexGrid').style.height = null;
    };

  }

  onRowClickedRisk($event) {
    let rowCount = this.gridApiRisk.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApiRisk.setDomLayout('autoHeight');
      document.getElementById('refSceRiskGrid').style.height = null;
    };
  }

  // it called when cell value is changed of Complexity Grid,it changes the calculation of the bottom row;
  onCellValueChangedComplex(params) {
    let isPushed = false;
    this.hasUnsavedChanges = true;
    this.isChangeinComplexGrid = true;
    if (params.oldValue != params.newValue && params.data["ComplexityRiskId"] != 0) {

      if (this.arrayComplexGridData.length == 0) {
        this.arrayComplexGridData.push(params.data);
        isPushed = true;
      }
      else {
        this.arrayComplexGridData.forEach(row => {
          if (row["ComplexityRiskId"] == params.data["ComplexityRiskId"]) {
            isPushed = true;
          }
        });
      }

      if (!isPushed) {
        this.arrayComplexGridData.push(params.data);
      }
    }
    if (params != 1) {
      if (params.colDef.field == "levelPlc") {
        let ttlCmplxlvl = this.totalComplx();
        this.pinnedBottomRowDataComplex = createDataCom(ttlCmplxlvl);
        this.gridApiComplex.setPinnedBottomRowData(this.pinnedBottomRowDataComplex);
      };
    } else {
      let ttlCmplxlvl = this.totalComplx();
      this.pinnedBottomRowDataComplex = createDataCom(ttlCmplxlvl);
      this.gridApiComplex.setPinnedBottomRowData(this.pinnedBottomRowDataComplex);
    }
  };

  // it called when cell value is changed of Risk Grid,it changes the calculation of the bottom row;
  onCellValueChangedRisk(params) {
    let isPushed = false;
    this.hasUnsavedChanges = true;
    this.isChangeinRiskGrid = true;
    if (params.oldValue != params.newValue && params.data["ComplexityRiskId"] != 0) {

      if (this.arrayRiskGridData.length == 0) {
        this.arrayRiskGridData.push(params.data);
        isPushed = true;
      }
      else {
        this.arrayRiskGridData.forEach(row => {
          if (row["ComplexityRiskId"] == params.data["ComplexityRiskId"]) {
            isPushed = true;
          }
        });
      }
      if (!isPushed) {
        this.arrayRiskGridData.push(params.data);
      }
    }
    if (params != 1) {
      if (params.colDef.field == "remainingPlc") {
        let calculation = this.totalRisk();
        this.pinnedBottonRowDataRisk = createDataRisk(calculation)
        this.gridApiRisk.setPinnedBottomRowData(this.pinnedBottonRowDataRisk)
      }
    } else {
      let calculation = this.totalRisk();
      this.pinnedBottonRowDataRisk = createDataRisk(calculation)
      this.gridApiRisk.setPinnedBottomRowData(this.pinnedBottonRowDataRisk)
    }
  };

  // Gets all Row Data for Risk Grid;
  AllRiskRowData() {
    let rowData = [];
    let rowAllData = [];
    let riskSurchrge = this.gridApiRisk.pinnedRowModel.pinnedBottomRows[1].data.Probability.name
    riskSurchrge = riskSurchrge.split('%').join('')
    this.riskSurcharge = riskSurchrge;
    this.gridApiRisk.forEachNode((node) => {
      rowAllData.push(node.data)
      if (node.data.ComplexityRiskId == 0) {
        rowData.push(node.data)
      }
    });

    let error = this.validateAllRisk(rowAllData);
    if (error) {
      return null;
    } else {
      this.arrayRiskGridData = this.arrayRiskGridData.concat(rowData);
      return this.arrayRiskGridData;
    }
  };
  // Get all Row Data for complx Grid;
  AllCmplxRowData() {
    let rowData = [];
    let rowAllData = [];
    let cmplxSurcharge = this.gridApiComplex.pinnedRowModel.pinnedBottomRows[1].data.levelPlc.name
    cmplxSurcharge = cmplxSurcharge.split('%').join('')
    this.cmplxSurcharge = cmplxSurcharge;
    this.gridApiComplex.forEachNode((node) => {
      rowAllData.push(node.data)
      if (node.data.ComplexityRiskId == 0) {
        rowData.push(node.data)
      }
    });
    let error = this.validateAllComplx(rowAllData);
    if (error) {
      return null;
    } else {
      this.arrayComplexGridData = this.arrayComplexGridData.concat(rowData);
      return this.arrayComplexGridData;
    }
  }

  // It will validate all the row data of Complexity Grid before post/insert/delete call;
  public validateAllComplx(rowData) {
    let error = [];
    let countComp = 0;
    let countRisk = 0;
    for (let i of rowData) {
      let checked = this.regex.test(i.CrtitlePlc)
      if (i.CrtitlePlc == undefined || i.CrtitlePlc == null || i.CrtitlePlc == "" || i.CrtitlePlc === "Add complexitiy item here,If applicable" || !checked) {
        if (countComp == 0) {
          this.finalErrorComplx.push({ Complexity: messages.complexityAndRisk.headingComplexity })
        }
        countComp += 1;
      }
      if (i.levelPlc.id == null && (i.CrtitlePlc != undefined || i.CrtitlePlc != null || i.CrtitlePlc != "" || i.CrtitlePlc != "Add complexitiy item here,If applicable")) {
        if (countRisk == 0) {
          this.finalErrorComplx.push({ Complexitiy_Level: messages.complexityAndRisk.headingComplexity })
        }
        countRisk += 1
      }
    }
    if (this.finalErrorComplx.length) {
      // this.openAlertDialog(error)
      return true;
    } else {
      return false;
    }
  };
  // It will validate all the row data of Risk Grid Before post/insert/delete call;
  public validateAllRisk(rowData) {
    let error = [];
    let countRisk = 0;
    let countProb = 0;
    let countMit = 0;
    let countRem = 0;
    for (let i of rowData) {
      let checkCrtitlePlcRisk = this.regex.test(i.CrtitlePlcRisk)
      if (i.CrtitlePlcRisk == undefined || i.CrtitlePlcRisk == null || i.CrtitlePlcRisk == "" || i.CrtitlePlcRisk === "Please add risk here, if applicable" || !checkCrtitlePlcRisk) {
        if (countRisk == 0) {
          this.finalErrorRisk.push({ Risk: messages.complexityAndRisk.headingRisk })
        }
        countRisk += 1;
      }
      if (i.Probability.id == null && (i.CrtitlePlcRisk != undefined || i.CrtitlePlcRisk != null || i.CrtitlePlcRisk != "" || i.CrtitlePlcRisk != "Please add risk here, if applicable")) {
        if (countProb == 0) {
          this.finalErrorRisk.push({ Probability: messages.complexityAndRisk.headingRisk })
        }
        countProb += 1
      }
      let checkCrcomment = this.regex.test(i.Crcomment)
      if ((i.Crcomment == "Please Enter" || !checkCrcomment || i.Crcomment == "" || i.Crcomment == undefined || i.Crcomment == null) && (i.CrtitlePlcRisk != undefined || i.CrtitlePlcRisk != null || i.CrtitlePlcRisk != "" || i.CrtitlePlcRisk != "Please add risk here, if applicable" || !checkCrtitlePlcRisk)) {
        if (countMit == 0) {
          this.finalErrorRisk.push({
            [messages.complexityAndRisk.validationMitigation]: messages.complexityAndRisk.headingRisk
          })
        }
        countMit += 1
      }
      if (i.remainingPlc.id == null && (i.CrtitlePlcRisk != undefined || i.CrtitlePlcRisk != null || i.CrtitlePlcRisk != "" || i.CrtitlePlcRisk != "Please add risk here, if applicable")) {
        if (countRem == 0) {
          this.finalErrorRisk.push({ Remaining_probability_after_mitigation: messages.complexityAndRisk.headingRisk })
        }
        countRem += 1
      }
    }
    if (this.finalErrorRisk.length) {

      return true;
    } else {
      return false;
    }
  }

  dataToSend(RiskData, CmplxData) {
    let finalData = [];

    if (CmplxData != null) {
      for (let i of CmplxData) {
        finalData.push({
          ComplexityRiskId: i.ComplexityRiskId,
          IdeaId: this.ideaId,
          SolutionId: i.SolutionId,
          Crtitle: i.CrtitlePlc == undefined ? null : i.CrtitlePlc.trim(),
          CrlevelId: i.levelPlc.id,
          Crcomment: i.Crcomment == undefined ? null : i.Crcomment.trim(),
          CrremainingProbability: null,
          Type: i.Type,
          IsPlc: this.IsPlc,
          IsActive: i.IsActive
        })
      };
    }

    if (RiskData != null) {
      for (let j of RiskData) {
        finalData.push({
          ComplexityRiskId: j.ComplexityRiskId,
          IdeaId: j.IdeaId,
          SolutionId: j.SolutionId,
          Crtitle: j.CrtitlePlcRisk == undefined ? null : j.CrtitlePlcRisk.trim(),
          CrlevelId: j.Probability.id,
          Crcomment: j.Crcomment == undefined ? null : j.Crcomment.trim(),
          CrremainingProbability: j.remainingPlc.id,
          Type: j.Type,
          IsPlc: this.IsPlc,
          IsActive: j.IsActive
        })
      }
    }

    let ComplexityRiskCalc = {};
    ComplexityRiskCalc = {
      NonPLCSummaryId: this.NonPLCSummaryId,
      IdeaId: this.ideaId,
      SolutionId: this.solutionId,
      Complevel: this.cmplxLevel,
      CompSurcharge: this.cmplxSurcharge / 100,
      Risklevel: this.riskLevel,
      RiskSurcharge: this.riskSurcharge / 100,
      IsPlc: true,
      IsActive: true
    }
    //Remarks changes: While POST;
    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: MSolutionType.OptionC,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.PLCComplexityRisk
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }

    let dataSend = {
      ComplexityRiskRows: finalData,
      ComplexityRiskCalc: ComplexityRiskCalc,
      IdeaId: this.ideaId,
      SolutionId: this.solutionId,
      Remark: Remark,
      IsPlc: true,
      UserId: this.userInOrg.UserId,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId
    }
    return dataSend;
  }


  submit(event?: any) {
    let response: any;
    this.finalErrorComplx = [];
    this.finalErrorRisk = [];
    this.submitted = true;
    let allDataRisk = this.AllRiskRowData();
    let allDataComplx = this.AllCmplxRowData();
    if (allDataComplx == null || allDataRisk == null) {
      let alertBoxError = [];
      alertBoxError = this.finalErrorComplx;
      for (let i of this.finalErrorRisk) {
        alertBoxError.push(i)
      }
      this.arrayRiskGridData = this.arrayRiskGridData.filter(newRow => newRow.ComplexityRiskId != 0)
      this.arrayComplexGridData = this.arrayComplexGridData.filter(newRow => newRow.ComplexityRiskId != 0)
      this.openAlertDialog(alertBoxError);
      this.submitted = false;
    } else {
      let finalData = this.dataToSend(allDataRisk, allDataComplx);
      if (!this.hasUnsavedChanges) {
        this.hasUnsavedChanges = false;
        if (event != undefined) {
          this.router.navigate(['/manageIdea'])
        }
        return true;
      } else {
        response = this.refService.postAllData(finalData).toPromise().then((data) => {
          this.submitResponse = data;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.hasUnsavedChanges = false;
              if (event != undefined) {
                this.router.navigate(['/manageIdea'])
              }
              return true;
            } else {
              //Show errorCode and errorMessage in the UI
              this.arrayRiskGridData = this.arrayRiskGridData.filter(newRow => newRow.ComplexityRiskId != 0);
              this.arrayComplexGridData = this.arrayComplexGridData.filter(newRow => newRow.ComplexityRiskId != 0);
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.submitted = false;
              return false;
            }
          } else {
            //Show errorCode and errorMessage in the UI
            this.arrayRiskGridData = this.arrayRiskGridData.filter(newRow => newRow.ComplexityRiskId != 0);
            this.arrayComplexGridData = this.arrayComplexGridData.filter(newRow => newRow.ComplexityRiskId != 0);
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }

        }, (error) => {

          this.arrayRiskGridData = this.arrayRiskGridData.filter(newRow => newRow.ComplexityRiskId != 0);
          this.arrayComplexGridData = this.arrayComplexGridData.filter(newRow => newRow.ComplexityRiskId != 0);
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
          this.submitted = false;
          return false;
        })
      }
    }
    return response;
  }

  public openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // get called when user click on cancel and exit;
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }

  // move user to back page;
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }

  // moves user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  // Remarks changes: While Input Changes;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
}
// creates row for "Total Complexity Level" and "Comlexity Surge" of Grid Complexity Assesment;
function createDataCom(ttlCmplxlvl) {
  let result = [];
  result.push({
    CrtitlePlc: "Total Complexitiy Level",
    levelPlc: {
      name: ttlCmplxlvl.ttlComplxLvl,
      id: null
    }
  });
  result.push({
    CrtitlePlc: "Complexity Surcharge",
    levelPlc:
    {
      name: ttlCmplxlvl.compSurchrg + "%",
      id: null
    }
  });
  return result;
};
// creates row "Total Risk Level" and "Risk SurCharge" of grid Risk Assesment;
function createDataRisk(ttlRiskCalculation) {
  let result = [];
  result.push({
    CrtitlePlcRisk: "Total Risk Level",
    Probability: {
      name: ttlRiskCalculation.ttlRiskLvl,
      id: null
    },
    remainingPlc:
    {
      name: null,
      id: null
    }
  });
  result.push({
    CrtitlePlcRisk: "Risk Surcharge",
    Probability: {
      name: ttlRiskCalculation.riskSurchrge + "%",
      id: null
    },
    remainingPlc:
    {
      name: null,
      id: null
    }
  });
  return result;
};
// Returns the name for "Level" dropdown of Grid "Complexity Assesment";
const complxLevelName = (params) => params.value.name;
// Returns the name for "Probability" dropdown of Grid "Risk Assesment";
const riskProbName = (params) => params.value.name;
// Returns the name for "Remaining Probability.." dropdown of Grid "Risk Assesment";
const riskRemProbName = (params) => params.value.name;