<mat-form-field appearance="legacy" class="myPanel no-underline" >
  <mat-select 
  [multiple]  ="!singleSelection"
    [formControl]="customControl"
    panelClass="customPanel"
    [compareWith]="compare"
    (closed)="clearSearch()"
    (click)="autofocus()"
  >
      <mat-form-field
      [floatLabel]="'never'"
      style="width: 95%;"
      class="no-underline no-wrapper sticky-search"
    >
      <mat-label class="search-label">Search Email...</mat-label>
      <input
        matInput
        #myInput
        [formControl]="filterControl"
        type="text"
        (input)="callParent()"
        autocomplete="off"
      />     
    </mat-form-field>

    <mat-select-trigger>
        <span *ngIf="dataSource.length!==0">
            {{dataSource ? userData : ''}}</span>
        <span *ngIf="dataSource?.length > 1" class="example-additional-selection">
          (+{{dataSource.length - 1}} {{dataSource?.length === 2 ? 'other' : 'others'}})
        </span>
    </mat-select-trigger>
    <mat-option class="mat-option-hide" disabled></mat-option>
    <mat-option
      *ngFor="let user of users; let i = index"
      [value]="user"
      id="user{{ i }}"
      (click)="getDetails(user)"
    >
      {{ user.email }}
    </mat-option>
  </mat-select>
</mat-form-field>
