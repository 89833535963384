import { Component, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CategorizationService } from "../../services/categorization.service";

import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { IdeaformService } from '../../services/ideaform.service';
import { Router } from '@angular/router';
import { serverMessage } from '../../popUpMessages/serverMessage';
import { messages } from '../../popUpMessages/messages';

import { default as moment } from 'moment';
import { MenuService } from 'src/app/services/menu.service';
import { EnumErrorCodes } from 'src/app/enum/EnumErrorCodes';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { UserInfoService } from "src/app/services/user-info.service";
import { HelpService } from 'src/app/services/help.service';
import { FxRatesService } from 'src/app/services/fx-rates.service';
import { Observable } from 'rxjs';
import { PlcSummaryService } from 'src/app/services/plc-summary.service';
import { NonplcsummaryService } from 'src/app/services/nonplcsummary.service';

@Component({
  selector: 'app-categorization',
  templateUrl: './categorization.component.html',
  styleUrls: ['./categorization.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CategorizationComponent implements Dirty {


  //Bind Form
  filterValue: string;
  filteredOptions: Observable<string[]>;
  loginUserDetails: any;
  categorizationForm: FormGroup;
  ideaId: any;
  dataBind = {};
  OPWFilterSubFilterArray: any = [];
  //get help text
  receivedHelpIconTextData: any;

  //Dropdownlist for helpicon
  // Dropdown1: any = "Measure Type";
  MeasureTypeHelpText: any;
  MeasureCategoryHelpText: any;
  StartDateHelpText: any;
  CurrencyHelpText: any;
  CostImpactHelpText: any;
  PLCAssesmentHelpText: any;
  StatusHelpText: any;
  ActivelyDrivenHelpText: any;
  DirectImpactOnEBITHelpText: any;
  DurationOfImpactHelpText: any;
  CostReductionVsReferencePeriodHelpText: any;
  ImprovementOfInputOutputRatioHelpText: any;
  CostAffectedHelpText: any;
  RevenueIncreaseHelpText: any;
  PurchasingVolumeImpactHelpText: any;
  PVODueToMaterialsOrServicesHelpText: any;
  CommodityHelpText: any;
  SubCommodityHelpText: any;
  OPWFilterHelpText: any;
  OPWSubFilterHelpText: any;
  OPWTagHelpText: any;
  public getPlcResponse: any;
  //Bind  properties
  txtOPWClassification: any;
  txtActionType: any;
  txtLeverType: any;
  txtImpactType: any;
  responsePost: any;
  isPLCseclected: any;
  isRequiredCostReduction: boolean = false;
  isRequiredCostReductionVal: boolean = false;
  isRequiredOPWClassificationBaseProductivity: boolean = false;
  isRequiredPPCORMCP: boolean = false;
  isRequiredPVO: boolean = false;
  setCommodityValue: any = null;
  isCommodityFill: any = false;
  isSubCommodityFill: any = false;
  orginalStartDate: any;
  originalStatus: any;
  filtered: any;
  finalCurrencyIdToSend: any;
  // for remark input field;
  remarkId: any;
  remark: any;
  remarkDataRecieved: any;
  isChangeRemarks: any;

  PVOMaterials1: boolean = true;
  //Measure Type
  measureType: any = [];

  //Measure category		
  measureCategory: any = [];

  // Currency 
  masterCurrencyData: any;

  //Cost Impact		
  costImpact: any = [];

  //Status
  status: any = [];

  //Product Lifecycle Cost assessment			
  costAssessment: any[] = [{ id: 1, title: "Required" }, { id: 2, title: "Not Required" }];

  //Actively driven action?		
  Action: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Direct impact on adjusted EBIT?		
  EBIT: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Duration of impact?		
  Impact: any[] = [{ id: 1, title: "Permanent" }, { id: 2, title: "One-Time Effect" }];

  //Cost reduction vs. reference period?		
  Cost: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Improvement of input/output ratio?			
  Radio: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Fix/var. costs affected?				
  FixCost: any[] = [{ id: 1, title: "Fix" }, { id: 2, title: "Variable" }];

  //Revenue increase?		
  RevenueIncrease: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Does the action have an impact on the purchasing volume (PVO)?		
  PVO: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Is the impact on the PVO due to a price change without modification of materials/services?	
  PVOMaterials: any[] = [{ id: 1, title: "Yes" }, { id: 2, title: "No" }];

  //Commoditiy Hierarchy
  MCommoditiyHierarchy: any = [];// ['Business Specific Healthineers', 'Pooling Direct', 'Pooling Indirect']

  //Sub Commoditiy Hierarchy
  TempMSubCommoditiyHierarchy: any = [];
  MSubCommoditiyHierarchy: any = [];

  //Currency 
  selectedCurrency = '';

  //Opw filter array
  MOPWFilter: any = [];
  SelectedOPWFilterId: any = [];
  OldSelected: any = [];


  //Opw sub filter array
  AllMOPWSubFilter: any;
  MOPWSubFilter: any = [];
  SelectedOPWSubFilterId: any = [];
  //Disable Options

  //Opw tag array
  MOPWTag: any = [];
  SelectedOPWTag: any = [];
  public FilterData: any = [];
  measureTitle: any;
  responseData: any = [];
  categorizationData: any = [];
  //Error Component
  public formerror = [];
  public errorMessage = {};
  public field = "";
  public headerMessage = "Please enter below required field:";
  public hasUnsavedChanges: boolean = false;
  public isEditable: boolean = false;
  public saveBtnDisplay: boolean = true;
  public showform = false;
  public recentChangedStatus: any;
  public submitted: boolean = false;
  public isPLCrequiredFromDB: any;
  public isPLCrequiredEdited: any;
  public nextMove = true;
  public PLCSummaryAccData: any;
  public gridOPWStoredData: any[];
  public allopwdata: any[];
  public nonPlcData: any[];

  submitData: any;
  userConsent: any;

  public errorObject = {
    // 'Measure Type': false,
    // 'Measure Category': false,
    // 'Currency': false,
    // 'StartDate': false,
    // 'Cost Impact': false,
    // 'Status': false,
    // 'Product Lifecycle Cost assessment': false,
    'Actively driven action?':
      false,
    'Direct impact on adjusted EBIT?': false,
    'Duration of impact?': false,
    'Cost reduction vs. reference period?': false,
    'Improvement of input/output ratio?': false,
    'Fix/var. costs affected?': false,
    'Revenue increase?': false,
    'Does the action have an impact on the purchasing volume (PVO)?': false,
    'Is the impact on the PVO due to a price change without modification of materials/services?': false,
    'Commodity': false,
    'Sub Commodity': false
  };

  public borderLeft: boolean = false;
  public borderLeft1: boolean = false;

  public pvoborderLeft: boolean = false;
  public pvoborderLeft1: boolean = false;

  public getErrorMessage(message, field, status,) {
    this.formerror = []
    this.errorObject[field] = JSON.parse(status)
  };

  public plcStatusError = [];

  openDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  //organisation
  public businessAreaId: number;
  public businessLineId: number;

  public userInOrg: any;
  public allUserList: any;
  public HelpTextList: any;
  public currencyError: any;
  constructor(
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private catService: CategorizationService,
    private dialog: MatDialog,
    private ToasterService: ToasterService,
    private LocalStorageService: LocalStorageService,
    private sharedService: SharedServiceService,
    private ideaservice: IdeaformService,
    private router: Router,
    private menuService: MenuService,
    private userService: UserInfoService,
    private service: HelpService,
    private fxservice: FxRatesService,
    private plcSummaryService: PlcSummaryService,
    private NonplcsummaryService: NonplcsummaryService,
  ) { }

  ngOnInit(): void {

    this.sharedService.getIdeaIdAndtitle();
    this.showform = false;
    this.sharedService.catgorizationOnClickOk.subscribe(
      () => {
        this.userConsent = true;
      }
    )
    this.sharedService.catgorizationOnClickCancel.subscribe(
      () => {
        this.userConsent = false;
        this.categorizationForm.patchValue(
          {
            IsPlcrequired: this.isPLCrequiredFromDB ? 1 : 2,
            MeasureStatusId: this.originalStatus,
            StartDate: new Date(this.orginalStartDate)
          }
        )
      }
    )

    //Make categorization Form with properties
    this.categorizationForm = this.fb.group({
      CategorizationId: [0],
      IdeadId: [this.ideaId],
      // MeasureTypeId: [''],
      // MeasureCategoryId: [''],
      // CurrencyId: [''],
      // StartDate: [''],
      // CostImpactId: [''],
      // MeasureStatusId: [''],
      // IsPlcrequired: [''],
      IsActivelyDriven: ['',[Validators.required]],
      IsDirectImpactOnEbit: ['',[Validators.required]],
      IsImpactPermanent: ['',[Validators.required]],
      IsCostReductionRequired: ['',[Validators.required]],
      IsRatioImprovementRequired: ['',[Validators.required]],
      IsFixCostAffected: ['',[Validators.required]],
      IsRevenueIncreased: ['',[Validators.required]],
      IsPvoImpacted: ['',[Validators.required]],
      IsPvoImpactRequired: ['',[Validators.required]],

      OPWClassification: [''],
      ActionType: [''],
      Lever: [''],
      ImpactType: [''],

      CommodityId: ['',],
      SubCommodityId: ['',],

      OpwFilterId: [],
      OpwSubFilterId: [],
      OpwTagId: [],

      Level3: [''],
      Level4: [''],
      Level5: [''],
      Level6: [''],
      Level7: [''],
      Level8: [''],
      Level9: [''],
      Level10: [''],
      Level11: [''],
      Level12: [''],
      Level13: ['']
    });

    this.categorizationForm.valueChanges.subscribe(e => {
      if (!this.hasUnsavedChanges)
        this.hasUnsavedChanges = this.categorizationForm.dirty;
    })

    //Get IdeaId from local Storage 
    this.ideaId = this.LocalStorageService.get('ideaId');
    if (this.ideaId != null) {
      this.ideaservice.getData(this.ideaId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          // if (data.Data.IsFPCApproved == true) {
          //   this.categorizationForm.get('IsActivelyDriven').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsDirectImpactOnEbit').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsImpactPermanent').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsCostReductionRequired').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsRatioImprovementRequired').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsFixCostAffected').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsRevenueIncreased').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsPvoImpacted').setValidators([Validators.required]);
          //   this.categorizationForm.get('IsPvoImpactRequired').setValidators([Validators.required]);
          // }
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  this.loginUserDetails = this.userInOrg;

                  if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.RoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }

                  // To make the form editable or ineditable according to user role
                  if (!this.isEditable) {
                    this.hasUnsavedChanges = false;
                    this.categorizationForm.disable();
                    this.saveBtnDisplay = false;
                  }
                  else if (this.isEditable) {
                    this.categorizationForm.enable();
                    this.saveBtnDisplay = true;
                  }
                }
              })
            }
          })
          //Bind all Categorizatio Dropdown 
          this.bindCategorizationDropdown();
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
      //Bind all Categorizatio Dropdown 
      this.bindCategorizationDropdown();
    }
    this.refreshHelpIconText();
  }

  onSearchChange(searchValue: string) {
    this.finalCurrencyIdToSend = null;
    this.filterValue = searchValue.trim();
    if (this.filterValue == "") {
      this.filtered = this.masterCurrencyData;
    }
    else if (this.filterValue.includes('-')) {
      this.filtered = this.masterCurrencyData.filter((val) => val.CurrencyCode.toLowerCase().includes(this.filterValue.toLowerCase().split('-')[0].trim()));
    }
    else {
      this.filtered = this.masterCurrencyData.filter((value) => value.Currency.toLowerCase().includes(this.filterValue.toLocaleLowerCase()) || value.CurrencyCode.toLowerCase().includes(this.filterValue.toLocaleLowerCase()));
    }
  }

  focusOutFunction(event: any) {
    if (this.filtered.length == 0) {
      let errorContainer = [
        { [serverMessage.currencyError]: serverMessage.serverErrorHeader }
      ]
      this.openAlertDialog(errorContainer);
      this.categorizationForm.get('CurrencyId')?.reset();
      this.filtered = this.masterCurrencyData;
      return false;

    }
  }

  onSelCurrency($event: any, option) {
    if ($event.isUserInput) {
      let currencyCode = this.masterCurrencyData.find(x => x.CurrencyId == option).CurrencyCode;
      this.finalCurrencyIdToSend = option;
      this.catService.changeMsg(currencyCode.trim());
    }

  }

  //old dropdown changes
  //selected currency to show in productivity target
  // onOptionsSelected(event) {
  //   // let value = event.target.value;
  //   let currencyCode = this.masterCurrencyData.find(x => x.CurrencyId == event).CurrencyCode;
  //   this.catService.changeMsg(currencyCode.trim());
  // }
  //Call Deactivate function
  canDeactivate() {
    return this.hasUnsavedChanges;
  }
  //get help icon text
  refreshHelpIconText() {
    this.service.getAllText().subscribe(data => {
      this.HelpTextList = data;

      let successCode = this.HelpTextList.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.HelpTextList.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedHelpIconTextData = this.HelpTextList.Data;
        this.bindhelpicontextdata();
      }
    });
  }

  bindhelpicontextdata() {
    for (let i of this.receivedHelpIconTextData) {

      if (i.HelpIconId == 1 && i.Language == "English") {

        this.MeasureTypeHelpText = i.HelpIconText;
      }
      if (i.HelpIconId == 2 && i.Language == "English") {

        this.MeasureCategoryHelpText = i.HelpIconText;
      }
      if (i.Component == "StartDate" && i.Language == "English") {

        this.StartDateHelpText = i.HelpIconText;
      }
      if (i.Component == "Currency" && i.Language == "English") {

        this.CurrencyHelpText = i.HelpIconText;
      }
      if (i.Component == "CostImpact" && i.Language == "English") {

        this.CostImpactHelpText = i.HelpIconText;
      }
      if (i.Component == "PLCAssesment" && i.Language == "English") {

        this.PLCAssesmentHelpText = i.HelpIconText;
      }
      if (i.Component == "Status" && i.Language == "English") {

        this.StatusHelpText = i.HelpIconText;
      }
      if (i.Component == "ActivelyDriven" && i.Language == "English") {

        this.ActivelyDrivenHelpText = i.HelpIconText;
      }
      if (i.Component == "DirectImpactOnEBIT" && i.Language == "English") {

        this.DirectImpactOnEBITHelpText = i.HelpIconText;
      }
      if (i.Component == "DurationOfImpact" && i.Language == "English") {

        this.DurationOfImpactHelpText = i.HelpIconText;
      }
      if (i.Component == "CostReductionVsReferencePeriod" && i.Language == "English") {

        this.CostReductionVsReferencePeriodHelpText = i.HelpIconText;
      }
      if (i.Component == "ImprovementOfInputOutputRatio" && i.Language == "English") {

        this.ImprovementOfInputOutputRatioHelpText = i.HelpIconText;
      }
      if (i.Component == "CostAffected" && i.Language == "English") {

        this.CostAffectedHelpText = i.HelpIconText;
      }
      if (i.Component == "RevenueIncrease" && i.Language == "English") {

        this.RevenueIncreaseHelpText = i.HelpIconText;
      }
      if (i.Component == "PurchasingVolumeImpact" && i.Language == "English") {

        this.PurchasingVolumeImpactHelpText = i.HelpIconText;
      }
      if (i.Component == "PVODueToMaterialsOrServices" && i.Language == "English") {

        this.PVODueToMaterialsOrServicesHelpText = i.HelpIconText;
      }
      if (i.Component == "Commodity" && i.Language == "English") {

        this.CommodityHelpText = i.HelpIconText;
      }
      if (i.Component == "SubCommodity" && i.Language == "English") {

        this.SubCommodityHelpText = i.HelpIconText;
      }
      if (i.Component == "OPWFilter" && i.Language == "English") {

        this.OPWFilterHelpText = i.HelpIconText;
      }
      if (i.Component == "OPWSubFilter" && i.Language == "English") {

        this.OPWSubFilterHelpText = i.HelpIconText;
      }
      if (i.Component == "OPWTag" && i.Language == "English") {

        this.OPWTagHelpText = i.HelpIconText;
      }
    }
  }

  // All Categorizatio Dropdown 
  bindCategorizationDropdown() {
    this.catService.getMeasureType(this.businessAreaId, this.businessLineId).subscribe((measureType: any) => {
      let errorMsgeFromBack = measureType.Data.Message;
      if (measureType.StatusCode == 200) {
        this.measureType = measureType;
        this.getMeasureCategory();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {

      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);

    });
  }

  getMeasureCategory() {
    this.catService.getMeasureCategory(this.businessAreaId, this.businessLineId).subscribe((measureCategory: any) => {
      let errorMsgeFromBack = measureCategory.Data.Message;
      if (measureCategory.StatusCode == 200) {
        this.measureCategory = measureCategory;
        this.getMasterCurrency();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getMasterCurrency() {
    this.fxservice.GetMasterCurrency().subscribe((currencyData: any) => {
      let errorMsgeFromBack = currencyData.Data.Message;
      if (currencyData.StatusCode == 200) {
        this.masterCurrencyData = currencyData.Data;
        this.masterCurrencyData.sort((a, b) => a.CurrencyId > b.CurrencyId ? 1 : -1);
        this.filtered = this.masterCurrencyData;
        this.getCostImpacts();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getCostImpacts() {
    this.catService.getCostImpacts(this.businessAreaId, this.businessLineId).subscribe((mcostImpact: any) => {
      let errorMsgeFromBack = mcostImpact.Data.Message;
      if (mcostImpact.StatusCode == 200) {
        this.costImpact = mcostImpact;
        this.getMeasureStatus();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
  getMeasureStatus() {
    this.catService.getMeasureStatus(this.businessAreaId, this.businessLineId).subscribe((measureStatus: any) => {
      let errorMsgeFromBack = measureStatus.Data.Message;
      if (measureStatus.StatusCode == 200) {
        this.status = measureStatus;
        this.getMCommoditiyHierarchy();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
  getMCommoditiyHierarchy() {
    this.catService.getMCommoditiyHierarchy(this.businessAreaId, this.businessLineId).subscribe((mCommoditiyHierarchy: any) => {
      let errorMsgeFromBack = mCommoditiyHierarchy.Data.Message;
      if (mCommoditiyHierarchy.StatusCode == 200) {
        this.MCommoditiyHierarchy = mCommoditiyHierarchy;
        this.getMSubCommoditiyHierarchy();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
  getMSubCommoditiyHierarchy() {
    this.catService.getMSubCommoditiyHierarchy(this.businessAreaId, this.businessLineId).subscribe((mSubCommoditiyHierarchy: any) => {
      let errorMsgeFromBack = mSubCommoditiyHierarchy.Data.Message;
      if (mSubCommoditiyHierarchy.StatusCode == 200) {
        this.TempMSubCommoditiyHierarchy = mSubCommoditiyHierarchy;
        this.getMOPWTag();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getMOPWTag() {
    this.catService.getMOPWTag(this.businessAreaId, this.businessLineId).subscribe((opwTag: any) => {
      let errorMsgeFromBack = opwTag.Data.Message;
      if (opwTag.StatusCode == 200) {
        this.MOPWTag = opwTag.Data;
        this.getMOPWFilter();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }


  getMOPWFilter() {
    this.catService.getMOPWFilter(this.businessAreaId, this.businessLineId).subscribe((mOpwFilter: any) => {
      let errorMsgeFromBack = mOpwFilter.Data.Message;
      if (mOpwFilter.StatusCode == 200) {
        this.MOPWFilter = mOpwFilter.Data;
        this.getMOPWSubFilter();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getMOPWSubFilter() {
    this.catService.getMOPWSubFilter(this.businessAreaId, this.businessLineId).subscribe((mOpwSubFilter: any) => {
      let errorMsgeFromBack = mOpwSubFilter.Data.Message;
      if (mOpwSubFilter.StatusCode == 200) {
        this.AllMOPWSubFilter = mOpwSubFilter.Data;
        this.getCategorizationFormData();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getCategorizationFormData() {
    //Get Categorization data from local Storage 
    let dataCategorization = this.LocalStorageService.get(this.ideaId + '_CategorizationData');
    let remarkLocalStorgae = this.LocalStorageService.get(this.ideaId + '_CategorizationData_Remarks');
    if (remarkLocalStorgae != null) {
      this.remark = remarkLocalStorgae;
    }

    if (dataCategorization != null) {
      this.showform = true;
      this.hasUnsavedChanges = true;
      this.fillCategorizationData(dataCategorization);
    } else {
      //Bind all Categorizatio Data by IdeaId 
      this.bindCategorizationByIdeadId();
    }
  }

  //Fill Categorization form data
  fillCategorizationData(objCategorization) {

    this.checkIsPLCrequiredChange(objCategorization);
    //Bind Sub Commoditiy based on CommoditiyId
    this.SubCommoditiyHierarchy(objCategorization.CommodityId);

    this.SelectedOPWTag = objCategorization.OPWTagId != null ? objCategorization.OPWTagId.split(',').map(Number) : 0;
    this.categorizationForm.get("OpwTagId").setValue(this.SelectedOPWTag);
    this.categorizationForm.get("OpwFilterId").setValue(this.SelectedOPWFilterId);
    this.opwFilterChange(this.SelectedOPWFilterId)
    this.categorizationForm.get("OpwSubFilterId").setValue(this.SelectedOPWSubFilterId);

    //Set Set Commodity Value for Commoditiy dropdown
    if (objCategorization.CommodityId > 0 && objCategorization.SubCommodityId > 0) {
      this.isCommodityFill = true;
      this.isSubCommodityFill = true;
    } else {
      this.setCommodityValue = 0;
    }
    //Bind Fix/var. costs affected? && Revenue increase? Dropdown based on IsCostReductionRequired
    if (objCategorization.IsCostReductionRequired) {
      this.isRequiredCostReduction = true;
      this.isRequiredCostReductionVal = true;
      this.resetIsFixNIsRevDrodpdown();
    }
    //Bind Is the impact on the PVO due to a price change without modification of materials/services? Dropdown based on IsPvoImpacted
    if (!objCategorization.IsPvoImpacted) {
      this.PVOMaterials1 = false;
      this.resetPVOMaterialsDropdown();
    }
    if (objCategorization.Lever == "Material Cost Productivity (MCP)" || objCategorization.Lever == "Purchasing Price Change (PPC)") {
      this.isRequiredPPCORMCP = true;
    }
    //Set The currencyTitle in the header component
    // if (objCategorization.CurrencyId > 0) {
    //   this.catService.changeMsg(this.masterCurrencyData.find(x => x.CurrencyId == objCategorization.CurrencyId).CurrencyCode);
    // }
    // Set the Currency Id to validate
    this.finalCurrencyIdToSend = objCategorization.CurrencyId;
    //Bind Form Data
    this.categorizationForm.patchValue({
      CategorizationId: objCategorization.CategorizationId,
      IdeaId: objCategorization.IdeaId,
      MeasureTypeId: objCategorization.MeasureTypeId,
      MeasureCategoryId: objCategorization.MeasureCategoryId,
      //for old dropdown
      //CurrencyId: objCategorization.CurrencyId == 0 ? "" : objCategorization.CurrencyId,
      CurrencyId: this.masterCurrencyData.find(x => x.CurrencyId == objCategorization.CurrencyId).CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyId == objCategorization.CurrencyId).Currency,
      StartDate: new Date(objCategorization.StartDate),
      CostImpactId: objCategorization.CostImpactId,
      MeasureStatusId: objCategorization.MeasureStatusId,

      IsPlcrequired: objCategorization.IsPLCRequired ? 1 : 2,
      // IsActivelyDriven: objCategorization.IsActivelyDriven ? 1 : 2,
      // IsDirectImpactOnEbit: objCategorization.IsDirectImpactOnEbit ? 1 : 2,
      // IsImpactPermanent: objCategorization.IsImpactPermanent ? 1 : 2,
      // IsCostReductionRequired: objCategorization.IsCostReductionRequired ? 1 : 2,
      // IsRatioImprovementRequired: objCategorization.IsRatioImprovementRequired ? 1 : 2,
      //IsFixCostAffected: objCategorization.IsFixCostAffected == null ? "" : objCategorization.IsFixCostAffected ? 1 : 2,
      // IsRevenueIncreased: objCategorization.IsRevenueIncreased == null ? "" : objCategorization.IsRevenueIncreased  ? 1 : 2,
      // IsPvoImpacted: objCategorization.IsPvoImpacted == null ? "" : objCategorization.IsPvoImpacted  ? 1 : 2,
      // IsPvoImpactRequired: objCategorization.IsPvoImpactRequired == null ? "" : objCategorization.IsPvoImpactRequired  ? 1 : 2,

      OPWClassification: objCategorization.OpwClassification,
      ActionType: objCategorization.ActionType,
      Lever: objCategorization.Lever,
      ImpactType: objCategorization.ImpactType,

      CommodityId: objCategorization.CommodityId,
      SubCommodityId: objCategorization.SubCommodityId,

      Level3: objCategorization.Level3,
      Level4: objCategorization.Level4,
      Level5: objCategorization.Level5,
      Level6: objCategorization.Level6,
      Level7: objCategorization.Level7,
      Level8: objCategorization.Level8,
      Level9: objCategorization.Level9,
      Level10: objCategorization.Level10,
      Level11: objCategorization.Level11,
      Level12: objCategorization.Level12,
      Level13: objCategorization.Level13,

      //IsFixCostAffected: objCategorization.IsFixCostAffected == null ? "" : objCategorization.IsFixCostAffected,
      IsFixCostAffected: objCategorization.IsFixCostAffected ?? "",
      IsRevenueIncreased: objCategorization.IsRevenueIncreased ?? "",
      IsPvoImpacted: objCategorization.IsPvoImpacted ?? "",
      IsPvoImpactRequired: objCategorization.IsPvoImpactRequired ?? "",
      IsActivelyDriven: objCategorization.IsActivelyDriven ?? "",
      IsDirectImpactOnEbit: objCategorization.IsDirectImpactOnEbit ?? "",
      IsImpactPermanent: objCategorization.IsImpactPermanent ?? "",
      IsCostReductionRequired: objCategorization.IsCostReductionRequired ?? "",
      IsRatioImprovementRequired: objCategorization.IsRatioImprovementRequired ?? ""
    });

    if (this.categorizationForm.controls.IsFixCostAffected.value === true) {
      objCategorization.IsFixCostAffected = 1;
      this.categorizationForm.patchValue({
        IsFixCostAffected: objCategorization.IsFixCostAffected
      });
    }
    if (this.categorizationForm.controls.IsFixCostAffected.value === false) {
      objCategorization.IsFixCostAffected = 2
      this.categorizationForm.patchValue({
        IsFixCostAffected: objCategorization.IsFixCostAffected
      });
    }
    if (this.categorizationForm.controls.IsRevenueIncreased.value === true) {
      objCategorization.IsRevenueIncreased = 1
      this.categorizationForm.patchValue({
        IsRevenueIncreased: objCategorization.IsRevenueIncreased
      });
    }
    if (this.categorizationForm.controls.IsRevenueIncreased.value === false) {
      objCategorization.IsRevenueIncreased = 2
      this.categorizationForm.patchValue({
        IsRevenueIncreased: objCategorization.IsRevenueIncreased
      });
    }
    if (this.categorizationForm.controls.IsPvoImpacted.value === true) {
      objCategorization.IsPvoImpacted = 1
      this.categorizationForm.patchValue({
        IsPvoImpacted: objCategorization.IsPvoImpacted
      });
    }
    if (this.categorizationForm.controls.IsPvoImpacted.value === false) {
      objCategorization.IsPvoImpacted = 2
      this.categorizationForm.patchValue({
        IsPvoImpacted: objCategorization.IsPvoImpacted
      });
    }
    if (this.categorizationForm.controls.IsPvoImpactRequired.value === true) {
      objCategorization.IsPvoImpactRequired = 1
      this.categorizationForm.patchValue({
        IsPvoImpactRequired: objCategorization.IsPvoImpactRequired
      });
    }
    if (this.categorizationForm.controls.IsPvoImpactRequired.value === false) {
      objCategorization.IsPvoImpactRequired = 2
      this.categorizationForm.patchValue({
        IsPvoImpactRequired: objCategorization.IsPvoImpactRequired
      });
    }
    if (this.categorizationForm.controls.IsActivelyDriven.value === true) {
      objCategorization.IsActivelyDriven = 1;
      this.categorizationForm.patchValue({
        IsActivelyDriven: objCategorization.IsActivelyDriven
      });
    }
    if (this.categorizationForm.controls.IsActivelyDriven.value === false) {
      objCategorization.IsActivelyDriven = 2;
      this.categorizationForm.patchValue({
        IsActivelyDriven: objCategorization.IsActivelyDriven
      });
    }
    if (this.categorizationForm.controls.IsDirectImpactOnEbit.value === true) {
      objCategorization.IsDirectImpactOnEbit = 1;
      this.categorizationForm.patchValue({
        IsDirectImpactOnEbit: objCategorization.IsDirectImpactOnEbit
      });
    }
    if (this.categorizationForm.controls.IsDirectImpactOnEbit.value === false) {
      objCategorization.IsDirectImpactOnEbit = 2;
      this.categorizationForm.patchValue({
        IsDirectImpactOnEbit: objCategorization.IsDirectImpactOnEbit
      });
    }
    if (this.categorizationForm.controls.IsImpactPermanent.value === true) {
      objCategorization.IsImpactPermanent = 1;
      this.categorizationForm.patchValue({
        IsImpactPermanent: objCategorization.IsImpactPermanent
      });
    }
    if (this.categorizationForm.controls.IsImpactPermanent.value === false) {
      objCategorization.IsImpactPermanent = 2;
      this.categorizationForm.patchValue({
        IsImpactPermanent: objCategorization.IsImpactPermanent
      });
    }
    if (this.categorizationForm.controls.IsCostReductionRequired.value === true) {
      objCategorization.IsCostReductionRequired = 1;
      this.categorizationForm.patchValue({
        IsCostReductionRequired: objCategorization.IsCostReductionRequired
      });
    }
    if (this.categorizationForm.controls.IsCostReductionRequired.value === false) {
      objCategorization.IsCostReductionRequired = 2;
      this.categorizationForm.patchValue({
        IsCostReductionRequired: objCategorization.IsCostReductionRequired
      });
    }
    if (this.categorizationForm.controls.IsRatioImprovementRequired.value === true) {
      objCategorization.IsRatioImprovementRequired = 1;
      this.categorizationForm.patchValue({
        IsRatioImprovementRequired: objCategorization.IsRatioImprovementRequired
      });
    }
    if (this.categorizationForm.controls.IsRatioImprovementRequired.value === false) {
      objCategorization.IsRatioImprovementRequired = 2;
      this.categorizationForm.patchValue({
        IsRatioImprovementRequired: objCategorization.IsRatioImprovementRequired
      });
    }
    //Disabled OPW Classification
    this.disabledOPWClassification();
    this.setFilterAndSubFilter();
  }

  // Get all Categorizatio data
  bindCategorizationByIdeadId() {
    if (this.ideaId == null) {
      this.ideaId = 0;
    }
    if (this.ideaId != null) {
      // && parseInt(this.ideaId) > 0) {
      this.catService.getCategorizationDataById(parseInt(this.ideaId)).subscribe((data: any) => {
        let errorMsgeFromBack = data.Data ? data.Data.Message : ""
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;
          this.categorizationData = data.Data.Categorization;
          if (this.categorizationData != null || this.categorizationData != undefined) {
            if (this.categorizationData.IsPLCRequired === true) {
              //this.checkPlcSummaryData();
            }
            else if (this.categorizationData.IsPLCRequired === false) {
              //this.checkNonPlcSummaryData();
            }
          }
          this.showform = true;
          this.hasUnsavedChanges = false;


          // for remark field;
          this.remarkDataRecieved = responseData.Data.Remark;
          if (this.remarkDataRecieved != null) {
            this.remarkId = this.remarkDataRecieved.RemarkId;
            this.remark = this.remarkDataRecieved.Remark;
            this.isChangeRemarks = this.remarkDataRecieved.Remark;
          } else {
            this.remarkId = null;
            this.remark = null;
            this.isChangeRemarks = null;
          }

          //For OPWFilterSubFilter Fields
          this.OPWFilterSubFilterArray = responseData.Data.OLPWFilterSubFilters;
          if (this.OPWFilterSubFilterArray != null) {
            this.OPWFilterSubFilterArray.forEach(element => {
              if (this.SelectedOPWFilterId.indexOf(element.OPWFilterId) == -1) {
                this.SelectedOPWFilterId.push(element.OPWFilterId);
              }
              if (element.OPWSubFilterId != 0) {
                this.SelectedOPWSubFilterId.push(element.OPWSubFilterId);
              }
            });
          }
          else {
            this.SelectedOPWFilterId = [];
            this.SelectedOPWSubFilterId = [];
          }

          if (responseData.Data.Categorization != null) {

            this.fillCategorizationData(responseData.Data.Categorization);
          } else {
            //Bind ideaForm Data
            this.ideaservice.getData(this.ideaId).subscribe((data) => {
              let responseData;
              responseData = data;

              if (responseData.Data != null) {
                this.categorizationForm.patchValue({
                  MeasureStatusId: responseData.Data.MeasureStatusId,
                })
              }
            });
            // Set the Currency dropdown to auto filled for AT and LD
            if (this.businessAreaId != 0) {
              if (this.businessAreaId == 1) {
                this.categorizationForm.patchValue({
                  //for old dropdown
                  //CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyId,
                  CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").Currency,
                })
                //for old dropdown
                //this.onOptionsSelected(this.categorizationForm.get('CurrencyId').value);
                // this.onSearchChange(this.categorizationForm.get('CurrencyId').value);
                this.finalCurrencyIdToSend = this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyId;
                this.catService.changeMsg(this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyCode.trim());
              }
              else if (this.businessAreaId == 3) {
                this.categorizationForm.patchValue({
                  //for old dropdown
                  //CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyId,
                  CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").Currency,
                })
                //for old dropdown
                //this.onOptionsSelected(this.categorizationForm.get('CurrencyId').value);
                // this.onSearchChange(this.categorizationForm.get('CurrencyId').value);
                this.finalCurrencyIdToSend = this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyId;
                this.catService.changeMsg(this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyCode.trim());
              }
              else {
                this.categorizationForm.patchValue({
                  CurrencyId: "",
                })
              }
            }
            else {
              this.categorizationForm.patchValue({
                CurrencyId: "",
              })
            }
            //Disabled OPW Classification
            this.disabledOPWClassification();
          }
        }
        else {
          //Show errorCode and errorMessage in the UI
          this.OPWFilterSubFilterArray = [];
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);

        }
      }, (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      });
    }
    if (this.categorizationForm.get('IsPvoImpacted').value == 1 && this.categorizationForm.get('commodityId').value == "") {
      this.borderLeft = true;
      this.borderLeft1 = true;
    }
  }

  // Fill Sub Commodity Dropdown by commudityId
  SubCommoditiyHierarchy(commodity) {
    this.MSubCommoditiyHierarchy = [];
    this.categorizationForm.controls["SubCommodityId"].setValue("");
    if (commodity == 0 && commodity != "") {
      this.isCommodityFill = false;
      this.isSubCommodityFill = false;
      this.categorizationForm.patchValue({
        CommodityId: "0",
        SubCommodityId: "0"
      })
    }
    else if (commodity == "") {
      this.isCommodityFill = false;
      this.isSubCommodityFill = false;
      this.categorizationForm.patchValue({
        CommodityId: "",
        SubCommodityId: ""
      })
    }
    else {
      this.isCommodityFill = true;
      this.MSubCommoditiyHierarchy = this.TempMSubCommoditiyHierarchy.Data.filter((item) => {
        return item.CommodityId === Number(commodity)
      });
      this.categorizationForm.patchValue({
        SubCommodityId: "0"
      })
    }
  }

  opwFilterChange(opwFilter?) {
    this.MOPWSubFilter = [];
    opwFilter.forEach(element => {
      if (element != 0) {
        let tempGroupObj: any = [];
        let findFilter = this.MOPWFilter.find(item => item.OPWFilterId == element);
        tempGroupObj["name"] = findFilter.OPWFilterTitle;
        tempGroupObj["subFilter"] = this.AllMOPWSubFilter.filter(of => of.OPWFilterId == element);
        this.MOPWSubFilter.push(tempGroupObj);
      }
    })
    let missing = null;
    this.OldSelected.forEach(element => {
      if (opwFilter.indexOf(element) == -1) {
        missing = element;
      }
    });
    this.OldSelected = this.SelectedOPWFilterId;
    if (missing != null) {
      let subfilterArray = this.AllMOPWSubFilter.filter(item => item.OPWFilterId == missing);
      let subfilterIdArray: any = [];
      subfilterArray.forEach(element => {
        subfilterIdArray.push(element.OPWSubFilterId);
      });
      this.SelectedOPWSubFilterId = this.SelectedOPWSubFilterId.filter(x => !subfilterIdArray.includes(x));
    }
  }

  // Call Sub Commodity Dropdown 
  SubCommoditiyEvent(subCommodity) {
    this.isRequiredPPCORMCP = true;
    if (this.isRequiredPPCORMCP) {
      if (subCommodity == "" || subCommodity == 0) {
        this.isSubCommodityFill = false;
      }
      else {
        this.isSubCommodityFill = true;
      }
    }
    else {
      this.isSubCommodityFill = false;
    }


  }

  //Call Cost reduction vs. reference period? dropdown
  onSelectedCost(event) {
    this.calOPWClassification();
    if (event.target.value == "") {
      this.isRequiredCostReduction = false;
    }
    else {
      if (parseInt(event.target.value) == 1) {
        this.isRequiredCostReduction = true;
      }
      else {
        this.isRequiredCostReduction = false;
      }
    }
    if (event.target.value == "") {
      this.isRequiredCostReductionVal = true;
    }
    else {
      if (parseInt(event.target.value) == 1) {
        this.isRequiredCostReductionVal = true;
      }
      else {
        this.isRequiredCostReductionVal = false;
      }
    }
    this.resetIsFixNIsRevDrodpdown();
  }

  //Reset Dropdown value, based on Cost reduction vs. reference period? value selection
  resetIsFixNIsRevDrodpdown() {
    this.categorizationForm.patchValue({
      IsFixCostAffected: "",
      IsRevenueIncreased: ""
    })
  }

  disabledOPWClassification() {
    this.categorizationForm.get("OPWClassification").disable();
    this.categorizationForm.get("ActionType").disable();
    this.categorizationForm.get("Lever").disable();
    this.categorizationForm.get("ImpactType").disable();
  }

  onPVOSelectedAction(event) {
    this.calOPWClassification();
    this.PVOMaterials1 = event == "2" ? false : true;
    this.resetPVOMaterialsDropdown();
  }
  resetPVOMaterialsDropdown() {
    this.categorizationForm.patchValue({
      IsPvoImpactRequired: "",
      CommodityId: "0",
      SubCommodityId: "0"
    })
    this.setCommodityValue = "0";
  }
  onSelectedAction(event) {
    this.calOPWClassification();
  }
  setValidationCommodityNSubCommodity(obj) {
    if (obj && (this.categorizationForm.get('CommodityId').value == "" || this.categorizationForm.get('CommodityId').value == 0)) {
      this.borderLeft = true;
      if ((this.categorizationForm.get('SubCommodityId').value == "" || this.categorizationForm.get('SubCommodityId').value == 0)) {
        this.borderLeft1 = true;
      }
    }
    else if (obj && (this.categorizationForm.get('SubCommodityId').value == "" || this.categorizationForm.get('SubCommodityId').value == 0)) {
      this.borderLeft1 = true;
    }
    else {
      this.borderLeft = false;
      this.borderLeft1 = false;
    }
  }

  setValidationPVOImpactNPVOPriceChange(obj) {
    this.pvoborderLeft = false;
    this.pvoborderLeft1 = false;

    if (obj && (this.categorizationForm.get('IsPvoImpacted').value == "" || this.categorizationForm.get('IsPvoImpacted').value == 0)) {
      this.pvoborderLeft = true;
    }

    if (obj && (this.categorizationForm.get('IsPvoImpactRequired').value == "" || this.categorizationForm.get('IsPvoImpactRequired').value == 0)) {
      this.pvoborderLeft1 = true;
    }
  }
  //Fill OPWClassification, Action Type, Lever and Type of Impact section		
  calOPWClassification() {
    this.isRequiredPVO = false;
    if (this.categorizationForm.value.IsActivelyDriven == "" || parseInt(this.categorizationForm.value.IsActivelyDriven) == 2) {
      this.categorizationForm.get('OPWClassification').setValue('No productivity action');
    }
    else {
      if (this.categorizationForm.value.IsDirectImpactOnEbit == "") {
        this.categorizationForm.get('OPWClassification').setValue('No productivity action');
      }
      else {
        if (parseInt(this.categorizationForm.value.IsDirectImpactOnEbit) == 2) {
          if (parseInt(this.categorizationForm.value.IsImpactPermanent) == 1) {
            this.categorizationForm.get('OPWClassification').setValue("Efficiency w/o profit impact");
          }
          else {
            this.categorizationForm.get('OPWClassification').setValue("No productivity action");
          }
        }
        else {
          if (parseInt(this.categorizationForm.value.IsImpactPermanent) == 2) {
            this.categorizationForm.get('OPWClassification').setValue("One-Time effect ");
          }
          else {
            if (this.categorizationForm.value.IsImpactPermanent == "") {
              this.categorizationForm.get('OPWClassification').setValue("No productivity action");
            }
            else {
              if (parseInt(this.categorizationForm.value.IsCostReductionRequired) == 1) {
                if (parseInt(this.categorizationForm.value.IsRatioImprovementRequired) == 1) {
                  this.categorizationForm.get('OPWClassification').setValue("Base productivity (VAM, PPC, MCP)");
                  this.isRequiredPVO = true;
                }
                else {
                  if (this.categorizationForm.value.IsRatioImprovementRequired == "") {
                    this.categorizationForm.get('OPWClassification').setValue('No productivity action');
                  }
                  else {
                    this.categorizationForm.get('OPWClassification').setValue("Active stop of process output (ASO)");
                  }
                }
              }
              else {
                if (this.categorizationForm.value.IsRatioImprovementRequired == "" || parseInt(this.categorizationForm.value.IsRatioImprovementRequired) == 2) {
                  this.categorizationForm.get('OPWClassification').setValue('No productivity action');
                }
                else {
                  if (parseInt(this.categorizationForm.value.IsRevenueIncreased) == 2 || this.categorizationForm.value.IsRevenueIncreased == "") {
                    this.categorizationForm.get('OPWClassification').setValue('No productivity action');
                  }
                  else {
                    if (parseInt(this.categorizationForm.value.IsFixCostAffected) == 1) {
                      this.categorizationForm.get('OPWClassification').setValue("Process improvement with degression impact (PID)");
                    }
                    else {
                      if (this.categorizationForm.value.IsFixCostAffected == "") {
                        this.categorizationForm.get('OPWClassification').setValue('No productivity action');
                      }
                      else {
                        this.categorizationForm.get('OPWClassification').setValue("Base productivity (VAM, PPC, MCP)");
                        this.isRequiredPVO = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.setValidationPVOImpactNPVOPriceChange(this.isRequiredPVO);

    //Fill Action Type field
    if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Base productivity (VAM, PPC, MCP)'.toLowerCase()) {
      this.categorizationForm.get('ActionType').setValue("Cost reduction");
    } else if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Process improvement with degression impact (PID)'.toLowerCase()) {
      this.categorizationForm.get('ActionType').setValue("Additional Improvement");
    } else if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Active stop of process output (ASO)'.toLowerCase()) {
      this.categorizationForm.get('ActionType').setValue("Additional Improvement");
    } else {
      this.categorizationForm.get('ActionType').setValue("No productivity (VAM, MCP, PPC, PID, ASO)");
    }

    //Fill Lever field
    this.isRequiredPPCORMCP = false;
    if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Process improvement with degression impact (PID)'.toLowerCase()) {
      this.categorizationForm.get('Lever').setValue("Process improvement with degression impact(PID)");
      this.ResetFilterSubFilterDropdown();
    } else if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Active stop of process output (ASO)'.toLowerCase()) {
      this.categorizationForm.get('Lever').setValue("Active stop of process output(ASO)");
      this.ResetFilterSubFilterDropdown();
    } else if (this.categorizationForm.value.IsPvoImpacted != "" && parseInt(this.categorizationForm.value.IsPvoImpacted) == 2) {
      this.categorizationForm.get('Lever').setValue("Value Added Measure (VAM)");
      this.ResetFilterSubFilterDropdown();
      this.setFilterAndSubFilter();
    } else if (this.categorizationForm.value.IsPvoImpactRequired != "" && parseInt(this.categorizationForm.value.IsPvoImpactRequired) == 2) {
      this.categorizationForm.get('Lever').setValue("Material Cost Productivity (MCP)");
      this.isRequiredPPCORMCP = true;
      this.ResetFilterSubFilterDropdown();
    } else if (this.categorizationForm.value.IsPvoImpactRequired != "" && parseInt(this.categorizationForm.value.IsPvoImpactRequired) == 1) {
      this.categorizationForm.get('Lever').setValue("Purchasing Price Change (PPC)");
      this.isRequiredPPCORMCP = true;
      this.ResetFilterSubFilterDropdown();
    } else {
      this.categorizationForm.get('Lever').setValue("Please fill the fields in OPW Measure Classification");
      this.ResetFilterSubFilterDropdown();
    }
    this.setValidationCommodityNSubCommodity(this.isRequiredPPCORMCP);

    //Fill Type of Impact field
    if (this.categorizationForm.value.IsImpactPermanent != "" && parseInt(this.categorizationForm.value.IsImpactPermanent) == 2) {
      this.categorizationForm.get('ImpactType').setValue("One-Time effect");
    } else if (this.categorizationForm.value.IsImpactPermanent != "" && parseInt(this.categorizationForm.value.IsImpactPermanent) == 1) {
      this.categorizationForm.get('ImpactType').setValue("Permanent");
    }

    //If OPW classification (filled in automatically) is "Base Productivity (VAM MCP PPC) user has to answer PVO related questions.
    if (this.categorizationForm.get("OPWClassification").value.toLowerCase() == 'Base productivity (VAM, PPC, MCP)'.toLowerCase()) {
      this.isRequiredOPWClassificationBaseProductivity = true;
    } else {
      this.isRequiredOPWClassificationBaseProductivity = false;
    }
  }

  setFilterAndSubFilter() {
    if (this.categorizationForm.get('Lever').value == "Value Added Measure (VAM)") {
      let vamFilterID = this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId

      if (!this.SelectedOPWFilterId.includes(vamFilterID)) {
        this.openAlertDialog([{ [serverMessage.VAMPopUpMessage]: serverMessage.warning }]);
        this.SelectedOPWFilterId.push(this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId);
        this.categorizationForm.get("OpwFilterId").setValue(this.SelectedOPWFilterId);
        this.opwFilterChange(this.SelectedOPWFilterId);
        this.categorizationForm.get("OpwSubFilterId").setValue(this.SelectedOPWSubFilterId);
      }
    }
  }

  isDisable(obj) {
    //Always Disable the VAM Filter
    return (obj == this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId);

    //Disable only When VAM Selected
    // if (this.categorizationForm.get('Lever').value == "Value Added Measure (VAM)") {
    //   return (obj == this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId) && this.SelectedOPWFilterId.includes(obj);
    // }
  }

  checkAtleastOneVAMSubFilterSelected() {
    if (this.categorizationForm.get('Lever').value == "Value Added Measure (VAM)") {
      let filterId = this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId;
      let selectedSubfilterList = this.AllMOPWSubFilter.filter(s => this.SelectedOPWSubFilterId.includes(s.OPWSubFilterId));
      let selectedVAMSubfilterList = selectedSubfilterList.filter(s => s.OPWFilterId == (this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId));
      if (this.SelectedOPWFilterId.includes(filterId) && selectedVAMSubfilterList.length == 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  checkSelected(item) {
    if (this.SelectedOPWSubFilterId.includes(item)) {
      return false;
    }
    else {
      return true;
    }
  }

  checkLength() {
    let selectedSubfilterList = this.AllMOPWSubFilter.filter(s => this.SelectedOPWSubFilterId.includes(s.OPWSubFilterId));
    let selectedVAMSubfilterList = selectedSubfilterList.filter(s => s.OPWFilterId == (this.MOPWFilter.find(obj => obj.OPWFilterTitle.toLowerCase() == "VAM action P&L effect".toLowerCase()).OPWFilterId));
    if (selectedVAMSubfilterList.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  ResetFilterSubFilterDropdown() {
    this.SelectedOPWFilterId = [];
    this.SelectedOPWSubFilterId = [];
  }

  //Bind form data 
  sendData() {
    //Set Start Date As UTC date
    //let dbDate = new Date(this.categorizationForm.value.StartDate);
    //let StartDate = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
    //let StartDate = null;
    let isFixCostAffectedData = this.categorizationForm.value.IsFixCostAffected;
    let isRevenueIncreasedData = this.categorizationForm.value.IsRevenueIncreased;
    let isPvoImpactedData = this.categorizationForm.value.IsPvoImpacted;
    let isPvoImpactRequiredData = this.categorizationForm.value.IsPvoImpactRequired;
    let isActivelyDrivenData = this.categorizationForm.value.IsActivelyDriven;
    let isDirectImpactOnEbitData = this.categorizationForm.value.IsDirectImpactOnEbit;
    let isImpactPermanentData = this.categorizationForm.value.IsImpactPermanent;
    let isCostReductionRequiredData = this.categorizationForm.value.IsCostReductionRequired;
    let isRatioImprovementRequiredData = this.categorizationForm.value.IsRatioImprovementRequired;

    // Actively driven action
    if (this.categorizationForm.value.IsActivelyDriven == "") {
      isActivelyDrivenData = null
    }
    else if (this.categorizationForm.value.IsActivelyDriven) {
      if (parseInt(this.categorizationForm.value.IsActivelyDriven) == 1) {
        isActivelyDrivenData = true
      }
      if (parseInt(this.categorizationForm.value.IsActivelyDriven) == 2) {
        isActivelyDrivenData = false
      }
    }
    else if (this.categorizationForm.value.IsActivelyDriven === false) {
      isActivelyDrivenData = false
    }

    // IsDirectImpactOnEbit action
    if (this.categorizationForm.value.IsDirectImpactOnEbit == "") {
      isDirectImpactOnEbitData = null
    }
    else if (this.categorizationForm.value.IsDirectImpactOnEbit) {
      if (parseInt(this.categorizationForm.value.IsDirectImpactOnEbit) == 1) {
        isDirectImpactOnEbitData = true
      }
      if (parseInt(this.categorizationForm.value.IsDirectImpactOnEbit) == 2) {
        isDirectImpactOnEbitData = false
      }
    }
    else if (this.categorizationForm.value.IsDirectImpactOnEbit === false) {
      isDirectImpactOnEbitData = false
    }

    // IsImpactPermanent action
    if (this.categorizationForm.value.IsImpactPermanent == "") {
      isImpactPermanentData = null
    }
    else if (this.categorizationForm.value.IsImpactPermanent) {
      if (parseInt(this.categorizationForm.value.IsImpactPermanent) == 1) {
        isImpactPermanentData = true
      }
      if (parseInt(this.categorizationForm.value.IsImpactPermanent) == 2) {
        isImpactPermanentData = false
      }
    }
    else if (this.categorizationForm.value.IsImpactPermanent === false) {
      isImpactPermanentData = false
    }

    // IsCostReductionRequired action
    if (this.categorizationForm.value.IsCostReductionRequired == "") {
      isCostReductionRequiredData = null
    }
    else if (this.categorizationForm.value.IsCostReductionRequired) {
      if (parseInt(this.categorizationForm.value.IsCostReductionRequired) == 1) {
        isCostReductionRequiredData = true
      }
      if (parseInt(this.categorizationForm.value.IsCostReductionRequired) == 2) {
        isCostReductionRequiredData = false
      }
    }
    else if (this.categorizationForm.value.IsCostReductionRequired === false) {
      isCostReductionRequiredData = false
    }

    // IsRatioImprovementRequired action
    if (this.categorizationForm.value.IsRatioImprovementRequired == "") {
      isRatioImprovementRequiredData = null
    }
    else if (this.categorizationForm.value.IsRatioImprovementRequired) {
      if (parseInt(this.categorizationForm.value.IsRatioImprovementRequired) == 1) {
        isRatioImprovementRequiredData = true
      }
      if (parseInt(this.categorizationForm.value.IsRatioImprovementRequired) == 2) {
        isRatioImprovementRequiredData = false
      }
    }
    else if (this.categorizationForm.value.IsRatioImprovementRequired === false) {
      isRatioImprovementRequiredData = false
    }


    // set IsFixCostAffected
    if (this.categorizationForm.value.IsFixCostAffected == "") {
      isFixCostAffectedData = null
    }
    else if (this.categorizationForm.value.IsFixCostAffected) {
      if (parseInt(this.categorizationForm.value.IsFixCostAffected) == 1) {
        isFixCostAffectedData = true
      }
      if (parseInt(this.categorizationForm.value.IsFixCostAffected) == 2) {
        isFixCostAffectedData = false
      }
    }
    else if (this.categorizationForm.value.IsFixCostAffected === false) {
      isFixCostAffectedData = false
    }

    // set IsRevenueIncreased
    if (this.categorizationForm.value.IsRevenueIncreased == "") {
      isRevenueIncreasedData = null
    }
    else if (this.categorizationForm.value.IsRevenueIncreased) {
      if (parseInt(this.categorizationForm.value.IsRevenueIncreased) == 1) {
        isRevenueIncreasedData = true
      }
      if (parseInt(this.categorizationForm.value.IsRevenueIncreased) == 2) {
        isRevenueIncreasedData = false
      }
    }
    else if (this.categorizationForm.value.IsRevenueIncreased === false) {
      isRevenueIncreasedData = false
    }

    // set IsPvoImpacted
    if (this.categorizationForm.value.IsPvoImpacted == undefined || this.categorizationForm.value.IsPvoImpacted == "") {
      isPvoImpactedData = null
    }
    else if (this.categorizationForm.value.IsPvoImpacted) {
      if (parseInt(this.categorizationForm.value.IsPvoImpacted) == 1) {
        isPvoImpactedData = true
      }
      if (parseInt(this.categorizationForm.value.IsPvoImpacted) == 2) {
        isPvoImpactedData = false
      }
    }

    else if (this.categorizationForm.value.IsPvoImpacted === false) {
      isPvoImpactedData = false
    }

    // set IsPvoImpactRequired
    if (this.categorizationForm.value.IsPvoImpactRequired == undefined || this.categorizationForm.value.IsPvoImpactRequired == "") {
      isPvoImpactRequiredData = null
    }
    else if (this.categorizationForm.value.IsPvoImpactRequired) {
      if (parseInt(this.categorizationForm.value.IsPvoImpactRequired) == 1) {
        isPvoImpactRequiredData = true
      }
      if (parseInt(this.categorizationForm.value.IsPvoImpactRequired) == 2) {
        isPvoImpactRequiredData = false
      }
    }
    else if (this.categorizationForm.value.IsPvoImpactRequired === false) {
      isPvoImpactRequiredData = false
    }

    this.dataBind = {
      CategorizationId: parseInt(this.categorizationForm.value.CategorizationId),
      IdeaId: parseInt(this.ideaId),
      //MeasureTypeId: parseInt(this.categorizationForm.value.MeasureTypeId),
      //MeasureCategoryId: parseInt(this.categorizationForm.value.MeasureCategoryId),
      //  MeasureCategoryId : this.categorizationForm.patchValue({
      //   IsRatioImprovementRequired: objCategorization.IsRatioImprovementRequired
      // }),
      //MeasureCategoryId:  parseInt(objCategorization.MeasureCategoryId),
      //for old dropdown
      //CurrencyId: parseInt(this.categorizationForm.value.CurrencyId),
      //CurrencyId: parseInt(this.finalCurrencyIdToSend),
      //StartDate: StartDate,
      //CostImpactId: parseInt(this.categorizationForm.value.CostImpactId),
      //MeasureStatusId: parseInt(this.categorizationForm.value.MeasureStatusId),

      //IsPLCRequired: parseInt(this.categorizationForm.value.IsPlcrequired) == 1 ? true : false,
      //IsActivelyDriven: parseInt(this.categorizationForm.value.IsActivelyDriven) == 1 ? true : false,
      // IsDirectImpactOnEbit: parseInt(this.categorizationForm.value.IsDirectImpactOnEbit) == 1 ? true : false,
      // IsImpactPermanent: parseInt(this.categorizationForm.value.IsImpactPermanent) == 1 ? true : false,
      // IsCostReductionRequired: parseInt(this.categorizationForm.value.IsCostReductionRequired) == 1 ? true : false,
      // IsRatioImprovementRequired: parseInt(this.categorizationForm.value.IsRatioImprovementRequired) == 1 ? true : false,
      //IsFixCostAffected: this.categorizationForm.value.IsFixCostAffected == "" ? null : parseInt(this.categorizationForm.value.IsFixCostAffected) == 1 ? true : false,
      //IsRevenueIncreased: this.categorizationForm.value.IsRevenueIncreased == "" ? null : parseInt(this.categorizationForm.value.IsRevenueIncreased) == 1 ? true : false,
      //IsPvoImpacted: this.categorizationForm.value.IsPvoImpacted == undefined || this.categorizationForm.value.IsPvoImpacted == "" ? null : parseInt(this.categorizationForm.value.IsPvoImpacted) == 1 ? true : false,
      //IsPvoImpactRequired: this.categorizationForm.value.IsPvoImpactRequired == undefined || this.categorizationForm.value.IsPvoImpactRequired == "" ? null : parseInt(this.categorizationForm.value.IsPvoImpactRequired) == 1 ? true : false,
      MeasureTypeId: this.categorizationData.MeasureTypeId,
      MeasureCategoryId: this.categorizationData.MeasureCategoryId,
      CurrencyId: this.categorizationData.CurrencyId,
      StartDate: this.categorizationData.StartDate,
      CostImpactId: this.categorizationData.CostImpactId,
      MeasureStatusId: this.categorizationData.MeasureStatusId,
      IsPLCRequired: this.categorizationData.IsPLCRequired,
      IsActivelyDriven: isActivelyDrivenData,
      IsDirectImpactOnEbit: isDirectImpactOnEbitData,
      IsImpactPermanent: isImpactPermanentData,
      IsCostReductionRequired: isCostReductionRequiredData,
      IsRatioImprovementRequired: isRatioImprovementRequiredData,
      IsFixCostAffected: isFixCostAffectedData,
      IsRevenueIncreased: isRevenueIncreasedData,
      IsPvoImpacted: isPvoImpactedData,
      IsPvoImpactRequired: isPvoImpactRequiredData,


      CommodityId: parseInt(this.categorizationForm.value.CommodityId == "" ? 0 : this.categorizationForm.value.CommodityId),
      SubCommodityId: parseInt(this.categorizationForm.value.CommodityId == "" || this.categorizationForm.value.SubCommodityId == "" ? 0 : this.categorizationForm.value.SubCommodityId),

      OpwTagId: this.categorizationForm.value.OpwTagId == "" || this.categorizationForm.value.OpwTagId == null ? 0 : this.categorizationForm.value.OpwTagId.join(','),

      Level3: this.categorizationForm.value.Level3 === "" ? null : this.categorizationForm.value.Level3,
      Level4: this.categorizationForm.value.Level4 == "" ? null : this.categorizationForm.value.Level4,
      Level5: this.categorizationForm.value.Level5 == "" ? null : this.categorizationForm.value.Level5,
      Level6: this.categorizationForm.value.Level6 == "" ? null : this.categorizationForm.value.Level6,
      Level7: this.categorizationForm.value.Level7 == "" ? null : this.categorizationForm.value.Level7,
      Level8: this.categorizationForm.value.Level8 == "" ? null : this.categorizationForm.value.Level8,
      Level9: this.categorizationForm.value.Level9 == "" ? null : this.categorizationForm.value.Level9,
      Level10: this.categorizationForm.value.Level10 == "" ? null : this.categorizationForm.value.Level10,
      Level11: this.categorizationForm.value.Level11 == "" ? null : this.categorizationForm.value.Level11,
      Level12: this.categorizationForm.value.Level12 == "" ? null : this.categorizationForm.value.Level12,
      Level13: this.categorizationForm.value.Level13 == "" ? null : this.categorizationForm.value.Level13,

      OpwClassification: (<HTMLInputElement>document.getElementById('txtOPWClassification')).value,
      ActionType: (<HTMLInputElement>document.getElementById('txtActionType')).value,
      Lever: (<HTMLInputElement>document.getElementById('txtLeverType')).value,
      ImpactType: (<HTMLInputElement>document.getElementById('txtImpactType')).value,

      IsActive: true,
      CreatedBy: this.loginUserDetails.UserId
    }


    // Remarks changes;
    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.loginUserDetails.UserId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: MSolutionType.Categorization,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.Categorization
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }

    if (this.SelectedOPWFilterId.length == 0 || this.SelectedOPWFilterId == undefined) {
      this.OPWFilterSubFilterArray = [];
    }
    else {
      if (this.SelectedOPWSubFilterId.length == 0 || this.SelectedOPWSubFilterId == undefined) {
        this.OPWFilterSubFilterArray = [];
        this.SelectedOPWFilterId.forEach(element => {
          this.OPWFilterSubFilterArray.push({
            IdeaId: this.ideaId,
            OPWFilterId: element,
            OPWSubFilterId: 0,
            IsActive: true,
            CreatedBy: this.loginUserDetails.UserId,
            CreatedOn: "2021-04-13T15:16:43.223"
          })
        });
      }
      else {
        this.OPWFilterSubFilterArray = [];
        let filterArr = this.SelectedOPWFilterId;
        this.SelectedOPWSubFilterId.forEach(element => {
          let subArr = this.AllMOPWSubFilter.find(item => item.OPWSubFilterId == element);
          this.OPWFilterSubFilterArray.push({
            IdeaId: this.ideaId,
            OPWFilterId: subArr.OPWFilterId,
            OPWSubFilterId: element,
            IsActive: true,
            CreatedBy: this.loginUserDetails.UserId,
            CreatedOn: "2021-04-13T15:16:43.223"
          });
          filterArr = filterArr.filter(item => item != subArr.OPWFilterId);
        });
        if (filterArr.length != 0) {
          filterArr.forEach(element => {
            this.OPWFilterSubFilterArray.push({
              IdeaId: this.ideaId,
              OPWFilterId: element,
              OPWSubFilterId: 0,
              IsActive: true,
              CreatedBy: this.loginUserDetails.UserId,
              CreatedOn: "2021-04-13T15:16:43.223"
            })
          });
        }
      }
    }
    let sendData = {
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId,
      Categorization: this.dataBind,
      IdeaId: this.ideaId,
      Remark: Remark,
      OLPWFilterSubFilters: this.OPWFilterSubFilterArray,
      UserId: this.loginUserDetails.UserId
    }
    return sendData;
  }

  // check apply for valuation;
  checkPlcStatusChange(event) {
    this.recentChangedStatus = this.categorizationForm.get("MeasureStatusId").value;
    let value = event.target.value;
    if (value == 1 || value == 2) {
      this.categorizationForm.controls['MeasureStatusId'].setValue(this.status.Data[1].MeasureStatusId);
    }
    this.openAlertDialog([{ [messages.categorization.plcAssesmentChange]: serverMessage.warning }]);
  }

  // checks for validation of status and startdate;
  checkStatusValidation() {
    let statusErrorMessage = [];

    let selectedDateUTC = this.categorizationForm.get("StartDate").value;
    if (selectedDateUTC === "") {
      return statusErrorMessage;
    }
    selectedDateUTC = selectedDateUTC.toUTCString()
    selectedDateUTC = moment(selectedDateUTC).format('MM/DD/YYYY');
    selectedDateUTC = new Date(selectedDateUTC);

    let todayDate: Date = new Date();
    let todayDateUTC: any;
    todayDateUTC = todayDate.toUTCString();
    todayDateUTC = moment(todayDateUTC).format('MM/DD/YYYY');
    todayDateUTC = new Date(todayDateUTC);

    // if (selectedDateUTC > todayDateUTC) {
    //   let status = this.categorizationForm.get("MeasureStatusId").value;
    //   if (status == 5) {
    //     statusErrorMessage.push({ Implementation: messages.categorization.startDateBig });
    //   }
    // } else
    if (selectedDateUTC < todayDateUTC) {
      let status = this.categorizationForm.get("MeasureStatusId").value;
      if (status == this.status.Data[0].MeasureStatusId || status == this.status.Data[1].MeasureStatusId || status == this.status.Data[2].MeasureStatusId || status == this.status.Data[3].MeasureStatusId) {
        let error = messages.categorization.todayDateBig;
        statusErrorMessage.push({ Assesment: error }, { Ideation: error }, { Prioritzation: error }, { Valuation: error })
      }
    } else if (selectedDateUTC.toString() === todayDateUTC.toString()) {
      let status = this.categorizationForm.get("MeasureStatusId").value;
      if (status == this.status.Data[0].MeasureStatusId || status == this.status.Data[1].MeasureStatusId || status == this.status.Data[2].MeasureStatusId || status == this.status.Data[3].MeasureStatusId) {
        let error = messages.categorization.equalDate;
        statusErrorMessage.push({ Assesment: error }, { Ideation: error }, { Prioritzation: error }, { Valuation: error })
      }
    }
    return statusErrorMessage;
  }

  //check for currency dropdown
  // checkCurrencyChange() {
  //   let errorMessage = [];
  //   if (this.finalCurrencyIdToSend == undefined || this.finalCurrencyIdToSend == null) {
  //     let error = serverMessage.currencyError;
  //     errorMessage.push(error);
  //   }
  //   return errorMessage;
  // }

  // checkActualsAndStartdate() {
  //   let errorMessage = [];
  //   let startDateFromDb = this.categorizationData.StartDate;
  //   if (this.categorizationData != null) {
  //     let ideaStateIdFromUI = this.categorizationForm.value.MeasureStatusId;
  //     let dbDate = new Date(this.categorizationForm.value.StartDate);
  //     let hasActualData = false;
  //     let masterStateid = (this.status.Data).filter((obj) => obj.MeasureStatusTitle.toLowerCase().startsWith('implementation'));
  //     let masterStatusId = masterStateid[0].MeasureStatusId;
  //     if (parseInt(this.categorizationForm.value.IsPlcrequired) == 1) {
  //       // call plc method
  //       if (dbDate.getTime() > new Date(startDateFromDb).getTime() && ideaStateIdFromUI == masterStatusId) {
  //         let filtered = this.allopwdata.filter((val) => val.SolutionId == 6)
  //         for (let i = 1; i <= 12; i++) {
  //           let month = 'Month' + i;
  //           if (filtered[0][month] != undefined) {
  //             if (filtered[0][month] != null) {
  //               if (filtered[0][month] != 0) {
  //                 hasActualData = true;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     else if (parseInt(this.categorizationForm.value.IsPlcrequired) == 2) {
  //       //call non plc method

  //       if (dbDate.getTime() > new Date(startDateFromDb).getTime() && ideaStateIdFromUI == masterStatusId) {
  //         let filteredData = this.nonPlcData.filter((val) => val.SolutionId == 6)
  //         for (let i = 1; i <= 12; i++) {
  //           let month = 'Month' + i;
  //           if (filteredData[0][month] != undefined) {
  //             if (filteredData[0][month] != null) {
  //               if (filteredData[0][month] != 0) {
  //                 hasActualData = true;
  //               }
  //             }
  //           }
  //         }
  //         //}
  //       }
  //     }
  //     if (hasActualData) {
  //       //error message
  //       let error = serverMessage.dateError1;
  //       errorMessage.push(error)
  //     }
  //   }
  //   return errorMessage;
  // }

  //check plcsummary data
  // checkPlcSummaryData() {
  //   this.ideaId = this.LocalStorageService.get('ideaId');
  //   this.plcSummaryService.getPLCSummaryOPWProductivity(this.ideaId).subscribe(
  //     (data: any) => {
  //       this.gridOPWStoredData = [];
  //       let getOPWResponse = data;
  //       let successCode = getOPWResponse.StatusCode;
  //       if (successCode != 200) {
  //         let errorMessage = serverMessage.serverErrorMessage;
  //         let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
  //         this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
  //         return false;
  //       }
  //       else if (successCode == 200) {
  //         this.sharedService.getIdeaIdAndtitle();
  //         this.allopwdata = getOPWResponse.Data;
  //         if (getOPWResponse.Data[0].MonthColumns == null && getOPWResponse.Data[0].MonthColumns == "") {
  //           let errorMessage = serverMessage.serverErrorMessage;
  //           let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
  //           this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
  //           return false;
  //         }
  //       }
  //     }, (error) => {
  //     })
  // }

  // checkNonPlcSummaryData() {
  //   this.ideaId = this.LocalStorageService.get('ideaId');
  //   this.NonplcsummaryService.getOPWProductivity(this.ideaId).subscribe(
  //     (data: any) => {
  //       let errorMsgeFromBack = data.Data.Message;
  //       if (data.StatusCode == 200) {
  //         this.sharedService.getIdeaIdAndtitle();
  //         let responseData;
  //         responseData = data;
  //         this.nonPlcData = responseData.Data;
  //       }
  //       else {
  //         //Show errorCode and errorMessage in the UI
  //         let errorContainer = [
  //           { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
  //           { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
  //         ]
  //         this.openAlertDialog(errorContainer)
  //       }
  //     },
  //     (error) => {
  //       // this.sharedService.getErrorMessage(error);
  //       //Show errorCode and errorMessage in the UI
  //       //this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
  //     }
  //   )
  // }



  // checks for change in plc assesment dropdown;
  // checkPlcAssessmentChange() {
  //   let message = [];
  //   if (this.isPLCrequiredFromDB != undefined) {
  //     if (this.submitData.IsPLCRequired != this.isPLCrequiredFromDB && !this.userConsent) {
  //       if (this.submitData.IsPLCRequired) {
  //         message.push(
  //           { [messages.categorization.plcChangeToRequiredMessage]: serverMessage.warning });
  //       }
  //       else {
  //         message.push({ [messages.categorization.plcChangeToNotRequiredMessage]: serverMessage.warning })
  //       }
  //       this.openAlertDialog(message);
  //     }
  //     else {
  //       this.userConsent = true;
  //     }
  //   }
  //   else {
  //     this.userConsent = true;
  //   }
  // }

  //Save form data;
  submit(event?: any) {

    if (this.ideaId == 0) {
      if (event) {
        this.openAlertDialog([{ [messages.ideaData]: "Warning!!" }]);
      }
      return true;
    }

    if (this.categorizationForm.get("CategorizationId").value == 0) {
      if (event) {
        this.openAlertDialog([{ [messages.impactData]: "Warning!!" }]);
      }
      return true;
    }

    this.plcStatusError = [];
    this.submitted = true;
    let startDateError;
    console.log(this.categorizationData);

    let response = null;
    for (let i in this.errorObject) {
      if (!this.errorObject[i]) {
        this.formerror.push({ [i]: this.headerMessage })
      }
    }
    // let statusError = this.checkStatusValidation();
    //let currencyError = this.checkCurrencyChange();
    // if (this.categorizationData != null || this.categorizationData != undefined) {
    //   startDateError = this.checkActualsAndStartdate();
    // }
    // if (startDateError != undefined) {
    //   if (startDateError.length != 0) {
    //     for (let i of startDateError) {
    //       this.formerror.push({ [i]: serverMessage.warning });
    //     }
    //   }

    // }

    // if (currencyError.length != 0) {
    //   for (let i of currencyError) {
    //     this.formerror.push({ [i]: this.headerMessage });
    //   }
    // }

    // if (statusError.length != 0) {
    //   for (let i of statusError) {
    //     this.formerror.push(i);
    //   }
    // }

    if (this.formerror.length) {
      this.submitted = false;
      this.openDialog(this.formerror)
    } else {
      let postData = this.sendData();

      this.submitData = postData.Categorization;
      //this.checkPlcAssessmentChange();
      // if (!this.userConsent) {
      //   this.submitted = false;
      //   return;
      // }
       if (this.hasUnsavedChanges) {
        response = this.catService.postCategorizationData(postData).toPromise().then(
          (response) => {
            this.responsePost = response;
            let successCode = this.responsePost.StatusCode;
            let errorCode = this.responsePost.Data.ErrorCode;
            let errorMsgeFromBack = this.responsePost.Data.Message;

            if (successCode == 200) {
              if (errorCode == 0) {
                this.OPWFilterSubFilterArray = [];
                this.sharedService.onRemoveCategorizationData();
                this.hasUnsavedChanges = false;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.ToasterService.notify(dataSaveMessage, "success");
                this.finalCurrencyIdToSend = null;


                // To route to the plc section if the isPlcRequired field value is required
                // if (event == "next" && this.categorizationForm.get("IsPlcrequired").value == 1) {
                //   this.router.navigate(['/plc-directcost']);
                // }
                // // To route to the plc section if the isPlcRequired field value is not required
                // else if (event == "next" && this.categorizationForm.get("IsPlcrequired").value == 2) {
                //   this.router.navigate(['/nonplc-costsavings']);
                // }

                // To route to the plc section if the isPlcRequired field value is required
                if (event == "next" && this.categorizationData.IsPLCRequired == true) {
                  this.router.navigate(['/plc-directcost']);
                }
                // // To route to the plc section if the isPlcRequired field value is not required
                else if (event == "next" && this.categorizationData.IsPLCRequired == false) {
                  this.router.navigate(['/nonplc-costsavings']);
                }
                // To route to the manage ideas page if save and exit button is clicked
                if (event == 'save&exit') {
                  this.router.navigate(['/manageIdea']);
                }
                return true;
              }
              else {
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer);
                this.submitted = false;
                this.OPWFilterSubFilterArray = [];
                return false;
              }
            }
            else if (this.responsePost.Data.ErrorCode == EnumErrorCodes.ImpactCategorizationValidateError) {
              //If Product Lifecycle Cost assessment is Required but user didn't select product from impact page; it will save the form data in LocalStorgae;           
              this.hasUnsavedChanges = false;
              this.nextMove = false;
              this.LocalStorageService.remove(this.ideaId + '_CategorizationData');
              this.LocalStorageService.remove(this.ideaId + '_CategorizationData_Remarks');
              this.LocalStorageService.set(this.ideaId + '_CategorizationData', postData.Categorization);
              let remarkData = postData.Remark == null ? null : postData.Remark.Remark;
              this.LocalStorageService.set(this.ideaId + '_CategorizationData_Remarks', remarkData);
              this.OPWFilterSubFilterArray = [];
              this.submitted = false;
              this.openAlertDialog([{ [this.responsePost.Data.Message]: serverMessage.warning }]);
              this.finalCurrencyIdToSend = null;
              return false;
            }
            else if (this.responsePost.Data.ErrorCode == EnumErrorCodes.PeriodLockValidationError) {
              this.OPWFilterSubFilterArray = [];
              this.openAlertDialog([{ [this.responsePost.Data.Message]: serverMessage.warning }]);
              this.submitted = false;
              return false;
            }
            else {
              this.OPWFilterSubFilterArray = [];
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.submitted = false;
              return false;
            }
          }, (error) => {
            this.OPWFilterSubFilterArray = [];
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
            return false;
          })
        return response;
      }
      else {
        this.sharedService.onRemoveCategorizationData();
        this.router.navigate(['/manageIdea']);
      }
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error });
  }

  // called when cancel&exit button is clicked
  onClickCancel() {
    this.sharedService.onRemoveCategorizationData();
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea']);
  }

  // called when back button is clicked
  onClickBack() {
    this.menuService.onClickNextAndBack("back");
    this.finalCurrencyIdToSend = null;
  }

  // called when next button is clicked;
  onClickNext() {
    console.log(this.categorizationData);
    console.log(this.categorizationForm.get("IsActivelyDriven").value);
    console.log(this.categorizationForm.value.IsActivelyDriven);
    //for newly creating categorization page
    if (this.hasUnsavedChanges || this.categorizationForm.get("CategorizationId").value == 0) {
      this.submit("next");
    }
    //for already created page
    else if (this.categorizationForm.get("CategorizationId").value != null && this.errorObject['Actively driven action?'] == false) {
      this.submit("next");
    }
    else {
      if (!this.nextMove) {
        this.sharedService.onRemoveCategorizationData();
        this.router.navigate(['/nonplc-costsavings']);
      }
      else if (this.categorizationData.MeasureTypeId != 0) {
        if (this.categorizationData.IsPLCRequired == true) {
          this.router.navigate(['/plc-directcost']);
        }
        else if (this.categorizationData.IsPLCRequired == false) {
          this.router.navigate(['/nonplc-costsavings']);
        }
      }
      // else if (this.categorizationForm.get("MeasureTypeId").value != 0) {
      //   if (this.categorizationForm.get("IsPlcrequired").value == 1) {
      //     this.router.navigate(['/plc-directcost']);
      //   }
      //   else if (this.categorizationForm.get("IsPlcrequired").value == 2) {
      //     this.router.navigate(['/nonplc-costsavings']);
      //   }
      // }
    }
  }

  // checks whether the new page or old pages;
  checkIsPLCrequiredChange(objCategorization) {
    if (objCategorization.CategorizationId != 0) {
      this.originalStatus = objCategorization.MeasureStatusId;
      this.orginalStartDate = objCategorization.StartDate;
      this.isPLCrequiredFromDB = objCategorization.IsPLCRequired;
    }
  }

  // check whether the remark fields are changed or not;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }

  bindActuals() {
    if (this.ideaId == null) {
      this.ideaId = 0;
    }
    if (this.ideaId != null) {
      // && parseInt(this.ideaId) > 0) {
      this.catService.getPLCSummaryActualUnitData(parseInt(this.ideaId)).subscribe((data: any) => {
      });
    }
  }

}
