import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FxRatesService {

  constructor(private http: HttpClient) { }
  private urlGetFXRates:string = environment.baseURL + "api/FxRates/GetFxRatesDetails";
  private urlGetMasterCurrencydata:string = environment.baseURL + "api/FxRates/GetFxCurrencyDetails";
  private urlInsertUpdateFXRates:string = environment.baseURL + "api/FxRates/InsertUpdateFxRatesData";

    //This is used to get Actual Month FX-Rates data from Backend
    GetFxRatesDetails(): Observable<any> {    
    return this.http.get<any>(this.urlGetFXRates).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  GetMasterCurrency(): Observable<any> {    
    return this.http.get<any>(this.urlGetMasterCurrencydata).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
