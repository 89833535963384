import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PeriodLockService } from 'src/app/services/period-lock.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { messages } from 'src/app/popUpMessages/messages';


let countryCode;
@Component({
  selector: 'app-period-lock',
  templateUrl: './period-lock.component.html',
  styleUrls: ['./period-lock.component.css']
})
export class PeriodLockComponent implements OnInit {

  public modifiedPeriodLockRows = [];
  public alertErrorBox = [];
  public errorMessage = []
  public roleMasterData = [];
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;

  public showGrid = false;

  submitResponse: any;
  refObject: any;
  receivedData: any;
  headerYears: any = [];
  startYear: any = "";
  monthsArr: any = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep"];
  unsvdParams: boolean = false;
  submittedPage: boolean = false;

  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";

  // inputColumns = ['FiscalYear', 'LockDate', 'LockTime', 'LockStatus'];
  periodLockTableData = [];

  displayColumns: string[];
  displayData: any[];
  showTable: boolean;
  Year = [];
  monthsArray = [];
  lockDateArray = [];
  modifiedLockDateArray = [];
  lockTimeArray = [];
  modifiedLockTimeArray = [];
  lockStatusArray = [];
  modifiedLockStatusArray = [];
  u9DateArray = [];
  modifiedU9DateArray = [];
  u9TimeArray = [];
  modifiedU9TimeArray = [];
  fiscalYearArr: any = [];
  reportingPeriodArr: any =[];

  constructor(
    private periodLockService: PeriodLockService,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private toast: ToasterService
  ) { this.frameworkComponents = { doublingEditor: DoublingEditorComponent }; }

  ngOnInit(): void {

    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.updateYearDropdown();
          this.disableBusinessLineDropdown = true;
        }
        this.showGrid = true;
      }
    )

    this.adminSubscription = this.sharedService.periodLock.subscribe(() => {
      this.submit();
    })
    countryCode = this.localStorageService.get('countryCode');
  }

  updateBusinessLine() {
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.updateYearDropdown();
    this.bindPeriodLockData();
  }

  updateYearDropdown() {
    this.startYear = "";
    if (this.selectBusinessLine != "") {
      this.startYear = 2021;
      for (let val = 0; val < 20; val++) {
        this.headerYears.push(this.startYear + val)
      }
      this.bindPeriodLockData();
      this.showTable = true;
    }
    else {
      this.startYear = "";
      this.headerYears = [];
      this.showGrid = true;
      this.showTable = false;
    }
  }

  async updatePeriodLockGrid() {
    await this.checkUnSavedChanges();
    this.bindPeriodLockData();
  }

  async checkUnSavedChanges() {
    if (this.unsvdParams) {
      let changeMessage = "There are unsaved changes \n Do You want to save the changes ?";
      const dialogRef = this.dialog.open(DialogComponent, { data: { message: changeMessage, okBtn: "Yes", cancelBtn: "No" } })

      await dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.submit('true');
        }
        else {
          this.onCancel();
        }
      })
    }
  }

  bindPeriodLockData() {
    this.showGrid = false;
    this.periodLockService.getPeriodLockData(Number(this.selectBusinessArea), Number(this.selectBusinessLine), Number(this.startYear)).subscribe((data) => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedData = data.Data;
        if (this.receivedData != "") {
          this.resetTableData();
          this.periodLockTableData = this.receivedData;
          this.getTableDataArray(this.periodLockTableData);
          this.showGrid = true;
          this.showTable = true;
        }
        else {
          this.showGrid = true;
          this.loadPeriodLockTable();
          return;
        }
      }
    });
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }



  modifiedLockDateData(event, index) {
    this.unsvdParams = true;
    if (this.modifiedLockDateArray.length == 0) {
      this.modifiedLockDateArray = [...this.lockDateArray];
    }
    let dbDate = event.value;
    let modifiedDate = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
    this.modifiedLockDateArray[index] = modifiedDate;
    // if(modifiedDate < this.lockDateArray[index]){
    //   this.openAlertDialog([{ [serverMessage.VAMPopUpMessage]: serverMessage.warning }]);
    // }
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
    this.unsvdParams = true;
  }

  modifiedLockTimeData(index) {
    let htmlElement = document.getElementById('time' + index) as HTMLInputElement;
    const timeValue = htmlElement.value;
    this.unsvdParams = true;
    if (this.modifiedLockTimeArray.length == 0) {
      this.modifiedLockTimeArray = [...this.lockTimeArray]
    }
    this.modifiedLockTimeArray[index] = timeValue;
    // console.log(timeValue);
    // console.log(this.lockTimeArray[index]);
    // if(timeValue < this.lockTimeArray[index]){
    //   this.openAlertDialog([{ [serverMessage.VAMPopUpMessage]: serverMessage.warning }]);
    // }
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
  }

  modifiedLockStatusData(event: any, index: number) {
    this.unsvdParams = true;
    if (this.modifiedLockStatusArray.length == 0) {
      this.modifiedLockStatusArray = [...this.lockStatusArray];
    }
    this.modifiedLockStatusArray[index] = event.checked;

    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
  }

  modifiedU9DateData(event, index) {
    this.unsvdParams = true;
    if (this.modifiedU9DateArray.length == 0) {
      this.modifiedU9DateArray = [...this.u9DateArray];
    }
    let dbDate = event.value;
    let modifiedDate = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
    this.modifiedU9DateArray[index] = modifiedDate;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }

    this.unSavedChanges.emit(valToEmit);
  }

  modifiedU9TimeData(index) {
    let htmlElement = document.getElementById('time' + index) as HTMLInputElement;
    const timeValue = htmlElement.value;
    this.unsvdParams = true;
    if (this.modifiedU9TimeArray.length == 0) {
      this.modifiedU9TimeArray = [...this.u9TimeArray]
    }
    this.modifiedU9TimeArray[index] = timeValue;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
  }

  getTableDataArray(recievedData) {
    recievedData.forEach(obj => {
      this.Year.push(obj.Year);
      this.fiscalYearArr.push(obj.FiscalYear);
      this.reportingPeriodArr.push(obj.ReportingPeriod);
      this.monthsArray.push(obj.Month);
      this.lockDateArray.push(obj.LockDate);
      this.lockTimeArray.push(String(obj.LockTime));
      this.lockStatusArray.push(obj.LockStatus == 1 ? true : false);
      this.u9DateArray.push(obj.U9Date);
      this.u9TimeArray.push(String(obj.U9Time));
    })
  }

  loadPeriodLockTable() {
    this.resetTableData();
    let date: string = new Date("2999-01-01").toISOString();
    this.monthsArr.forEach((element, index) => {
      this.periodLockTableData.push({
        PeriodLockId: 0,
        Year: (index < 3) ? (this.startYear - 1) : this.startYear,
        FiscalYear: this.startYear,
        ReportingPeriod: index + 1,
        Month: (index < 3) ? (element + String(this.startYear - 1).slice(-2)) : (element + String(this.startYear).slice(-2)),
        LockDate: date,
        LockTime: String("12:00"),
        LockStatus: 0,
        U9Date: date,
        U9Time: String("12:00"),
        CreatedBy: this.loggedInUser.UserId,
        CreatedOn: new Date().toISOString(),
        ModifiedBy: null,
        ModifiedOn: null,
        BusinessAreaId: this.selectBusinessArea,
        BusinessLineId: this.selectBusinessLine,
      })
    })
    this.getTableDataArray(this.periodLockTableData);
  }

  resetTableData() {
    this.periodLockTableData = [];
    this.Year = [];
    this.fiscalYearArr = [];
    this.reportingPeriodArr = [];
    this.monthsArray = [];
    this.lockDateArray = [];
    this.lockStatusArray = [];
    this.lockTimeArray = [];
    this.u9DateArray = [];
    this.u9TimeArray = [];
    this.modifiedLockDateArray = [];
    this.modifiedLockStatusArray = [];
    this.modifiedLockTimeArray = [];
    this.modifiedU9DateArray = [];
    this.modifiedU9TimeArray = [];
    this.modifiedPeriodLockRows = [];
    this.unsvdParams = false;
  }

  async submit(event?: any) {
    await this.sendData();
    let response = null;
    let postData: any;
    if (this.modifiedPeriodLockRows.length != 0) {
      this.modifiedPeriodLockRows = [];
      postData = this.refObject;
      response = this.periodLockService.updateData(postData).toPromise()
        .then(res => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unSavedChanges.emit(valToEmit);
              this.submittedPage = true;
              this.unsvdAdminChanges = false;
              this.toast.notify(serverMessage.dataSaveMessage, "success");

              this.resetTableData();
              this.unsvdParams = false;
              this.bindPeriodLockData();
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              return false;
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI           
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          return false;
        })
    }
    else {
      // changes;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unsvdAdminChanges = false;
      this.unSavedChanges.emit(valToEmit);
      // end;
      return true;
    }
    return response;
  }

  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unsvdParams = false;
    this.resetTableData();
    this.unSavedChanges.emit(valToEmit);
    this.bindPeriodLockData();
  }

  async sendData() {
    let backDateSelected = false;
    let futureDateSelected = false;
    if (this.modifiedLockDateArray.length != 0 || this.modifiedLockStatusArray.length != 0 || this.modifiedLockTimeArray.length != 0 || this.modifiedU9DateArray.length != 0 || this.modifiedU9TimeArray.length != 0) {
      this.modifiedPeriodLockRows = this.periodLockTableData;
      if (this.modifiedLockDateArray.length != 0) {
        this.modifiedPeriodLockRows.forEach((row, index) => {
          if (row.PeriodLockId != 0 && this.lockDateArray[index] != this.modifiedLockDateArray[index]) {
            row.LockDate = this.modifiedLockDateArray[index];
            row.ModifiedBy = this.loggedInUser.UserId;
            row.ModifiedOn = new Date().toISOString();
          }
          else if (row.PeriodLockId == 0 && this.lockDateArray[index] != this.modifiedLockDateArray[index]) {
            row.LockDate = this.modifiedLockDateArray[index];
          }
        })
      }
      if (this.modifiedLockTimeArray.length != 0) {
        this.modifiedPeriodLockRows.forEach((row, index) => {
          if (row.PeriodLockId != 0 && this.lockTimeArray[index] != this.modifiedLockTimeArray[index]) {
            row.LockTime = this.modifiedLockTimeArray[index];
            row.ModifiedBy = this.loggedInUser.UserId;
            row.ModifiedOn = new Date().toISOString();
          }
          else if (row.PeriodLockId == 0 && this.lockTimeArray[index] != this.modifiedLockTimeArray[index]) {
            row.LockTime = this.modifiedLockTimeArray[index];
          }
        })
      }
      if (this.modifiedLockStatusArray.length != 0) {
        this.modifiedPeriodLockRows.forEach((row, index) => {
          if (row.PeriodLockId != 0 && this.lockStatusArray[index] != this.modifiedLockStatusArray[index]) {
            row.LockStatus = this.modifiedLockStatusArray[index] ? 1 : 0;
            row.ModifiedBy = this.loggedInUser.UserId;
            row.ModifiedOn = new Date().toISOString();
          }
          else if (row.PeriodLockId == 0 && this.lockStatusArray[index] != this.modifiedLockStatusArray[index]) {
            row.LockStatus = this.modifiedLockStatusArray[index] ? 1 : 0;
          }
        })
      }
      if (this.modifiedU9DateArray.length != 0) {
        this.modifiedPeriodLockRows.forEach((row, index) => {
          if (row.PeriodLockId != 0 && this.u9DateArray[index] != this.modifiedU9DateArray[index]) {
            row.U9Date = this.modifiedU9DateArray[index];
            row.ModifiedBy = this.loggedInUser.UserId;
            row.ModifiedOn = new Date().toISOString();
          }
          else if (row.PeriodLockId == 0 && this.u9DateArray[index] != this.modifiedU9DateArray[index]) {
            row.U9Date = this.modifiedU9DateArray[index];
          }
        })
      }
      if (this.modifiedU9TimeArray.length != 0) {
        this.modifiedPeriodLockRows.forEach((row, index) => {
          if (row.PeriodLockId != 0 && this.u9TimeArray[index] != this.modifiedU9TimeArray[index]) {
            row.U9Time = this.modifiedU9TimeArray[index];
            row.ModifiedBy = this.loggedInUser.UserId;
            row.ModifiedOn = new Date().toISOString();
          }
          else if (row.PeriodLockId == 0 && this.u9TimeArray[index] != this.modifiedU9TimeArray[index]) {
            row.U9Time = this.modifiedU9TimeArray[index];
          }
        })
      }
    }
    this.refObject = this.modifiedPeriodLockRows;
    this.modifiedPeriodLockRows.forEach((row, index) => {
      if (row.PeriodLockId != 0) {
        if (row.LockDate != this.lockDateArray[index] && row.LockDate < new Date().toISOString()) {
          if (row.LockTime < this.lockTimeArray[index]) {
            if (row.LockStatus == true && this.lockStatusArray[index] == true) {
              backDateSelected = true;
            }
          }
          else if (row.LockDate != this.lockDateArray[index] && row.LockDate < new Date().toISOString()) {
            if (row.LockStatus == true && this.lockStatusArray[index] == true) {
              backDateSelected = true;
            }
          }
        }
      }
    })
    if (backDateSelected) {
        const dialogRef = this.dialog.open(DialogComponent, { data: { message: messages.PeriodLock.BackDatedPopUpMessage, okBtn: "Yes", cancelBtn: "No" } })

        await dialogRef.afterClosed().toPromise().then(result => {
          if (result) {
            // this.submit('true');
          }
          else {
            this.onCancel();
          }
        })
      }
      this.modifiedPeriodLockRows.forEach((row, index) => {
        if (row.PeriodLockId != 0) {
          if (row.LockDate != this.lockDateArray[index] && row.LockDate > new Date().toISOString()) {
            if (row.LockTime > this.lockTimeArray[index]) {
              if (row.LockStatus == false && this.lockStatusArray[index] == true) {
                futureDateSelected = true;
              }
            }
            else if (row.LockDate != this.lockDateArray[index] && row.LockDate > new Date().toISOString()) {
              if (row.LockStatus == false && this.lockStatusArray[index] == true) {
                futureDateSelected = true;
              }
            }
          }
        }
      })
      if (futureDateSelected) {
          const dialogRef = this.dialog.open(DialogComponent, { data: { message: messages.PeriodLock.BackDatedPopUpMessage, okBtn: "Yes", cancelBtn: "No" } })
  
          await dialogRef.afterClosed().toPromise().then(result => {
            if (result) {
              // this.submit('true');
            }
            else {
              this.onCancel();
            }
          })
        }
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }

}






