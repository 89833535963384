import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DirectCostService {
  readonly baseUrl=environment.baseURL;
  public urlGet =this.baseUrl + "api/DirectCost/GetDirectCostDetailById";
  // this.baseUrl+"api/ComplexityRisk/GetComplexityRiskData" + '?ideaId=' + ideaId + '&&solutionId=' + solutionId
  public urlPost = this.baseUrl+ "api/DirectCost/InsertUpdateDirectCost";
  constructor(private http: HttpClient) { }
  getAllData(ideaId, solutionId, userId) {
   return this.http.get( this.urlGet + '?ideaId=' + ideaId + '&&solutionId=' + solutionId + '&userId=' + userId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  postAllData(data){
    
   return  this.http.post(this.urlPost,data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
