import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MDunitsService {

  constructor(private http: HttpClient) { }
  private urlGetMdUnits: string = environment.baseURL + "api/MDUnit/GetMDUnitDetails";
  private urlUpdate: string = environment.baseURL+ "api/MDUnit/InsertUpdateMDUnit";
  
  //This is used to get data from backend 
  getAllMdUnits(businessAreaId?: number, businessLineId?: number): Observable<any> {
    return this.http.get<any>(this.urlGetMdUnits+"?businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //  This is used to update new any Data in Database;
  updateData(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    return this.http.post<any>(this.urlUpdate,data,httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
