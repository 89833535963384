import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICellRendererParams } from 'ag-grid-community';
import { PeoplePickerService } from '../services/people-picker.service';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-contact-dropdown',
  templateUrl: './contact-dropdown.component.html',
  styleUrls: ['./contact-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ContactDropdownComponent implements OnInit {
  value: any;
  gridApi: any;
  rowIndex: number;
  dataSource = [];
  userData: string[] = [];
  showData: any;
  contactData: string[] = [];
  //  responsible input search field;
  searchInput = new FormControl();
  // responsible people picker form variable;
  multiSelectPeoplePickr: FormGroup;
  searchedDataMatch: any = [];

  @Output() sendSavedData = new EventEmitter<any>();
  @Output() userSendData = new EventEmitter<any>();

  // used for the autofocus in the input field for the people picker;
  @ViewChild('myInput') myInputField: ElementRef;

  constructor(
    private peoplePickerService: PeoplePickerService,
    private sharedService: SharedServiceService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    let dataFromField: any;
    this.rowIndex = params.node.rowIndex;
    this.gridApi = params.api;
    // This will takes email from the gird email field and, search with graph Api
    // and gives back the detail of the user based on matching employees;
    dataFromField = params.data.Email;
    if (dataFromField != null) {
      dataFromField = dataFromField.split(';');
      for (let i of dataFromField) {
        let val: any;
        // graph Api call;
        this.peoplePickerService.getUsers(i).subscribe(
          (res: any) => {
            if (res) {
              val = res.value.map(user => {
                return {
                  displayName: user.displayName,
                  firstName: user.givenName,
                  lastName: user.surname,
                  email: user.mail,
                  department: user.department,
                  checked: false
                }
              })
              this.searchedDataMatch = val;
              //calling function to provide the seleced matched data with the dropdown;
              this.getDetails(val[0]);
            }
          }
        )
      }
    }
  }

  // this send value to the Grid from which it is called;
  getValue() {
    let emailArray = [];
    this.value = this.multiSelectPeoplePickr.get("multiSelectPeoplePickr").value;
    if (this.value && this.value.length != 0) {
      this.value.forEach(obj => {
        emailArray.push(obj.email)
        let name = obj.displayName + "[" + obj.department + "]";
        this.contactData.push(name);
      })

      let emailsString = emailArray.join(";");
      this.gridApi.forEachNode(node => {
        if (this.rowIndex == node.rowIndex) {
          node.data.Email = emailsString;
        }
      })
      // sending the updated gridApi to corresponding people picker page;
      this.sharedService.peoplePickerGridApi.emit(this.gridApi);
      return this.contactData.join("; ");
    }
    else {
      this.gridApi.forEachNode(node => {
        if (this.rowIndex == node.rowIndex) {
          node.data.Email = null;
        }
      })

      this.sharedService.peoplePickerGridApi.emit(this.gridApi);
      return null;
    }
  }

  ngOnInit(): void {
    this.multiSelectPeoplePickr = this.formBuilder.group({
      multiSelectPeoplePickr: [null, Validators.required]
    })
  }

  // call the graph Api and return the matched value;
  fetchUserData(input, bind) {
    let searchInput: string = "";
    // bind == false; it means function is called from the HTML;
    // bind == true; it means function is called from the Class;
    if (!bind) {
      searchInput = input.target.value.toLowerCase();
    } else {
      searchInput = input.toLowerCase();
    }
    if (searchInput.length >= 4) {
      let val: any
      this.peoplePickerService.getUsers(searchInput).subscribe(
        (res: any) => {
          if (res) {
            val = res.value.map(user => {
              return {

                displayName: user.displayName,
                firstName: user.givenName,
                lastName: user.surname,
                email: user.mail,
                department: user.department,
                checked: false
              }
            })
            this.searchedDataMatch = val;
          }
        }
      )
    } else {
      this.searchedDataMatch = this.dataSource;
    }
  }

  // assign value to dropdown and update the selected list;
  getDetails(user) {
    this.userData = [];
    this.showData = null;
    this.dataSource.forEach((data) => {
      if (data.email == user.email) {
        user.checked = true;
      }
    })
    user.checked = !user.checked;
    if (user.checked) {
      this.dataSource.push(user)
      this.dataSource.forEach(obj => {
        let name = obj.displayName + "[" + obj.department + "]";
        this.userData.push(name);
      })
      this.showData = this.userData.join(';');
    }
    else {
      // To remove the user from the selected list if it is unselected
      this.dataSource = this.dataSource.filter((data: any) => data.email != user.email)
      this.dataSource.forEach(obj => {
        let name = obj.displayName + "[" + obj.department + "]";
        this.userData.push(name);
      });

      this.showData = this.userData.join(';');
    }
    this.searchedDataMatch = this.dataSource;
    this.multiSelectPeoplePickr.get('multiSelectPeoplePickr').setValue(this.dataSource)
  }

  // It will clear the search input field once the dropdown is closed;
  clearSearch() {
    this.searchInput.setValue("");
    this.searchedDataMatch = this.dataSource;
  }

  // To move the cursor automatically to the input field;
  autofocus() {
    this.cd.detectChanges()
    // Giving the focus in the input field and making it blink;
    setInterval(() => this.myInputField?.nativeElement.focus(), 500);
    // making the focus at the input field at it's opening;
    this.myInputField?.nativeElement.focus();
  }

  // To make two user same if their email is same;
  compare(o1: any, o2: any) {
    return o1 && o2 && o1.email === o2.email;
  }
}


