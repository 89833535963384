import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { messages } from 'src/app/popUpMessages/messages';

@Component({
  selector: 'app-add-delete',
  templateUrl: './add-delete.component.html'
  // styleUrls: ['./add-delete.component.css']
})
export class AddDeleteComponent implements ICellRendererAngularComp {
  constructor(public dialog: MatDialog, private sharedService: SharedServiceService) { }

  public params: any;
  public gridApi: any;
  public addVisible = true;
  public delVisible = true;
  public newItemCount: any;
  public message: any;
  public countRow: any;
  public lblOneTimeCosts = "One time costs/ Process costs";
  public lblRecurringExpenses = "Recurring Expenses";

  agInit(params: any): void {
    this.params = params;

    if (this.params.node.IsEditable || (this.params.data?.IsEditable)) {
      if (params.node.leafGroup === true) {
        this.addVisible = true;
        this.delVisible = false;
      } else {
        if (params.node.id != "rowGroupFooter_ROOT_NODE_ID") {
          this.addVisible = false;
          this.delVisible = true;
        } else {
          this.addVisible = false;
          this.delVisible = false;
        }
      }
    } 
    else {
      this.addVisible = false;
      this.delVisible = false;
    }

  }

  refresh(): boolean {
    return false;
  }

  public delData(params: any) {
    this.gridApi = params.api;
    this.params = params;
    let rowDataItem = null;

    let dialogRef = this.dialog.open(DialogComponent, { data: { message: "Do you want to delete it?", okBtn: "Yes", cancelBtn: "No" } }) // To warn the user before deleting data

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridApi.forEachNode((node: any) => {
          /* If the row number of particular field is 1, after clicking delete button 
          it will replace the existing row with a new empty row */
          if (params.node.data.agGridId == "refCostSavingGrid") {
            if (node.allChildrenCount == 1 && node.key.toLowerCase() == this.params.data.refCostHeader.toLowerCase()) {

              if (node.key.toLowerCase() == this.lblOneTimeCosts.toLowerCase()) {
                rowDataItem = ({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.lblOneTimeCosts, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null });
              } else if (node.key.toLowerCase() == this.lblRecurringExpenses.toLowerCase()) {
                rowDataItem = ({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.lblRecurringExpenses, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null });
              }

              this.gridApi.applyTransaction({ add: [rowDataItem] });
              this.gridApi.applyTransaction({ remove: [this.params.data] });
              this.sharedService.onCostSaving(this.params.data);
            }
            // To delete the row of particular field if the no. of row is >1
            else if (node.allChildrenCount > 1 && node.key.toLowerCase() == this.params.data.refCostHeader.toLowerCase()) {
              this.gridApi.applyTransaction({ remove: [this.params.data] });
              this.sharedService.onCostSaving(this.params.data);
            }
          } else {

            if (node.allChildrenCount == 1 && node.key.toLowerCase() == this.params.data.tgtCostHeader.toLowerCase()) {

              if (node.key.toLowerCase() == this.lblOneTimeCosts.toLowerCase()) {
                rowDataItem = ({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.lblOneTimeCosts, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null });
              } else if (node.key.toLowerCase() == this.lblRecurringExpenses.toLowerCase()) {
                rowDataItem = ({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.lblRecurringExpenses, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null });
              }

              this.gridApi.applyTransaction({ add: [rowDataItem] });
              this.gridApi.applyTransaction({ remove: [this.params.data] });
              this.sharedService.onCostSaving(this.params.data);
            }
            // To delete the row of particular field if the no. of row is >1
            else if (node.allChildrenCount > 1 && node.key.toLowerCase() == this.params.data.tgtCostHeader.toLowerCase()) {
              this.gridApi.applyTransaction({ remove: [this.params.data] });
              this.sharedService.onCostSaving(this.params.data);
            }
          }
        })
      }
    });
  }
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error });
  }

  public addData(params: any) {
    this.gridApi = params.api;
    params.node.setExpanded(true);
    let rowDataItem = null;
    if (params.node.field == "refCostHeader") {
      if (params.node.key.toLowerCase() == this.lblOneTimeCosts.toLowerCase()) {
        rowDataItem = ({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.lblOneTimeCosts, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsEditable: params.node.IsEditable });
      } else if (params.node.key.toLowerCase() == this.lblRecurringExpenses.toLowerCase()) {
        rowDataItem = ({ agGridId: "refCostSavingGrid", CostSavingDetailId: null, refCostHeader: this.lblRecurringExpenses, refCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsEditable: params.node.IsEditable });
      }
    } else {
      if (params.node.key.toLowerCase() == this.lblOneTimeCosts.toLowerCase()) {
        rowDataItem = ({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.lblOneTimeCosts, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsEditable: params.node.IsEditable });
      } else if (params.node.key.toLowerCase() == this.lblRecurringExpenses.toLowerCase()) {
        rowDataItem = ({ agGridId: "tgtCostSavingGrid", CostSavingDetailId: null, tgtCostHeader: this.lblRecurringExpenses, tgtCostTitle: 'Add title', CostType: 'Please Select', LabourRate: null, Effort: null, TotalEffort: null, CostsUnit: null, Total: null, AffectedUnits: null, TotalCostAcc: null, IsEditable: params.node.IsEditable });
      }
    }

    this.gridApi.forEachNode(node => {
      if (node.data != undefined) {
        if ((node.data.refCostHeader == this.lblRecurringExpenses && params.node.key == this.lblRecurringExpenses && node.lastChild) || (node.data.refCostHeader == this.lblOneTimeCosts && params.node.key == this.lblOneTimeCosts && node.lastChild)) {
          this.countRow = node
        }
        // else if (node.data.refCostHeader == this.lblOneTimeCosts && params.node.key == this.lblOneTimeCosts && node.lastChild) {
        //   this.countRow = node
        // }
        if ((node.data.tgtCostHeader == this.lblRecurringExpenses && params.node.key == this.lblRecurringExpenses && node.lastChild) || (node.data.tgtCostHeader == this.lblOneTimeCosts && params.node.key == this.lblOneTimeCosts && node.lastChild)) {
          this.countRow = node
        }
        // else if (node.data.tgtCostHeader == this.lblOneTimeCosts && params.node.key == this.lblOneTimeCosts && node.lastChild) {
        //   this.countRow = node
        // }
      }
    })


    if (this.countRow != undefined) {
      let regex = new RegExp(/\^s*$/);
      let error = [];
      let tarError = [];
      if (this.countRow.data.refCostTitle !== undefined && (regex.test(this.countRow.data.refCostTitle) ||
        this.countRow.data.refCostTitle == "" || this.countRow.data.refCostTitle.toLowerCase() == "Add Title".toLowerCase())) {
        error.push({ "Title": messages.costAndSaving.fillRefCostTypeValidationMessage });
      }
      if (this.countRow.data.tgtCostTitle !== undefined && (regex.test(this.countRow.data.tgtCostTitle) ||
        this.countRow.data.tgtCostTitle == "" || this.countRow.data.tgtCostTitle.toLowerCase() == "Add Title".toLowerCase())) {
        tarError.push({ "Title": "Please fill the following field" });
      }
      if ((this.countRow.data.AffectedUnits == "" || this.countRow.data.AffectedUnits == null) && this.countRow.data.AffectedUnits != 0
        && this.countRow.data.tgtCostHeader == 'Recurring Expenses') {
        tarError.push({ "Affected Units": "Please fill the following field" })
      }
      if (error.length > 0) {
        this.openAlertDialog(error);
      }
      else if (tarError.length > 0) {
        this.openAlertDialog(tarError);
      }
      else {
        this.gridApi.forEachNode((node: any) => {
          if (node.allChildrenCount != null) {
            // To check the last row of the particular field
            let lastNode = node.childrenAfterGroup[node.allChildrenCount - 1];

            if (params.node.field == "refCostHeader") {
              if (lastNode.data.refCostHeader.toLowerCase() == params.node.key.toLowerCase()) {
                this.gridApi.applyTransaction({ add: [rowDataItem] });
              }
            } else {
              if (lastNode.data.tgtCostHeader.toLowerCase() == params.node.key.toLowerCase()) {
                this.gridApi.applyTransaction({ add: [rowDataItem] });
              }
            }
          }
        })
      }
    }
  }
}
