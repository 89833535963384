<!--Loading Spinner -->
<app-progress-spinner *ngIf="showSpinner()"></app-progress-spinner>

<div class="container" style="margin-bottom: 20px">
  <div>
    <div class="label-header">
      <label class="label-headercolor">OPW Productivity (</label>
      <label class="label-headercolor" style="font-size: 0.55rem !important">
        If total is not divisable by 12 w/o remainder, 11 months will be
        linearly populated; remainder in 12. month; please change as
        needed)</label
      >
    </div>
    <!-- Ag-Grid -->
    <ag-grid-angular
      *ngIf="showOPWGrid"
      id="plcOPWGrid"
      style="width: 100%"
      class="ag-theme-alpine"
      [headerHeight]="headerHeight"
      [rowHeight]="rowHeight"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [domLayout]="domLayout"
      [frameworkComponents]="frameworkComponents"
      [rowClassRules]="rowClassRules"
      [headerHeight]="headerHeightRef"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
      (cellValueChanged)="onCellValueChangedPlcSummary($event)"
      (gridSizeChanged)="onGridSizeChanged($event)"
    >
    </ag-grid-angular>
  </div>

  <div style="margin-top: 10px">
    <div class="label-header">
      <label class="label-headercolor"
        >Product Lifecycle Cost Development - Chart</label
      >
    </div>
    <!-- Ag-Grid Chart -->
    <div>
      <ag-charts-angular
        [options]="productCostOptions"
        *ngIf="showProductGraph"
      >
      </ag-charts-angular>
    </div>
    <!-- Ag-Grid Chart -->
    <div>
      <ag-charts-angular [options]="changeinPlcOptions" *ngIf="showPLCGraph">
      </ag-charts-angular>
    </div>
  </div>
  <div>
    <div class="label-header">
      <label class="label-headercolor"
        >Change in Product Lifecycle Costs vs. Reference Scenario</label
      >
    </div>
    <!-- Ag-Grid -->
    <ag-grid-angular
      *ngIf="showPLCGrid"
      style="width: 100%"
      class="ag-theme-alpine"
      [headerHeight]="PlcHeaderHeight"
      [rowHeight]="PlcRowHeight"
      [rowData]="ChangeInPlcRowData"
      [columnDefs]="ChangeInPlcColumnDefs"
      [defaultColDef]="defaultPlcColDef"
      [domLayout]="domLayout"
      (gridReady)="onChangeInPlcGridReady($event)"
      (gridSizeChanged)="onPlcGridSizeChanged($event)"
    >
    </ag-grid-angular>
  </div>
  <!-- Buttons -->
  <div class="row">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle">
        <button class="button button--small" (click)="onClickCancel()">
          Cancel & Exit</button
        >&nbsp;
        <button
          class="button button--small"
          [disabled]="submitted"
          (click)="onClickBack()"
        >
          Back</button
        >&nbsp;
        <button
          class="button button--small"
          [disabled]="submitted"
          (click)="onClickNext()"
        >
          Next</button
        >&nbsp;
        <button
          class="button button--small"
          type="submit"
          [disabled]="submitted || !isEditable"
          (click)="submit($event)"
        >
          Save & Exit
        </button>
      </div>
    </div>
  </div>
</div>
