<div style="width: 500px">
  <!-- display 1st error message -->
  <mat-dialog-content style="padding-bottom: 6px"><b class="error">{{ errorMessage1[0] }}</b>
  </mat-dialog-content>
  <ul style="margin-bottom: 0px;">
    <div *ngFor="let item of field1" class="error">
      <li [ngClass]="{ noBullet: noBulletCheck, bullet: bulletCheck }">
        {{ item }}
      </li>
    </div>
  </ul>
  <!-- display second error message -->
  <mat-dialog-content style="padding-top: 6px"><b class="error">{{ errorMessage2[0] }}</b>
  </mat-dialog-content>
  <ul style="margin-bottom: 0px;">
    <div *ngFor="let item of field2" class="error">
      <li [ngClass]="{ noBullet: noBulletCheck, bullet: bulletCheck }">
        {{ item }}
      </li>
    </div>
  </ul>
  <mat-dialog-actions align="center">
    <!-- used in normal warning dialog box where on button click no function get called; -->
    <button *ngIf="showOkCancel" mat-button mat-dialog-close class="cancel-button">
      Ok
    </button>
    <!-- used in dialog box where on button click "confirmation-YES" function get called; -->
    <button *ngIf="showOk" (click)="callEventEmitrOk()" mat-button mat-dialog-close class="cancel-button">
      Ok
    </button>
    <!-- used in dialog box where on button click "confirmation-NO" function get called; -->
    <button *ngIf="showCancel" (click)="callEventEmitrCancel()" mat-button mat-dialog-close class="cancel-button">
      Cancel
    </button>
  </mat-dialog-actions>
</div>