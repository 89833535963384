import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
import { CategorizationService } from './categorization.service';
import { HelpService } from './help.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  data: any;
  flatMenu: any;
  menu: BehaviorSubject<any> = new BehaviorSubject([])
  flatdata = [];

  treeData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  backMenuId: number = 0;
  currentMenuId: number = 0;
  nextMenuId: number = 0;
  isRouted: boolean = false;
  helpId: number = 0;

  isPLCSummary_RefTar_NextBtnVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPLCSummary_OptA_NextBtnVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPLCSummary_OptB_NextBtnVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private url: string = environment.baseURL + "api/Idea/GetMenuForIdea";
  private url1: any = environment.baseURL + "api/Master/GetAllData?masterTypeId=" + EnumMasterType.Menu;

  constructor(private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private helpService: HelpService,
    private categorizationService: CategorizationService
  ) { }

  getMenuData(ideaId): Observable<any> {
    if (ideaId == null) { ideaId = 0 }
    return this.http.get(this.url + "?ideaId=" + ideaId)
  }
  getUpdateMenuData(routeUrl?) {
    let ideaId = this.localStorage.get("ideaId")
    this.getMenuData(ideaId).subscribe(data => {
      this.data = data.Data
      this.flatdata = []
      this.getFlatData(this.data);
      this.checkNextBtnInPlcSummary(this.flatdata)
      this.menu.next(this.flatdata)
      this.treeData.next(this.data)
    })
  }
  getAllMenuData(): Observable<any> {
    return this.http.get(this.url1)
  }
  getFlatData(dataSource: any) {
    dataSource.forEach((node: any) => {
      if (!node.Children) {
        this.flatdata.push(node)
      }
      else if (node.Children) {
        this.flatdata.push(node);
        this.getFlatData(node.Children)
      }
    })
  }
  getHelpIdForMenu(route) {
    this.getAllMenuData().subscribe(
      (res) => {
        this.flatMenu = res.Data
        this.flatMenu.forEach(element => {
          if ('/' + element.Route == route) {
            this.helpId = element.HelpId
          }
        });
        this.helpService.getDatabyId(this.helpId).subscribe(
          (res: any) => {
            if (res.Data) {
              this.helpService.emitHelpId(res.Data.HelpText)
            }
          }
        )
      }
    )
  }
  onClickNextAndBack(btn, menuid?) {
    this.isRouted = false
    this.menu.subscribe(res => {
      if (res != [] && !this.isRouted) {
        if (menuid == undefined) {
          for (let element of res) {
            if (this.router.url == "/" + element.Route) {
              this.currentMenuId = element.MenuId;
              if (btn == "back") { break; }
            }
          }
        }
        else {
          this.nextMenuId = menuid + 1;
          this.backMenuId = menuid - 1
        }
        if (this.currentMenuId != 0) {
          if (btn == "back") {
            if (menuid == undefined) {
              this.backMenuId = this.currentMenuId - 1;
            }
            res.forEach(e1 => {
              if (e1.MenuId == this.backMenuId) {
                if (!e1.IsDisplay) {
                  this.onClickNextAndBack("back", this.backMenuId)
                }
                else {
                  let backroute = "/" + e1.Route;
                  this.isRouted = true
                  this.router.navigate([backroute])
                }
              }
            });
          }
          else if (btn == "next") {
            if (menuid == undefined) {
              this.nextMenuId = this.currentMenuId + 1;
            }
            res.forEach(e2 => {
              if (e2.MenuId == this.nextMenuId) {
                if (!e2.IsDisplay) {
                  this.onClickNextAndBack("next", this.nextMenuId)
                }
                else {
                  let nextroute = "/" + e2.Route;
                  this.isRouted = true
                  this.router.navigate([nextroute])
                }
              }
            });
          }
        }
      }
    })
  }
  checkNextBtnInPlcSummary(menu){
    let optionsAvailable=null;
    menu.forEach(node=>{
      if (node.MenuId == 50 && node.IsDisplay) {
        optionsAvailable=true
        this.isPLCSummary_RefTar_NextBtnVisible.next(true)
        this.isPLCSummary_OptA_NextBtnVisible.next(false)
        this.isPLCSummary_OptB_NextBtnVisible.next(false)
      }
      if (node.MenuId == 51 && node.IsDisplay) {
        optionsAvailable=true
        this.isPLCSummary_RefTar_NextBtnVisible.next(true)
        this.isPLCSummary_OptA_NextBtnVisible.next(true)
        this.isPLCSummary_OptB_NextBtnVisible.next(false)
      }
      if (node.MenuId == 52 && node.IsDisplay) {
        optionsAvailable=true;
        this.isPLCSummary_RefTar_NextBtnVisible.next(true)
        this.isPLCSummary_OptA_NextBtnVisible.next(true)
        this.isPLCSummary_OptB_NextBtnVisible.next(true)
      }
      
    })
    if(optionsAvailable==null){
      this.isPLCSummary_RefTar_NextBtnVisible.next(false)
    }

  }
}
