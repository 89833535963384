<main>
  <app-progress-spinner *ngIf="!user"></app-progress-spinner>
<div *ngIf="user" class="container" style="padding:0px;overflow: hidden;width:100%;height: 100%;">
  <div class="homeImage" style="position: fixed;top: 70px;bottom: 0px;right: 0px;left: 0px;">
    <div  class="row" style="cursor: pointer; margin-top: 30px;">
      <div (click)="gotoCreateIdea()" class="col-md-2 col-lg-2" style="height:65px;width:100px;background-color:white;margin-left:42px">
        <button   title="Create Idea" style="padding:10px">
          <img alt="ideaIcon" src="../assets/icons/idea.svg" width="50" height="50" style="padding-bottom: 6px;">
          <span style="margin-left:10px;font-weight: bold;">Create Idea</span>
        </button>
      </div>
      <div (click)="gotoManageIdea()" class="col-md-2 col-lg-2" style="height:65px;width:100px;background-color:white;margin-left:5px">
        <button  title="Manage Ideas" style="padding:10px">
          <img alt="createIcon" src="../assets/create.svg" width="50" height="50" style="padding-bottom: 6px;">
          <span style="margin-left:10px;font-weight: bold;">Manage Ideas</span>
        </button>
      </div>
    </div>
    <div class="row" style="position: fixed;bottom: 50px;right: -20px;left: 0;">
      <div class="offset-9 col-md-1 col-lg-1" style="margin-right: -40px;float: right;">
        <button (click)="gotoProcessGuide()" title="Process Guide">
          <img alt="infoIcon" src="../assets/info.svg" width="50" height="50">
          <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Process <br>Guide
          </figcaption>
        </button>
      </div>
      <div class=" col-md-1 col-lg-1" style="margin-right: -30px;">
        <button (click)="gotoLegalTerms()" title="Legal terms">
          <img alt="legalIcon" src="../assets/legal.svg" width="50" height="50">
          <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Legal <br>Terms
          </figcaption>
        </button>
      </div>
      <div class="col-md-1 col-lg-1" style="padding:0px" *ngIf="isMasterDataManagementVisible">
        <button (click)="gotoMasterData()" title="Admin Login">
          <img alt="adminIcon" src="../assets/admin.svg" width="50" height="50">
          <figcaption style="padding:0px;text-align: center;font-weight: bold;color:white"> Admin <br>Login
          </figcaption>
        </button>
      </div>
    </div>
  </div>
</div>
</main>