<app-progress-spinner *ngIf="!showoptBPlcAnalysis"></app-progress-spinner>
<div class="container" style="margin-bottom: 20px;">
  
        <div class="label-header">
          <label class="label-headercolor">Option B </label>
      </div>
     
        <ag-grid-angular style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        [headerHeight]="optBheaderHeight"
        [suppressRowTransform]="true"
        [getRowStyle]="getRowStyle"
        [rowClassRules]="optBrowClassRules"
        [rowHeight]="optBRowHeight"
        [rowStyle]="rowStyle"
        [rowData]="optBRowData"
        [columnDefs]="optBColumnDefs"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupoptBCostColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded"
        [suppressAggFuncInHeader]="true"
        [enableBrowserTooltips]="true"
        [domLayout]="domLayout"
        (gridReady)="onoptBGridReady($event)"
        (gridSizeChanged)="onoptBGridSizeChanged($event)"

        (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
    >
    
    </ag-grid-angular>

    <div class="row">
      <div class="offset-4 col-md-8">
          <div class="buttonStyle">
            <button class="button button--small" (click)="onClickBack()">Back</button>&nbsp;
            <button class="button button--small" *ngIf="isNextBtnVisible" (click)="onClickNext()">Next</button>&nbsp;
           
          </div>
      </div>
  </div>
    </div>